import React from "react"
import QueueInfoItem from "../../QueueInfoItem/QueueInfoItem"
import {
    faClipboardCheck,
    faClipboardList,
    faClock,
    faCommentAltCheck,
    faCommentAltTimes,
    faUser
} from "@fortawesome/pro-light-svg-icons"
import { testId } from "../../../utility/tests/testId"
import { Time } from "../../../utility/common/time"
import { useTranslation } from "react-i18next"
import { useLocation } from "react-router-dom"
import { Queue } from "../../../models/queue"
import { Operator } from "../../../models/operator"
import { actions } from "../../../store/queues/slice"
import { useAppDispatch } from "../../../store/hooks"
import useQueueStatistics from "../utils/useQueueStatistics"

const tNamespace = "queues:card."

type QueueInfoItemsProps = {
    queue: Queue
    queues: Queue[]
    filteredOperators: Operator[]
}

const QueueInfoItems = (props: QueueInfoItemsProps) => {
    const { queue, queues, filteredOperators } = props
    const { t } = useTranslation()
    const location = useLocation()
    const dispatch = useAppDispatch()
    const isQueuesV2 = location.pathname.includes("queuesV2")
    const {
        activeTasksCount,
        awtStatEntries,
        finishDialogsStat,
        freeOperatorsV2,
        freeOperators,
        loadedOperators,
        loadedOperatorsCount,
        operatorsTotal
    } = useQueueStatistics(queue, queues, filteredOperators)

    const handleOpenQueueTasksSidebar = () => {
        dispatch(actions.closeQueuePendingTasksSidebar())
        dispatch(actions.closeQueuesOperatorSidebar())
        dispatch(actions.closeQueuesTaskSidebar())

        dispatch(actions.openQueueTasksSidebar())
    }

    const handleOpenQueuePendingTasksSidebar = () => {
        dispatch(actions.closeQueueTasksSidebar())
        dispatch(actions.closeQueuesOperatorSidebar())
        dispatch(actions.closeQueuesTaskSidebar())

        dispatch(actions.openQueuePendingTasksSidebar())
    }

    return (
        <>
            <QueueInfoItem
                title={t(`${tNamespace}active-tasks`)}
                icon={faClipboardCheck}
                value={isQueuesV2 ? queue.ActiveTasksCount : activeTasksCount}
                testId={testId.activeTasksCount}
                isSmall={isQueuesV2}
            />
            <QueueInfoItem
                title={t(`${tNamespace}queued-tasks-count`)}
                icon={faClipboardList}
                value={isQueuesV2 ? queue.AllTasksCount ?? 0 : queue.PendingTasks.length}
                testId={testId.queuedTasksCount}
                isSmall={isQueuesV2}
                isSelectable={isQueuesV2}
                onClick={handleOpenQueueTasksSidebar}
                data-testid={testId.queueTasksInQueueButton}
            />
            <QueueInfoItem
                title={t(`${tNamespace}pending-tasks-count`)}
                icon={faClipboardList}
                value={queue.PendingIndividualTasks.length ?? queue.PendingIndividualTasksCount}
                testId={testId.queuedIndividualTasksCount}
                isSmall={isQueuesV2}
                isSelectable={isQueuesV2}
                onClick={handleOpenQueuePendingTasksSidebar}
                data-testid={testId.queuePendingTasksInQueueButton}
            />
            {queue.SlSettings ? (
                <QueueInfoItem
                    title={t(`${tNamespace}service-level`)}
                    icon={faClock}
                    value={queue.Sl === undefined ? 100 : queue.Sl}
                    unit={"%"}
                    period={
                        queue.SlSettings &&
                        `${t(`${tNamespace}in`)} ${Time.msToSeconds(queue.SlSettings.SlThresholdMSec)} ${t(
                            `${tNamespace}sec`
                        )}`
                    }
                    target={queue.SlSettings?.SlTarget}
                    isSmall={isQueuesV2}
                />
            ) : (
                <QueueInfoItem title={t(`${tNamespace}service-level`)} icon={faClock} isSmall={isQueuesV2} />
            )}
            <QueueInfoItem
                title={t(`${tNamespace}active-employees`)}
                icon={faUser}
                value={isQueuesV2 ? operatorsTotal : filteredOperators.length}
                isSmall={isQueuesV2}
            />
            {awtStatEntries.map(stat => (
                <QueueInfoItem
                    key={stat.id}
                    title={t(`${tNamespace}awt-statistics`)}
                    icon={faClock}
                    value={Time.humanizeDuration(stat.value, t)}
                    description={stat.description}
                    isSmall={isQueuesV2}
                />
            ))}
            {finishDialogsStat.map(stat => (
                <QueueInfoItem
                    key={stat.id + "handled"}
                    title={t(`${tNamespace}chats-handled`)}
                    icon={faCommentAltCheck}
                    value={stat.handled}
                    description={stat.description}
                    isSmall={isQueuesV2}
                />
            ))}
            {finishDialogsStat.map(stat => (
                <QueueInfoItem
                    key={stat.id + "lost"}
                    title={t(`${tNamespace}chats-lost`)}
                    icon={faCommentAltTimes}
                    value={stat.lost}
                    description={stat.description}
                    isSmall={isQueuesV2}
                />
            ))}
            <QueueInfoItem
                title={t(`${tNamespace}free-operators`)}
                icon={faUser}
                value={isQueuesV2 ? (freeOperatorsV2 >= 0 ? freeOperatorsV2 : 0) : freeOperators}
                isSmall={isQueuesV2}
            />
            <QueueInfoItem
                title={t(`${tNamespace}loaded-operators`)}
                icon={faUser}
                value={isQueuesV2 ? loadedOperatorsCount : loadedOperators}
                isSmall={isQueuesV2}
            />
        </>
    )
}

export default QueueInfoItems
