const TRANSLATION_NAMESPACE = "error:knowledgeBase."

export const GET_CATALOGS_FAILED_MESSAGE = `${TRANSLATION_NAMESPACE}get-catalogs-failed`
export const GET_CATEGORIES_FAILED_MESSAGE = `${TRANSLATION_NAMESPACE}get-categories-failed`
export const GET_ARTICLE_FAILED_MESSAGE = `${TRANSLATION_NAMESPACE}get-article-failed`
export const GET_ARTICLE_COMPARE_FAILED_MESSAGE = `${TRANSLATION_NAMESPACE}get-article-compare-failed`
export const GET_SUB_ARTICLES_FAILED_MESSAGE = `${TRANSLATION_NAMESPACE}get-sub-articles-failed`
export const GET_DEFAULT_ARTICLE_FAILED_MESSAGE = `${TRANSLATION_NAMESPACE}get-default-article-failed`

export const CREATE_CATALOG_FAILED_MESSAGE = `${TRANSLATION_NAMESPACE}create-catalog-failed`
export const UPDATE_CATALOG_FAILED_MESSAGE = `${TRANSLATION_NAMESPACE}update-catalog-failed`
export const DELETE_CATALOG_FAILED_MESSAGE = `${TRANSLATION_NAMESPACE}delete-catalog-failed`
export const GET_CATALOG_SHARING_FAILED_MESSAGE = `${TRANSLATION_NAMESPACE}share-catalog-sharing-failed`
export const SHARE_CATALOG_FAILED_MESSAGE = `${TRANSLATION_NAMESPACE}share-catalog-failed`
export const REMOVE_CATALOG_SHARING_FAILED_MESSAGE = `${TRANSLATION_NAMESPACE}remove-catalog-sharing-failed`
export const CREATE_CATEGORY_FAILED_MESSAGE = `${TRANSLATION_NAMESPACE}create-category-failed`
export const MOVE_CATEGORY_FAILED_MESSAGE = `${TRANSLATION_NAMESPACE}move-category-failed`
export const UPDATE_ARTICLE_FAILED_MESSAGE = `${TRANSLATION_NAMESPACE}update-article-failed`
export const SHARE_ARTICLE_FAILED_MESSAGE = `${TRANSLATION_NAMESPACE}share-article-failed`
export const GET_ARTICLE_SHARING_FAILED_MESSAGE = `${TRANSLATION_NAMESPACE}get-article-sharing-failed`
export const REMOVE_ARTICLE_SHARING_FAILED_MESSAGE = `${TRANSLATION_NAMESPACE}remove-article-sharing-failed`
export const PUBLISH_ARTICLE_FAILED_MESSAGE = `${TRANSLATION_NAMESPACE}publish-article-failed`
export const REMOVE_ARTICLE_FAILED_MESSAGE = `${TRANSLATION_NAMESPACE}remove-article-failed`
export const SET_UNPUBLISHED_STATUS_FOR_ARTICLE_FAILED_MESSAGE = `${TRANSLATION_NAMESPACE}set-unpublished-status-for-article-failed`
export const SEND_ARTICLE_FOR_APPROVAL_FAILED_MESSAGE = `${TRANSLATION_NAMESPACE}send-article-for-approval-failed`
export const SEARCH_ARTICLES_FAILED_MESSAGE = `${TRANSLATION_NAMESPACE}search-articles-failed`
export const SAVE_ARTICLE_READ_CONFIRMATION_FAILED_MESSAGE = `${TRANSLATION_NAMESPACE}save-article-read-confirmation-failed`

export const GET_MESSAGE_MARKUP_FAILED = `${TRANSLATION_NAMESPACE}get-message-markup-failed`
export const REMOVE_MESSAGE_MARKUP_FAILED = `${TRANSLATION_NAMESPACE}remove-message-markup-failed`
export const REMOVE_ALL_MESSAGE_MARKUP_FAILED = `${TRANSLATION_NAMESPACE}remove-all-message-markup-failed`

export const GET_SHARED_TYPE_FAILED = `${TRANSLATION_NAMESPACE}get-shared-type-failed`

export const TOGGLE_CATALOG_NEWSLINE_FAILED_MESSAGE = `${TRANSLATION_NAMESPACE}toggle-newsline-failed`
export const UPDATE_ARTICLE_NEWSLINE_SETTINGS_FAILED_MESSAGE = `${TRANSLATION_NAMESPACE}update-article-newsline-settings-failed`

export const GET_ARTICLE_TYPES_FAILED_MESSAGE = `${TRANSLATION_NAMESPACE}get-article-types-failed`
export const CREATE_ARTICLE_TYPE_FAILED_MESSAGE = `${TRANSLATION_NAMESPACE}create-article-type-failed`
export const UPDATE_ARTICLE_TYPE_FAILED_MESSAGE = `${TRANSLATION_NAMESPACE}update-article-type-failed`
export const DELETE_ARTICLE_TYPE_FAILED_MESSAGE = `${TRANSLATION_NAMESPACE}delete-article-type-failed`
export const GET_CHANNEL_FAILED_MESSAGE = `${TRANSLATION_NAMESPACE}get-channel-failed`

export const SET_FAVORITE_ARTICLE_FAILED_MESSAGE = `${TRANSLATION_NAMESPACE}set-favorite-article-failed`
export const GET_FAVORITE_ARTICLES_BLOCK_FAILED_MESSAGE = `${TRANSLATION_NAMESPACE}get-articles-favorite-block-failed`
export const EXPAND_FAVORITE_ARTICLES_BLOCK_FAILED_MESSAGE = `${TRANSLATION_NAMESPACE}expand-articles-favorite-block-failed`
export const MOVE_FAVORITE_ARTICLE_FAILED_MESSAGE = `${TRANSLATION_NAMESPACE}move-favorite-article-failed`
export const GET_ARTICLE_HISTORY_FAILED_MESSAGE = `${TRANSLATION_NAMESPACE}get-article-history-failed`
export const GET_ARTICLE_PREVIOS_VERSION_FAILED_MESSAGE = `${TRANSLATION_NAMESPACE}get-article-previous-version-failed`
export const CREATE_DRAFT_FROM_ARTICLE_FAILED_MESSAGE = `${TRANSLATION_NAMESPACE}create-draft-from-article-failed`
