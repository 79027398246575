import { NewslineFormValues, NewslineKeys } from "../../models/knowledgeBase/newsline"
import { KnowledgeBaseAccessType } from "../../models/knowledgeBase/permission"

export const getNewslineDefaultValues = (currentProjectId: string | undefined): NewslineFormValues => ({
    [NewslineKeys.isInNewsline]: false,
    [NewslineKeys.isHideAfterReading]: false,
    [NewslineKeys.isEndless]: false,
    [NewslineKeys.lifeTime]: 0,
    [NewslineKeys.description]: "",
    [NewslineKeys.subscriptions]: currentProjectId
        ? [
              {
                  Type: KnowledgeBaseAccessType.All,
                  Value: "All",
                  ProjectId: currentProjectId
              }
          ]
        : []
})

// The regular expression pattern ^[\p{L}\p{P}\d\s]+$ is used to match a string that consists of one or more Unicode letters (\p{L}), punctuation marks (\p{P}), digits (\d), or whitespace characters (\s). The ^ and $ symbols represent the start and end of the string. The giu flags are used for global search, case-insensitive search, and Unicode matching.
export const NEWLINE_DESCRIPTION_REGEXP = new RegExp(/^[\p{L}\p{P}\d\s]+$/giu)
