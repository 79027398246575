import React from "react"
import { formTranslation } from "../../../locales/form"
import ValidatableInput from "../../ValidatableInput/ValidatableInput"
import { useSelector } from "react-redux"
import { selectSlots } from "../../../store/slots/selectors"
import { SlotType } from "../../../models/slot"
import { useTranslation } from "react-i18next"

interface Props {
    id: string
    name: string
    label: string
    type?: SlotType
    icon?: JSX.Element
}

const SlotSelectControl: React.FC<Props> = props => {
    const { type, ...inputProps } = props
    const slots = useSelector(selectSlots)
    const { t } = useTranslation()

    const filteredSlots = type ? slots.filter(s => s.Type === type) : slots

    return (
        <ValidatableInput as="select" disabled={!filteredSlots.length} {...inputProps}>
            <option value="">{t(formTranslation.nothingSelected)}</option>
            {filteredSlots.map(({ Id, Title, ExternalId }) => (
                <option value={ExternalId} key={Id}>
                    {`${Title}`}
                </option>
            ))}
        </ValidatableInput>
    )
}

export default SlotSelectControl
