import {
    DeleteFromQueueRequest,
    Operator,
    OperatorSummary,
    TenantQueues,
    UpdateOperatorQueuesRequest
} from "../../models/operator"
import { apiInstance } from "../instances/apiInstance"
import { OperatorDto, OperatorPaginationResponse } from "../../models/operatorDto"
import { processUser } from "../../utility/common/processUser"
import { mapConverters } from "../../utility/common/mapConverters"
import { PaginationRequest } from "../../models/pagination"
import { queryApi } from "../api"
import { OperatorDtoConverter } from "../../utility/operators/convert"
import store from "../../store/store"

const operatorsController = {
    getActiveByTenant: (id: string): Promise<OperatorDto[]> =>
        apiInstance
            .get(`/tenants/${id}/operators/active`)
            .then(response => {
                return response.data.map(mapConverters.taskConverter.mapOperator)
            })
            .then(data => data.map(processUser)),
    getActiveByQueue: (
        projectId: string,
        queueId: string,
        paginationParams: PaginationRequest
    ): Promise<OperatorPaginationResponse<OperatorDto>> =>
        apiInstance.post(`/${projectId}/operators/active/${queueId}`, paginationParams).then(response => {
            return {
                ...response.data,
                Operators: response.data.Operators.map(mapConverters.taskConverter.mapOperator)
            }
        }),
    getOperatorById: (id: string): Promise<OperatorDto> =>
        apiInstance.get(`/task-queue-operators/${id}/without-tasks`).then(response => response.data),
    deleteFromQueue: (id: string, request: DeleteFromQueueRequest): Promise<void> =>
        apiInstance.post(`/operators/${id}/delete_from_queue`, request).then(response => response.data),
    updateQueues: (id: string, request: UpdateOperatorQueuesRequest): Promise<TenantQueues> =>
        apiInstance.put(`/operators/${id}/queues`, request).then(response => response.data),
    makeOffline: (id: string): Promise<void> =>
        apiInstance.post(`/operators/${id}/make_offline`).then(response => response.data),
    getAllSummaryByTenant: (id: string, onlyDoRoute: boolean): Promise<OperatorSummary[]> =>
        apiInstance
            .get(`/tenants/${id}/operators/summary?onlyDoRoute=${onlyDoRoute}`)
            .then(response => response.data.map(processUser)),
    connectOperator: (projectId: string, operatorId: string, dialogId: string, notificationId: string) =>
        apiInstance.post(`/${projectId}/connected-operator/connect-to-dialog`, undefined, {
            params: { operatorId, dialogId, notificationId }
        })
}

export default operatorsController

export const operatorsApiController = queryApi.injectEndpoints({
    endpoints(build) {
        return {
            getOperatorById: build.query<Operator, string>({
                query: operatorId => ({
                    url: `/task-queue-operators/${operatorId}/without-tasks`,
                    method: "GET"
                }),
                transformResponse: (operator: OperatorDto): Operator => {
                    const operatorStatuses = store.getState().userOperator.statuses

                    return OperatorDtoConverter.toOperator(operator, operatorStatuses)
                }
            })
        }
    }
})

export const { useGetOperatorByIdQuery } = operatorsApiController
