import React, { useMemo } from "react"
import "./ArticleParameters.scss"
import { ArticleEditFormValues, ArticleParameter, ArticleParameterValue } from "../../models/article"
import { nameof } from "../../utility/common/nameof"
import { FieldArray, useFormikContext } from "formik"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faGripLinesVertical, faTimes } from "@fortawesome/pro-light-svg-icons"
import { useSelector } from "react-redux"
import { selectSlots } from "../../store/slots/selectors"
import { Form } from "react-bootstrap"
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd"
import cn from "classnames"
import DebouncedValidatableInput from "../ValidatableInput/DebouncedValidatableInput"
import { getSlotById, getSlotTitlesMap } from "../../utility/knowledgeBase/slot"

interface Props {
    parameters: ArticleParameter[]
}

const ArticleParameters: React.FC<Props> = props => {
    const { parameters } = props
    const slots = useSelector(selectSlots)

    return (
        <>
            {parameters.map((p, index) => {
                const slot = getSlotById(p.SlotId, slots)
                const slotIdComparison = p.Comparison?.SlotId.ComparisonType
                const slotValueComparison = p.Comparison?.Value.ComparisonType

                return (
                    <div key={index} className="article-params__item">
                        <span
                            className={cn(
                                slotIdComparison
                                    ? cn(`article-params__title${slotIdComparison}`, "article-params__title")
                                    : "article-params__title"
                            )}
                        >
                            {slot?.Title}
                        </span>
                        <Form.Control
                            disabled
                            className={cn(
                                slotValueComparison
                                    ? cn(`article-params__value${slotValueComparison}`, "article-params__value")
                                    : "article-params__value"
                            )}
                            value={p.Value}
                        ></Form.Control>
                    </div>
                )
            })}
        </>
    )
}

interface FormProps {
    addButton: React.ReactNode
}

const FormikArticleParameters: React.FC<FormProps> = ({ addButton }) => {
    const { values } = useFormikContext<ArticleEditFormValues>()
    const slots = useSelector(selectSlots)
    const slotsMap = useMemo(() => getSlotTitlesMap(slots), [slots])

    return (
        <FieldArray
            name={nameof<ArticleEditFormValues>("Parameters")}
            render={({ remove, move }) => (
                <>
                    <DragDropContext
                        onDragEnd={(result: DropResult) => {
                            if (!result.destination || result.destination.index === result.source.index) return
                            move(result.source.index, result.destination.index)
                        }}
                    >
                        <Droppable droppableId="droppable">
                            {provided => (
                                <div {...provided.droppableProps} ref={provided.innerRef} className="article-params">
                                    {values.Parameters.map((parameter: ArticleParameterValue, index: number) => (
                                        <Draggable key={parameter.Id} draggableId={parameter.Id} index={index}>
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    className={cn(
                                                        "article-params__item",
                                                        snapshot.isDragging && "article-params__item_dragging"
                                                    )}
                                                >
                                                    <span
                                                        className="article-params__drag-icon"
                                                        {...provided.dragHandleProps}
                                                    >
                                                        <FontAwesomeIcon icon={faGripLinesVertical} />
                                                    </span>
                                                    <span className="article-params__title">
                                                        {slotsMap[parameter.SlotId]}
                                                    </span>
                                                    <DebouncedValidatableInput
                                                        id={`article-param${index}`}
                                                        className="article-params__value"
                                                        name={`Parameters[${index}].Value`}
                                                    />
                                                    <FontAwesomeIcon
                                                        icon={faTimes}
                                                        className="article-params__remove-icon"
                                                        onClick={() => remove(index)}
                                                    />
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                    {values.Parameters.length !== 0 && addButton}
                </>
            )}
        />
    )
}

export { ArticleParameters, FormikArticleParameters }
