import React from "react"
import cn from "classnames"
import type { NotificationContentFunction } from "../../../utility/notifications/notificationContentType"
import styles from "./NotificationExportFilesItem.module.scss"
import ProgressBar from "react-bootstrap/ProgressBar"
import { useAppSelector } from "../../../store/hooks"
import truncate from "lodash/truncate"
import { ReactComponent as DownloadIcon } from "../../../assets/images/icons/download.svg"
import { ReactComponent as CautionIcon } from "../../../assets/images/icons/caution.svg"
import { selectExportAttachmentsProgress } from "../../../store/dialogs/selectors"

const tNamespace = "notification:export-attachments"

const NotificationExportFilesItem: NotificationContentFunction = (notification, t) => {
    const { text, progress, isError } = useAppSelector(selectExportAttachmentsProgress)

    const iconContent = isError ? <CautionIcon /> : <DownloadIcon className={cn(styles.notification__icon)} />

    const buttons = <></>

    const mainContent = (
        <div className={styles.notification}>
            <div className={cn(styles.notification__title, isError && styles.notification__error)}>
                {t(`${tNamespace}.title`)}
            </div>
            <div className={cn(styles.notification__content, isError && styles.notification__error)}>
                {truncate(text, { length: 30, omission: "..." })}
            </div>
            {progress !== 0 && <ProgressBar now={progress} className={styles.notification__progress} />}
        </div>
    )

    return {
        iconContent,
        mainContent,
        buttons
    }
}

export default NotificationExportFilesItem
