import React, { memo } from "react"
import styles from "./OperatorTask.module.scss"
import ChannelIconLarge from "../../ChannelIconLarge/ChannelIconLarge"
import { generateFullName } from "../../../utility/common/generateFullName"
import { formatDistanceStrictLocal } from "../../../utility/common/time"
import { Task } from "../../../models/task"
import { useTranslation } from "react-i18next"
import { Operator } from "../../../models/operator"
import { textDomParser } from "../../../utility/common/textDomParser"

type OperatorTaskProps = {
    task: Task
    operator: Operator
    onTaskClick?: (task: Task, operator: Operator) => void
}

const OperatorTask = (props: OperatorTaskProps) => {
    const { task, operator, onTaskClick } = props
    const { t } = useTranslation()

    const previewText = task.Preview?.Text ?? ""
    const previewMessage = previewText === "task:form.preview-attachment" ? t(previewText) : previewText
    const parsedMessage = textDomParser(previewMessage)

    const handleClick = (task: Task, operator: Operator) => {
        if (!onTaskClick) {
            return
        }

        onTaskClick(task, operator)
    }

    return (
        <div className={styles.operatorTask} onClick={() => handleClick(task, operator)}>
            <div>
                <ChannelIconLarge type={task.Channel.Type} className={styles.operatorTask__channelLogo} />
            </div>
            <div className={styles.operatorTask__main}>
                <div className={styles.operatorTask__header}>
                    <div className={styles.operatorTask__channelInfo}>
                        <span>{task.Channel.Title}</span>
                        <span>
                            {generateFullName(task.Client.FirstName, task.Client.LastName, task.Client.MiddleName)}
                        </span>
                    </div>
                </div>
                <div className={styles.operatorTask__footer}>
                    <div className={styles.operatorTask__preview}>{parsedMessage}</div>
                    <div className={styles.operatorTask__time}>{formatDistanceStrictLocal(task.CreatedAt)}</div>
                </div>
            </div>
        </div>
    )
}

export default memo(OperatorTask)
