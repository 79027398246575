import React from "react"
import "./QueueCardLoader.scss"
import Skeleton from "../Skeleton/Skeleton"
import useDelay from "../../utility/common/useDelay"
import { duplicateComponent } from "../../utility/common/duplicateComponent"

interface Props {
    count: number
}

const QueueCardLoader: React.FC<Props> = props => {
    const { count } = props

    const display = useDelay()

    if (!display) {
        return null
    }

    return (
        <div className="queue-card-loader">
            <div className="queue-card-loader__items">
                {duplicateComponent(count)(() => (
                    <div className="queue-card-loader__item">
                        <Skeleton />
                    </div>
                ))}
            </div>
        </div>
    )
}

export default QueueCardLoader
