import React, { memo, useCallback, useEffect } from "react"
import { useAppDispatch, useAppSelector } from "../../../store/hooks"
import SidebarRight from "../../SidebarRight/SidebarRight"
import { useTranslation } from "react-i18next"
import { actions } from "../../../store/queues/slice"
import { useLazyGetQueueTasksQuery } from "../../../api/controllers/queuesV2"
import IconControl from "../../IconControl/IconControl"
import { faSync } from "@fortawesome/pro-light-svg-icons"
import { useQueueSidebarData } from "../utils/useQueueSidebar"
import QueuesTasksTable from "../QueuesTable/QueuesTable"
import { selectQueueTasksSidebar } from "../../../store/queues/selectors"
import { Task } from "../../../models/task"
import { Operator } from "../../../models/operator"
import AsyncQuery from "../../Async/AsyncQuery"
import ErrorMessage from "../../ErrorMessage/ErrorMessage"
import QueuesTaskTableLoader from "../QueuesTaskTableLoader/QueuesTaskTableLoader"
import { QUEUES_TASKS_PER_PAGE } from "../../../models/pagination"
import { TaskPaginationResponse } from "../../../models/queue"
import styles from "./QueuesTasksSidebar.module.scss"

const tNamespace = "queues:"

const QueuesTasksSidebar = () => {
    const { isOpened } = useAppSelector(selectQueueTasksSidebar)
    const { queueId, projectId } = useQueueSidebarData()
    const dispatch = useAppDispatch()
    const { t } = useTranslation()

    const handleToggleQueueTasksSidebar = useCallback(() => {
        dispatch(actions.closeQueueTasksSidebar())
    }, [dispatch])

    const queueTasksTriggerRequest = {
        projectId,
        queueId,
        paginationParams: {
            PageFrom: 0,
            PageSize: QUEUES_TASKS_PER_PAGE
        }
    }

    const [queueTasksTrigger, queueTasksQuery] = useLazyGetQueueTasksQuery()

    useEffect(() => {
        if (queueId && isOpened) {
            queueTasksTrigger(queueTasksTriggerRequest)
        }
    }, [queueId, isOpened])

    const handleAssignedTaskClick = useCallback(
        (task: Task, operator?: Operator) => {
            dispatch(actions.openQueuesTaskSidebar({ task: task, operator: operator, isBack: false }))
        },
        [dispatch]
    )

    return (
        <SidebarRight
            open={isOpened}
            title={t(`${tNamespace}queue-tasks-view`)}
            onCloseClick={handleToggleQueueTasksSidebar}
            onClose={() => void 0}
            width={864}
            action={
                <IconControl
                    icon={faSync}
                    size="1x"
                    onClick={() => {
                        queueTasksTrigger(queueTasksTriggerRequest)
                    }}
                />
            }
        >
            <AsyncQuery
                query={queueTasksQuery}
                processView={<QueuesTaskTableLoader />}
                errorView={({ message }) => <ErrorMessage text={message} />}
                validator={query => {
                    const tasks = query.data as TaskPaginationResponse<Task>

                    return !!tasks.Tasks.length
                }}
                emptyDataView={
                    <span className={styles.queuesTasksSidebar_empty}>{t(`${tNamespace}no-tasks-in-queue`)}</span>
                }
            >
                {({ data }) => (
                    <QueuesTasksTable
                        handleAssignedTaskView={handleAssignedTaskClick}
                        tasksPagination={data}
                        queueId={queueId}
                        projectId={projectId}
                        queryTriggerCallback={queueTasksTrigger}
                    />
                )}
            </AsyncQuery>
        </SidebarRight>
    )
}

export default memo(QueuesTasksSidebar)
