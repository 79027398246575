import { apiInstance } from "../instances/apiInstance"
import {
    GetSharedCatalogResponse,
    GetSharedRelatedCategoriesRequest,
    GetShareResponse,
    SearchSharedArticlesRequest
} from "../../models/sharing"
import { GetArticleTypesResponse } from "../../models/articleType"
import { Article, SearchArticlesResponse } from "../../models/article"
import { GetCategoriesResponse, GetRelatedCategoriesResponse } from "../../models/category"

const sharedKnowledgeBaseController = {
    getSharedType(shareId: string): Promise<GetShareResponse> {
        return apiInstance.get(`/share/${shareId}`).then(response => response.data)
    },
    getSharedCatalog(shareId: string): Promise<GetSharedCatalogResponse> {
        return apiInstance.get(`/share/${shareId}/catalogs`).then(response => response.data)
    },
    getSharedArticle(shareId: string): Promise<Article> {
        return apiInstance.get(`/share/${shareId}/articles`).then(response => response.data)
    },
    getArticle(shareId: string, articleCode: string): Promise<Article> {
        return apiInstance.get(`/share/${shareId}/articles/${articleCode}`).then(response => response.data)
    },
    getArticleTypes(shareId: string): Promise<GetArticleTypesResponse> {
        return apiInstance.get(`/share/${shareId}/article_types`).then(response => response.data)
    },
    getActualArticle(shareId: string, articleCode: string): Promise<Article> {
        return apiInstance.get(`/share/${shareId}/${articleCode}`).then(response => response.data)
    },
    getCategories(shareId: string, parentId: string): Promise<GetCategoriesResponse> {
        return apiInstance
            .post(`/share/${shareId}/catalogs/categories/${parentId}/categories`)
            .then(response => response.data)
    },
    getRelatedCategories(
        shareId: string,
        request: GetSharedRelatedCategoriesRequest
    ): Promise<GetRelatedCategoriesResponse> {
        return apiInstance
            .post(`/share/${shareId}/catalogs/categories/related`, request)
            .then(response => response.data)
    },
    searchArticles(shareId: string, request: SearchSharedArticlesRequest): Promise<SearchArticlesResponse> {
        return apiInstance.post(`/share/${shareId}/articles/search`, request).then(response => response.data)
    }
}

export default sharedKnowledgeBaseController
