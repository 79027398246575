import { Agent } from "../../models/agent"
import { apiInstance } from "../instances/apiInstance"
import { ParameterDeclarationDto } from "../../models/parameterDeclaration"

const agentsController = {
    getAgents: (projectId: string): Promise<Agent[]> =>
        apiInstance.get(`/projects/${projectId}/agents`).then(response => response.data),
    getDeclarations: (projectId: string, type: string): Promise<ParameterDeclarationDto[]> =>
        apiInstance.get(`/agents/${type}/declarations`).then(response => response.data),
    create: (request: Agent): Promise<Agent> => apiInstance.post("/agents", request).then(response => response.data),
    update: (request: Agent): Promise<Agent> => apiInstance.put("/agents", request).then(response => response.data),
    delete: (projectId: string, agentId: string): Promise<void> =>
        apiInstance.delete(`/projects/${projectId}/agents/${agentId}`).then(response => response.data)
}

export default agentsController
