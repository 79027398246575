import {
    AddSendingJobItemsRequest,
    AddSendingJobItemsResponse,
    CreateSendingJobRequest,
    DeleteByParentSendingJobItemsRequest,
    DeleteByParentSendingJobItemsResponse,
    DeleteSendingJobItemsRequest,
    DeleteSendingJobItemsResponse,
    DeleteSendingJobRequest,
    GetSendingJobItemsByPageRequest,
    GetSendingJobItemsRequest,
    GetSendingJobItemsResponse,
    GetSendingJobRequest,
    MakeMassMailingReportRequest,
    MakeMassMailingReportResponse,
    MassMailingResponse,
    SearchSendingJobRequest,
    SearchSendingJobResponse,
    SendingJob,
    UpdateSendingJobRequest
} from "../../models/massMailing"
import { ListMassSendingReportsRequest, ListReadyReportsByReportIdResponse } from "../../models/reports"
import {
    LIST_MASS_MAILING_REPORTS_FAILED_MESSAGE,
    MAKE_MASS_MAILING_REPORT_FAILED_MESSAGE,
    SEARCH_MASS_MAILINGS_FAILED_MESSAGE
} from "../../store/massMailing/constants"
import { massMailingActions } from "../../store/massMailing/slice"
import { saveNotificationError } from "../../store/notifications/thunks"
import { apiCallExceptionToSystemError } from "../../utility/common/apiCallExceptionToSystemError"
import { queryApi } from "../api"
import { handleQueryApiError } from "./dialogs.helpers"

const jobPrefix = "/mass-sending-service/sending-job"
const itemsPrefix = "/mass-sending-service/sending-job-items"
const transformFunction =
    <Req>() =>
    (response: MassMailingResponse<Req>): Req => {
        if ("data" in response) {
            return response.data
        } else {
            throw new Error(response.errors[0].message)
        }
    }

export const massMailingController = queryApi.injectEndpoints({
    endpoints: build => ({
        createMassMailing: build.mutation<SendingJob, CreateSendingJobRequest>({
            query: body => ({
                baseUrl: "/api",
                url: `${jobPrefix}/create`,
                method: "POST",
                body
            }),
            transformResponse: transformFunction<SendingJob>()
        }),
        getMassMailing: build.mutation<SendingJob, GetSendingJobRequest>({
            query: body => ({
                baseUrl: "/api",
                url: `${jobPrefix}/get`,
                method: "POST",
                body
            }),
            transformResponse: transformFunction<SendingJob>(),
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                const { data } = await queryFulfilled
                dispatch(massMailingActions.setMassMailings([data]))
            }
        }),
        updateMassMailing: build.mutation<SendingJob, UpdateSendingJobRequest>({
            query: body => ({
                baseUrl: "/api",
                url: `${jobPrefix}/update`,
                method: "POST",
                body
            }),
            transformResponse: transformFunction<SendingJob>(),
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                const { data } = await queryFulfilled
                dispatch(massMailingActions.setMassMailings([data]))
            }
        }),
        deleteMassMailing: build.mutation<SendingJob, DeleteSendingJobRequest>({
            query: body => ({
                baseUrl: "/api",
                url: `${jobPrefix}/delete`,
                method: "POST",
                body
            }),
            transformResponse: transformFunction<SendingJob>(),
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                const { data } = await queryFulfilled
                dispatch(massMailingActions.setMassMailings([data]))
            }
        }),
        searchMassMailing: build.mutation<SearchSendingJobResponse, SearchSendingJobRequest>({
            query: body => ({
                baseUrl: "/api",
                url: `${jobPrefix}/search`,
                method: "POST",
                body
            }),
            transformResponse: transformFunction<SearchSendingJobResponse>(),
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled
                    dispatch(massMailingActions.setMassMailings(data.SendingJobs))
                } catch (e) {
                    handleQueryApiError(dispatch, SEARCH_MASS_MAILINGS_FAILED_MESSAGE, e)
                }
            }
        }),
        addMassMailingItems: build.mutation<AddSendingJobItemsResponse, AddSendingJobItemsRequest>({
            query: body => ({
                baseUrl: "/api",
                url: `${itemsPrefix}/add`,
                method: "POST",
                body
            }),
            transformResponse: transformFunction<AddSendingJobItemsResponse>()
        }),
        getMassMailingItems: build.mutation<GetSendingJobItemsResponse, GetSendingJobItemsRequest>({
            query: body => ({
                baseUrl: "/api",
                url: `${itemsPrefix}/get`,
                method: "POST",
                body
            }),
            transformResponse: transformFunction<GetSendingJobItemsResponse>()
        }),
        getMassMailingItemsByPage: build.mutation<GetSendingJobItemsResponse, GetSendingJobItemsByPageRequest>({
            query: body => ({
                baseUrl: "/api",
                url: `${itemsPrefix}/get-by-page`,
                method: "POST",
                body
            }),
            transformResponse: transformFunction<GetSendingJobItemsResponse>()
        }),
        deleteMassMailingItems: build.mutation<DeleteSendingJobItemsResponse, DeleteSendingJobItemsRequest>({
            query: body => ({
                baseUrl: "/api",
                url: `${itemsPrefix}/delete`,
                method: "POST",
                body
            }),
            transformResponse: transformFunction<DeleteSendingJobItemsResponse>()
        }),
        deleteByParentMassMailingItems: build.mutation<
            DeleteByParentSendingJobItemsResponse,
            DeleteByParentSendingJobItemsRequest
        >({
            query: body => ({
                baseUrl: "/api",
                url: `${itemsPrefix}/delete/by-parentId`,
                method: "POST",
                body
            }),
            transformResponse: transformFunction<DeleteByParentSendingJobItemsResponse>()
        }),
        listMassMailingReports: build.mutation<ListReadyReportsByReportIdResponse, ListMassSendingReportsRequest>({
            query: body => ({
                baseUrl: "/api",
                url: "/reports/list-mass-sending-reports",
                method: "POST",
                body
            }),
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                await queryFulfilled.catch(e => {
                    const error = apiCallExceptionToSystemError(LIST_MASS_MAILING_REPORTS_FAILED_MESSAGE, e)
                    saveNotificationError(dispatch, error)
                })
            }
        }),
        makeMassMailingReport: build.mutation<MakeMassMailingReportResponse, MakeMassMailingReportRequest>({
            query: body => ({
                baseUrl: "/api",
                url: "/mass-sending-service/sending-job/make-report",
                method: "POST",
                body
            }),
            transformResponse: transformFunction<MakeMassMailingReportResponse>(),
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                await queryFulfilled.catch(e => {
                    const error = apiCallExceptionToSystemError(MAKE_MASS_MAILING_REPORT_FAILED_MESSAGE, e)
                    saveNotificationError(dispatch, error)
                })
            }
        })
    })
})

export const {
    useCreateMassMailingMutation,
    useGetMassMailingMutation,
    useUpdateMassMailingMutation,
    useDeleteMassMailingMutation,
    useSearchMassMailingMutation,
    useAddMassMailingItemsMutation,
    useGetMassMailingItemsMutation,
    useDeleteMassMailingItemsMutation,
    useGetMassMailingItemsByPageMutation,
    useDeleteByParentMassMailingItemsMutation,
    useMakeMassMailingReportMutation,
    useListMassMailingReportsMutation
} = massMailingController
