import React from "react"
import Skeleton from "../../Skeleton/Skeleton"
import styles from "./QueuesTaskTableLoader.module.scss"

const QueuesTaskTableLoader = () => {
    return (
        <div className={styles.queuesTaskTableLoader__loader}>
            <div className={styles.queuesTaskTableLoader__loaderTableInput}>
                <Skeleton />
            </div>
            <div className={styles.queuesTaskTableLoader__loaderTable}>
                <Skeleton />
            </div>
        </div>
    )
}

export default QueuesTaskTableLoader
