import React, { FormEvent, useCallback, useEffect, useState } from "react"
import { Form, FormControl, InputGroup } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { formTranslation } from "../../locales/form"
import { Operator } from "../../models/operator"
import { Queue, QueueName } from "../../models/queue"
import { selectUpdateQueuesState } from "../../store/operators/selectors"
import { updateQueues } from "../../store/operators/thunks"
import { getQueuesNames } from "../../utility/queues/getQueuesNames"
import AddButton from "../AddButton/AddButton"
import LoadingButton from "../LoadingButton/LoadingButton"
import OperatorQueueItem from "../OperatorQueueItem/OperatorQueueItem"
import "./OperatorQueuesForm.scss"

const tNamespace = "queues:operator."
interface Props {
    projectId: string
    operator: Operator
    queues: Queue[]
    onClose: () => void
}

const getOperatorQueueNames = (operator: Operator, queues: Queue[], projectId: string): QueueName[] => {
    const tenantQueues = operator.TenantsQueues.find(v => v.TenantId === projectId)
    const operatorQueues = tenantQueues ? tenantQueues.Queues : []

    return getQueuesNames(operatorQueues, queues)
}

const getRemainingQueuesNames = (operatorQueuesNames: QueueName[], allQueues: Queue[]): QueueName[] => {
    return allQueues
        .filter(q => operatorQueuesNames.findIndex(oq => oq.Id === q.Id) === -1)
        .map(q => ({ Id: q.Id, Name: q.Name }))
}

const OperatorQueuesForm: React.FC<Props> = props => {
    const { projectId, operator, queues, onClose } = props
    const { t } = useTranslation()

    const [operatorQueuesNames, setQueuesNames] = useState<QueueName[]>([])
    const [remainingQueuesNames, setRemainingQueuesNames] = useState<QueueName[]>([])
    const [selectedQueueId, setSelectedQueueId] = useState<string | undefined>()

    const updateQueuesNames = useCallback((operatorQueuesNames: QueueName[], queues: Queue[]) => {
        setQueuesNames(operatorQueuesNames)
        setRemainingQueuesNames(getRemainingQueuesNames(operatorQueuesNames, queues))
    }, [])

    useEffect(() => {
        updateQueuesNames(getOperatorQueueNames(operator, queues, projectId), queues)
    }, [updateQueuesNames, operator, queues, projectId])

    const handleAddClick = () => {
        if (!selectedQueueId) return
        const queueName = remainingQueuesNames.find(v => v.Id === selectedQueueId)
        if (queueName) {
            updateQueuesNames([...operatorQueuesNames, queueName], queues)
            setSelectedQueueId(undefined)
        }
    }

    const handleQueueSelectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedQueueId(event.target.value)
    }

    const handleRemoveClick = (id: string) => {
        updateQueuesNames(
            operatorQueuesNames.filter(v => v.Id !== id),
            queues
        )
    }

    const asyncState = useSelector(selectUpdateQueuesState)

    const dispatch = useDispatch()
    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        dispatch(
            updateQueues(
                operator.Id,
                {
                    TenantId: projectId,
                    Queues: operatorQueuesNames.map(v => v.Id)
                },
                onClose
            )
        )
    }

    return (
        <Form className="operator-queues-form" onSubmit={handleSubmit}>
            <div className="operator-queues-form__content">
                <div className="operator-queues-form__queues">
                    {operatorQueuesNames.map(v => (
                        <OperatorQueueItem
                            key={v.Id}
                            className="operator-queues-form__queue"
                            onClose={handleRemoveClick}
                            queueName={v}
                        />
                    ))}
                </div>
                {remainingQueuesNames.length > 0 ? (
                    <div className="operator-queues-form__add-queue">
                        <InputGroup>
                            <FormControl as="select" onChange={handleQueueSelectChange} value={selectedQueueId}>
                                <option value="">{t(formTranslation.nothingSelected)}</option>
                                {remainingQueuesNames.map(q => (
                                    <option key={q.Id} value={q.Id}>
                                        {q.Name}
                                    </option>
                                ))}
                            </FormControl>
                            <InputGroup.Append>
                                <AddButton
                                    text={t(`${tNamespace}add-queue`)}
                                    variant="outline-primary"
                                    onClick={handleAddClick}
                                    disabled={!selectedQueueId}
                                />
                            </InputGroup.Append>
                        </InputGroup>
                    </div>
                ) : null}
            </div>
            <div className="operator-queues-form__footer">
                <LoadingButton type="submit" loading={asyncState.inProcess} variant="primary" block>
                    {t(formTranslation.save)}
                </LoadingButton>
            </div>
        </Form>
    )
}

export default OperatorQueuesForm
