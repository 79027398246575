import React from "react"
import styles from "./QueueInfoItem.module.scss"
import Skeleton from "../Skeleton/Skeleton"

const QueueInfoItemLoader = () => {
    return (
        <div className={styles.queueInfoItem__loader}>
            <Skeleton />
        </div>
    )
}

export default QueueInfoItemLoader
