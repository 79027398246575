import { TFunction } from "i18next"
import React from "react"
import FormAccordion from "../../FormAccordion/FormAccordion"
import styles from "./ArticleHistoryItem.module.scss"
import { ArticleHistoryGroupedByDate } from "../ArticleHistoryContainer"
import ArticleHistoryItem from "./ArticleHistoryItem/ArticleHistoryItem"
import { Time } from "../../../utility/common/time"

export interface ArticleHistoryListProps {
    groupedHistory: ArticleHistoryGroupedByDate
    t: TFunction
}

const tDateNamespace = "date:"

const ArticleHistoryList: React.FC<ArticleHistoryListProps> = props => {
    const { groupedHistory, t } = props

    const monthsArray = Time.getMonthsArray(t)
    const articleHistoryArray = Object.keys(groupedHistory)

    return (
        <div>
            {articleHistoryArray.map(key => {
                const splitedText = key.split(".")
                const title =
                    splitedText.length > 1
                        ? `${monthsArray[Number(splitedText[0])]} ${splitedText[1]} ${t(
                              `${tDateNamespace}years.year-short`
                          )}`
                        : t(`${tDateNamespace}months-and-days.${key}`)
                return (
                    <FormAccordion
                        title={title}
                        headerTitleClassName={styles.accordion__title}
                        toggleClassName={styles.accordion__toggle}
                        key={key}
                    >
                        {groupedHistory[key].map(element => {
                            return (
                                <ArticleHistoryItem
                                    articleHistoryElement={element}
                                    isLastVersion={element.isLastVersion}
                                    key={element.ArticleId}
                                ></ArticleHistoryItem>
                            )
                        })}
                    </FormAccordion>
                )
            })}
        </div>
    )
}

export default ArticleHistoryList
