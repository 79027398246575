import React from "react"
import "./KeyboardButtonRow.scss"
import KeyboardButton from "../KeyboardButton/KeyboardButton"
import { faPlus } from "@fortawesome/pro-light-svg-icons/faPlus"
import IconControl from "../../../../IconControl/IconControl"
import cn from "classnames"
import { MarkdownButton } from "../../../../../utility/articleContentEditor/buttonsParser/buttonsParser"
import { Dispatch } from "../../../../../utility/common/storeHelper"

interface Props {
    buttonRow: MarkdownButton[]
    onAdd: () => void
    onEdit: (updatedButton: MarkdownButton) => void
    disabled?: boolean
    projectId: string
    handleDeleteButton: (rowNumber: number, column: number) => void
    isEditable: boolean
    handleOpenArticle: (articleCode: string) => void
    dispatch: Dispatch
}

const KeyboardButtonRow: React.FC<Props> = props => {
    const {
        buttonRow,
        onAdd,
        onEdit,
        disabled,
        projectId,
        handleDeleteButton,
        isEditable,
        handleOpenArticle,
        dispatch
    } = props

    const lastIndex = buttonRow.length - 1
    const isAddAllowed = buttonRow.length < 3

    return (
        <>
            {buttonRow.map((button, index) => (
                <div
                    key={button.id}
                    className={cn(
                        "keyboard-button-row",
                        isAddAllowed && !disabled && "keyboard-button-row_add-allowed",
                        disabled && "keyboard-button-row_disabled"
                    )}
                >
                    <KeyboardButton
                        projectId={projectId}
                        button={button}
                        className="keyboard-button-row__btn"
                        onEdit={onEdit}
                        disabled={disabled}
                        onDelete={e => {
                            e.stopPropagation()
                            handleDeleteButton(button.Row, index)
                        }}
                        isEditable={isEditable}
                        handleOpenArticle={handleOpenArticle}
                        dispatch={dispatch}
                    />
                    {index === lastIndex && isEditable && (
                        <IconControl icon={faPlus} className="keyboard-button-row__add-icon" onClick={onAdd} />
                    )}
                </div>
            ))}
        </>
    )
}

export default KeyboardButtonRow
