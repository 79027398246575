import React from "react"
import { Form } from "react-bootstrap"
import InfoIcon from "../InfoIcon/InfoIcon"
import { CreatableSelect } from "../Select/Select"
import { formTranslation } from "../../locales/form"
import RemovableListItem from "../FormHelpers/RemovableListItem/RemovableListItem"
import { FieldArray, useField } from "formik"
import { useTranslation } from "react-i18next"
import styles from "./CreatableGroupedSelect.module.scss"

export interface CreatableGroupedSelectProps {
    name: string
    label: string
    tooltip?: string
    id: string
}

const CreatableGroupedSelect: React.FC<CreatableGroupedSelectProps> = props => {
    const { name, label, tooltip, id } = props
    const { t } = useTranslation()
    const [field] = useField(name)

    return (
        <FieldArray
            name={name}
            render={({ push, remove }) => (
                <>
                    <Form.Group controlId={id}>
                        <Form.Label>
                            {label}
                            {tooltip && <InfoIcon id={`tooltip${id}`} content={tooltip} />}
                        </Form.Label>
                        <CreatableSelect
                            value={{ label: t(formTranslation.enterText), value: "" }}
                            className={styles.creatableGroupedSelect}
                            options={[]}
                            noOptionsMessage={() => null}
                            formatCreateLabel={(inputValue: string) => `${t(formTranslation.addPhrase)}: ${inputValue}`}
                            onChange={option => {
                                if (option) {
                                    push(option.value)
                                }
                            }}
                            hideDropdownIndicator
                        />
                    </Form.Group>
                    <div>
                        {field.value.map((v: string, index: number) => (
                            <RemovableListItem
                                key={index}
                                onDelete={() => {
                                    remove(index)
                                }}
                            >
                                {v}
                            </RemovableListItem>
                        ))}
                    </div>
                </>
            )}
        />
    )
}

export default CreatableGroupedSelect
