import React from "react"
import styles from "./NotificationSection.module.scss"
import FormAccordion from "../FormAccordion/FormAccordion"
import NotificationItem from "../NotificationItem/NotificationItem"
import { DeleteNewsState, Notification } from "../../models/notification"
import { useTranslation } from "react-i18next"
import ScalableList from "../ScalableList/ScalableList"
import { AcceptInviteOperatorFunction } from "../../models/operator"

export interface NotificationSectionProps {
    data: Notification[]
    title: string
    firstViewCount?: number
    loading?: boolean
    onNotificationClose?: (notificationId: string) => void
    onNotificationClick?: (link: string) => void
    notificationClosingState?: DeleteNewsState
    onAcceptInviteOperator?: AcceptInviteOperatorFunction
}

const NotificationSection: React.FC<NotificationSectionProps> = props => {
    const {
        data,
        title,
        firstViewCount = 3,
        loading = false,
        onNotificationClose,
        onNotificationClick,
        notificationClosingState = {},
        onAcceptInviteOperator
    } = props
    const handleClose = (notification: Notification) =>
        !notification.NeedApprove && notification.Type !== "error" && notification.Type !== "importFiles"
            ? onNotificationClose
            : undefined
    const { t } = useTranslation()

    return (
        <FormAccordion
            title={`${title} (${data.length})`}
            className={styles.notificationSection}
            loading={loading}
            initialOpened
        >
            <ScalableList
                t={t}
                items={data}
                limit={firstViewCount}
                mainContainerClassName={styles.notificationSection__mainItemsList}
                restContainerClassName={styles.notificationSection__restItemsContainer}
                toggleClassName={styles.notificationSection__restItemsToggle}
            >
                {item => (
                    <NotificationItem
                        key={item.Timestamp}
                        notification={item}
                        className={styles.notificationSection__item}
                        onClose={handleClose(item)}
                        onClick={onNotificationClick}
                        closing={notificationClosingState[item.Id]}
                        onAcceptInviteOperator={onAcceptInviteOperator}
                    />
                )}
            </ScalableList>
        </FormAccordion>
    )
}

export default NotificationSection
