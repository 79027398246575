import React, { useCallback } from "react"
import { FormikQueueFormProps } from "../FormikQueueForm/FormikQueueForm"
import { preventSubmitOnEnter } from "../../utility/common/preventSubmitOnEnter"
import { Form } from "react-bootstrap"
import { nameof } from "../../utility/common/nameof"
import { MetricToAdd, MetricValue, QueueValues } from "../../models/queueValues"
import { v4 as uuidV4 } from "uuid"
import { FieldArray } from "formik"
import QueueMetric from "./controls/QueueMetric/QueueMetric"
import LoadingButton from "../LoadingButton/LoadingButton"
import { formTranslation } from "../../locales/form"
import { shallowEqual, useSelector } from "react-redux"
import { selectCreateQueueState, selectUpdateQueueState } from "../../store/queues/selectors"
import { TFunction } from "i18next"
import { testId } from "../../utility/tests/testId"

const tNamespace = "queues:settings."

const getTitle = (metric: MetricToAdd, t: TFunction) => {
    switch (metric) {
        case MetricToAdd.Awt:
            return t(`${tNamespace}awt`)
        case MetricToAdd.FinishedDialogs:
            return t(`${tNamespace}handled-lost-chats-interval`)
        case MetricToAdd.FinishedDialogsDaily:
            return t(`${tNamespace}handled-lost-chats-reset`)
    }
}

const getDefaultValues = (hr = 0, min = 0) => ({
    id: uuidV4(),
    hr,
    min
})

const QueueFormMetrics: React.FC<FormikQueueFormProps> = props => {
    const { t, queue, values, handleSubmit } = props
    const asyncState = useSelector(queue ? selectUpdateQueueState : selectCreateQueueState, shallowEqual)

    const metricOptions = [MetricToAdd.Awt, MetricToAdd.FinishedDialogs, MetricToAdd.FinishedDialogsDaily].map(m => ({
        label: getTitle(m, t),
        value: m
    }))

    const handleAddMetric = useCallback((metric: string, push: (metric: MetricValue) => void) => {
        switch (metric) {
            case MetricToAdd.Awt:
            case MetricToAdd.FinishedDialogs:
                push({
                    type: metric,
                    value: getDefaultValues(0, 30)
                })
                break
            case MetricToAdd.FinishedDialogsDaily:
                push({
                    type: metric,
                    value: getDefaultValues(0, 0)
                })
        }
    }, [])

    return (
        <Form className="queue-form" onSubmit={handleSubmit} onKeyPress={preventSubmitOnEnter}>
            <div className="queue-form__content">
                <div className="queue-form__section">
                    <FieldArray
                        name={nameof<QueueValues>("metrics")}
                        render={({ remove, push }) => (
                            <>
                                <Form.Group controlId="formMetrics">
                                    <Form.Control
                                        as="select"
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            handleAddMetric(e.target.value, push)
                                        }}
                                        value=""
                                    >
                                        <option value="" hidden>
                                            {t(`${tNamespace}select-metric`)}
                                        </option>
                                        {metricOptions.map(option => (
                                            <option value={option.value} key={option.value}>
                                                {option.label}
                                            </option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>
                                {values.metrics.map((metric, index) => (
                                    <QueueMetric
                                        key={`queue-metric-${metric.type}-${index}`}
                                        index={index}
                                        metric={metric}
                                        t={t}
                                        onDelete={() => remove(index)}
                                    />
                                ))}
                            </>
                        )}
                    />
                </div>
            </div>
            <div className="queue-form__footer">
                <LoadingButton
                    type="submit"
                    loading={asyncState.inProcess}
                    variant="primary"
                    testId={testId.saveQueue}
                    block
                >
                    {t(formTranslation.save)}
                </LoadingButton>
            </div>
        </Form>
    )
}

export default QueueFormMetrics
