import React from "react"
import styles from "./Button.module.scss"
import Button, { ButtonProps } from "react-bootstrap/Button"
import cn from "classnames"

export interface IButtonProps extends ButtonProps {
    testId?: string
}

const ButtonPrimitive: React.FC<IButtonProps> = props => {
    const { children, testId, variant = "primary", className, ...buttonProps } = props

    return (
        <Button {...buttonProps} variant={variant} data-testid={testId} className={cn(styles.button, className)}>
            {children}
        </Button>
    )
}

export default ButtonPrimitive
