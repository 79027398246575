import React from "react"
import { Form } from "react-bootstrap"
import { Formik, FormikProps } from "formik"
import { useTranslation } from "react-i18next"
import "./IndividualQueueForm.scss"
import { Queue, UpdateIndividualQueuesRequest, IndividualTimeoutSettings } from "../../models/queue"
import "rc-slider/assets/index.css"
import Slider from "../Slider/Slider"
import { preventSubmitOnEnter } from "../../utility/common/preventSubmitOnEnter"
import { nameof } from "../../utility/common/nameof"
import { WithT } from "i18next"
import IndividualTimeoutSettingsInput, { IndividualTimeoutSettingsValues } from "./IndividualTimeoutSettingsInput"
import { formTranslation } from "../../locales/form"
import { useSelector, useDispatch, shallowEqual } from "react-redux"
import { selectUpdateIndividualQueuesState } from "../../store/queues/selectors"
import { updateIndividualQueues } from "../../store/queues/thunks"
import { Time } from "../../utility/common/time"
import LoadingButton from "../LoadingButton/LoadingButton"
import { testId } from "../../utility/tests/testId"

const tNamespace = "queues:form."

interface IndividualQueueValues {
    priority: number
    timeoutValues: IndividualTimeoutSettingsValues
}

interface Props {
    queue: Queue
    onClose: () => void
}

const FormikIndividualQueueForm: React.FC<Props & FormikProps<IndividualQueueValues> & WithT> = props => {
    const { t, values, handleSubmit, setFieldValue } = props

    const handlePriorityChange = (v: number) => setFieldValue(nameof<IndividualQueueValues>("priority"), v, false)

    const updateState = useSelector(selectUpdateIndividualQueuesState, shallowEqual)

    return (
        <Form className="individual-queue-form" onSubmit={handleSubmit} onKeyPress={preventSubmitOnEnter}>
            <div className="individual-queue-form__content">
                <div className="queue-form__section">
                    <Form.Group controlId="formPriority">
                        <Form.Label>{t(`${tNamespace}priority`)}</Form.Label>
                        <Slider
                            min={0}
                            max={1000}
                            step={1}
                            marks={{ 0: "0", 1000: "1000" }}
                            onChange={handlePriorityChange}
                            value={values.priority}
                        />
                    </Form.Group>
                    <label>{t(`${tNamespace}timeout-queue-general`)}</label>
                    <IndividualTimeoutSettingsInput name={nameof<IndividualQueueValues>("timeoutValues")} />
                </div>
            </div>
            <div className="individual-queue-form__footer">
                <LoadingButton
                    type="submit"
                    loading={updateState.inProcess}
                    variant="primary"
                    testId={testId.saveQueue}
                    block
                >
                    {t(formTranslation.save)}
                </LoadingButton>
            </div>
        </Form>
    )
}

const getValuesFromQueue = (queue: Queue): IndividualQueueValues => {
    const { Hr, Min, Sec } = Time.msToTimeSpan(queue.IndividualTimeoutSettings.TimeoutMSec)
    const timeoutValues = {
        timeoutHr: Hr,
        timeoutMin: Min,
        timeoutSec: Sec
    }

    return {
        priority: queue.IndividualPriority,
        timeoutValues: timeoutValues
    }
}

const getTimeoutSettings = (values: IndividualTimeoutSettingsValues): IndividualTimeoutSettings => {
    return {
        TimeoutMSec:
            Time.hoursToMs(values.timeoutHr) + Time.minutesToMs(values.timeoutMin) + Time.secondsToMs(values.timeoutSec)
    }
}

const buildUpdateQueueRequest = (values: IndividualQueueValues): UpdateIndividualQueuesRequest => {
    return {
        Priority: values.priority,
        TimeoutSettings: getTimeoutSettings(values.timeoutValues)
    }
}

const IndividualQueueForm: React.FC<Props> = props => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { queue, onClose } = props

    return (
        <Formik
            enableReinitialize={true}
            initialValues={getValuesFromQueue(props.queue)}
            onSubmit={(values: IndividualQueueValues) => {
                dispatch(updateIndividualQueues(queue.TenantId, queue.Id, buildUpdateQueueRequest(values), onClose))
            }}
        >
            {formikProps => <FormikIndividualQueueForm {...props} {...formikProps} t={t} />}
        </Formik>
    )
}

export default IndividualQueueForm
