import React from "react"
import { WithT } from "i18next"
import DebouncedValidatableInput from "../../../../ValidatableInput/DebouncedValidatableInput"
import { useAppSelector } from "../../../../../store/hooks"
import { selectCatalogsMap, selectCatalogsState } from "../../../../../store/knowledgeBase/selectors"
import { formTranslation } from "../../../../../locales/form"

export interface QueueFastReplyTemplatesProps extends WithT {
    fieldName: string
}

const QueueFastReplyTemplates: React.FC<QueueFastReplyTemplatesProps> = props => {
    const { t, fieldName } = props
    const catalogsState = useAppSelector(selectCatalogsState)
    const catalogsMap = useAppSelector(selectCatalogsMap)

    const catalogs = catalogsState.data?.map(code => {
        const tree = catalogsMap[code]
        const item = tree.items[tree.rootId]

        return {
            id: item.id,
            title: item.data.title
        }
    })

    return (
        <DebouncedValidatableInput
            as="select"
            id="formQueueFastReplyTemplates"
            name={fieldName}
            label={""}
            disabled={!catalogs?.length}
        >
            <option value="">{t(formTranslation.nothingSelected)}</option>
            {catalogs?.map(({ id, title }) => (
                <option value={id} key={id}>
                    {title}
                </option>
            ))}
        </DebouncedValidatableInput>
    )
}

export default QueueFastReplyTemplates
