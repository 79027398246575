import React, { useEffect, useState } from "react"
import styles from "./QueueForAutomaticProcessing.module.scss"
import { MonitoringOverview } from "../../models/queue"
import QueueCardBase from "../QueueCardBase/QueueCardBase"
import IconButton from "../IconButton/IconButton"
import { useTranslation } from "react-i18next"
import { faSync } from "@fortawesome/pro-light-svg-icons/faSync"
import AsyncState from "../../core/asyncState"
import { TaskOfAutomaticProcessing } from "../../models/task"
import MonitoringOverviewInfoItem, { MonitoringOverviewInfoItemError } from "./MonitoringOverviewInfoItem"
import QueueInfoItemLoader from "../QueueInfoItem/QueueInfoItemLoader"
import { Channel, ChannelNames } from "../../models/channel"
import QueueForAutomaticProcessingBody from "./QueueForAutomaticProcessingBody"
import { ClassProps } from "../../utility/common/props"
import cn from "classnames"
import Async from "../Async/Async"
import ErrorMessage from "../ErrorMessage/ErrorMessage"
import QueueForAutomaticProcessingContentLoader from "./QueueForAutomaticProcessingContentLoader"
import { useOpenedState } from "../../utility/common/useOpenedState"

const queueNameKey = "queues:dialogs-on-automatic-processing.queue-name"

interface Props extends ClassProps {
    overviewState: AsyncState<MonitoringOverview>
    channelNames: ChannelNames
    onTaskClick: (task: TaskOfAutomaticProcessing) => void
    tasksState: AsyncState<TaskOfAutomaticProcessing[]>
    onQueueOpen: () => void
    onUpdate: (isOpened: boolean) => void
    categoryId: string
    channels?: Channel[]
}

const QueueForAutomaticProcessingContent: React.FC<Props> = props => {
    const { overviewState, onUpdate, onQueueOpen, tasksState, channelNames, onTaskClick, className, categoryId } = props
    const [openedOnce, setOpenedOnce] = useState(false)
    const [opened, toggleOpen] = useOpenedState(false)
    const { t } = useTranslation()
    const handleUpdate = () => onUpdate(opened)
    const isLoading = tasksState.inProcess || overviewState.inProcess

    useEffect(() => {
        if (opened && !openedOnce) {
            onQueueOpen()
            setOpenedOnce(true)
        }
    }, [onQueueOpen, opened, openedOnce])

    return (
        <QueueCardBase
            title={t(queueNameKey)}
            isOpened={opened}
            onToggle={toggleOpen}
            className={cn(styles.queueForAutomaticProcessing, className)}
            items={
                <Async
                    dataState={overviewState}
                    processView={<QueueInfoItemLoader />}
                    errorView={() => <MonitoringOverviewInfoItemError t={t} />}
                >
                    {({ data }) => <MonitoringOverviewInfoItem t={t} overview={data} categoryId={categoryId} />}
                </Async>
            }
            buttons={
                <IconButton
                    as="div"
                    icon={faSync}
                    variant="link"
                    onClick={handleUpdate}
                    disabled={isLoading}
                    spin={isLoading}
                    className={styles.queueForAutomaticProcessing__button}
                />
            }
        >
            <Async
                dataState={tasksState}
                processView={<QueueForAutomaticProcessingContentLoader />}
                errorView={({ message }) => <ErrorMessage text={message} />}
            >
                {({ data }) => (
                    <QueueForAutomaticProcessingBody
                        channelsNames={channelNames}
                        tasks={data}
                        onTaskClick={onTaskClick}
                    />
                )}
            </Async>
        </QueueCardBase>
    )
}

export default QueueForAutomaticProcessingContent
