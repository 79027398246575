import React, { useRef } from "react"
import { Button, Modal, ModalProps } from "react-bootstrap"
import { formTranslation } from "../../locales/form"
import { useTranslation } from "react-i18next"
import { Formik, FormikProps } from "formik"
import style from "./CommonModal.module.scss"
import LoadingButton from "../LoadingButton/LoadingButton"
import { testId } from "./CommonModal.const"

export interface CommonModalProps<T> extends ModalProps {
    show: boolean
    onClose: () => void
    onSubmit: (values: T) => void
    headerText: string
    closeBtnText?: string
    submitBtnText?: string
    renderBody?: (formikProps: FormikProps<T>) => JSX.Element
    initialValues?: T
    isLoading?: boolean
    isCenterModal?: boolean
}

function CommonModal<T>(props: CommonModalProps<T>) {
    const {
        show,
        onClose,
        onSubmit,
        headerText,
        submitBtnText,
        closeBtnText,
        initialValues,
        renderBody,
        isLoading,
        isCenterModal = false,
        ...modalProps
    } = props
    const { t } = useTranslation()
    const formRef = useRef<FormikProps<T>>(null)

    return (
        <Modal
            {...modalProps}
            centered={isCenterModal}
            show={show}
            onHide={onClose}
            dialogClassName={style.modalContainer}
            contentClassName={style.modalContainer__content}
        >
            <Modal.Header className={style.modalContainer__header}>{headerText}</Modal.Header>
            {initialValues && renderBody && (
                <Modal.Body className={style.modalContainer__body}>
                    <Formik<T> initialValues={{ ...initialValues }} onSubmit={onSubmit} innerRef={formRef}>
                        {formikProps => renderBody(formikProps)}
                    </Formik>
                </Modal.Body>
            )}
            <Modal.Footer className={style.modalContainer__footer}>
                <Button variant="light" onClick={onClose} data-testid={testId.cancelButton}>
                    {closeBtnText ? closeBtnText : t(formTranslation.cancel)}
                </Button>
                <LoadingButton
                    variant="primary"
                    type="submit"
                    onClick={() => {
                        if (formRef.current) {
                            formRef.current.handleSubmit()
                        }
                    }}
                    loading={isLoading}
                    data-testid={testId.submitButton}
                >
                    {submitBtnText ? submitBtnText : t(formTranslation.apply)}
                </LoadingButton>
            </Modal.Footer>
        </Modal>
    )
}

export default CommonModal
