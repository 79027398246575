import React, { RefObject, useRef } from "react"
import cn from "classnames"
import { faPaperclip, faArrowToTop, faTrash } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ClassProps } from "../../utility/common/props"
import style from "./FileUploader.module.scss"
import { useTranslation } from "react-i18next"
import { UploadedFile, uploadFile } from "../../utility/common/files"
import { useField } from "formik"
import { Form } from "react-bootstrap"
import { testId } from "../../utility/tests/testId"
import { translateIfPossible } from "../../utility/common/language"

export interface FileUploaderProps extends ClassProps {
    name: string
    disabled?: boolean
    includeRowFile?: boolean
}

export type FileUploaderFileType = UploadedFile & { File?: File }

const tNamespace = "channel:form."

const FileUploader: React.FC<FileUploaderProps> = props => {
    const { t, i18n } = useTranslation()
    const { className, name, disabled = false, includeRowFile = false } = props
    const inputFileRef = useRef<HTMLInputElement>(null)
    const linkFileRef = useRef<HTMLAnchorElement>(null)
    const [field, meta, { setValue, setTouched }] = useField<FileUploaderFileType | undefined | null>(name)
    const error = meta.touched && meta.error ? translateIfPossible(meta.error, t, i18n) : undefined
    const isFileControlAvailable = !disabled && field.value
    const handleClickRedirect = (elementRef: RefObject<HTMLInputElement | HTMLAnchorElement>) => {
        if (elementRef && elementRef.current) {
            elementRef.current.click()
        }
    }

    const handleSelectFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files == null || e.target.files.length === 0) {
            return
        }
        const selectedFile = e.target.files[0]
        const attachment: FileUploaderFileType = await uploadFile(selectedFile)

        if (includeRowFile) {
            attachment.File = selectedFile
        }

        setValue(attachment)
        setTouched(true)
    }

    const handleFileDelete = () => {
        setValue(null)
        if (inputFileRef.current) {
            inputFileRef.current.value = ""
        }
    }

    return (
        <>
            <div className={cn(style.fileUploader, className)} data-testid={testId.fileUploaderInput}>
                <FontAwesomeIcon
                    size={"lg"}
                    icon={faPaperclip}
                    className={cn(
                        style.fileUploader__icon,
                        style.fileUploader__icon_left,
                        disabled && style.fileUploader__icon_disabled
                    )}
                    onClick={() => {
                        !disabled && handleClickRedirect(inputFileRef)
                    }}
                />
                <div>{field.value ? field.value.Name : t(`${tNamespace}add-file`)}</div>
                <div className={style.fileUploader__controls}>
                    <FontAwesomeIcon
                        icon={faTrash}
                        size={"lg"}
                        className={cn(
                            style.fileUploader__icon,
                            !isFileControlAvailable && style.fileUploader__icon_disabled
                        )}
                        onClick={isFileControlAvailable ? handleFileDelete : undefined}
                    />
                    <FontAwesomeIcon
                        icon={faArrowToTop}
                        size={"lg"}
                        className={cn(
                            style.fileUploader__icon,
                            !isFileControlAvailable && style.fileUploader__icon_disabled
                        )}
                        onClick={() => {
                            isFileControlAvailable && handleClickRedirect(linkFileRef)
                        }}
                    />
                </div>
                <a
                    href={field.value ? field.value.Url + "?file_name=" + field.value.Name : ""}
                    className={style.fileUploader__fileLink}
                    ref={linkFileRef}
                    target="_blank"
                    rel="noopener noreferrer"
                />
            </div>
            <Form.Control
                type="file"
                style={{ display: "none" }}
                ref={inputFileRef}
                onChange={handleSelectFile}
                disabled={disabled}
                isInvalid={!!error}
            />
            <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
        </>
    )
}

export default FileUploader
