import { faTimes } from "@fortawesome/pro-light-svg-icons/faTimes"
import cn from "classnames"
import { testId } from "../../utility/tests/testId"
import IconControl, { IconControlProps } from "../IconControl/IconControl"

type CloseButtonProps = Omit<IconControlProps, "icon">

const CloseButton: React.FC<CloseButtonProps> = props => {
    const { className, onClick, size, testId: dataTestId, disabled } = props

    return (
        <IconControl
            icon={faTimes}
            onClick={onClick}
            size={size}
            className={cn("btn-close", className)}
            testId={dataTestId ?? testId.btnClose}
            disabled={disabled}
        />
    )
}

export default CloseButton
