import { NewslineItem } from "../../../../models/knowledgeBase/newsline"
import { NewsListItem } from "../NewsListItem/NewsListItem"
import styles from "./NewsList.module.scss"

interface NewsListProps {
    list: NewslineItem[]
}

export const NewsList = ({ list }: NewsListProps) => {
    return (
        <ul className={styles.list}>
            {list.map(item => (
                <NewsListItem key={item.Id} item={item} />
            ))}
        </ul>
    )
}
