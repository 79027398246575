import { SlSettingsValues } from "../components/QueueForm/controls/SlSettingsInput"
import { TimeoutSettingsValues } from "../components/QueueForm/controls/TimeoutSettingsInput"
import { Settings } from "../types/settings"
import { ItemId } from "@atlaskit/tree/types"

export interface QueueValues {
    name: string
    priority: number
    taskSize: number
    slValues?: SlSettingsValues
    timeoutValues?: TimeoutSettingsValues
    metrics: MetricValue[]
    extraSettings: ExtraSettingsValue[]
}

export type MetricValue = AwtMetricValue | FinishedDialogsMetricValue | FinishedDialogsDailyMetricValue

export enum MetricToAdd {
    Awt = "Awt",
    FinishedDialogs = "FinishedDialogs",
    FinishedDialogsDaily = "FinishedDialogsDaily"
}

export enum ExtraSettings {
    DialogTimer = "DialogTimer",
    DialogFinish = "FinishDialog",
    AutoGreeting = "AutoGreeting",
    ClientSurvey = "ClientSurvey",
    AdditionalResendConfig = "AdditionalResendConfig",
    EmailChannels = "EmailChannels",
    FastReplyTemplates = "FastReplyTemplates",
    DialogGroups = "DialogGroups",
    AutoHolding = "AutoHolding",
    AllowCatalogs = "AllowCatalogs",
    QueueTransferRestriction = "QueueTransferRestriction"
}

// В будущем это будет union из всех возможных значений доп настроек по аналогии с MetricValue
export type ExtraSettingsValue =
    | DialogTimerValue
    | DialogFinishValue
    | AutoGreetingValue
    | ClientFormSurvey
    | AdditionalResendConfig
    | EmailChannels
    | FastReplyTemplates
    | AutoHolding
    | DialogGroups
    | AllowCatalogs
    | QueueTransferRestriction

export interface DialogTimerValue {
    type: ExtraSettings.DialogTimer
    value: DialogTimerValues
}

export type DialogFinishValue = {
    type: ExtraSettings.DialogFinish
    value: DialogFinishValues
}

export interface AutoGreetingValue {
    type: ExtraSettings.AutoGreeting
    value: AutoGreetingValues
}

export interface ClientFormSurvey {
    type: ExtraSettings.ClientSurvey
    value: ClientSurveyValues
}

export interface AdditionalResendConfig {
    type: ExtraSettings.AdditionalResendConfig
    value: AdditionalResendConfigValues
}

export interface EmailChannels {
    type: ExtraSettings.EmailChannels
    value: EmailChannelsValues
}

export interface FastReplyTemplates {
    type: ExtraSettings.FastReplyTemplates
    value: FastReplyTemplatesValues
}

export interface AutoHolding {
    type: ExtraSettings.AutoHolding
    value: AutoHoldingValues
}

export type DialogGroups = {
    type: ExtraSettings.DialogGroups
    value: DialogGroupsValues
}

export interface AllowCatalogs {
    type: ExtraSettings.AllowCatalogs
    value: AllowCatalogsValue
}

export interface QueueTransferRestriction {
    type: ExtraSettings.QueueTransferRestriction
    value: QueueTransferRestrictionValues
}

export interface AllowCatalogsValue {
    Allowed: Settings<ItemId>
}

export interface QueueTransferRestrictionValues {
    queues: string[]
}

export interface AwtMetricValue {
    type: MetricToAdd.Awt
    value: AwtSettingsValues
}

export interface FinishedDialogsMetricValue {
    type: MetricToAdd.FinishedDialogs
    value: FinishedDialogsSettingsValues
}

export interface FinishedDialogsDailyMetricValue {
    type: MetricToAdd.FinishedDialogsDaily
    value: FinishedDialogsSettingsValues
}

export interface DialogTimerValues {
    greenTimer: DaysHoursMinutesSecondsValues
    yellowTimer: DaysHoursMinutesSecondsValues
}

export type DialogFinishValues = {
    finishDialogArticle: string
}

export interface AutoGreetingValues {
    operatorAutoGreetingEnabled: boolean
    operatorAfterHoldGreetingEnabled: boolean
    template: string
}

export interface ClientSurveyValues {
    mainSurveyId: string
    shortSurveyId: string
}

export interface AdditionalResendConfigValues {
    enable: boolean
    template: string
}

export interface EmailChannelsValues {
    defaultChannelId: string
    additionalChannelsIds: string[]
}

export interface AutoHoldingValues {
    enabled: boolean
    triggerMessages: string[]
    messagesToSend: string[]
    timeout: DaysHoursMinutesSecondsValues
    multipleSending: boolean
}

export type DialogGroupsValues = {
    groups: string[]
}

export interface AwtSettingsValues {
    id: string
    hr: number
    min: number
}

export interface FinishedDialogsSettingsValues {
    id: string
    hr: number
    min: number
}

export interface HoursMinutesSettingsValues {
    id: string
    hr: number
    min: number
}

export interface DaysHoursMinutesSecondsValues {
    Days: number
    Hours: number
    Minutes: number
    Seconds: number
}

export interface FastReplyTemplatesValues {
    catalogId: string
}
