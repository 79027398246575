import React from "react"
import styles from "./Slider.module.scss"
import RcSlider from "rc-slider"
import "rc-slider/assets/index.css"
import { SliderProps as BaseSliderProps } from "rc-slider/lib/Slider"

interface SliderProps extends BaseSliderProps {
    ctValue?: string
    testId?: string
}

const Slider: React.FC<SliderProps> = props => {
    return (
        <div className={styles.slider}>
            <div className={styles.slider__body}>
                <RcSlider {...props} />
            </div>
            <div className={styles.slider__value} data-testid={props.testId}>
                {props.ctValue ?? props.value}
            </div>
        </div>
    )
}

export default Slider
