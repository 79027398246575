import { zoomIn, zoomOut, setTransform, resetTransform, zoomToElement, centerView, } from "../core/handlers/handlers.logic";
export var getControls = function (contextInstance) {
    return {
        instance: contextInstance,
        zoomIn: zoomIn(contextInstance),
        zoomOut: zoomOut(contextInstance),
        setTransform: setTransform(contextInstance),
        resetTransform: resetTransform(contextInstance),
        centerView: centerView(contextInstance),
        zoomToElement: zoomToElement(contextInstance),
    };
};
export var getState = function (contextInstance) {
    return {
        instance: contextInstance,
        state: contextInstance.transformState,
    };
};
export var getContext = function (contextInstance) {
    var ref = {};
    Object.assign(ref, getState(contextInstance));
    Object.assign(ref, getControls(contextInstance));
    return ref;
};
