import React from "react"
import styles from "./RemovableListItem.module.scss"
import { faTimes } from "@fortawesome/pro-light-svg-icons/faTimes"
import IconButton from "../../IconButton/IconButton"

export interface RemovableListItemProps {
    onDelete: () => void
    disabled?: boolean
}

const RemovableListItem: React.FC<RemovableListItemProps> = props => {
    const { children, onDelete, disabled } = props

    return (
        <div className={styles.removableListItem}>
            <span className={styles.removableListItem__title}>{children}</span>

            {!disabled && (
                <IconButton
                    icon={faTimes}
                    variant="link"
                    className={styles.removableListItem__deleteIcon}
                    onClick={onDelete}
                />
            )}
        </div>
    )
}

export default RemovableListItem
