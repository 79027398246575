import { Dialog, DialogBadge, IGetOperatorClientResponse } from "../../../models/Dialogs/dialog"
import { LinkedToOperatorClient } from "../../../models/Dialogs/linksToOperator"

export const getLinkedClientFromSubject = (
    subject: IGetOperatorClientResponse | Dialog | DialogBadge
): LinkedToOperatorClient => {
    const dialog = subject as Dialog
    const client = subject as IGetOperatorClientResponse

    return dialog.Client
        ? {
              OmniUserId: dialog.Client.OmniUserId,
              Firstname: dialog.Client.Firstname,
              Lastname: dialog.Client.Lastname,
              Middlename: dialog.Client.Middlename ?? "",
              ProjectId: "",
              Picture: dialog.Client.Picture
          }
        : {
              OmniUserId: client.Id,
              Firstname: client.Firstname,
              Lastname: client.Lastname,
              Middlename: client.Middlename ?? "",
              ProjectId: client.Project?.Id,
              Picture: client.Picture ?? ""
          }
}
