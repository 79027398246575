import { useDispatch, useSelector } from "react-redux"
import { selectCurrentProjectState } from "../store/projects/selectors"
import { useEffect } from "react"
import { changeProject } from "../store/users/thunks"
import { startApp } from "../store/config/thunks"
import { getCookie } from "../utility/auth/credentialHelper"

export const useProjectStarter = () => {
    const projectState = useSelector(selectCurrentProjectState)
    const dispatch = useDispatch()

    useEffect(() => {
        ;(async () => {
            const selectingProjectId = localStorage.getItem("selectingProjectId")

            if (selectingProjectId) {
                await dispatch(changeProject(selectingProjectId))
                localStorage.removeItem("selectingProjectId")
            }

            if (projectState.data?.id && projectState.data.id !== getCookie("CurrentCustomerId")) {
                await dispatch(changeProject(projectState.data.id))
            }

            if (!projectState.data) {
                dispatch(startApp())
            }
        })()
    }, [projectState.data])
}
