import React, { useCallback } from "react"
import "./QueueOperatorView.scss"
import { Operator } from "../../models/operator"
import OperatorCard from "../OperatorCard/OperatorCard"
import { useTranslation } from "react-i18next"
import TaskBoard from "../TaskBoard/TaskBoard"
import { Task } from "../../models/task"
import { Queue } from "../../models/queue"
import SettingsContextMenu from "../SettingsContextMenu/SettingsContextMenu"
import SettingsContextMenuItem from "../SettingsContextMenuItem/SettingsContextMenuItem"
import { useDispatch } from "react-redux"
import SettingsButton from "../SettingsButton/SettingsButton"
import cn from "classnames"
import { deleteFromQueue, makeOffline } from "../../store/operators/thunks"
import { ChannelNames } from "../../models/channel"

const tNamespace = "queues:card."

interface Props {
    operator: Operator
    boardQueue: Queue
    queues: Queue[]
    selectedTask: string | null
    selected?: boolean
    channelNames?: ChannelNames
    onTaskClick?: (task: Task, operator: Operator) => void
    onOperatorClick?: (operator: Operator) => void
}

const QueueOperatorView: React.FC<Props> = props => {
    const { operator, boardQueue, queues, onTaskClick, selectedTask, onOperatorClick, selected, channelNames } = props
    const { t } = useTranslation()

    const dispatch = useDispatch()

    const handleClick = useCallback((task: Task) => onTaskClick && onTaskClick(task, operator), [onTaskClick, operator])
    const handleMakeOffline = useCallback(() => {
        dispatch(makeOffline(operator.Id))
    }, [dispatch, operator.Id])
    const handleDeleteFromQueue = useCallback(() => {
        dispatch(
            deleteFromQueue(operator.Id, {
                TenantId: boardQueue.TenantId,
                QueueId: boardQueue.Id
            })
        )
    }, [boardQueue.Id, boardQueue.TenantId, dispatch, operator.Id])

    const items = [
        <SettingsContextMenuItem
            key="make-offline"
            id="make-offline"
            text={t(`${tNamespace}make-offline`)}
            onClick={handleMakeOffline}
        />,
        <SettingsContextMenuItem
            key="delete-from-queue"
            id="delete-from-queue"
            text={t(`${tNamespace}delete-from-queue`)}
            onClick={handleDeleteFromQueue}
        />
    ]
    return (
        <div className="queue-operator-view">
            <div className={cn("queue-operator-view__operator", selected && "queue-operator-view__operator_selected")}>
                <OperatorCard operator={operator} onClick={onOperatorClick} />
                <SettingsContextMenu className="queue-operator-view__settings" items={items}>
                    <SettingsButton className="queue-operator-view__settings-btn" />
                </SettingsContextMenu>
            </div>
            <div className="queue-operator-view__stats">
                <div className="queue-operator-view__stats-item">
                    <span className="queue-operator-view__stats-count">{operator.Tasks.length}</span>
                    <span className="queue-operator-view__stats-field-name">{t(`${tNamespace}tasks-count`)}</span>
                </div>
            </div>
            <div className="queue-operator-view__tasks">
                <TaskBoard
                    boardQueue={boardQueue}
                    queues={queues}
                    tasks={operator.Tasks}
                    channelNames={channelNames}
                    onClick={handleClick}
                    selectedTask={selectedTask}
                />
            </div>
        </div>
    )
}

export default QueueOperatorView
