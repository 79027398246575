import React, { HTMLAttributes } from "react"
import styles from "./QueueCardBase.module.scss"
import { Accordion } from "react-bootstrap"
import { ClassProps } from "../../utility/common/props"
import cn from "classnames"
import ArrowToggle from "../ArrowToggle/ArrowToggle"

export interface QueueCardBaseProps extends ClassProps {
    title: string
    isOpened: boolean
    onToggle: () => void
    buttons?: JSX.Element
    items?: JSX.Element
    isNewQueue?: boolean
}

const ToggleButton: React.FC<HTMLAttributes<HTMLDivElement>> = props => {
    return <div {...props} />
}

const QueueCardBase: React.FC<QueueCardBaseProps> = props => {
    const { title, buttons, className, items, onToggle, children, isOpened, isNewQueue = false } = props

    return (
        <Accordion className={cn(styles.queueCardBase, className)} defaultActiveKey={isNewQueue ? "0" : undefined}>
            <div className={styles.queueCardBase__header}>
                <div className={styles.queueCardBase__title}>{title}</div>
                {buttons && <div className={styles.queueCardBase__buttons}>{buttons}</div>}
            </div>
            {items && (
                <div className={cn(styles.queueCardBase__items, isNewQueue && styles.queueCardBase__items_new)}>
                    {items}
                </div>
            )}
            <Accordion.Collapse eventKey="0">
                <div className={cn(styles.queueCardBase__body, isNewQueue && styles.queueCardBase__body_new)}>
                    {children}
                </div>
            </Accordion.Collapse>
            <Accordion.Toggle
                className={styles.queueCardBase__buttonToggle}
                as={ToggleButton}
                eventKey="0"
                onClick={onToggle}
            >
                {!isNewQueue && <ArrowToggle opened={isOpened} />}
            </Accordion.Toggle>
        </Accordion>
    )
}

export default QueueCardBase
