import { Operator } from "../../models/operator"
import cn from "classnames"
import { ClassProps } from "../../utility/common/props"
import UserCard from "../UserCard/UserCard"
import styles from "./OperatorCard.module.scss"
import { useTranslation } from "react-i18next"
import OperatorStatusContainer from "../OperatorStatusContainer/OperatorStatusContainer"
import { formatDistanceToNowLocal } from "../../utility/common/time"
import { useProjectSettingCheck } from "../../utility/common/useProjectSettingCheck"
import { SystemProjectSettings } from "../../models/projectSettings"
import { operatorStatusColors } from "../../helpers/color"
import { useSelector } from "react-redux"
import { selectOperatorStatuses } from "../../store/userOperator/selectors"

interface Props extends ClassProps {
    operator: Operator
    onClick?: (operator: Operator) => void
    isDisplayId?: boolean
}

const OperatorCard: React.FC<Props> = props => {
    const { className, operator, onClick, isDisplayId = false } = props
    const { t } = useTranslation()
    const isDisplayIdFromSettings = useProjectSettingCheck<SystemProjectSettings, "DisplayOperatorIdEnabled">(
        "DisplayOperatorIdEnabled"
    )

    const operatorStatuses = useSelector(selectOperatorStatuses)
    const foundStatus = operatorStatuses.find(s => s.Description === operator.Status.Description)
    const statusColor = foundStatus?.Color ?? operatorStatusColors[operator.Status.Description]

    return (
        <UserCard
            className={cn(styles.operatorCard, className)}
            infoClass={styles.operatorCard__info}
            firstName={operator.FirstName}
            lastName={operator.LastName}
            onClick={onClick ? () => onClick(operator) : undefined}
        >
            {isDisplayId && isDisplayIdFromSettings && <div className={styles.operatorCard__id}>{operator.Login}</div>}
            <div className={styles.operatorCard__status}>
                <OperatorStatusContainer
                    status={operator.Status}
                    t={t}
                    className={styles.operatorCard__item}
                    currentColor={statusColor}
                />
                <div className={styles.operatorCard__duration}>
                    {formatDistanceToNowLocal(operator.Status.UpdatedAt)}
                </div>
            </div>
        </UserCard>
    )
}

export default OperatorCard
