import i18next, { TFunction } from "i18next"
import i18n from "../../i18n"
import { enGB, ru } from "date-fns/locale"
import { format, formatDistance, formatDistanceStrict, formatDistanceToNow } from "date-fns"
import { getDateLocale } from "../../locales"

export const DAY_IN_MS = 86_400_000

export interface TimeSpan {
    Hr: number
    Min: number
    Sec: number
}

const secondDuration = 1000
const minuteDuration = secondDuration * 60
const hourDuration = minuteDuration * 60
const dayDuration = hourDuration * 24

const dateLocales: { [key: string]: Locale } = {
    enGB,
    ru,
    "ru-RU": ru,
    en: enGB
}

export type TimeInHms = {
    hours: number
    minutes: number
    seconds: number
}

export class Time {
    static msToTimeSpan(ms: number) {
        const seconds = Math.floor(ms / 1000)
        const secondsPart = seconds % 60

        const minutes = Math.floor(seconds / 60)
        const minutesPart = minutes % 60

        const hours = Math.floor(minutes / 60)
        const hoursPart = hours % 24

        const daysPart = Math.floor(hours / 24)

        return {
            Days: daysPart,
            Hr: hoursPart,
            Min: minutesPart,
            Sec: secondsPart
        }
    }

    static msToSeconds(ms: number) {
        return Math.floor(ms / 1000)
    }

    static msToMinutes(ms: number) {
        return Math.floor(Time.msToSeconds(ms) / 60)
    }

    static msToHours(ms: number) {
        return Math.floor(Time.msToMinutes(ms) / 60)
    }

    static humanizeDuration(ms: number, t: TFunction) {
        if (ms < 1000) return t("queues:time.zero-seconds")

        let res = ""

        const { Hr, Min, Sec } = Time.msToTimeSpan(ms)

        if (Hr >= 1) res += Hr + t("queues:time.hour")
        if (Min >= 1) res += Min + t("queues:time.minute")
        if (Sec >= 1 && !Hr) res += Sec + t("queues:time.second")

        return res === "" ? t("queues:time.zero-seconds") : res
    }

    static msToTimeString(ms: number) {
        const { Hr, Min } = Time.msToTimeSpan(ms)
        return `${Hr > 9 ? "" : 0}${Hr}:${Min > 9 ? "" : 0}${Min}`
    }

    static daysToMs(days: number) {
        return Time.hoursToMs(24 * days)
    }

    static hoursToMs(hr: number) {
        return Time.minutesToMs(60 * hr)
    }

    static minutesToMs(min: number) {
        return Time.secondsToMs(60 * min)
    }

    static secondsToMs(sec: number) {
        return sec * 1000
    }

    static secondsToHours(sec: number) {
        return Time.msToHours(Time.secondsToMs(sec))
    }

    static addDaysToDate = (date: Date, days: number) => new Date(date.getTime() + days * dayDuration)

    static startOfDay = (date: Date) => new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0)

    static endOfDay = (date: Date) => new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59, 999)

    static getMonthsArray = (translate: TFunction) => {
        const tNameSpace = "date:"
        return [
            translate(`${tNameSpace}months-and-days.january`),
            translate(`${tNameSpace}months-and-days.february`),
            translate(`${tNameSpace}months-and-days.march`),
            translate(`${tNameSpace}months-and-days.april`),
            translate(`${tNameSpace}months-and-days.may`),
            translate(`${tNameSpace}months-and-days.june`),
            translate(`${tNameSpace}months-and-days.july`),
            translate(`${tNameSpace}months-and-days.august`),
            translate(`${tNameSpace}months-and-days.september`),
            translate(`${tNameSpace}months-and-days.october`),
            translate(`${tNameSpace}months-and-days.november`),
            translate(`${tNameSpace}months-and-days.december`)
        ]
    }

    static stampToLocalDate(timestamp: number) {
        const date = new Date(timestamp)
        return `${date.toLocaleTimeString(i18n.language, {
            hour: "2-digit",
            minute: "2-digit"
        })} ${date.toLocaleDateString(i18n.language)}`
    }
}

export const formatDistanceLocal = (date: Date | number, baseDate: Date | number) =>
    formatDistance(date, baseDate, {
        locale: dateLocales[i18next.language]
    })

export const formatDistanceToNowLocal = (date: Date | number) =>
    formatDistanceToNow(date, {
        locale: dateLocales[i18next.language]
    })

export const formatDistanceStrictLocal = (date: Date | number) =>
    formatDistanceStrict(date, Date.now(), {
        locale: dateLocales[i18next.language]
    })

export const formatMillisecondsToFullDate = (ms: number) => {
    const locale = { locale: getDateLocale(i18next.language) }
    return format(ms, "dd-MM-yyyy HH:mm:ss", locale)
}

export const formatMillisecondsToMMSS = (ms: number) => {
    const locale = { locale: getDateLocale(i18next.language) }

    return format(ms, "mm:ss", locale)
}

export const convertMsToHMS = (ms: number): TimeInHms => {
    const s = Math.floor(ms / 1000)
    const hours = Math.floor(s / 3600)
    const minutes = Math.floor((s % 3600) / 60)
    const seconds = (s % 3600) % 60
    return { hours, minutes, seconds }
}

export const convertTimeDigitToString = (digit: number): string => {
    return digit.toString().padStart(2, "0")
}

export const convertHMSToString = ({ hours, minutes, seconds }: TimeInHms): string => {
    const bHours = convertTimeDigitToString(hours)
    const bMinutes = convertTimeDigitToString(minutes)
    const bSeconds = convertTimeDigitToString(seconds)

    return `${bHours}:${bMinutes}:${bSeconds}`
}

export const beautifyMsToHMSString = (ms: number): string => {
    return convertHMSToString(convertMsToHMS(ms))
}

export const convertHMSToMs = ({ hours, minutes, seconds }: TimeInHms): number => {
    return (hours * 3600 + minutes * 60 + seconds) * 1000
}

export const convertHMSStringToMs = (hms: string): number => {
    const [hours, minutes, seconds] = hms.split(":")
    return convertHMSToMs({ hours: Number(hours), minutes: Number(minutes), seconds: Number(seconds) })
}

export const getHMSFromDate = (date: Date): TimeInHms => {
    const hours = date.getHours()
    const minutes = date.getMinutes()
    const seconds = date.getSeconds()
    return {
        hours,
        minutes,
        seconds
    }
}

export const formatToTwoDigits = (value: number | string): string => {
    const num = parseInt(value.toString(), 10)
    if (isNaN(num)) return "00"
    return num < 10 ? `0${num}` : num.toString().slice(0, 2)
}

export default formatDistanceLocal
