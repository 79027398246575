import React, { useEffect, useState } from "react"
import style from "./UpdataAppButton.module.scss"
import cn from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCloud } from "@fortawesome/pro-light-svg-icons/faCloud"
import { WithT } from "i18next"
import { useModal } from "../../utility/common/useModal"
import AlertDialog from "../AlertDialog/AlertDialog"
import * as serviceWorker from "../../serviceWorker"
import { FetchFileType, fetchPublicFile } from "../../helpers/file"

const localStorageKey = "LAST_UPDATE_DATA"
const tNamespace = "project:"
const UpdateAppButton: React.FC<WithT> = props => {
    const { t } = props
    const [isVisible, setIsVisible] = useState(false)
    const { modalOpen, openModal, closeModal, onExited } = useModal(() => (
        <AlertDialog
            show={modalOpen}
            title={t(`${tNamespace}update-title`)}
            message={t(`${tNamespace}update-text`)}
            submitBtnText={t(`${tNamespace}update-btn-text`)}
            cancelBtnText={t(`${tNamespace}later-btn-text`)}
            onClose={closeModal}
            onSubmit={clearCache}
            onExited={onExited}
        />
    ))
    const handleClick = () => {
        openModal()
    }
    const clearCache = async () => {
        serviceWorker.unregister()
        const cacheNames = await caches.keys()
        for (const name of cacheNames) {
            await caches.delete(name)
        }
        window.location.reload()
    }
    const checkAppVersion = async () => {
        try {
            const jsonRaw = await fetchPublicFile("version.json", FetchFileType.JSON, true)
            const updateData = JSON.stringify(jsonRaw)
            const savedData = localStorage.getItem(localStorageKey)
            if (savedData === null) {
                localStorage.setItem(localStorageKey, updateData)
            } else if (updateData !== savedData) {
                setIsVisible(true)
                localStorage.setItem(localStorageKey, updateData)
            }
        } catch (e) {
            console.error("Error checking app version:", e)
        }
    }
    useEffect(() => {
        const timer = setInterval(checkAppVersion, 15 * 1000 * 60)
        return () => {
            clearInterval(timer)
        }
    }, [])
    return (
        <div className={cn(style.updateAppContainer, isVisible && style.updateAppContainerOpen)} onClick={handleClick}>
            <FontAwesomeIcon icon={faCloud} className={style.updateAppContainer__icon} size={"2x"} />
            <div className={style.updateAppContainer__text}>{t(`${tNamespace}menu-item-update-app`)}</div>
        </div>
    )
}

export default UpdateAppButton
