import React from "react"
import AgentFormWrapper from "../../../AgentFormWrapper/AgentFormWrapper"
import DocLink from "../../../DocLink/DocLink"
import DebouncedValidatableInput from "../../../ValidatableInput/DebouncedValidatableInput"
import { nameof, nameof2 } from "../../../../utility/common/nameof"
import { AgentValues } from "../../../../models/agent"
import { FormikAgentFormProps } from "../../AgentForm"
import { ParamValues } from "../../../../models/parameterDeclaration"
import SlotSelectControl from "../../common/SlotSelectControl"
import { SlotType } from "../../../../models/slot"

const tNamespace = "agent:form.medods_integration."
const tFormNamespace = "agent:form."
const tLinkNamespace = "agent:link."

const FormikMEDODSIntegrationFormGeneral: React.FC<FormikAgentFormProps> = props => {
    const { asyncState, agentType, t, handleSubmit, disabled } = props

    return (
        <AgentFormWrapper
            agentType={agentType}
            onSubmit={handleSubmit}
            disabled={disabled}
            loading={asyncState.inProcess}
        >
            <DocLink type={agentType}>{t(`${tLinkNamespace}creating-agent-instructions`)}</DocLink>
            <div className="agent-form__section">
                <DebouncedValidatableInput
                    id="formTitle"
                    type="text"
                    name={nameof<AgentValues>("Id")}
                    label={t(`${tFormNamespace}title`)}
                />
                <SlotSelectControl
                    id="formSpecialistId"
                    name={nameof2<AgentValues, ParamValues>("Params", "specialist_slot_id")}
                    label={t(`${tNamespace}specialist-id`)}
                />
                <SlotSelectControl
                    id="formClinicSlotId"
                    name={nameof2<AgentValues, ParamValues>("Params", "clinic_slot_id")}
                    label={t(`${tNamespace}clinic-id`)}
                />
                <SlotSelectControl
                    type={SlotType.Calendar}
                    id="formResultSlotId"
                    name={nameof2<AgentValues, ParamValues>("Params", "result_slot_id")}
                    label={t(`${tNamespace}recording-result`)}
                />
                <DebouncedValidatableInput
                    id="formPrivatKey"
                    type="text"
                    name={nameof2<AgentValues, ParamValues>("Params", "private_key")}
                    label={t(`${tNamespace}secret-key`)}
                />
                <DebouncedValidatableInput
                    id="formIdentity"
                    type="text"
                    name={nameof2<AgentValues, ParamValues>("Params", "identity")}
                    label={t(`${tNamespace}access-id`)}
                />
            </div>
        </AgentFormWrapper>
    )
}

export default FormikMEDODSIntegrationFormGeneral
