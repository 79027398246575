import { createContext, Dispatch, SetStateAction } from "react"
import { UploadedFile } from "../../utility/common/files"

export interface DroppableAreaContextType {
    isDropped: boolean
    files: UploadedFile[] | File[]
    setFiles: Dispatch<SetStateAction<UploadedFile[] | File[]>>
}

export const droppableAreaContextDefaultValue: DroppableAreaContextType = {
    isDropped: false,
    files: [],
    setFiles: prev => prev
}

const DroppableAreaContext = createContext<DroppableAreaContextType>(droppableAreaContextDefaultValue)

export default DroppableAreaContext
