import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import AsyncState from "../../core/asyncState"
import { SystemError } from "../../core/error"
import { GetOperatorStatusesResponse, OperatorStatus, SelectedOperatorStatus } from "../../models/operatorStatus"
import { toSelectedOperatorStatusModel } from "../../utility/operatorStatus/convert"
import { resetReducerState } from "../action"

export type UserOperatorState = Readonly<{
    status: AsyncState<SelectedOperatorStatus>
    statuses: OperatorStatus[]
    connected: boolean
}>

const initialState: UserOperatorState = {
    status: AsyncState.create(),
    statuses: [],
    connected: false
}

interface UpdateStatusRetry {
    retryCallback: () => void
    retryAfterMs: number
}

const userOperator = createSlice({
    name: "userOperator",
    initialState,
    reducers: {
        updateStatusProcess(state, action: PayloadAction<UpdateStatusRetry | undefined>) {
            state.status = action.payload
                ? state.status.toProcessWithRetry(action.payload.retryCallback, action.payload.retryAfterMs)
                : state.status.toProcess()
        },
        updateStatusSuccess(state, action: PayloadAction<GetOperatorStatusesResponse>) {
            const newStatus = toSelectedOperatorStatusModel(action.payload.CurrentStatus, action.payload.Statuses)
            state.status = state.status.toSuccess(newStatus)
        },
        updateStatusesSuccess(state, action: PayloadAction<GetOperatorStatusesResponse>) {
            state.statuses = action.payload.Statuses
            state.status = state.status.toSuccess(
                toSelectedOperatorStatusModel(action.payload.CurrentStatus, action.payload.Statuses)
            )
        },
        updateStatusFailed(state, action: PayloadAction<SystemError>) {
            state.status = state.status.toFailed(action.payload)
        },
        setConnected(state) {
            state.connected = true
        },
        setDisconnected(state) {
            state.connected = false
        }
    },
    extraReducers(builder) {
        builder.addCase(resetReducerState, () => {
            return initialState
        })
    }
})

export const UserOperatorReducer = userOperator.reducer

export const actions = userOperator.actions
