import { CreateRoleRequest, UpdateManyRolesRequest, UpdateRoleRequest } from "../../models/role"
import { Dispatch } from "../../utility/common/storeHelper"
import * as constants from "./constants"
import rolesController from "../../api/controllers/roles"
import { actions } from "./slice"
import { handleHttpException } from "../handleHttpException"

export function getRoles(projectId: string) {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(actions.getRolesProcess())
            const roles = await rolesController.getRoles(projectId)
            dispatch(actions.getRolesSuccess(roles))
        } catch (e) {
            handleHttpException(e, constants.GET_ROLES_FAILED_MESSAGE, err => actions.getRolesFailed(err), dispatch)
        }
    }
}

export function getAllRoleSettings(projectId: string) {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(actions.getAllRoleSettingsProcess())
            const settings = await rolesController.getAllRoleSettings(projectId)
            dispatch(actions.getAllRoleSettingsSuccess(settings))
        } catch (e) {
            handleHttpException(
                e,
                constants.GET_ALL_ROLE_SETTINGS_FAILED_MESSAGE,
                err => actions.getAllRoleSettingsFailed(err),
                dispatch
            )
        }
    }
}

export function createRole(request: CreateRoleRequest, callback: () => void) {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(actions.createRoleProcess())
            const role = await rolesController.create(request)
            dispatch(actions.createRoleSuccess(role))
            callback()
        } catch (e) {
            handleHttpException(e, constants.CREATE_ROLE_FAILED_MESSAGE, err => actions.createRoleFailed(err), dispatch)
        }
    }
}

export function updateRole(id: string, request: UpdateRoleRequest, callback: () => void) {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(actions.updateRoleProcess())
            const role = await rolesController.update(id, request)
            dispatch(actions.updateRoleSuccess(role))
            callback()
        } catch (e) {
            handleHttpException(e, constants.UPDATE_ROLE_FAILED_MESSAGE, err => actions.updateRoleFailed(err), dispatch)
        }
    }
}

export function updateManyRoles(customerId: string, request: UpdateManyRolesRequest, callback: () => void) {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(actions.updateManyRolesProcess())
            const role = await rolesController.updateMany(customerId, request)
            dispatch(actions.updateManyRolesSuccess(role))
            callback()
        } catch (e) {
            handleHttpException(
                e,
                constants.UPDATE_ROLE_FAILED_MESSAGE,
                err => actions.updateManyRolesFailed(err),
                dispatch
            )
        }
    }
}

export const getAllGlobalPermissions = () => {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(actions.getAllGlobalPermissionsProcess())
            const settings = await rolesController.getAllGlobalPermissions()
            dispatch(actions.getAllGlobalPermissionsSuccess(settings))
        } catch (e) {
            handleHttpException(
                e,
                constants.GET_ALL_GLOBAL_PERMISSIONS_FAILED_MESSAGE,
                err => actions.getAllGlobalPermissionsFailed(err),
                dispatch
            )
        }
    }
}

export const deleteRole = (id: string) => {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(actions.deleteRoleProcess())
            await rolesController.delete(id)
            dispatch(actions.deleteRoleSuccess(id))
        } catch (e) {
            handleHttpException(e, constants.DELETE_ROLE_FAILED_MESSAGE, err => actions.deleteRoleFailed(err), dispatch)
        }
    }
}
