export enum MarkdownCustomLanguages {
    Buttons = "buttons",
    Sticker = "sticker",
    NextAnswer = "nextanswer",
    Markdown = "markdown",
    QuickActions = "quickactions",
    Widget = "widget",
    DebugWebchat = "scenario",
    BlockComparison = "comparison"
}
