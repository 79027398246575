import mime from "mime"
import filesController from "../../api/controllers/files"
import { IconDefinition } from "@fortawesome/fontawesome-svg-core"
import { faFile, faFileExcel, faFileImage, faFilePdf, faFileVideo, faFileWord } from "@fortawesome/pro-light-svg-icons"

export enum DragEvent {
    Drag = "drag",
    DragEnd = "dragend",
    DragEnter = "dragenter",
    DragLeave = "dragleave",
    DragOver = "dragover",
    DragStart = "dragstart",
    Drop = "drop"
}

const imageType = "image"
const fileType = "file"

enum imageTypes {
    jpeg = ".jpeg",
    jpg = ".jpg",
    png = ".png",
    svg = ".svg"
}

export enum FileType {
    image = "image",
    other = "other",
    video = "video"
}

export const specifyAttachmentType = (fileName: string) => {
    for (const type of Object.values(imageTypes)) {
        if (fileName.endsWith(type)) {
            return imageType
        }
    }

    return fileType
}

export const uploadFileToUrl = async (file: File) =>
    filesController.uploadFileB64({
        Name: file.name,
        Content: file
    })

export const uploadFile = async (file: File): Promise<UploadedFile> => {
    const url = await uploadFileToUrl(file)
    return {
        Url: url,
        ContentType: specifyAttachmentType(file.name),
        Name: file.name,
        Size: file.size
    }
}

export type UploadedFile = {
    Url: string
    ContentType: string
    Name: string
    Size: number
}

export const uploadFiles = async (files: File[]): Promise<UploadedFile[]> => {
    return await Promise.all(
        files.map(async file => {
            return await uploadFile(file)
        })
    )
}

export const getFileExt = (url: string): string => url.slice(url.lastIndexOf(".") + 1, url.length)

export const fileEndings = ["B", "KB", "MB", "GB", "TB"]

// Getting size in bytes
export const getBeautifiedFileSize = (size: number): string => {
    let resultStr = `${size} B`

    for (let i = 0; i < fileEndings.length; i++) {
        const currEndingIndex = fileEndings.length - i - 1
        const currSize = Math.floor(size / 1024 ** currEndingIndex)
        if (currSize > 0) {
            resultStr = `${currSize} ${fileEndings[currEndingIndex]}`
            return resultStr
        }
    }
    return resultStr
}

export const selectFileType = (mimeType: string): FileType => {
    switch (mimeType.split("/")[0]) {
        case "video":
            return FileType.video
        case "image":
            return FileType.image
        default:
            return FileType.other
    }
}

export const getFileInfo = (url: string): { itemType: FileType; fileExtension: string } => {
    const fileExtension = getFileExt(url)
    const mimeType = mime.getType(fileExtension) ?? ""
    const itemType = selectFileType(mimeType)

    return {
        itemType,
        fileExtension
    }
}

enum DocFile {
    doc = "doc",
    docx = "docx",
    rtf = "rtf",
    xls = "xls",
    xlsx = "xlsx",
    pdf = "pdf"
}

const selectDocIcon = (fileExtension: string): IconDefinition => {
    switch (fileExtension) {
        case DocFile.doc:
        case DocFile.docx:
        case DocFile.rtf:
            return faFileWord
        case DocFile.xls:
        case DocFile.xlsx:
            return faFileExcel
        case DocFile.pdf:
            return faFilePdf
        default:
            return faFile
    }
}

export const selectFileIcon = (itemType: FileType, fileExtension: string): IconDefinition => {
    switch (itemType) {
        case FileType.other:
            return selectDocIcon(fileExtension)
        case FileType.image:
            return faFileImage
        case FileType.video:
            return faFileVideo
    }
}
