import { ArticleView, SearchArticlesHighlights, SearchArticlesScores } from "../../../../models/article"

export function filterSearchData(
    articles: ArticleView[],
    highlights: SearchArticlesHighlights | undefined,
    scores: SearchArticlesScores | undefined,
    isNotKB: boolean
) {
    if (!isNotKB) {
        return { articles, highlights, scores }
    }

    const filteredArticles = articles.filter(article => !article.Scenario)

    const filteredHighlights = Object.fromEntries(
        Object.entries(highlights || {}).filter(([key]) => filteredArticles.some(article => article.Id === key))
    )

    const filteredScores = Object.fromEntries(
        Object.entries(scores || {}).filter(([key]) => filteredArticles.some(article => article.Id === key))
    )

    return {
        articles: filteredArticles,
        highlights: filteredHighlights,
        scores: filteredScores
    }
}
