import { ActionResult, Dispatch } from "../../utility/common/storeHelper"
import * as constants from "./constants"
import { actions } from "./slice"
import { handleHttpException } from "../handleHttpException"
import classifiersController from "../../api/controllers/classifiers"
import {
    CreateClassifierRequest,
    RetrainClassifierOptions,
    RollbackClassifierRequest,
    StartClassifierRequest,
    UpdateClassifierRequest
} from "../../models/classifier"
import { classifiersHub } from "../../api/instances/classifiersHub"
import { apiCallExceptionToSystemError } from "../../utility/common/apiCallExceptionToSystemError"
import { logError } from "../../utility/common/logError"
import { saveNotificationError } from "../notifications/thunks"
import { updateCurrentClassifier } from "./actions"

export const subscribeOnClassifierEvents = (projectId: string) => async (dispatch: Dispatch) => {
    try {
        await classifiersHub.subscribe(projectId)
    } catch (e) {
        const error = apiCallExceptionToSystemError(constants.SUBSCRIBE_ON_CLASSIFIER_EVENTS_FAILED, e)
        logError(error)
        saveNotificationError(dispatch, error)
    }
}

export const unsubscribeOnClassifierEvents = (projectId: string) => async (dispatch: Dispatch) => {
    try {
        await classifiersHub.unsubscribe(projectId)
    } catch (e) {
        const error = apiCallExceptionToSystemError(constants.UNSUBSCRIBE_ON_CLASSIFIER_EVENTS_FAILED, e)
        logError(error)
        saveNotificationError(dispatch, error)
    }
}

export const getClassifiers =
    (projectId: string): ActionResult<Promise<void>> =>
    async (dispatch: Dispatch) => {
        dispatch(actions.getClassifiersProcess())
        try {
            const classifiers = await classifiersController.getClassifiers(projectId)
            dispatch(actions.getClassifiersSuccess(classifiers))
        } catch (e) {
            handleHttpException(
                e,
                constants.FETCH_CLASSIFIERS_FAILED_MESSAGE,
                err => actions.getClassifiersFailed(err),
                dispatch
            )
        }
    }

export const getClassifierServers =
    (projectId: string): ActionResult<Promise<void>> =>
    async (dispatch: Dispatch) => {
        dispatch(actions.getClassifierServersProcess())
        try {
            const servers = await classifiersController.getServers(projectId)
            dispatch(actions.getClassifierServersSuccess(servers))
        } catch (e) {
            handleHttpException(
                e,
                constants.FETCH_CLASSIFIER_SERVERS_FAILED_MESSAGE,
                err => actions.getClassifierServersFailed(err),
                dispatch
            )
        }
    }

export const getClassifierModels =
    (projectId: string, configId: string): ActionResult<Promise<void>> =>
    async (dispatch: Dispatch) => {
        dispatch(actions.getModelsProcess())
        try {
            const models = await classifiersController.getClassifierModels(projectId, configId)
            dispatch(actions.getModelsSuccess({ configId, models }))
        } catch (e) {
            handleHttpException(
                e,
                constants.FETCH_CLASSIFIER_MODELS_FAILED_MESSAGE,
                err => actions.getModelsFailed(err),
                dispatch
            )
        }
    }

export const createClassifier =
    (request: CreateClassifierRequest, callback: () => void): ActionResult<Promise<void>> =>
    async (dispatch: Dispatch) => {
        dispatch(actions.createClassifierProcess())
        try {
            const createdClassifiers = await classifiersController.createClassifier(
                request.Classifier.ProjectId,
                request
            )
            dispatch(actions.createClassifierSuccess(createdClassifiers))
            callback()
        } catch (e) {
            handleHttpException(
                e,
                constants.CREATE_CLASSIFIER_FAILED_MESSAGE,
                err => actions.createClassifierFailed(err),
                dispatch
            )
        }
    }

export const updateClassifier =
    (request: UpdateClassifierRequest, callback: () => void): ActionResult<Promise<void>> =>
    async (dispatch: Dispatch) => {
        dispatch(actions.updateClassifierProcess())
        try {
            const updatedClassifier = await classifiersController.updateClassifier(
                request.Classifier.ProjectId,
                request.Classifier.ClassifierConfigId,
                request
            )
            dispatch(actions.updateClassifierSuccess(updatedClassifier))
            callback()
        } catch (e) {
            handleHttpException(
                e,
                constants.UPDATE_CLASSIFIER_FAILED_MESSAGE,
                err => actions.updateClassifierFailed(err),
                dispatch
            )
        }
    }

export const deleteClassifier =
    (projectId: string, configId: string, callback: () => void): ActionResult<Promise<void>> =>
    async (dispatch: Dispatch) => {
        dispatch(actions.deleteClassifierProcess())
        try {
            await classifiersController.deleteClassifier(projectId, configId)
            dispatch(actions.deleteClassifierSuccess(configId))
            callback()
        } catch (e) {
            handleHttpException(
                e,
                constants.DELETE_CLASSIFIER_FAILED_MESSAGE,
                err => actions.deleteClassifierFailed(err),
                dispatch
            )
        }
    }

export const activateClassifier =
    (projectId: string, configId: string): ActionResult<Promise<void>> =>
    async (dispatch: Dispatch) => {
        dispatch(actions.activateClassifierProcess())
        try {
            const classifier = await classifiersController.activateClassifier(projectId, configId)
            dispatch(actions.activateClassifierSuccess(classifier))
            updateCurrentClassifier(dispatch, classifier)
        } catch (e) {
            handleHttpException(
                e,
                constants.ACTIVATE_CLASSIFIER_FAILED_MESSAGE,
                err => actions.activateClassifierFailed(err),
                dispatch
            )
        }
    }

export const deactivateClassifier =
    (projectId: string, configId: string): ActionResult<Promise<void>> =>
    async (dispatch: Dispatch) => {
        dispatch(actions.deactivateClassifierProcess())
        try {
            const classifier = await classifiersController.deactivateClassifier(projectId, configId)
            dispatch(actions.deactivateClassifierSuccess(classifier))
            updateCurrentClassifier(dispatch, classifier)
        } catch (e) {
            handleHttpException(
                e,
                constants.DEACTIVATE_CLASSIFIER_FAILED_MESSAGE,
                err => actions.deactivateClassifierFailed(err),
                dispatch
            )
        }
    }

export const startTraining =
    (
        projectId: string,
        configId: string,
        active: boolean,
        options: RetrainClassifierOptions,
        callback: () => void
    ): ActionResult<Promise<void>> =>
    async (dispatch: Dispatch) => {
        dispatch(actions.startTrainingProcess())
        try {
            const request: StartClassifierRequest = {
                Active: active,
                RetrainOptions: options
            }
            const classifier = await classifiersController.startTraining(projectId, configId, request)
            dispatch(actions.startTrainingSuccess(classifier))
            updateCurrentClassifier(dispatch, classifier)
            callback()
        } catch (e) {
            handleHttpException(
                e,
                constants.START_TRAINING_CLASSIFIER_FAILED_MESSAGE,
                err => actions.startTrainingFailed(err),
                dispatch
            )
        }
    }

export const stopTraining =
    (projectId: string, configId: string): ActionResult<Promise<void>> =>
    async (dispatch: Dispatch) => {
        dispatch(actions.stopTrainingProcess())
        try {
            const classifier = await classifiersController.stopTraining(projectId, configId)
            dispatch(actions.stopTrainingSuccess(classifier))
            updateCurrentClassifier(dispatch, classifier)
            dispatch(actions.updateTrainingClassifier(null))
        } catch (e) {
            handleHttpException(
                e,
                constants.STOP_TRAINING_CLASSIFIER_FAILED_MESSAGE,
                err => actions.stopTrainingFailed(err),
                dispatch
            )
        }
    }

export const rollbackClassifier =
    (projectId: string, configId: string, classifierId: string): ActionResult<Promise<void>> =>
    async (dispatch: Dispatch) => {
        dispatch(actions.rollbackClassifierProcess())
        try {
            const request: RollbackClassifierRequest = {
                ClassifierId: classifierId
            }
            const classifier = await classifiersController.rollbackClassifier(projectId, configId, request)
            dispatch(actions.rollbackClassifierSuccess(classifier))
            updateCurrentClassifier(dispatch, classifier)
        } catch (e) {
            handleHttpException(
                e,
                constants.ROLLBACK_CLASSIFIER_FAILED_MESSAGE,
                err => actions.rollbackClassifierFailed(err),
                dispatch
            )
        }
    }
