import { useAppSelector } from "../store/hooks"
import { DialogsApiEndpointNames, dialogsApi } from "./controllers/dialogs"

/**
 * Returns the cached data from the specified dialogs API endpoint.
 *
 * @param endpoint - The name of the DialogsApiEndpoint.
 * @param selectArgs - Optional arguments for the selector function.
 * @return The cached data from the DialogsApiEndpoint.
 */
export const useGetDialogApiCachedEndpointData = <
    EndpointName extends DialogsApiEndpointNames,
    EndpointSelector extends typeof dialogsApi.endpoints[EndpointName]["select"]
>(
    endpoint: EndpointName,
    selectArgs?: Parameters<EndpointSelector>[0]
) => {
    return useAppSelector(state => {
        const select = dialogsApi.endpoints[endpoint].select
        const getGenSelector = select(selectArgs as keyof typeof select)
        return getGenSelector(state) as ReturnType<ReturnType<EndpointSelector>>
    })
}
