import React from "react"
import SettingsContextMenu from "../SettingsContextMenu/SettingsContextMenu"
import SettingsButton from "../SettingsButton/SettingsButton"
import SettingsContextMenuItem from "../SettingsContextMenuItem/SettingsContextMenuItem"
import { useSetDefaultQueueMutation } from "../../api/controllers/queues"
import { selectCurrentProjectId } from "../../store/projects/selectors"
import { useAppSelector } from "../../store/hooks"
import { USE_QUEUE_CONTEXT_MENU_CONST } from "./QueueContextMenuContainer.const"
import { useTranslation } from "react-i18next"

const { tNamespace, tPaths } = USE_QUEUE_CONTEXT_MENU_CONST

export interface QueueContextMenuContainerProps {
    onEditClick: (id: string) => void
    queueId: string
    isDefault: boolean
}

const QueueContextMenuContainer: React.FC<QueueContextMenuContainerProps> = props => {
    const { onEditClick, queueId, isDefault } = props

    const { t } = useTranslation(tNamespace)

    const projectId = useAppSelector(selectCurrentProjectId)
    const [setDefaultQueue] = useSetDefaultQueueMutation()

    const handleEdit = () => {
        onEditClick(queueId)
    }

    const handleChangeDefaultStatus = async () => {
        onEditClick(queueId)

        if (!projectId) {
            return
        }

        await setDefaultQueue({ QueueId: queueId, IsDefault: !isDefault, projectId })
    }

    return (
        <SettingsContextMenu
            items={[
                <SettingsContextMenuItem
                    key="edit-queue"
                    id="edit-queue"
                    text={isDefault ? t(tPaths.unset) : t(tPaths.set)}
                    onClick={() => {
                        handleChangeDefaultStatus()
                    }}
                />
            ]}
            onHide={handleEdit}
            placement="bottom-end"
        >
            <SettingsButton onClick={handleEdit} />
        </SettingsContextMenu>
    )
}

export default QueueContextMenuContainer
