import { NotificationType } from "../../models/notification"
import {
    faCheckCircle,
    faEnvelope,
    faExclamationCircle,
    faFileDownload,
    faFileImport,
    faInfoCircle,
    faNewspaper
} from "@fortawesome/pro-light-svg-icons"
import { faHandPaper } from "@fortawesome/pro-light-svg-icons/faHandPaper"

export const getIconByType = (notificationType: NotificationType) => {
    switch (notificationType) {
        case "error":
            return faExclamationCircle
        case "info":
            return faInfoCircle
        case "message":
            return faEnvelope
        case "success":
            return faCheckCircle
        case "commonNews":
            return faNewspaper
        case "knowledgeBase":
            return faNewspaper
        case "inviteOperator":
            return faHandPaper
        case "importFiles":
            return faFileImport
        case "exportFiles":
            return faFileDownload
    }
}
