import { __assign } from "tslib";
import React, { useContext, useEffect, useRef } from "react";
import { mergeRefs } from "utils/ref.utils";
import { Context } from "../transform-wrapper/transform-wrapper";
export var KeepScale = React.forwardRef(function (props, ref) {
    var localRef = useRef(null);
    var instance = useContext(Context);
    useEffect(function () {
        return instance.onChange(function (ctx) {
            if (localRef.current) {
                var positionX = 0;
                var positionY = 0;
                localRef.current.style.transform = instance.handleTransformStyles(positionX, positionY, 1 / ctx.instance.transformState.scale);
            }
        });
    }, [instance]);
    return React.createElement("div", __assign({}, props, { ref: mergeRefs([localRef, ref]) }));
});
