import React, { useContext } from "react"
import "./LoginForm.scss"
import { LoginRequest } from "../../models/login"
import { useTranslation } from "react-i18next"
import { Formik, FormikProps } from "formik"
import * as Yup from "yup"
import { Form } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { login } from "../../store/auth/thunks"
import { selectLoginState } from "../../store/auth/selectors"
import { faExclamationCircle } from "@fortawesome/pro-light-svg-icons/faExclamationCircle"
import NotificationCard from "../NotificationCard/NotificationCard"
import ValidatableInput from "../ValidatableInput/ValidatableInput"
import { nameof } from "../../utility/common/nameof"
import { WithT } from "i18next"
import LoadingButton from "../LoadingButton/LoadingButton"
import { forgotPasswordPath, sendSignUpEmailPath } from "../../routerPaths"
import { testId } from "../../utility/tests/testId"
import { Link } from "react-router-dom"
import PasswordControl from "../PasswordControl/PasswordControl"
import { formTranslation } from "../../locales/form"
import ConfigContext from "../ConfigContext/ConfigContext"

const tNamespace = "login:"

const LoginForm: React.FC<FormikProps<LoginRequest> & WithT> = props => {
    const { t, handleSubmit, isSubmitting, values } = props
    const loginState = useSelector(selectLoginState)
    const {
        WebConfig: {
            appSettings: { selfSignUpEnabled }
        }
    } = useContext(ConfigContext)

    return (
        <div className="login-form">
            <h1 className="login-form__title">{t("login:sign-in")}</h1>
            <div className="login-form__container">
                {loginState.error && (
                    <NotificationCard
                        message={t(`${tNamespace}error-with-login-self-host`)}
                        icon={faExclamationCircle}
                    />
                )}
                <Form noValidate onSubmit={handleSubmit}>
                    <ValidatableInput
                        className="login-form__login"
                        id="loginFormLogin"
                        type="text"
                        name={nameof<LoginRequest>("login")}
                        label={t(formTranslation.email)}
                        placeholder={t(`${tNamespace}email-input-placeholder`)}
                        testId={testId.login}
                    />
                    <PasswordControl
                        name={nameof<LoginRequest>("password")}
                        label={t(formTranslation.password)}
                        testId={testId.password}
                        isPasswordSet={values.password.length > 0}
                        isPasswordValid={!props.errors.password}
                    />
                    <div className="login-form__forgot-password">
                        <Link to={forgotPasswordPath} data-testid={testId.forgotPassword}>
                            {t(`${tNamespace}forgot-password`)}
                        </Link>
                    </div>
                    <LoadingButton
                        type="submit"
                        className="login-form__submit"
                        loading={isSubmitting || loginState.inProcess}
                        variant="primary"
                        testId={testId.submitEnter}
                    >
                        {t(`${tNamespace}submit-form`)}
                    </LoadingButton>
                    {selfSignUpEnabled && (
                        <div className="login-form__footer">
                            {t(`${tNamespace}do-not-have-account`)}{" "}
                            <Link to={sendSignUpEmailPath}>{t(`${tNamespace}sign-up`)}</Link>
                        </div>
                    )}
                </Form>
            </div>
        </div>
    )
}

const FormikLoginForm: React.FC = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    return (
        <Formik
            initialValues={{ login: "", password: "" }}
            validationSchema={Yup.object().shape({
                login: Yup.string().requiredExcludeEmpty(`${tNamespace}email-input-error`),
                password: Yup.string().requiredExcludeEmpty(`${tNamespace}pass-input-error`)
            })}
            onSubmit={(values: LoginRequest, { setSubmitting }) => {
                dispatch(login(values))
                setSubmitting(false)
            }}
        >
            {formikProps => <LoginForm {...formikProps} t={t} />}
        </Formik>
    )
}

export default FormikLoginForm
