import { Collapser } from "../../../Collapser/Collapser"
import { Formik } from "formik"
import CreatableTagSelect from "../../../CreatableTagSelect/CreatableTagSelect"
import { useTranslation } from "react-i18next"
import CreatableGroupedTagSelect from "../../../CreatableTagSelect/CreatableGroupedTagSelect"
import { FormikSubmitListener } from "../../../../helpers/formik"
import styles from "./DialogTransferOperatorSearchFilters.module.scss"
import { TGroupedQueuesReturn } from "../../helpers"
import { SelectOption } from "../../../Select/Select"

const tNamespace = "dialogs:transfer."

export type TSearchFiltersFormValues = {
    roles: string[]
    statuses: string[]
    queues: string[]
}

const filtersFormInitialValues: TSearchFiltersFormValues = {
    roles: [],
    statuses: [],
    queues: []
}

export interface IDialogTransferOperatorSearchFiltersProps {
    onSubmitForm: (formData: TSearchFiltersFormValues) => void
    initialData?: TSearchFiltersFormValues
    rolesOptions?: SelectOption[]
    availableQueuesOptions?: TGroupedQueuesReturn
}

export const DialogTransferOperatorSearchFilters: React.FC<IDialogTransferOperatorSearchFiltersProps> = props => {
    const { onSubmitForm, initialData = {}, rolesOptions = [], availableQueuesOptions = [] } = props
    const { t } = useTranslation()

    const initialCombined = Object.assign(filtersFormInitialValues, initialData)
    const someFilterApplied = Object.values<string[]>(initialData).some(x => x.length)

    return (
        <Formik<TSearchFiltersFormValues> enableReinitialize initialValues={initialCombined} onSubmit={onSubmitForm}>
            <Collapser
                title={t(`${tNamespace}operators-list-filter`)}
                value={someFilterApplied}
                BodyProps={{
                    className: styles.collapser
                }}
                expanderCls={styles.collapser__expander}
            >
                <CreatableTagSelect
                    name="roles"
                    id="search-filter-roles"
                    className={styles.collapser__filter}
                    placeholder={t(`${tNamespace}all-roles`)}
                    placeholderOnAnySelected={t(`${tNamespace}choose-role`)}
                    options={rolesOptions}
                    selectedTagLabelShow
                    disableOnAllSelected
                    nonCreatable
                    t={t}
                />
                {/* <CreatableTagSelect
                    name="statuses"
                    id="search-filter-statuses"
                    className={styles.collapser__filter}
                    placeholder={t(`${tNamespace}all-statuses`)}
                    placeholderOnAnySelected={t(`${tNamespace}choose-status`)}
                    options={rmpOpts}
                    disableOnAllSelected
                    nonCreatable
                    t={t}
                /> */}
                <CreatableGroupedTagSelect
                    name="queues"
                    id="search-filter-queues"
                    className={styles.collapser__filter}
                    placeholder={t(`${tNamespace}all-queues`)}
                    placeholderOnAnySelected={t(`${tNamespace}choose-queue`)}
                    options={availableQueuesOptions}
                    selectedTagLabelShow
                    disableOnAllSelected
                    nonCreatable
                    t={t}
                />
                <FormikSubmitListener<TSearchFiltersFormValues> />
            </Collapser>
        </Formik>
    )
}
