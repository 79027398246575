import channelsController from "../../api/controllers/channels"
import { OptionType } from "../AsyncSearchableInput/AsyncSearchableInput"
import { handleHttpExceptionWithoutAction } from "../../store/handleHttpException"
import { GET_CHANNEL_FAILED_MESSAGE } from "../../store/knowledgeBase/constants"
import { Dispatch } from "../../utility/common/storeHelper"

export const searchChannel = async (dispatch: Dispatch, projectId: string, input: string) => {
    try {
        const channelNames = await channelsController.getChannelNames(projectId)

        return Object.entries(channelNames).reduce<OptionType[]>((acc, [id, title]) => {
            if ((title && title.includes(input)) || id.includes(input)) {
                acc.push({ value: id, label: title ?? "" })
            }
            return acc
        }, [])
    } catch (e) {
        handleHttpExceptionWithoutAction(e, GET_CHANNEL_FAILED_MESSAGE, dispatch)
        return []
    }
}
