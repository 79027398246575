import { fetchNewsline } from "../../../api/controllers/knowledgeBase/helpers"
import { ActionResult, Dispatch } from "../../../utility/common/storeHelper"
import { handleHttpExceptionWithoutAction } from "../../handleHttpException"
import store from "../../store"
import { TOGGLE_CATALOG_NEWSLINE_FAILED_MESSAGE } from "../constants"
import { selectCatalogsMap } from "../selectors"
import { actions } from "../slice"
import knowledgeBaseController from "./../../../api/controllers/knowledgeBase/knowledgeBase"

export const toggleCatalogNewsline =
    (projectId: string, catalogCode: string, callbackFn?: () => void): ActionResult<Promise<void>> =>
    async (dispatch: Dispatch) => {
        try {
            const catalogsMap = selectCatalogsMap(store.getState())

            if (catalogCode in catalogsMap) {
                const catalog = catalogsMap[catalogCode]
                const root = catalog.items[catalog.rootId]

                await knowledgeBaseController.updateCatalog(projectId, catalogCode, {
                    WithNested: true,
                    IsNewslineActive: !root.data.newsline
                })

                dispatch(
                    actions.updateCatalogsMapItem({
                        catalogId: catalogCode,
                        treeItemId: catalog.rootId,
                        item: {
                            ...root,
                            data: {
                                ...root.data,
                                newsline: !root.data.newsline
                            }
                        }
                    })
                )
                dispatch(fetchNewsline(projectId))

                if (callbackFn) {
                    callbackFn()
                }
            }
        } catch (e) {
            handleHttpExceptionWithoutAction(e, TOGGLE_CATALOG_NEWSLINE_FAILED_MESSAGE, dispatch)
        }
    }
