import { useTranslation } from "react-i18next"
import styles from "./ArticleTableOfContent.module.scss"
import { DropdownPlicked } from "../DropdownPlicked"
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react"
import cn from "classnames"
import debounce from "lodash/debounce"
import { getAllSiblings } from "../../helpers/dom"
import { IntersectionObserver, IntersectionObserverEntry } from "../../utility/observers/IntersectionObserver"

const tNamespace = "knowledgeBase:article-prepended."

export const articleTableOfContentHeadings = ["H1", "H2", "H3"] as const
export type TArticleTableOfContentHeadings = typeof articleTableOfContentHeadings[number]

export type TTableOfContentItem = {
    label: string
    ref: string
    type: TArticleTableOfContentHeadings
    marginCls?: string
    hidden?: boolean
}

export interface IArticleTableOfContentProps {
    editorCls?: string
    sticked?: boolean
}

type HTMLHeadingElementCustom = HTMLHeadingElement & {
    textContent: string
    previousElementSibling: HTMLLinkElement
    tagName: TArticleTableOfContentHeadings
}

export const TABLEOFCONTENT_LIST_SCROLL_TOPDIFF = 0
export const CONTENT_INTERSECT_TOP_RATIO_PERCENT = 0.25
export const CONTENT_INTERSECT_BOTTOM_RATIO_PERCENT = 0.75
export const CONTENT_INTERSECT_SLOT_SIZE = 36 * 5

const CONTENT_INTERSECT_DEBUG_MODE = false

export const trigProsemirrorReadyEvent = () => document.dispatchEvent(new Event("prosemirrorReady"))

export const ArticleTableOfContent: React.FC<IArticleTableOfContentProps> = props => {
    const { editorCls = ".ProseMirror", sticked = true } = props
    const { t } = useTranslation()
    const [navigationLinks, setNavigationLinks] = useState<TTableOfContentItem[]>([])
    const containerRef = useRef<HTMLDivElement>(null)
    const tableOfContentWrapperRef = useRef<HTMLDivElement>(null)
    const [currLinkHash, setCurrLinkHash] = useState<string>()
    const observerSysRef = useRef<IntersectionObserver>()

    const docScrollableArea = document.documentElement.scrollHeight
    const slotSizeHalf = CONTENT_INTERSECT_SLOT_SIZE / 2
    const topMargin = docScrollableArea * CONTENT_INTERSECT_TOP_RATIO_PERCENT - slotSizeHalf
    const bottomMargin = docScrollableArea * CONTENT_INTERSECT_BOTTOM_RATIO_PERCENT - slotSizeHalf

    const contentSrollDiff = topMargin

    useEffect(
        () => () => {
            if (!observerSysRef.current) {
                return
            }

            observerSysRef.current.disconnect()
        },
        []
    )

    const setupObservers = useCallback(
        (refs: Array<TTableOfContentItem["ref"]>) => {
            const entriesCb = (entries: IntersectionObserverEntry[]) => {
                const visibleEntries = entries.filter(x => x.isIntersecting)
                const upperEntry = visibleEntries[0]
                const target = upperEntry?.target as HTMLSpanElement

                if (!target) {
                    return
                }

                const targetParent = target.parentElement as HTMLHeadingElement

                if (!targetParent) {
                    return
                }

                const targetLinkHref = targetParent.previousElementSibling as HTMLLinkElement

                if (!targetLinkHref) {
                    return
                }

                const currIntersectedId = targetLinkHref.id
                const tableOfContentInstance = document.querySelector<HTMLElement>(
                    `[data-related-ref="#${currIntersectedId}"]`
                )

                if (!tableOfContentInstance) {
                    return
                }

                const siblings = getAllSiblings<HTMLElement>(
                    tableOfContentInstance,
                    el =>
                        el.nodeName.toLowerCase() === "div" &&
                        el.classList.contains(styles.articleTableOfContent__item_active)
                )

                for (const sib of siblings) {
                    sib.classList.remove(styles.articleTableOfContent__item_active)
                }

                tableOfContentInstance.classList.add(styles.articleTableOfContent__item_active)
                const tableofcontentWrapper = tableOfContentWrapperRef.current

                if (!tableofcontentWrapper) {
                    return
                }

                const tableofcontentScrollable = tableofcontentWrapper.firstChild as HTMLDivElement

                const tableOfContentInstanceCoords = tableOfContentInstance.getBoundingClientRect()
                const tableofcontentScrollableCoords = tableofcontentScrollable.getBoundingClientRect()

                const tableOfContentInstanceTop = tableOfContentInstanceCoords.top
                const tableofcontentScrollableTop = tableofcontentScrollableCoords.top

                tableofcontentWrapper.scrollTo({
                    behavior: "smooth",
                    top: tableOfContentInstanceTop - tableofcontentScrollableTop - TABLEOFCONTENT_LIST_SCROLL_TOPDIFF
                })
            }

            if (CONTENT_INTERSECT_DEBUG_MODE) {
                const existsDebugLine = document.querySelector(".intersect-debug-line")

                if (!existsDebugLine) {
                    const debugLine = document.createElement("div")

                    debugLine.innerHTML = `<span>Top: ${topMargin}px; Bottom: ${bottomMargin}px</span>`
                    debugLine.style.top = topMargin + "px"
                    debugLine.style.bottom = bottomMargin + "px"

                    debugLine.classList.add(styles.intersectAreas__line)
                    debugLine.classList.add("intersect-debug-line")
                    document.body.append(debugLine)
                }
            }

            if (observerSysRef.current) {
                observerSysRef.current.disconnect()
            }

            const observer = new IntersectionObserver(entriesCb, {
                root: document.getElementById("page-layout-content"),
                rootMargin: `${-topMargin}px 0px ${-bottomMargin}px 0px`
            })

            observerSysRef.current = observer

            for (const ref of refs) {
                const target = document.querySelector<HTMLElement>(ref)

                if (!target) {
                    continue
                }

                const headingEl = target.nextElementSibling as HTMLHeadingElement

                if (!headingEl) {
                    continue
                }

                const headingContent = headingEl.querySelector<HTMLSpanElement>(".heading-actions")

                if (!headingContent) {
                    continue
                }

                observer.observe(headingContent)
            }
        },
        [bottomMargin, topMargin]
    )

    const handleOnEditorReady = useCallback(() => {
        const editorNode = document.querySelector<HTMLHeadingElement>(editorCls)

        if (!editorNode) {
            return
        }

        const editorNodeChilds = Array.from<Element>(editorNode.children) as HTMLHeadingElementCustom[]
        const headingsList = editorNodeChilds.filter(x => articleTableOfContentHeadings.includes(x.tagName))

        const navigationLinks = headingsList
            .filter(x => x.textContent.replace(/#/, "") && x.previousElementSibling)
            .map<TTableOfContentItem>(x => ({
                label: x.textContent.slice(1),
                ref: `#${x.previousElementSibling?.id}`,
                type: x.tagName,
                hidden: getComputedStyle(x, null).display !== "block"
            }))

        const allRefs = navigationLinks.map(x => x.ref)

        if (allRefs.length) {
            setupObservers(allRefs)
        }

        const findNearestLeftAnyHeading = (idxOrig: number, parentTag: string, breakTag?: string) => {
            let idx = idxOrig

            while (idx >= 0) {
                if (navigationLinks[idx]?.type === breakTag) {
                    return false
                }

                if (navigationLinks[idx]?.type === parentTag) {
                    return true
                }

                idx--
            }

            return false
        }

        const hasFullPathByTags = (idx: number, tags: string[] = []) => {
            const navigationLinksTmp = [...navigationLinks]
            const slice = navigationLinksTmp.slice(0, idx)
            const sliceFiltered = slice.filter(x => tags.includes(x.type))
            const sliceRemapped = sliceFiltered.map(x => x.type)
            const sliceReversed = [...sliceRemapped].reverse()

            const sliceDedupl: string[] = []

            for (const x of sliceReversed) {
                if (sliceDedupl.indexOf(x) > -1) {
                    continue
                }

                sliceDedupl.push(x)
            }

            return tags.join("") === sliceDedupl.join("")
        }

        for (let i = 0; i < navigationLinks.length; i++) {
            const curr = navigationLinks[i]

            if (curr.type === "H2") {
                if (findNearestLeftAnyHeading(i, "H1")) {
                    curr.marginCls = "marginX1"
                }
            } else if (curr.type === "H3") {
                if (hasFullPathByTags(i, ["H2", "H1"])) {
                    curr.marginCls = "marginX2"
                } else if (findNearestLeftAnyHeading(i, "H2") || findNearestLeftAnyHeading(i, "H1")) {
                    curr.marginCls = "marginX1"
                }
            }
        }

        setNavigationLinks(navigationLinks)
    }, [editorCls, setupObservers])

    const handleDropdownChildClick = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation()
    }, [])

    const handleScrollTo = useCallback(
        (ref: string) => {
            const scrollArea = document.getElementById("page-layout-content")
            const refEl = document.querySelector<HTMLElement>(ref)

            if (!scrollArea || !refEl) {
                return
            }

            const tableOfContentInstance = document.querySelector<HTMLElement>(`[data-related-ref="${ref}"]`)

            if (!tableOfContentInstance) {
                return
            }

            if (tableOfContentInstance.classList.contains(styles.articleTableOfContent__item_active)) {
                return
            }

            const siblings = getAllSiblings<HTMLElement>(
                tableOfContentInstance,
                el =>
                    el.nodeName.toLowerCase() === "div" &&
                    el.classList.contains(styles.articleTableOfContent__item_active)
            )

            for (const sib of siblings) {
                sib.classList.remove(styles.articleTableOfContent__item_active)
            }

            const headingEl = refEl.nextElementSibling as HTMLHeadingElement

            if (!headingEl) {
                return
            }

            const headingElCssDisplay = getComputedStyle(headingEl, null).display

            if (headingElCssDisplay !== "block") {
                return
            }

            const headingContent = headingEl.querySelector<HTMLSpanElement>(".heading-actions")

            if (!headingContent) {
                return
            }

            const scrollAreaViewed = scrollArea.querySelectorAll(":scope > .article-view")[0]

            if (!scrollAreaViewed) {
                return
            }

            const scrollAreaViewedBoundings = scrollAreaViewed.getBoundingClientRect()
            const refElBoundings = headingContent.getBoundingClientRect()

            scrollArea.scrollTo({
                behavior: "smooth",
                top: refElBoundings.top - scrollAreaViewedBoundings.top - contentSrollDiff
            })

            setCurrLinkHash(ref)
        },
        [contentSrollDiff]
    )

    const setupContainerBoundings = useCallback(() => {
        const el = containerRef.current

        if (!el) {
            return
        }

        const containerEl = el.parentElement

        if (!containerEl) {
            return
        }

        const { bottom } = containerEl.getBoundingClientRect()

        if (el.style.top) {
            return
        }

        el.style.top = `${bottom + 30}px`
    }, [])

    useLayoutEffect(() => {
        setupContainerBoundings()

        const handleOnEditorReadyDeb = debounce(handleOnEditorReady)
        document.addEventListener("prosemirrorReady", handleOnEditorReadyDeb)
        window.addEventListener("resize", setupContainerBoundings)

        return () => {
            document.removeEventListener("prosemirrorReady", handleOnEditorReadyDeb)
            window.removeEventListener("resize", setupContainerBoundings)
        }
    }, [handleOnEditorReady, setupContainerBoundings])

    return (
        <div
            ref={containerRef}
            className={cn(
                styles.articleTableOfContent,
                sticked && styles.articleTableOfContent_sticked,
                navigationLinks.length > 0 && styles.articleTableOfContent_visible
            )}
        >
            {navigationLinks.length ? (
                <DropdownPlicked
                    defaultVisible
                    title={t(`${tNamespace}table-of-contents`)}
                    OverlayProps={{
                        className: styles.articleTableOfContent__overlay
                    }}
                    HeaderProps={{
                        className: styles.articleTableOfContent__overlay_header
                    }}
                >
                    <div
                        ref={tableOfContentWrapperRef}
                        className={styles.articleTableOfContent__dropdown}
                        onClick={handleDropdownChildClick}
                    >
                        <div className={styles.articleTableOfContent__scrollable}>
                            {navigationLinks.map(x => (
                                <div
                                    data-related-ref={x.ref}
                                    onClick={() => handleScrollTo(x.ref)}
                                    className={cn(
                                        styles.articleTableOfContent__item,
                                        styles[`articleTableOfContent__item_${x.type}Heading`],
                                        styles[`articleTableOfContent__item_${x.marginCls}`],
                                        currLinkHash === x.ref && styles.articleTableOfContent__item_active,
                                        x.hidden && styles.articleTableOfContent__item_hidden
                                    )}
                                    key={x.ref}
                                >
                                    {x.label}
                                </div>
                            ))}
                        </div>
                    </div>
                </DropdownPlicked>
            ) : null}
        </div>
    )
}
