import React from "react"
import { WithT } from "i18next"
import InfoIcon from "../../../../InfoIcon/InfoIcon"
import DebouncedCheckBox from "../../../../CheckBoxValidatableInput/DebouncedCheckBox"
import styles from "./QueueEmailResendConfig.module.scss"
import DebouncedValidatableInput from "../../../../ValidatableInput/DebouncedValidatableInput"
import { useProjectSettingCheck } from "../../../../../utility/common/useProjectSettingCheck"
import { SystemProjectSettings } from "../../../../../models/projectSettings"

export interface QueueEmailResendConfigProps extends WithT {
    enabledFreeFilling: string
    template: string
}

const tNamespace = "queues:settings."
const ADDITIONAL_RESEND_CONFIG = "AdditionalResendConfigDependsOnQueue"

const QueueEmailResendConfig: React.FC<QueueEmailResendConfigProps> = props => {
    const { enabledFreeFilling, template, t } = props
    const isEnabledFreeFilling = useProjectSettingCheck<SystemProjectSettings, "AdditionalResendConfigDependsOnQueue">(
        ADDITIONAL_RESEND_CONFIG
    )

    return (
        <>
            {isEnabledFreeFilling && (
                <DebouncedCheckBox
                    id="formQueueEmailResendFreeFilling"
                    name={enabledFreeFilling}
                    label={t(`${tNamespace}email-free-filling`)}
                    icon={
                        <InfoIcon
                            id="formQueueEmailResendFreeFillingInfo"
                            content={t(`${tNamespace}email-free-filling-tooltip`)}
                        />
                    }
                    className={styles.queueEmailResend__freeFilling}
                />
            )}
            <DebouncedValidatableInput
                id="formQueueEmailResendTemplate"
                as="textarea"
                name={template}
                label={t(`${tNamespace}email-resend-template`)}
                className={styles.queueEmailResend__textarea}
            />
        </>
    )
}

export default QueueEmailResendConfig
