import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import AsyncState from "../../core/asyncState"
import { SystemError } from "../../core/error"
import { TaskOfAutomaticProcessing } from "../../models/task"
import { resetReducerState } from "../action"

export type TasksState = Readonly<{
    moveTask: AsyncState<boolean>
    rerouteTask: AsyncState<void>
    getAutomaticProcessing: AsyncState<TaskOfAutomaticProcessing[]>
    switchTask: AsyncState<void>
}>

const initialState: TasksState = {
    moveTask: AsyncState.create(),
    rerouteTask: AsyncState.create(),
    getAutomaticProcessing: AsyncState.create(),
    switchTask: AsyncState.create()
}

const tasks = createSlice({
    name: "tasks",
    initialState,
    reducers: {
        moveTaskProcess(state) {
            state.moveTask = state.moveTask.toProcess()
        },
        moveTaskSuccess(state, action: PayloadAction<boolean>) {
            state.moveTask = state.moveTask.toSuccess(action.payload)
        },
        moveTaskFailed(state, action: PayloadAction<SystemError>) {
            state.moveTask = state.moveTask.toFailed(action.payload)
        },
        rerouteTaskProcess(state) {
            state.rerouteTask = state.rerouteTask.toProcess()
        },
        rerouteTaskSuccess(state) {
            state.rerouteTask = state.rerouteTask.toSuccess()
        },
        rerouteTaskFailed(state, action: PayloadAction<SystemError>) {
            state.rerouteTask = state.rerouteTask.toFailed(action.payload)
        },
        getAutomaticProcessingProcess(state) {
            state.getAutomaticProcessing = state.getAutomaticProcessing.toProcess()
        },
        getAutomaticProcessingSuccess(state, action: PayloadAction<TaskOfAutomaticProcessing[]>) {
            state.getAutomaticProcessing = state.getAutomaticProcessing.toSuccess(action.payload)
        },
        getAutomaticProcessingFailed(state, action: PayloadAction<SystemError>) {
            state.getAutomaticProcessing = state.getAutomaticProcessing.toFailed(action.payload)
        },
        switchTaskProcess(state) {
            state.switchTask = state.switchTask.toProcess()
        },
        switchTaskSuccess(state) {
            state.switchTask = state.switchTask.toSuccess()
        },
        switchTaskFailed(state, action: PayloadAction<SystemError>) {
            state.switchTask = state.switchTask.toFailed(action.payload)
        }
    },
    extraReducers(builder) {
        builder.addCase(resetReducerState, () => {
            return initialState
        })
    }
})

export const TasksReducer = tasks.reducer

export const actions = tasks.actions
