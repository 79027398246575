import { GetGlobalUserSurveyResponse } from "../../models/globalUserSurvey"
import {
    CreateSurveyRequest,
    CreateSurveyResponse,
    DeleteSurveyResponse,
    GetSurveysRequest,
    GetSurveysResponse,
    SurveyDto,
    UpdateSurveyRequest,
    UpdateSurveyResponse
} from "../../models/survey"
import { apiInstance } from "../instances/apiInstance"

const surveysController = {
    getSurveys: (projectId: string, request: GetSurveysRequest): Promise<GetSurveysResponse> =>
        apiInstance.post(`${projectId}/survey/page`, request).then(response => response.data),
    getSurvey: (projectId: string, surveyId: string): Promise<SurveyDto> =>
        apiInstance.get(`${projectId}/survey/${surveyId}`).then(response => response.data),
    create: (projectId: string, request: CreateSurveyRequest): Promise<CreateSurveyResponse> =>
        apiInstance.post(`${projectId}/survey`, request).then(response => response.data),
    update: (projectId: string, surveyId: string, request: UpdateSurveyRequest): Promise<UpdateSurveyResponse> =>
        apiInstance.put(`${projectId}/survey/${surveyId}`, request).then(response => response.data),
    delete: (projectId: string, surveyId: string): Promise<DeleteSurveyResponse> =>
        apiInstance.delete(`${projectId}/survey/${surveyId}`).then(response => response.data),
    getGlobalUserSurvey: (): Promise<GetGlobalUserSurveyResponse> =>
        apiInstance.get(`/survey/default-project-survey`).then(response => response.data)
}

export default surveysController
