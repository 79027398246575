import styles from "./BounceIndicator.module.scss"

export const BounceIndicator = () => {
    return (
        <div className={styles.bounceIndicator}>
            <div className={styles.bounceIndicator__wave}></div>
            <div className={styles.bounceIndicator__wave}></div>
            <div className={styles.bounceIndicator__wave}></div>
        </div>
    )
}
