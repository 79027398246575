import React, { useCallback, useContext } from "react"
import {
    useGetAllowedAudioExtensions,
    useGetAllowedFileExtensions,
    useGetAllowedVideoExtensions
} from "../../utility/knowledgeBase/articleEditorEmbeds"
import { WithT } from "i18next"
import "./ArticleContentEditor.scss"
import { useDispatch, useSelector } from "react-redux"
import { push } from "connected-react-router"
import { buildArticleActionUrl, buildSharedArticleActionUrl } from "../../utility/knowledgeBase/articleUrlHelper"
import { ArticleAction } from "../../models/article"
import KnowledgeBaseContext from "../KnowledgeBaseProvider/KnowledgeBaseContext"
import { clearCurrentBranch, mutateCurrentBranch } from "../../store/knowledgeBase/slice"
import { definedMenuItems } from "../../utility/menu/definedMenuItems"
import LazyContentEditor from "../ContentEditor/LazyContentEditor"
import ErrorBoundary from "../ErrorBoundary/ErrorBoundary"
import useRefValues from "../../utility/common/useRefValues"
import { selectCurrentArticle, selectCurrentBranch } from "../../store/knowledgeBase/selectors"
import { actions } from "../../store/dialogs/slice"
import { getArticle, getRelatedCategories } from "../../store/knowledgeBase/thunks"
import type { TreeItem } from "@atlaskit/tree/types"
import { useAppSelector } from "../../store/hooks"
import { selectCurrentUserLogin } from "../../store/users/selectors"
import { ArticleContext } from "../../pages/KnowledgeBase/KnowledgeBase"

export interface ArticleContentEditorProps extends WithT {
    content: string
    onChange?: (content: string) => void
    onDocStateChange?: () => void
    onInit?: () => void
    isEditable?: boolean
}

const ArticleContentEditor: React.FC<ArticleContentEditorProps> = props => {
    const { content, onInit, onDocStateChange, onChange, isEditable, t } = props
    const dispatch = useDispatch()

    const { url, projectId, shareId } = useContext(KnowledgeBaseContext)
    const { isInWorkplace } = useContext(ArticleContext)

    const login = useAppSelector(selectCurrentUserLogin) as string
    const { CurrentCatalogCode, CurrentCategoryId } = useAppSelector(selectCurrentBranch)

    const SCENARIO_WEBCHAT_CHANNELS_VALUES = "InteractiveArticlesChannelIds"

    const openArticleInSidebar = useCallback(
        (code: string, categoryItem?: TreeItem, catalogCode?: string) => {
            dispatch(
                mutateCurrentBranch({
                    CurrentCategoryId: categoryItem?.id as string,
                    CurrentCatalogCode: catalogCode
                })
            )
            dispatch(actions.openArticleSidebar())
            dispatch(getRelatedCategories(projectId as string, CurrentCategoryId, code, CurrentCatalogCode))
            dispatch(getArticle(projectId as string, login, code, false))
        },
        [dispatch, login, projectId, CurrentCategoryId, CurrentCatalogCode]
    )

    const handleOpenArticle = useCallback(
        (articleCode: string, categoryItem?: TreeItem, catalogCode?: string) => {
            dispatch(clearCurrentBranch())
            if (shareId) {
                dispatch(push(buildSharedArticleActionUrl(url, articleCode)))
            } else if (isInWorkplace) {
                openArticleInSidebar(articleCode, categoryItem, catalogCode)
            } else {
                dispatch(push(buildArticleActionUrl(url, ArticleAction.View, articleCode)))
            }
        },
        [dispatch, shareId, url, isInWorkplace, openArticleInSidebar]
    )

    const handleContentChange = useCallback(
        (getContent: () => string) => {
            if (!onChange) {
                return
            }
            onChange(getContent())
        },
        [onChange]
    )

    const handleLocationChange = useCallback(
        (path: string) => {
            dispatch(push(`${url.replace(new RegExp(`${definedMenuItems.KnowledgeBase.id}$`), "")}${path}`))
        },
        [dispatch, url]
    )

    const allowedFileExtensions = useGetAllowedFileExtensions()
    const allowedVideoExtensions = useGetAllowedVideoExtensions()
    const allowedAudioExtensions = useGetAllowedAudioExtensions()
    const channels = useRefValues(SCENARIO_WEBCHAT_CHANNELS_VALUES)
    const currentArticle = useSelector(selectCurrentArticle)

    return (
        <ErrorBoundary local>
            <LazyContentEditor
                className="article-content-editor"
                allowedFileExtensions={allowedFileExtensions}
                allowedVideoExtensions={allowedVideoExtensions}
                allowedAudioExtensions={allowedAudioExtensions}
                content={content}
                onInit={onInit}
                onDocStateChange={onDocStateChange}
                onChange={handleContentChange}
                projectId={projectId}
                t={t}
                isEditable={isEditable}
                onOpenArticle={handleOpenArticle}
                onChangeLocation={handleLocationChange}
                onDispatch={dispatch}
                channels={channels}
                articleSymbolCode={currentArticle?.Article?.SymbolCode}
                isInArticle
            />
        </ErrorBoundary>
    )
}

export default ArticleContentEditor
