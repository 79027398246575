import AsyncState from "../../core/asyncState"
import { KnowledgeBaseArticleSubscriptions } from "../../models/knowledgeBase/subscription"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { SystemError } from "../../core/error"
import { resetReducerState } from "../action"

export type KnowledgeBaseSubscriptionsState = Readonly<{
    currentSubscriptions: AsyncState<KnowledgeBaseArticleSubscriptions>
    updateSubscriptions: AsyncState<void>
}>

const initialState: KnowledgeBaseSubscriptionsState = {
    currentSubscriptions: AsyncState.create(),
    updateSubscriptions: AsyncState.create()
}

const knowledgeBaseSubscriptions = createSlice({
    name: "knowledgeBaseSubscriptions",
    initialState,
    reducers: {
        getCurrentSubscriptionsProcess(state) {
            state.currentSubscriptions = state.currentSubscriptions.toProcess()
        },
        getCurrentSubscriptionsSuccess(state, action: PayloadAction<KnowledgeBaseArticleSubscriptions>) {
            state.currentSubscriptions = state.currentSubscriptions.toSuccess(action.payload)
        },
        getCurrentSubscriptionsError(state, action: PayloadAction<SystemError>) {
            state.currentSubscriptions = state.currentSubscriptions.toFailed(action.payload)
        },
        updateSubscriptionsProcess(state) {
            state.updateSubscriptions = state.updateSubscriptions.toProcess()
        },
        updateSubscriptionsSuccess(state, action: PayloadAction<KnowledgeBaseArticleSubscriptions>) {
            state.updateSubscriptions = state.updateSubscriptions.toSuccess(void 0)
            state.currentSubscriptions = state.currentSubscriptions.toSuccess(action.payload)
        },
        updateSubscriptionsFailed(state, action: PayloadAction<SystemError>) {
            state.updateSubscriptions = state.updateSubscriptions.toFailed(action.payload)
        }
    },
    extraReducers(builder) {
        builder.addCase(resetReducerState, () => {
            return initialState
        })
    }
})

export const KnowledgeBaseSubscriptionsReducer = knowledgeBaseSubscriptions.reducer

export const actions = knowledgeBaseSubscriptions.actions
