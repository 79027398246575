import { __assign, __rest } from "tslib";
/* eslint-disable react/require-default-props */
import React, { useCallback, useEffect, useMemo, useRef, useState, } from "react";
import { useTransformContext, useTransformEffect, useTransformInit, } from "hooks";
import { useResize } from "./use-resize.hook";
var previewStyles = {
    position: "absolute",
    zIndex: 2,
    top: "0px",
    left: "0px",
    boxSizing: "border-box",
    border: "3px solid red",
    transformOrigin: "0% 0%",
    boxShadow: "rgba(0,0,0,0.2) 0 0 0 10000000px",
};
export var MiniMap = function (_a) {
    var _b = _a.width, width = _b === void 0 ? 200 : _b, _c = _a.height, height = _c === void 0 ? 200 : _c, _d = _a.borderColor, borderColor = _d === void 0 ? "red" : _d, children = _a.children, rest = __rest(_a, ["width", "height", "borderColor", "children"]);
    var _e = useState(false), initialized = _e[0], setInitialized = _e[1];
    var instance = useTransformContext();
    var miniMapInstance = useRef(null);
    var mainRef = useRef(null);
    var wrapperRef = useRef(null);
    var previewRef = useRef(null);
    var getContentSize = useCallback(function () {
        if (instance.contentComponent) {
            var rect = instance.contentComponent.getBoundingClientRect();
            return {
                width: rect.width / instance.transformState.scale,
                height: rect.height / instance.transformState.scale,
            };
        }
        return {
            width: 0,
            height: 0,
        };
    }, [instance.contentComponent, instance.transformState.scale]);
    var computeMiniMapScale = useCallback(function () {
        var contentSize = getContentSize();
        var scaleX = width / contentSize.width;
        var scaleY = height / contentSize.height;
        var scale = scaleY > scaleX ? scaleX : scaleY;
        return scale;
    }, [getContentSize, height, width]);
    var computeMiniMapSize = function () {
        var contentSize = getContentSize();
        var scaleX = width / contentSize.width;
        var scaleY = height / contentSize.height;
        if (scaleY > scaleX) {
            return { width: width, height: contentSize.height * scaleX };
        }
        return { width: contentSize.width * scaleY, height: height };
    };
    var computeMiniMapStyle = function () {
        var scale = computeMiniMapScale();
        var style = {
            transform: "scale(".concat(scale || 1, ")"),
            transformOrigin: "0% 0%",
            position: "absolute",
            boxSizing: "border-box",
            zIndex: 1,
            overflow: "hidden",
        };
        Object.keys(style).forEach(function (key) {
            if (wrapperRef.current) {
                wrapperRef.current.style[key] = style[key];
            }
        });
    };
    var transformMiniMap = function () {
        computeMiniMapStyle();
        var miniSize = computeMiniMapSize();
        var wrapSize = getContentSize();
        if (wrapperRef.current) {
            wrapperRef.current.style.width = "".concat(wrapSize.width, "px");
            wrapperRef.current.style.height = "".concat(wrapSize.height, "px");
        }
        if (mainRef.current) {
            mainRef.current.style.width = "".concat(miniSize.width, "px");
            mainRef.current.style.height = "".concat(miniSize.height, "px");
        }
        if (previewRef.current) {
            var size = getContentSize();
            var scale = computeMiniMapScale();
            var previewScale = scale * (1 / instance.transformState.scale);
            var transform = instance.handleTransformStyles(-instance.transformState.positionX * previewScale, -instance.transformState.positionY * previewScale, 1);
            previewRef.current.style.transform = transform;
            previewRef.current.style.width = "".concat(size.width * previewScale, "px");
            previewRef.current.style.height = "".concat(size.height * previewScale, "px");
        }
    };
    var initialize = function () {
        transformMiniMap();
    };
    useTransformEffect(function () {
        transformMiniMap();
    });
    useTransformInit(function () {
        initialize();
        setInitialized(true);
    });
    useResize(instance.contentComponent, initialize, [initialized]);
    useEffect(function () {
        return instance.onChange(function (zpp) {
            var scale = computeMiniMapScale();
            if (miniMapInstance.current) {
                miniMapInstance.current.instance.transformState.scale =
                    zpp.instance.transformState.scale;
                miniMapInstance.current.instance.transformState.positionX =
                    zpp.instance.transformState.positionX * scale;
                miniMapInstance.current.instance.transformState.positionY =
                    zpp.instance.transformState.positionY * scale;
            }
        });
    }, [computeMiniMapScale, instance, miniMapInstance]);
    var wrapperStyle = useMemo(function () {
        return {
            position: "relative",
            zIndex: 2,
            overflow: "hidden",
        };
    }, []);
    return (React.createElement("div", __assign({}, rest, { ref: mainRef, style: wrapperStyle, className: "rzpp-mini-map ".concat(rest.className || "") }),
        React.createElement("div", __assign({}, rest, { ref: wrapperRef, className: "rzpp-wrapper" }), children),
        React.createElement("div", { className: "rzpp-preview", ref: previewRef, style: __assign(__assign({}, previewStyles), { borderColor: borderColor }) })));
};
