import { FormikProps } from "formik"
import { RefObject, useEffect } from "react"
import { useDispatch } from "react-redux"
import { MassMailingFormFields, MassMailingFormValues } from "../../../../../models/massMailing"
import { actions as dialogsActions } from "../../../../../store/dialogs/slice"
import { selectCurrentArticle } from "../../../../../store/knowledgeBase/selectors"
import { actions as knowledgeBaseActions } from "../../../../../store/knowledgeBase/slice"
import store from "../../../../../store/store"

export const useKBSidebarOpenerInMassMailing = (formRef: RefObject<FormikProps<MassMailingFormValues>>) => {
    const dispatch = useDispatch()

    const open = () => {
        dispatch(dialogsActions.openKnowledgeBaseSidebar())
        dispatch(
            knowledgeBaseActions.setArticleActionButtonsActions({
                send: {
                    disabled: false,
                    onClick: () => {
                        const articleState = selectCurrentArticle(store.getState())

                        if (formRef.current && articleState && articleState.Article) {
                            const article = articleState.Article
                            formRef.current.setValues(values => ({
                                ...values,
                                [MassMailingFormFields.article]: {
                                    Code: article.SymbolCode,
                                    Title: article.Title
                                }
                            }))
                        }

                        dispatch(dialogsActions.closeKnowledgeBaseSidebar())
                        dispatch(dialogsActions.closeArticleSidebar())
                    }
                }
            })
        )
    }

    useEffect(() => {
        return () => {
            dispatch(knowledgeBaseActions.clearArticleActionButtonsActions())
        }
    }, [dispatch])

    return open
}
