import SearchFormInput from "../SearchInput/SearchFormInput"
import React, { useCallback, useRef, useState } from "react"
import styles from "./DialogTransferModal.module.scss"
import { DialogTransferOperatorSearchDropdown } from "./components/DialogTransferOperatorSearchDropdown"
import { DialogTransferOperatorListItemProps } from "./components/DialogTransferOperatorListItem/DialogTransferOperatorListItem"
import { useField } from "formik"
import { FormikCustomErrorMsg } from "../../helpers/formik"
import cn from "classnames"

export interface IDialogTransferOperatorSearchProps {
    name: string
    disabled?: boolean
    className?: string
    disabledInlineErrors?: boolean
    includeCurrentUser: boolean
    channelId?: string
    queueId?: string
}

export const DialogTransferOperatorSearch: React.FC<IDialogTransferOperatorSearchProps> = props => {
    const { name, disabled, className, disabledInlineErrors, includeCurrentUser, channelId, queueId } = props
    const [, , { setValue, setError }] = useField(name)

    const [queryCriterion, setQueryCriterion] = useState<string>("")
    const dropdownRef = useRef<HTMLDivElement>(null)
    const searchInputRef = useRef<HTMLDivElement>(null)

    const handleOnFieldClear = useCallback(() => {
        setQueryCriterion("")
        setValue("")
        setError(undefined)
    }, [setValue, setError])

    const handleOnFieldChange = useCallback((queryCriterion: string) => {
        setQueryCriterion(queryCriterion)
    }, [])

    const handleOnSelectOperator = useCallback<DialogTransferOperatorListItemProps["onSelect"]>(
        operator => {
            setValue(operator.OperatorId)
            setQueryCriterion(operator.Fullname)
            setError(undefined)
        },
        [setValue, setError]
    )

    return (
        <div className={cn(className, styles.dialogTransferOperatorSearch)}>
            <SearchFormInput
                value={queryCriterion}
                disabled={disabled}
                ref={searchInputRef}
                onClear={handleOnFieldClear}
                onChange={handleOnFieldChange}
            />
            {!disabledInlineErrors && (
                <FormikCustomErrorMsg name={name} className={styles.dialogTransferOperatorSearch__error} />
            )}
            <DialogTransferOperatorSearchDropdown
                ref={dropdownRef}
                queryCriterion={queryCriterion}
                onSelectOperator={handleOnSelectOperator}
                includeCurrentUser={includeCurrentUser}
                channelId={channelId}
                queueId={queueId}
            />
        </div>
    )
}
