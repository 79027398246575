interface OperatorFields {
    OperatorId: string
    Firstname: string
    Lastname: string
    Middlename: string
    Position: string
    IsLinkedToClient: boolean
    Picture: string
}

interface RoleFields {
    RoleId: string
    Title: string
    IsLinkedToClient: boolean
}

export enum LinkCase {
    OPERATOR = "Operator",
    ROLE = "Role"
}

export type ClientSubscriptionResponse =
    | {
          Case: LinkCase.OPERATOR
          Fields: OperatorFields
      }
    | {
          Case: LinkCase.ROLE
          Fields: RoleFields
      }

export interface ClientSubscriptionRequest {
    ClientId: string
    Query: string
    Size: number
}

export type LinkedToOperatorClient = {
    OmniUserId: string
    Firstname: string
    Lastname: string
    Middlename: string
    ProjectId: string
    Picture?: string
}

export interface LinkClientRequest {
    ProjectId: string
    OmniUserId: string
    RoleId?: string
    OperatorId?: string
}
