import { AnyAction, Dispatch } from "redux"
import RichMarkdownEditor from "rich-markdown-editor"
import { TextSelection } from "prosemirror-state"
import { actions } from "../../store/dialogs/slice"

export const setCursorInputPosition = (
    dispatch: Dispatch<AnyAction>,
    model: RichMarkdownEditor | null,
    position?: number
) => {
    if (model && position !== undefined) {
        const { tr } = model.view.state

        const newPosition = Math.max(0, Math.min(position, model.view.state.doc.content.size))

        const transaction = tr.setSelection(TextSelection.create(model.view.state.doc, newPosition))
        model.view.dispatch(transaction)
        dispatch(actions.resetShouldHandleCursorPosition())
    }
}
