import { useEffect } from "react"
import { useSearchMassMailingMutation } from "../../../../api/controllers/massMailing"
import SearchInput from "../../../../components/SearchInput/SearchInput"
import { useAppSelector } from "../../../../store/hooks"
import { selectCurrentProjectId } from "../../../../store/projects/selectors"
import { SendingJob } from "./../../../../models/massMailing"

interface MassMailingSearchInputProps {
    onSearch: (data: SendingJob[]) => void
}

export const MassMailingSearchInput = ({ onSearch }: MassMailingSearchInputProps) => {
    const [search, { isLoading }] = useSearchMassMailingMutation()
    const projectId = useAppSelector(selectCurrentProjectId)
    const filter = {
        ProjectId: String(projectId),
        DateCreateFrom: 0,
        DateCreateTo: Date.now()
    }

    useEffect(() => {
        if (projectId) {
            search(filter)
        }
    }, [search, projectId])

    const handleChange = async (query: string) => {
        if (projectId) {
            const res = await search(query ? { ...filter, TitleFilter: query } : filter)
            if ("data" in res && res.data) {
                onSearch(res.data.SendingJobs)
            }
        }
    }

    return <SearchInput onChange={handleChange} isLoading={isLoading} />
}
