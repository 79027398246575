import { ArticleHistoryElement } from "../../models/ArticleHistory"
import { ArticleHistoryGroupedByDate } from "./ArticleHistoryContainer"
import { Time } from "../../utility/common/time"

const getGroup = (date: Date, startOfToday: Date, endOfToday: Date, startOfYesterday: Date, endOfYesterday: Date) => {
    if (startOfToday < date && date < endOfToday) {
        return "today"
    } else if (startOfYesterday < date && date < endOfYesterday) {
        return "yesterday"
    } else {
        const month = date.getMonth()
        const year = date.getFullYear()
        return `${month}.${year}`
    }
}

export const formatArticleHistory = (articleHistory: ArticleHistoryElement[]) => {
    const now = new Date()
    const startOfToday = Time.startOfDay(now)
    const endOfToday = Time.endOfDay(now)
    const startOfYesterday = Time.addDaysToDate(startOfToday, -1)
    const endOfYesterday = Time.addDaysToDate(endOfToday, -1)
    return articleHistory.reduce<ArticleHistoryGroupedByDate>((acc, articleVersion, i) => {
        const date = new Date(articleVersion.ModifiedDate)

        articleVersion.isLastVersion = i === 0

        const group = getGroup(date, startOfToday, endOfToday, startOfYesterday, endOfYesterday)
        acc[group] ? acc[group].push(articleVersion) : (acc[group] = [articleVersion])
        return acc
    }, {})
}

const formatMonthOrDay = (num: number) => {
    return num > 9 ? String(num) : "0" + num
}

export const formatDate = (timeStamp: number) => {
    const date = new Date(timeStamp)
    const minutes = date.getMinutes()
    const formattedMinutes = minutes > 9 ? minutes : `0${minutes}`
    return `${formatMonthOrDay(date.getDate())}.${formatMonthOrDay(
        date.getMonth() + 1
    )}.${date.getFullYear()} ${date.getHours()}:${formattedMinutes}`
}
