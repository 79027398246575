import { format } from "date-fns"
import { useCallback } from "react"
import { useTranslation } from "react-i18next"
import { getDateLocale } from "../../../../locales"
import { ArticleView, SearchArticleHighlights } from "../../../../models/article"
import { getLanguage } from "../../../../utility/common/language"
import { putHighlightsIntoArticleTitleAndText } from "../../../../utility/knowledgeBase/knowledgeBase"
import styles from "./Item.module.scss"

interface SearchQueryArticlesItemProps {
    article: ArticleView
    highlights?: SearchArticleHighlights
    onArticleClick: (articleCode: string) => void
}

export const SearchQueryArticlesItem = (props: SearchQueryArticlesItemProps) => {
    const { article, highlights, onArticleClick } = props
    const { i18n } = useTranslation()
    const locale = { locale: getDateLocale(getLanguage(i18n)) }

    const formatModificationDate = (modifiedDate: number) =>
        modifiedDate && format(modifiedDate, "dd.MM.yyyy HH:mm", locale)

    const articleText = article.Answers && article.Answers.length > 0 ? article.Answers[0].Text : ""
    const [title, text] = putHighlightsIntoArticleTitleAndText(article.Title, articleText, highlights)

    const handleClick = useCallback(() => {
        onArticleClick(article.SymbolCode)
    }, [onArticleClick, article.SymbolCode])

    return (
        <div className={styles.article} onClick={handleClick}>
            <div className={styles.article__header} dangerouslySetInnerHTML={{ __html: title }} />
            <div className={styles.article__body} dangerouslySetInnerHTML={{ __html: text }} />
            <div className={styles.article__footer}>
                <div className={styles.article__modificationDate}>{formatModificationDate(article.ModifiedDate)}</div>
            </div>
        </div>
    )
}
