const tNamespace = "table:"

export const tableTranslation = {
    fullClientName: `${tNamespace}full-client-name`,
    fullOperatorName: `${tNamespace}full-operator-name`,
    channel: `${tNamespace}channel`,
    duration: `${tNamespace}duration`,
    status: `${tNamespace}status`,
    activeTasks: `${tNamespace}active-tasks`,
    freeSlots: `${tNamespace}free-slots`,
    durationInStatus: `${tNamespace}duration-in-status`
}
