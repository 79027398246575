import React, { useCallback, useEffect, useState } from "react"
import { selectQueueCategories } from "../../store/queues/selectors"
import type { ItemId } from "@atlaskit/tree/types"
import Tree, { mutateTree, RenderItemParams, TreeData } from "@atlaskit/tree"
import { Category } from "../Category/Category"
import { faFileAlt } from "@fortawesome/pro-light-svg-icons"
import styles from "./CatalogCheckboxSelector.module.scss"
import { useField } from "formik"
import cn from "classnames"
import { Form } from "react-bootstrap"
import { useAppSelector } from "../../store/hooks"

export interface CatalogCheckboxSelectorProps {
    fieldName: string
}

const CatalogCheckboxSelector: React.FC<CatalogCheckboxSelectorProps> = props => {
    const { fieldName } = props
    const queueCategories = useAppSelector(selectQueueCategories)
    const [tree, setTree] = useState<TreeData | undefined>(undefined)
    const [field, , { setValue }] = useField(fieldName)

    const updateItem = (updatedTree: TreeData, id: ItemId, checked: boolean) => {
        if (updatedTree.items[id]) {
            updatedTree.items[id] = {
                ...updatedTree.items[id],
                data: {
                    ...updatedTree.items[id].data,
                    checked
                }
            }
        }
    }

    const getAllDescendantIds = (itemId: ItemId): ItemId[] => {
        if (!tree) return []
        const item = tree.items[itemId]
        if (!item || !item.children) return []

        const descendants: ItemId[] = [...item.children]
        item.children.forEach(childId => {
            descendants.push(...getAllDescendantIds(childId))
        })
        return descendants
    }

    const getInitialValues = useCallback(() => {
        if (!queueCategories) return

        const mappingTree = { rootId: queueCategories.rootId, items: {} as TreeData["items"] }
        const items = Object.entries((tree ?? queueCategories).items)

        items.forEach(([key, value]) => {
            mappingTree.items[key] = { ...value }
            updateItem(mappingTree, value.id, field.value.includes(value.id))
        })

        setTree(mappingTree)
    }, [field.value, queueCategories])

    useEffect(() => {
        getInitialValues()
    }, [])

    const handleToggle = (itemId: ItemId, isExpanded: boolean) => {
        if (!tree) {
            return
        }

        setTree(
            mutateTree(tree, itemId, {
                isExpanded: isExpanded
            })
        )
    }

    const handleCollapse = (itemId: ItemId) => {
        handleToggle(itemId, false)
    }

    const handleExpand = (itemId: ItemId) => {
        handleToggle(itemId, true)
    }

    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>, itemId: ItemId, checked: boolean) => {
        e.stopPropagation()

        const allDescendants = getAllDescendantIds(itemId)
        const currentSelection = new Set(field.value)

        if (checked) {
            currentSelection.add(itemId)
            allDescendants.forEach(id => currentSelection.add(id))
        } else {
            currentSelection.delete(itemId)
            allDescendants.forEach(id => currentSelection.delete(id))
        }

        setValue(Array.from(currentSelection))

        if (tree) {
            const updatedTree = { ...tree }
            updateItem(updatedTree, itemId, checked)
            allDescendants.forEach(id => updateItem(updatedTree, id, checked))
            setTree(updatedTree)
        }
    }

    const renderItem = (params: RenderItemParams) => {
        const { provided, item, ...rest } = params

        return (
            <div className={styles.catalogItem} ref={provided.innerRef} {...provided.draggableProps}>
                <Category {...rest} item={item} onCanEdit={() => false} icon={faFileAlt} />
                <Form.Check
                    custom
                    inline
                    type="checkbox"
                    id={`queue-${item.id}-input`}
                    name={field.name}
                    onChange={e => handleCheckboxChange(e, item.id, e.target.checked)}
                    checked={item.data.checked}
                    className={cn("checkbox-input", styles.catalogItem__checkbox)}
                />
            </div>
        )
    }

    const rootItem = tree?.items[tree.rootId]
    if (!rootItem) return null

    return (
        <>
            <Category
                item={rootItem}
                onExpand={handleExpand}
                onCanEdit={() => false}
                onCollapse={handleCollapse}
                isRoot
            />
            {rootItem.isExpanded && (
                <Tree
                    tree={tree}
                    renderItem={renderItem}
                    onExpand={handleExpand}
                    onCollapse={handleCollapse}
                    offsetPerLevel={20}
                />
            )}
        </>
    )
}

export default CatalogCheckboxSelector
