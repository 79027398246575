import { createContext } from "react"
import { KnowledgeBasePermittedActionSelectItem } from "../../models/knowledgeBase/permission"
import { onOpenSharingCatalogFunc } from "../../types/knowledgeBaseFunctions"
import { SharedType } from "../../models/sharing"

export interface KnowledgeBaseContextType {
    projectId?: string
    shareId?: string
    url: string
    canModifyKnowledgeBase: boolean
    permittedActionOptions: KnowledgeBasePermittedActionSelectItem[]
    onOpenSharingCatalog: onOpenSharingCatalogFunc
    sharedType?: SharedType
}

export const knowledgeBaseContextDefaultValue: KnowledgeBaseContextType = {
    url: "",
    canModifyKnowledgeBase: true,
    permittedActionOptions: [],
    onOpenSharingCatalog: () => void 0
}

const KnowledgeBaseContext = createContext<KnowledgeBaseContextType>(knowledgeBaseContextDefaultValue)

export default KnowledgeBaseContext
