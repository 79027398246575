import React, { RefObject, useContext } from "react"
import {
    Article as KnowledgeBaseArticle,
    ArticleEditFormValues,
    ArticleStatusType,
    ArticleStatusType as Status
} from "../../models/article"
import { useTranslation } from "react-i18next"
import { Scenario } from "../../models/scenario"
import { graphToJson } from "../../utility/scenario/scenario"
import ScenarioEditor from "../ScenarioEditor/ScenarioEditor"
import styles from "./ScenarioArticle.module.scss"
import { ScenarioContext } from "../ScenarioEditor/ScenarioContext"
import ArticleEditForm from "../ArticleEditForm/ArticleEditForm"
import { ArticleType } from "../ArticleType/ArticleType"
import { ArticleTags } from "../ArticleTags/ArticleTags"
import ArticleUserViewsPopoverContainer from "../ArticleUserViewsPopover/ArticleUserViewsPopoverContainer"
import Article from "../Article/Article"
import ArticleStatus from "../ArticleStatus/ArticleStatus"
import ArticleCommentsViewContainer from "../ArticleCommentsView/ArticleCommentsViewContainer"
import { FormikProps } from "formik/dist/types"
import { ReactFlowProvider } from "react-flow-renderer"
import { useSelector } from "react-redux"
import { selectArticleExpanded, selectArticleFormQuestions } from "../../store/knowledgeBase/selectors"
import ArticleToolbar from "../ArticleToolbar/ArticleToolbar"
import { ArticleContext } from "../../pages/KnowledgeBase/KnowledgeBase"
import CurrentBranchContainer from "../CurrentBranch/CurrentBranchContainer"
import { TreeItem } from "@atlaskit/tree/types"
import { CatalogPlacement } from "../Catalogs/Catalogs"
import { KnowledgeBaseArticlePublishType } from "../../models/knowledgeBase/knowledgeBasePublish"
import ArticlePublishInfo from "../ArticlePublishInfo/ArticlePublishInfo"

const tNamespace = "knowledgeBase:"
export const SCENARIO_ARTICLE_ID = "scenario-article"

export interface Props {
    article: KnowledgeBaseArticle
    formRef?: RefObject<FormikProps<ArticleEditFormValues>>
    isEdit: boolean
    canEdit: boolean
    transformToScenario: boolean
    onUpdate?: (articleFormValues: ArticleEditFormValues, scenario: Scenario, versionId: string) => void
    onRelatedArticleClick: (category: TreeItem) => void
    actions?: JSX.Element
    articlePlacement?: CatalogPlacement
}

const ScenarioArticle: React.FC<Props> = props => {
    const {
        article,
        formRef,
        isEdit,
        canEdit,
        onUpdate,
        transformToScenario,
        onRelatedArticleClick,
        actions,
        articlePlacement
    } = props

    const { t } = useTranslation()
    const { instance, scenarioTouched } = useContext(ScenarioContext)
    const { onOpenQuestions } = useContext(ArticleContext)
    const questions = useSelector(selectArticleFormQuestions)
    const articleExpanded = useSelector(selectArticleExpanded)
    const isKB = articlePlacement === CatalogPlacement.knowledgeBase

    const handleUpdateScenario = (values: ArticleEditFormValues) => {
        if (onUpdate) {
            const elements = instance?.toObject().elements || []
            const scenario = graphToJson(elements)
            onUpdate(values, scenario, article.Id)
        }
    }

    const handleOpenQuestions = canEdit ? () => onOpenQuestions(false) : undefined

    return (
        <div className={styles.scenarioArticle} id={SCENARIO_ARTICLE_ID}>
            {isKB && (
                <div className={styles.scenarioArticle__header}>
                    <CurrentBranchContainer onParentClick={onRelatedArticleClick} />
                    {actions}
                </div>
            )}
            <div className={styles.scenarioArticle__body}>
                {isEdit && formRef ? (
                    <ArticleEditForm
                        article={article}
                        formRef={formRef}
                        onSubmit={handleUpdateScenario}
                        transformToScenario={transformToScenario}
                        questionsCount={questions.length}
                        scenarioTouched={scenarioTouched}
                        isArticleExpanded={articleExpanded}
                    />
                ) : (
                    <>
                        <Article
                            isPublished={article.Status === Status.Active}
                            symbolCode={article.SymbolCode}
                            prepended={
                                articlePlacement !== CatalogPlacement.workplace ? (
                                    <>
                                        {article.PublishInfo?.Type === KnowledgeBaseArticlePublishType.Timeout &&
                                            !article.PublishInfo.IsCompleted && (
                                                <div className={styles.scenarioArticle__publishInfo}>
                                                    {article.Status !== ArticleStatusType.Active && (
                                                        <ArticlePublishInfo
                                                            title={t(`${tNamespace}publish-info.publish-timer`)}
                                                            timestamp={article.PublishInfo?.PublishDate}
                                                        />
                                                    )}
                                                    <ArticlePublishInfo
                                                        title={t(`${tNamespace}publish-info.unpublish-timer`)}
                                                        timestamp={article.PublishInfo?.UnpublishDate}
                                                    />
                                                </div>
                                            )}
                                    </>
                                ) : undefined
                            }
                            headerTop={
                                isKB && (
                                    <>
                                        <ArticleType type={article.Type} />
                                        <ArticleTags tags={article.Tags} />
                                    </>
                                )
                            }
                            headerRight={
                                isKB && (
                                    <>
                                        <ArticleStatus status={article.Status} />
                                        {article.Status === Status.Active && (
                                            <ArticleUserViewsPopoverContainer currentArticleCode={article.SymbolCode} />
                                        )}
                                    </>
                                )
                            }
                            withActions={false}
                            title={article.Title ? article.Title : t(`${tNamespace}untitled`)}
                            widgets={
                                <ArticleToolbar
                                    onOpenQuestions={handleOpenQuestions}
                                    questionsCount={questions.length}
                                />
                            }
                            isExpanded={articleExpanded}
                        />
                    </>
                )}
                <div className={styles.scenarioArticle__editor} key={`scenario-article-editor-${isEdit}`}>
                    <ReactFlowProvider>
                        <ScenarioEditor isEditing={isEdit} scenario={article.Scenario} />
                    </ReactFlowProvider>
                </div>
                {!isEdit && (
                    <ArticleCommentsViewContainer articleCode={article.SymbolCode} articleTitle={article.Title} />
                )}
            </div>
        </div>
    )
}

export default ScenarioArticle
