import React from "react"
import { Operator } from "../../../models/operator"
import styles from "../../QueueCard/QueueCard.module.scss"
import QueueOperatorView from "../../QueueOperatorView/QueueOperatorView"
import { Queue } from "../../../models/queue"
import { useTranslation } from "react-i18next"
import { ChannelNames } from "../../../models/channel"
import { Task } from "../../../models/task"

type QueueOperatorsViewProps = {
    queue: Queue
    queues: Queue[]
    filteredOperators: Operator[]
    channelNames?: ChannelNames
    selectedTask: string | null
    onTaskClick?: (task: Task, operator: Operator) => void
    onOperatorClick: (operator: Operator) => void
    selectedOperator: string | null
}

const tNamespace = "queues:card."

const QueueOperatorsView = (props: QueueOperatorsViewProps) => {
    const {
        queue,
        queues,
        filteredOperators,
        channelNames,
        selectedTask,
        onTaskClick,
        onOperatorClick,
        selectedOperator
    } = props
    const { t } = useTranslation()

    if (!filteredOperators.length) {
        return <div className={styles.queueCard__body_noActiveEmployees}>{t(`${tNamespace}no-active-employees`)}</div>
    }

    return (
        <>
            {filteredOperators.map(o => (
                <QueueOperatorView
                    key={o.Id}
                    operator={o}
                    boardQueue={queue}
                    queues={queues}
                    channelNames={channelNames}
                    selectedTask={selectedTask}
                    onTaskClick={onTaskClick && onTaskClick}
                    onOperatorClick={onOperatorClick}
                    selected={selectedOperator === o.Id}
                />
            ))}
        </>
    )
}

export default QueueOperatorsView
