import { ArticleAction } from "../../models/article"
import { ArticleWorkMode } from "../../models/knowledgeBase/workMode"

export const mapArticleActionToWorkMode = (action: ArticleAction) => {
    switch (action) {
        case ArticleAction.View:
            return ArticleWorkMode.View
        case ArticleAction.Edit:
            return ArticleWorkMode.Edit
        default:
            return ArticleWorkMode.View
    }
}
