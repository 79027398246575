import React, { useMemo } from "react"
import { ClassProps } from "../../utility/common/props"
import styles from "./ProjectHeader.module.scss"
import HugeLogo from "../HugeLogo/HugeLogo"
import ProjectSelector, { ProjectSelectorLoading } from "../ProjectSelector/ProjectSelector"
import { definedMenuItems } from "../../utility/menu/definedMenuItems"
import MenuItemUser from "../MenuItemUser/MenuItemUser"
import {
    selectCurrentProject,
    selectFrameLoadingState,
    selectOtherProjects,
    selectThemeInitializingStatus
} from "../../store/projects/selectors"
import { useDispatch, useSelector } from "react-redux"
import { selectCurrentMainMenu } from "../../store/menu/selectors"
import { selectCurrentPermissions, selectCurrentUser } from "../../store/users/selectors"
import { useTranslation } from "react-i18next"
import OperatorStatusSelector, { OperatorStatusSelectorLoading } from "../OperatorStatusSelector/OperatorStatusSelector"
import { useAppSelector } from "../../store/hooks"
import { selectOperatorStatuses, selectOperatorStatusState } from "../../store/userOperator/selectors"
import MenuItemComponent from "../MenuItem/MenuItem"
import { getAllowedMenuItems } from "../../utility/project/getAllowedMenuItems"
import { updateOperatorStatus } from "../../store/userOperator/thunks"
import { useGetActiveDialogsCountQuery } from "../../api/controllers/dialogs"
import Indicator from "../Indicator/Indicator"
import { OperatorStatusValue } from "../../models/operatorStatus"
import AsyncWithoutError from "../Async/AsyncWithoutError"
import usePermissionsCheck from "../../utility/common/usePermissionsCheck"
import { ViewNewOperatorUI } from "../../permissions"
import { Newsline } from "../Newsline/Newsline"
import { useRouteMatch } from "react-router-dom"
import OperatorStatusTimer from "../OperatorStatusTimer/OperatorStatusTimer"

const tNamespace = "project:"
const knowledgeBaseRoute = "/project/:projectId/knowledge-base"

interface Props extends ClassProps {
    selectProjectUrl: (projectId: string, selectedMainMenu?: string) => void
    getSelectedMenu: (menuId: string, selectedMainMenu?: string) => boolean
    selectMenu: (menuItemId: string) => void
}

const ProjectHeader: React.FC<Props> = props => {
    const { selectProjectUrl, getSelectedMenu, selectMenu } = props
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const frameState = useSelector(selectFrameLoadingState)
    const selectedProject = useSelector(selectCurrentProject)
    const selectedMainMenu = useSelector(selectCurrentMainMenu)
    const nonSelectedProjects = useSelector(selectOtherProjects)
    const operatorStatuses = useSelector(selectOperatorStatuses)
    const user = useSelector(selectCurrentUser)
    const currentPermissions = useSelector(selectCurrentPermissions)
    const themeInitStatus = useSelector(selectThemeInitializingStatus)
    const viewNewOperatorUIAllowed = usePermissionsCheck([ViewNewOperatorUI])
    const operatorStatusState = useAppSelector(selectOperatorStatusState)

    const isOperatorOffline = operatorStatusState.data?.Value === OperatorStatusValue.Offline
    const isDialogsCounterAllowed = viewNewOperatorUIAllowed && !isOperatorOffline && !themeInitStatus.inProcess
    const isKnowledgeBase = Boolean(useRouteMatch(knowledgeBaseRoute))
    const offlineStatus = operatorStatuses.find(status => status.Value === OperatorStatusValue.Offline)

    const { data: count } = useGetActiveDialogsCountQuery(undefined, {
        refetchOnReconnect: true,
        refetchOnMountOrArgChange: true
    })

    const dialogMenu = useMemo(() => {
        return getAllowedMenuItems(
            [definedMenuItems.Dialogs, definedMenuItems.DialogsOld],
            Array.from(currentPermissions)
        ).shift()
    }, [currentPermissions])

    const handleProjectSelection = (projectId: string) => {
        offlineStatus && dispatch(updateOperatorStatus(offlineStatus))
        selectProjectUrl(projectId, selectedMainMenu)
    }

    if (!user) return null

    return (
        <div className={styles.header}>
            <div className={styles.header__side}>
                <HugeLogo />
                {selectedProject && nonSelectedProjects && !frameState.inProcess ? (
                    <ProjectSelector
                        selectedProject={selectedProject}
                        nonSelectedProject={nonSelectedProjects}
                        selectProject={handleProjectSelection}
                        inHeader
                    />
                ) : (
                    <ProjectSelectorLoading t={t} inHeader />
                )}
            </div>
            {isKnowledgeBase && <Newsline className={styles.header__newsline} />}
            <div className={styles.header__controlArea}>
                {isDialogsCounterAllowed && <Indicator className={styles.header__counter}>{count}</Indicator>}
                {dialogMenu && (
                    <MenuItemComponent
                        id={dialogMenu.id}
                        title={t(`${tNamespace}${dialogMenu.title}`)}
                        isSelected={getSelectedMenu(dialogMenu.id)}
                        onClick={() => selectMenu(dialogMenu.id)}
                        testId={dialogMenu.id}
                        inHeader
                    />
                )}
            </div>
            <div className={styles.header__side}>
                <AsyncWithoutError
                    dataState={operatorStatusState}
                    processView={<OperatorStatusSelectorLoading t={t} inHeader />}
                >
                    {({ data }) => (
                        <>
                            <OperatorStatusTimer status={data} />
                            <OperatorStatusSelector
                                currentStatus={data}
                                statuses={operatorStatuses}
                                onChange={status => dispatch(updateOperatorStatus(status))}
                                inHeader
                            />
                        </>
                    )}
                </AsyncWithoutError>
                {definedMenuItems.Profile && (
                    <MenuItemUser
                        user={user}
                        selectMenu={selectMenu}
                        getSelectedMenu={menuId => getSelectedMenu(menuId, selectedMainMenu)}
                        className={styles.projectViewHeader__userProfile}
                        inHeader
                    />
                )}
            </div>
        </div>
    )
}

export default ProjectHeader
