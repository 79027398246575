import { operatorsLangParser } from "./operatorsLangParser"
import { FilledSlot } from "../../models/slot"
import { Dialog, DialogPerson, IGetOperatorClientResponse } from "../../models/Dialogs/dialog"
import { User } from "../../models/user"

export const fillSlots = (text: string, operator: Partial<DialogPerson> | undefined, slots: FilledSlot[]) => {
    return text.replace(/{{[a-zA-Z0-9_-]*}}/g, substring => {
        const withoutBrace = substring.slice(2, -2)

        if (operator) {
            const operatorsParseResult = operatorsLangParser(operator, withoutBrace, substring)
            if (operatorsParseResult.isFulfilled) return operatorsParseResult.substring
        }

        const matchedClientSlot = slots.find(element => element.Id === withoutBrace)
        if (matchedClientSlot && matchedClientSlot.Value && matchedClientSlot.Value.length > 0) {
            return matchedClientSlot.Value
        }

        return substring
    })
}

export const getOperatorNameSlots = (data?: Dialog | User) => {
    if (data) {
        const isDialog = "Operator" in data

        return {
            Firstname: isDialog ? data.Operator.Firstname : data.FirstName,
            Lastname: isDialog ? data.Operator.Lastname : data.LastName
        }
    }
}

export const getFilledSlots = (data?: Dialog | IGetOperatorClientResponse) => {
    return data?.SlotContext.FilledSlots ?? []
}
