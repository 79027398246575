import { Dispatch } from "../../utility/common/storeHelper"
import { updateOperatorStatus } from "./thunks"
import { operatorStatusChangeReasons, SelectedOperatorStatus } from "../../models/operatorStatus"

export function saveStatusTimer(status: SelectedOperatorStatus, elapsed: number, useBeacon = false) {
    return (dispatch: Dispatch) => {
        dispatch(
            updateOperatorStatus(
                {
                    Value: status.Value,
                    Description: status.Description,
                    StatusSetTime: elapsed
                },
                operatorStatusChangeReasons.statusTimerUpdate,
                useBeacon
            )
        )
    }
}
