import React, { memo, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import cn from "classnames"
import { IconProp } from "@fortawesome/fontawesome-svg-core"
import ValidatableInput from "../ValidatableInput/ValidatableInput"
import { formTranslation } from "../../locales/form"
import { SlotType } from "../../models/slot"
import { GlobalUserSurveySlot } from "../../models/globalUserSurvey"
import styles from "./GlobalUserSurveyField.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { DisplayTypeValue } from "../../models/survey"
import Linkify from "linkify-react"
import DebouncedValidatableInput from "../ValidatableInput/DebouncedValidatableInput"
import copy from "copy-to-clipboard"
import { Button } from "react-bootstrap"
import { faCopy } from "@fortawesome/pro-light-svg-icons"
import TooltipTrigger from "../TooltipTrigger/TooltipTrigger"
import { useTooltipWithTimeout } from "../../utility/common/useTooltipWithTimeout"
import unescape from "lodash/unescape"
import { testId } from "./../../utility/tests/testId"

export enum GlobalUserSurveyFieldActionNames {
    undo = "undo",
    accept = "accept"
}

type GlobalUserSurveyFieldActionProps = {
    name: GlobalUserSurveyFieldActionNames
    icon: IconProp
    type: "button" | "submit"
    onClick?: (slot: GlobalUserSurveySlot) => void
    className?: string
}

export interface GlobalUserSurveyFieldProps {
    slot: GlobalUserSurveySlot
    slotValue: string
    previousSlotValue: string
    name: string
    id: string
    containerClassName?: string
    actions?: GlobalUserSurveyFieldActionProps[]
    actionsContainerClassName?: string
    titleClassName?: string
    contentClassName?: string
    disabled?: boolean
    readonly?: boolean
    onFocus?: () => void
}

const GlobalUserSurveyField = ({
    slot,
    name,
    id,
    slotValue,
    previousSlotValue,
    containerClassName,
    actions,
    actionsContainerClassName: actionsClassName,
    titleClassName,
    contentClassName,
    disabled,
    readonly,
    onFocus
}: GlobalUserSurveyFieldProps) => {
    const { t } = useTranslation()
    const [isEditing, setEditing] = useState(false)
    const containerRef = useRef<HTMLDivElement>(null)
    const [show, tooltipLabel, setTooltipLabel] = useTooltipWithTimeout()

    const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation()
        let value = slotValue
        if (slot.Type === SlotType.Dictionary) {
            value = slot.Domain.find(d => d.Id === slotValue)?.Title || ""
        }

        copy(value)
        setTooltipLabel(t(formTranslation.copied))
    }

    useEffect(() => {
        setEditing(!(slotValue === previousSlotValue))
    }, [slotValue, previousSlotValue])

    const CopyButton = () => (
        <div
            className={cn(
                styles.copy,
                isEditing && styles.copy_removedOnEdit,
                (isEmptySlot || slot.IsCopyRestricted) && styles.copy_hidden
            )}
        >
            <TooltipTrigger id={id} condition={show} placement="top" content={tooltipLabel} show={show}>
                <Button
                    data-testid={testId.copyBtn}
                    as="div"
                    className={styles.copy__button}
                    variant="light"
                    onClick={handleClick}
                >
                    <FontAwesomeIcon icon={faCopy} />
                </Button>
            </TooltipTrigger>
        </div>
    )

    // Сделано в рамках задачи: https://youtrack.craft-talk.ru/issue/MONIKI-119
    const slotValueUnescaped = unescape(slotValue)

    const isLink = slot.DisplayType === DisplayTypeValue.Link
    const isLongSlotValue = slotValue ? slotValue.length > 17 : false

    const renderFieldInner = () => {
        switch (slot.Type) {
            case SlotType.Dictionary:
                return (
                    <div className={styles.slot__input}>
                        <ValidatableInput
                            id={id}
                            name={name}
                            as="select"
                            type="text"
                            disabled={disabled || !slot.Domain.length}
                        >
                            <option value="">{t(formTranslation.nothingSelected)}</option>
                            {slot.Domain.map(option => (
                                <option value={option.Id} key={option.Id}>
                                    {unescape(option.Title)}
                                </option>
                            ))}
                        </ValidatableInput>
                    </div>
                )
            default:
                return (
                    <div className={styles.slot__input}>
                        {slot.IncreasedSize ? (
                            <DebouncedValidatableInput
                                id={id}
                                as="textarea"
                                name={name}
                                className={styles.slot__input_increased}
                                disabled={disabled}
                                placeholder={slot.Title}
                            />
                        ) : (
                            <ValidatableInput
                                id={id}
                                name={name}
                                type="text"
                                disabled={disabled}
                                placeholder={slot.Title}
                            />
                        )}
                    </div>
                )
        }
    }

    const isDictionary = slot.Type === SlotType.Dictionary
    const isEmptySlot = !slotValue || slotValue.length === 0

    const getInitialValue = () => {
        const displayLinkCondition = isLink ? (
            <Linkify options={{ target: "_blank" }}>{slotValueUnescaped}</Linkify>
        ) : (
            slotValueUnescaped
        )

        return isDictionary ? unescape(slot.Domain.find(d => d.Id === slotValue)?.Title || "") : displayLinkCondition
    }

    return (
        <div className={cn(styles.slot__section, containerClassName)} ref={containerRef} onFocus={onFocus}>
            {slot.ReadOnly || readonly ? (
                <>
                    <div className={cn(styles.slot__title, titleClassName)}>{slot.Title}</div>
                    <div className={cn(styles.slot__section, styles.slot__section_readonly)}>
                        <TooltipTrigger
                            id={`slot-value-${id}`}
                            condition={isLink && isLongSlotValue}
                            placement="top"
                            content={slotValueUnescaped}
                        >
                            <div
                                className={cn(
                                    contentClassName,
                                    styles.slot__content,
                                    slot.IsCopyRestricted && styles.slot__content_disabled,
                                    isLink ? styles.slot__content_link : styles.slot__content_readonly
                                )}
                            >
                                {getInitialValue()}
                            </div>
                        </TooltipTrigger>
                        <CopyButton />
                    </div>
                </>
            ) : (
                <>
                    <label className={cn(styles.slot__title, titleClassName)} htmlFor={id}>
                        {slot.Title}
                    </label>
                    <div className={styles.slot__section}>
                        {renderFieldInner()}
                        <CopyButton />
                        {!disabled && Array.isArray(actions) && (
                            <div className={cn(styles.actions, !isEditing && styles.actions_hidden, actionsClassName)}>
                                {actions.map(({ name, icon, onClick, className, type }) => {
                                    return (
                                        <button
                                            key={name}
                                            onClick={() => onClick && onClick(slot)}
                                            type={type}
                                            className={cn(styles.actions__button, className)}
                                        >
                                            <FontAwesomeIcon icon={icon} />
                                        </button>
                                    )
                                })}
                            </div>
                        )}
                    </div>
                </>
            )}
        </div>
    )
}

export default memo(GlobalUserSurveyField)
