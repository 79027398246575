import { BaseQueryFn, fetchBaseQuery, FetchArgs, FetchBaseQueryError } from "@reduxjs/toolkit/query/react"

interface DynamicBaseQueryArgs extends FetchArgs {
    baseUrl?: string
}

const dynamicBaseQuery: BaseQueryFn<DynamicBaseQueryArgs, unknown, FetchBaseQueryError> = async (
    { baseUrl = "/api/v2", timeout = 25000, ...rest },
    api,
    extraOptions
) => {
    const baseQuery = fetchBaseQuery({ baseUrl, timeout })
    return baseQuery(rest, api, extraOptions)
}

export default dynamicBaseQuery
