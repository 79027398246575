import React, { ClipboardEvent, CSSProperties, useEffect, useRef } from "react"
import Highlight, { defaultProps, Language } from "prism-react-renderer"
import theme from "prism-react-renderer/themes/nightOwlLight"
import cn from "classnames"
import styles from "./CodeBlockInput.module.scss"
import Editor from "react-simple-code-editor"
import CloseButton from "../CloseButton/CloseButton"
import { ClassProps } from "../../utility/common/props"

export interface CodeBlockInputProps extends ClassProps {
    value: string
    initial: boolean
    isEditable: boolean
    onBlur?: () => void
    onChange: (code: string) => void
    onRemove?: () => void
    padding?: number
    style?: CSSProperties
    disabled?: boolean
    language?: Language
}

const CodeBlockInput: React.FC<CodeBlockInputProps> = props => {
    const { value, initial, isEditable, onBlur, onChange, onRemove, padding, style, className, disabled, language } =
        props

    const ref = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (initial) {
            const items = ref.current?.getElementsByTagName("textarea")

            if (items && items.length) {
                const textarea = items[0] as HTMLTextAreaElement
                textarea.focus()
            }
        }
    }, [initial])

    const handleHighlight = (code: string) => (
        <Highlight {...defaultProps} theme={theme} code={code} language={language ?? "jsx"}>
            {({ tokens, getLineProps, getTokenProps }) => (
                <>
                    {tokens.map((line, i) => (
                        <div {...getLineProps({ line, key: i })}>
                            {line.map((token, key) => (
                                <span {...getTokenProps({ token, key })} />
                            ))}
                        </div>
                    ))}
                </>
            )}
        </Highlight>
    )

    const handleCopy = (e: ClipboardEvent) => {
        const selection = document.getSelection()
        if (selection) {
            e.clipboardData.setData("text/plain", selection.toString())
            e.clipboardData.setData("is-code-copied", "true")
            e.preventDefault()
        }
    }

    return (
        <div ref={ref} className={cn(styles.codeBlockInput, className)}>
            <Editor
                value={value}
                readOnly={!isEditable}
                onBlur={onBlur}
                onValueChange={onChange}
                onCopy={handleCopy}
                highlight={handleHighlight}
                style={{
                    color: color,
                    opacity: opacity,
                    fontStyle: fontStyle,
                    textDecorationLine: textDecorationLine,
                    ...style
                }}
                padding={padding}
                disabled={disabled}
            />
            {isEditable && onRemove && (
                <CloseButton className={styles.codeBlockInput__removeButton} onClick={onRemove} />
            )}
        </div>
    )
}

const { color, opacity, fontStyle, textDecorationLine } = theme.plain

export default CodeBlockInput
