import Spinner from "../Spinner/Spinner"
import IconButton from "../IconButton/IconButton"
import { faEye } from "@fortawesome/pro-light-svg-icons"
import LinkClientToOperatorPopover from "./LinkClientToOperatorPopover"
import cn from "classnames"
import styles from "../Dialogs/ClientSurvey/ClientSurvey.module.scss"
import AsyncQuery from "../Async/AsyncQuery"
import React, { useCallback, useEffect, useMemo } from "react"
import { usePopoverShown } from "../../utility/knowledgeBase/usePopoverShown"
import { ArticleSubscriptionFormValues } from "../../models/knowledgeBase/subscription"
import { actions } from "../../store/dialogs/slice"
import { getRequestBody } from "../Dialogs/ClientSurvey/helpers/getLinkRequestBody"
import {
    useLazyLinkClientToOperatorQuery,
    useLazyLinkClientToRoleQuery,
    useLazyPostClientSubscriptionsQuery,
    useLazyUnlinkClientToOperatorQuery,
    useLazyUnlinkClientToRoleQuery
} from "../../api/controllers/dialogs"
import { useAppDispatch, useAppSelector } from "../../store/hooks"
import { selectClientSubscriptionsProcess } from "../../store/dialogs/selectors"
import { selectCurrentProject } from "../../store/projects/selectors"
import { useTranslation } from "react-i18next"
import { Dialog, IGetOperatorClientResponse } from "../../models/Dialogs/dialog"
import { updateLinkedClients } from "./utils/updateLinkedClients"
import { getLinkedClientFromSubject } from "./utils/getLinkedClientFromSubject"
import { isShowLinkedClients } from "./utils/isShowLinkedClients"
import { UserAccessActions } from "../../models/knowledgeBase/userAccess"

interface LinkClientToOperatorPopoverWrapperProps {
    subject: IGetOperatorClientResponse | Dialog
}

const tNamespace = "dialogs:client-survey."
export const MAX_LINK_SIZE = 10000

const LinkClientToOperatorPopoverWrapper = (props: LinkClientToOperatorPopoverWrapperProps) => {
    const { subject } = props

    const { t } = useTranslation()
    const dispatch = useAppDispatch()

    const clientSubscriptionsProcess = useAppSelector(selectClientSubscriptionsProcess)
    const currentProject = useAppSelector(selectCurrentProject)

    const [clientSubscriptionsTrigger, clientSubscriptionsQuery] = useLazyPostClientSubscriptionsQuery()
    const [linkClientToOperatorTrigger] = useLazyLinkClientToOperatorQuery()
    const [unlinkClientToOperatorTrigger] = useLazyUnlinkClientToOperatorQuery()
    const [linkClientToRoleTrigger] = useLazyLinkClientToRoleQuery()
    const [unlinkClientToRoleTrigger] = useLazyUnlinkClientToRoleQuery()

    const linkedClient = useMemo(() => getLinkedClientFromSubject(subject), [subject])

    useEffect(() => {
        linkedClient.OmniUserId &&
            clientSubscriptionsTrigger({
                ClientId: linkedClient.OmniUserId,
                Query: "",
                Size: MAX_LINK_SIZE
            })
    }, [linkedClient])

    const {
        shown: articleSubscribePopoverShown,
        showPopover: showLinkClientToOperatorPopover,
        hidePopover: hideLinkClientToOperatorPopover
    } = usePopoverShown()

    const handleSubmitSubscriptions = useCallback(
        async (values: ArticleSubscriptionFormValues, hidePopover: () => void) => {
            if (!linkedClient || !currentProject || !linkedClient.OmniUserId) {
                return
            }

            dispatch(actions.setClientSubscriptionsProcess(true))

            const { SubscriptionDiff } = values

            for (const key of Object.keys(SubscriptionDiff)) {
                const requestBody = getRequestBody(currentProject.id, linkedClient.OmniUserId, SubscriptionDiff[key])

                switch (SubscriptionDiff[key].action) {
                    case UserAccessActions.Added:
                        requestBody.OperatorId
                            ? await linkClientToOperatorTrigger(requestBody)
                            : await linkClientToRoleTrigger(requestBody)

                        updateLinkedClients(
                            dispatch,
                            SubscriptionDiff[key].action,
                            { ...linkedClient, ProjectId: currentProject.id },
                            isShowLinkedClients()
                        )
                        break
                    case UserAccessActions.Removed:
                        requestBody.OperatorId
                            ? await unlinkClientToOperatorTrigger(requestBody)
                            : await unlinkClientToRoleTrigger(requestBody)

                        updateLinkedClients(
                            dispatch,
                            SubscriptionDiff[key].action,
                            { ...linkedClient, ProjectId: currentProject.id },
                            isShowLinkedClients()
                        )
                        break
                }
            }

            await clientSubscriptionsTrigger({
                ClientId: linkedClient.OmniUserId,
                Query: "",
                Size: MAX_LINK_SIZE
            })

            dispatch(actions.setClientSubscriptionsProcess(false))
            hidePopover()
        },
        [linkedClient, currentProject?.id]
    )

    return (
        <AsyncQuery
            query={clientSubscriptionsQuery}
            processView={<Spinner size={30} />}
            errorView={() => <IconButton variant="link" icon={faEye} disabled />}
        >
            {({ data: subscriptions }) => (
                <LinkClientToOperatorPopover
                    loading={clientSubscriptionsProcess}
                    show={articleSubscribePopoverShown}
                    onSubmit={handleSubmitSubscriptions}
                    onCancel={hideLinkClientToOperatorPopover}
                    clientSubscriptions={subscriptions}
                    title={t(`${tNamespace}link-to-operator`)}
                    clientId={linkedClient.OmniUserId}
                    currentProject={currentProject}
                >
                    <IconButton
                        variant="link"
                        icon={faEye}
                        className={cn(
                            styles.clientSurvey__linkAction,
                            articleSubscribePopoverShown && styles.clientSurvey__linkAction_clicked
                        )}
                        onClick={
                            articleSubscribePopoverShown
                                ? hideLinkClientToOperatorPopover
                                : showLinkClientToOperatorPopover
                        }
                    />
                </LinkClientToOperatorPopover>
            )}
        </AsyncQuery>
    )
}

export default LinkClientToOperatorPopoverWrapper
