import { RefObject, useEffect } from "react"

export function useOutsideClick<T extends HTMLElement>(action: () => void, ref?: RefObject<T>, selectorName?: string) {
    const handleClickOutside = (e: MouseEvent) => {
        if (!e.target) {
            return
        }
        if (!ref?.current?.contains(e.target as Node) && selectorName === undefined) {
            action()
        }

        const element = selectorName ? document.querySelector(selectorName) : null
        if (!element?.contains(e.target as Node) && ref === undefined) {
            action()
        }
    }
    useEffect(() => {
        document.addEventListener("click", handleClickOutside)
        return () => document.removeEventListener("click", handleClickOutside)
    })
}
