import React from "react"
import DebouncedValidatableInput from "../../../../ValidatableInput/DebouncedValidatableInput"
import { useSelector } from "react-redux"
import { selectSurveys } from "../../../../../store/surveys/selectors"
import { WithT } from "i18next"

export interface QueueClientSurveysProps extends WithT {
    mainSurveyId: string
    shortSurveyId: string
}

const tNamespace = "queues:settings."

const QueueClientSurveys: React.FC<QueueClientSurveysProps> = props => {
    const { mainSurveyId, shortSurveyId, t } = props
    const surveys = useSelector(selectSurveys)

    return (
        <>
            <DebouncedValidatableInput
                id="queueFormMainSurveyId"
                as="select"
                disabled={!surveys.length}
                name={mainSurveyId}
                label={t(`${tNamespace}main-client-survey`)}
            >
                <option value="" hidden>
                    {t(`${tNamespace}select-client-survey`)}
                </option>
                {surveys.map(option => (
                    <option value={option.Id} key={option.Id}>
                        {option.Title}
                    </option>
                ))}
            </DebouncedValidatableInput>
            <DebouncedValidatableInput
                id="queueFormShortSurveyId"
                as="select"
                disabled={!surveys.length}
                name={shortSurveyId}
                label={t(`${tNamespace}additional-client-survey`)}
            >
                <option value="" hidden>
                    {t(`${tNamespace}select-client-survey`)}
                </option>
                {surveys.map(option => (
                    <option value={option.Id} key={option.Id}>
                        {option.Title}
                    </option>
                ))}
            </DebouncedValidatableInput>
        </>
    )
}

export default QueueClientSurveys
