import React, { useCallback } from "react"
import {
    isKnowledgeBasePermission,
    KnowledgeBaseAccessType,
    KnowledgeBasePermission,
    KnowledgeBasePermittedActionSelectItem
} from "../../models/knowledgeBase/permission"
import UserCard from "../UserCard/UserCard"
import { SimpleUser } from "../../models/user"
import { SimpleRole } from "../../models/role"
import styles from "./KnowledgeBaseUserAccessItem.module.scss"
import { ClassProps } from "../../utility/common/props"
import cn from "classnames"
import SidebarContextMenuSelect from "../SidebarContextMenuSelect/SidebarContextMenuSelect"
import { KnowledgeBaseSubscriptionSelectItem } from "../../models/knowledgeBase/subscription"
import MenuItemRow from "../MenuItemRow/MenuItemRow"
import { OnChangeAccessItemFunction, UserAccessPrimitive } from "../../models/knowledgeBase/userAccess"
import { isKnowledgeBaseSubscription } from "../../utility/knowledgeBase/knowledgeBase"
import { formTranslation } from "../../locales/form"
import { useTranslation } from "react-i18next"
import { faTimes } from "@fortawesome/pro-light-svg-icons/faTimes"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

interface Props extends ClassProps {
    data: UserAccessPrimitive
    subscriptionOptions?: KnowledgeBaseSubscriptionSelectItem[]
    permittedActionOptions?: KnowledgeBasePermittedActionSelectItem[]
    role?: SimpleRole
    user?: SimpleUser
    onChange: OnChangeAccessItemFunction
    onRemove: OnChangeAccessItemFunction
    hideProject?: boolean
}

const tProjectNamespace = "project:"

const KnowledgeBaseUserAccessItem: React.FC<Props> = props => {
    const {
        className,
        data,
        subscriptionOptions,
        permittedActionOptions,
        role,
        user,
        onRemove,
        onChange,
        hideProject
    } = props
    const { t } = useTranslation()

    const currentOptionType = isKnowledgeBaseSubscription(data)
        ? data.SubscriptionType
        : isKnowledgeBasePermission(data)
        ? data.Action
        : undefined

    const selectedOption =
        currentOptionType && subscriptionOptions
            ? subscriptionOptions.find(el => el.Type === currentOptionType)
            : permittedActionOptions
            ? permittedActionOptions.find(el => el.Action === currentOptionType)
            : undefined

    const handleSelectSubscriptionOption = (option: KnowledgeBaseSubscriptionSelectItem) => () => {
        if (isKnowledgeBaseSubscription(data)) {
            const updatedItem = { ...data, SubscriptionType: option.Type }
            onChange(updatedItem)
        }
    }

    const handleSelectPermissionOption = (option: KnowledgeBasePermittedActionSelectItem) => () => {
        if (isKnowledgeBasePermission(data)) {
            const updatedItem: KnowledgeBasePermission = {
                ...data,
                Action: option.Action
            }
            onChange(updatedItem)
        }
    }

    const getCardInfo = () => {
        const empty = ["", "", ""]
        switch (data.Type) {
            case KnowledgeBaseAccessType.Login:
                return user
                    ? [user.FirstName, user.LastName, user.Picture ?? ""]
                    : [data.Value, t(formTranslation.user), ""]
            case KnowledgeBaseAccessType.Role:
                return role ? ["", role.Title, ""] : [data.Value, t(formTranslation.role), ""]
            case KnowledgeBaseAccessType.All:
                return ["", t(formTranslation.all), ""]
            default:
                return empty
        }
    }

    const [firstName, lastName, picture] = getCardInfo()

    const handleRemove = useCallback(() => {
        onRemove(data)
    }, [onRemove, data])

    const options = subscriptionOptions
        ? subscriptionOptions?.map(option => (
              <MenuItemRow key={option.Type} title={option.Title} onClick={handleSelectSubscriptionOption(option)} />
          ))
        : permittedActionOptions
        ? permittedActionOptions?.map(option => (
              <MenuItemRow key={option.Action} title={option.Title} onClick={handleSelectPermissionOption(option)} />
          ))
        : undefined

    return (
        <div className={cn(styles.permission, className)}>
            <UserCard
                className={styles.permission__card}
                firstName={firstName}
                lastName={lastName}
                picture={picture}
                userCardMaxLength={15}
                size="xs"
            >
                {!hideProject && (
                    <div className={styles.permission__project}>
                        {`${t(`${tProjectNamespace}project`)} ${data.ProjectId}`}
                    </div>
                )}
            </UserCard>
            {options && <SidebarContextMenuSelect items={options} selected={selectedOption?.Title} />}
            <div className={styles.permission__remove} tabIndex={0}>
                <FontAwesomeIcon onClick={handleRemove} icon={faTimes} />
            </div>
        </div>
    )
}

export default KnowledgeBaseUserAccessItem
