import React from "react"
import styles from "./Comparison.module.scss"
import cn from "classnames"
import { useTranslation } from "react-i18next"
import { ComparisonBlockType, ComparisonCloseColor } from "../../../../models/articleCompare"

export interface ComparisonProps {
    type: ComparisonBlockType
    openOffset: number
    closeOffset: number
    closeColor?: ComparisonCloseColor
}

const tNamespace = "knowledgeBase:article-compare."

const Comparison: React.FC<ComparisonProps> = props => {
    const { type, openOffset, closeOffset, closeColor } = props
    const isClose = type === ComparisonBlockType.Close
    const isAdded = type === ComparisonBlockType.Added
    const { t } = useTranslation()

    const getTextByType = (type: ComparisonBlockType) => {
        switch (type) {
            case ComparisonBlockType.Removed:
                return t(`${tNamespace}deleted-block`)
            case ComparisonBlockType.Added:
                return t(`${tNamespace}added-block`)
            case ComparisonBlockType.Ignored:
                return t(`${tNamespace}ignored-block`)
            default:
                return ""
        }
    }

    if (isClose) {
        return (
            <div
                className={cn(
                    styles.comparison,
                    styles.comparison__close,
                    closeColor === ComparisonBlockType.Added && styles.comparison__close_added
                )}
                style={{ bottom: closeOffset + "px" }}
            ></div>
        )
    }

    return (
        <div className={styles.comparison} style={{ bottom: openOffset + "px" }}>
            <div className={cn(styles.comparison__line, isAdded && styles.comparison__line_added)}></div>
            <div className={styles.comparison__text}>{getTextByType(type)}</div>
        </div>
    )
}

export default Comparison
