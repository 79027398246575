import { queuesV2ApiController } from "../controllers/queuesV2"
import { Queue, QueueOperatorTasksCountUpdatedMsg } from "../../models/queue"
import { RootState } from "../../store/rootReducer"

export const selectCurrentQueue = (projectId: string, queueId: string, state: RootState) => {
    const currentQueueSelector = queuesV2ApiController.endpoints.getQueue.select({ projectId, queueId })
    const currentQueue = currentQueueSelector(state)

    return currentQueue.data
}

export const updateCurrentQueue = (projectId: string, queueId: string, queue: Queue) => {
    return queuesV2ApiController.util.upsertQueryData("getQueue", { projectId, queueId }, queue)
}

export const parseQueueIdFromPath = () => {
    const pathSegments = document.location.pathname.split("/")
    const queueId = pathSegments[pathSegments.length - 1]

    return queueId
}

export const updateOperatorTasksCount = (queue: Queue, message: QueueOperatorTasksCountUpdatedMsg): Queue => {
    const operatorsTasksCount = { ...queue.OperatorsTasksCount }

    operatorsTasksCount[message.OperatorId] = message.Count

    const updatedQueue = {
        ...queue,
        OperatorsTasksCount: operatorsTasksCount
    }

    return updatedQueue
}
