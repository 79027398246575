import { KnowledgeBasePermission, KnowledgeBasePermittedAction } from "../../models/knowledgeBase/permission"
import {
    AllowedActionDto,
    AllowedRightsDto,
    CreateQueueCategoryRequest,
    UpdateQueueCategoryRequest
} from "../../models/queueCategory"
import { CatalogFormValues } from "../../models/catalog"
import { permissionWeightMapper } from "../knowledgeBase/knowledgeBase"

export const convertQueueAction = (formAction: KnowledgeBasePermittedAction): AllowedActionDto => {
    switch (formAction) {
        case KnowledgeBasePermittedAction.Edit:
            return AllowedActionDto.Modify
        case KnowledgeBasePermittedAction.View:
            return AllowedActionDto.View
    }
}

export const convertQueueActionToKnowledgeBaseAction = (
    queueAction: AllowedActionDto
): KnowledgeBasePermittedAction => {
    switch (queueAction) {
        case AllowedActionDto.Modify:
            return KnowledgeBasePermittedAction.Edit
        case AllowedActionDto.View:
            return KnowledgeBasePermittedAction.View
        case AllowedActionDto.None:
            return KnowledgeBasePermittedAction.View
    }
}

export const convertQueuePermission = (formPermission: KnowledgeBasePermission): AllowedRightsDto => ({
    Id: formPermission.Value,
    Action: convertQueueAction(formPermission.Action),
    ProjectId: formPermission.ProjectId,
    Type: formPermission.Type
})

export const convertQueuePermissionToKnowledgeBasePermission = (
    queuePermission: AllowedRightsDto
): KnowledgeBasePermission => {
    const action = convertQueueActionToKnowledgeBaseAction(queuePermission.Action)
    return {
        Type: queuePermission.Type,
        Action: action,
        Value: queuePermission.Id,
        ProjectId: queuePermission.ProjectId,
        SearchValue: "",
        Weight: permissionWeightMapper(queuePermission.Type, action)
    }
}

export const convertCreateQueueCategoryRequest = (formValues: CatalogFormValues): CreateQueueCategoryRequest => ({
    Access: formValues.Permissions.map(convertQueuePermission),
    Name: formValues.Title,
    Queues: []
})

export const convertUpdateQueueCategoryRequest = (formValues: CatalogFormValues): UpdateQueueCategoryRequest => ({
    Access: formValues.Permissions.map(convertQueuePermission),
    Name: formValues.Title,
    Queues: []
})
