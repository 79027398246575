import React, { useMemo } from "react"
import i18n, { WithT } from "i18next"
import { useSelector } from "react-redux"
import { selectChannels } from "../../../../../store/channels/selectors"
import { EmailChannelsValues } from "../../../../../models/queueValues"
import { ChannelTypeString } from "../../../../../models/channel"
import { getLanguage } from "../../../../../utility/common/language"
import DebouncedValidatableInput from "../../../../ValidatableInput/DebouncedValidatableInput"
import FormControlWithAdd from "../../../../FormHelpers/ControlWithAdd/FormControlWithAdd"
import CloseButton from "../../../../CloseButton/CloseButton"
import styles from "./QueueEmailChannels.module.scss"

export interface QueueEmailChannelsProps extends WithT {
    defaultChannelTemplate: string
    additionalChannelsTemplate: string
    values: EmailChannelsValues
}

const tNamespace = "queues:settings."

const QueueEmailChannels: React.FC<QueueEmailChannelsProps> = props => {
    const { t, defaultChannelTemplate, additionalChannelsTemplate, values } = props
    const channels = useSelector(selectChannels)
    const locale = getLanguage(i18n)

    const emailChannels = useMemo(
        () =>
            channels
                ?.filter(channel => channel.Type === ChannelTypeString.Email)
                .sort((a, b) => a.Title.toLowerCase().localeCompare(b.Title.toLowerCase(), locale)),
        [channels, locale]
    )

    return (
        <>
            <DebouncedValidatableInput
                id="queueFormEmailChannelSelectByDefault"
                as="select"
                disabled={!emailChannels?.length}
                name={defaultChannelTemplate}
                label={t(`${tNamespace}email-channel-by-default`)}
            >
                <option value="">{t(`${tNamespace}select-email-channel-by-default`)}</option>
                {emailChannels?.map(option => (
                    <option value={option.Id} key={option.Id}>
                        {option.Title}
                    </option>
                ))}
            </DebouncedValidatableInput>
            <FormControlWithAdd
                name={additionalChannelsTemplate}
                label={t(`${tNamespace}add-email-channel-for-answer`)}
                onCreateEmptyValue={() => ""}
                small
            >
                {({ name, remove }) =>
                    values.additionalChannelsIds &&
                    values.additionalChannelsIds.map((_, index) => (
                        <div className={styles.emailChannel__content} key={index}>
                            <DebouncedValidatableInput
                                id={`queueFormEmailChannelSelectForAnswer-${index}`}
                                as="select"
                                disabled={!emailChannels?.length}
                                name={`${name}[${index}]`}
                                className={styles.emailChannel__input}
                            >
                                <option value="" hidden>
                                    {t(`${tNamespace}select-email-channel-by-default`)}
                                </option>
                                {emailChannels?.map(option => (
                                    <option
                                        hidden={
                                            values.additionalChannelsIds.includes(option.Id) ||
                                            values.defaultChannelId === option.Id
                                        }
                                        value={option.Id}
                                        key={option.Id}
                                    >
                                        {option.Title}
                                    </option>
                                ))}
                            </DebouncedValidatableInput>
                            <CloseButton onClick={() => remove(index)} className={styles.emailChannel__deleteIcon} />
                        </div>
                    ))
                }
            </FormControlWithAdd>
        </>
    )
}

export default QueueEmailChannels
