import React, { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import PageLayout from "../../components/PageLayout/PageLayout"
import styles from "./QueuesV2.module.scss"
import { shallowEqual, useSelector } from "react-redux"
import { selectQueues } from "../../store/queues/selectors"
import { selectCurrentProject, selectCurrentProjectId } from "../../store/projects/selectors"
import { subscribeOnQueuesV2Data, unsubscribeFromQueuesV2Data } from "../../store/queues/thunks"
import { selectGetChannelsNamesState } from "../../store/channels/selectors"
import useQueuesSidebar from "./QueuesV2Sidebar"
import QueueCategoriesContainer from "../../components/QueueCategories/QueueCategoriesContainer"
import { Route, Switch } from "react-router"
import { useAppDispatch, useAppSelector } from "../../store/hooks"
import { push } from "connected-react-router"
import QueueContainer from "../../components/Queues/QueueContainer"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFileUser } from "@fortawesome/pro-light-svg-icons"
import QueuesTasksSidebar from "../../components/Queues/QueuesTasksSidebar/QueuesTasksSidebar"
import QueuePendingTasksSidebar from "../../components/Queues/QueuePendingTasksSidebar/QueuePendingTasksSidebar"
import QueueOperatorSidebar from "../../components/Queues/QueueOperatorSidebar/QueueOperatorSidebar"
import QueuesTaskSidebar from "../../components/Queues/QueuesTaskSidebar/QueuesTaskSidebar"
import { actions } from "../../store/queues/slice"

const QUEUES_PAGE_ID = "queue"
const tNamespace = "queues"

const QueuesV2: React.FC = () => {
    const { t } = useTranslation(tNamespace)
    const project = useAppSelector(selectCurrentProject)
    const allQueues = useAppSelector(selectQueues, shallowEqual)
    const dispatch = useAppDispatch()
    const [leftSidebarScrollable, setLeftSidebarScrollable] = useState(true)
    const {
        handleUpdateQueue,
        handleAddQueue,
        handleTaskOfAutomaticProcessingView,
        onCloseSidebar,
        renderSidebarContent,
        sidebarClosed,
        sidebarContent
    } = useQueuesSidebar(dispatch, allQueues, project)
    const [isHubConnected, setIsHubConnected] = useState(false)
    const { title = "", settings, onBack, action } = sidebarContent || {}

    useEffect(() => {
        if (!project) return

        dispatch(subscribeOnQueuesV2Data(project.id)).then(() => {
            setIsHubConnected(true)
        })
        return () => {
            dispatch(unsubscribeFromQueuesV2Data(project.id))
            setIsHubConnected(false)

            dispatch(actions.closeAllSidebars())
        }
    }, [project, dispatch])

    const getChannelsNamesState = useSelector(selectGetChannelsNamesState)

    const handleEditCatalog = useCallback(() => setLeftSidebarScrollable(false), [])
    const handleCancelEditCatalog = useCallback(() => setLeftSidebarScrollable(true), [])

    const projectId = useAppSelector(selectCurrentProjectId)
    const pagePath = `/project/${projectId}/queuesV2`

    if (!project) {
        return null
    }

    const handleCategoryClick = (queueId: string) => {
        const newUrl = `/project/${projectId}/queuesV2/${queueId}`

        dispatch(push(newUrl))
    }

    return (
        <PageLayout isSidebarCollapsed={sidebarClosed}>
            <PageLayout.LeftSidebar
                isScrollable={leftSidebarScrollable}
                title={t("queues")}
                isExpandable
                id={QUEUES_PAGE_ID}
            >
                <QueueCategoriesContainer
                    onEditCatalogClick={handleEditCatalog}
                    onCancelEditCatalogClick={handleCancelEditCatalog}
                    projectId={project.id}
                    onAddQueue={handleAddQueue}
                    isSelectCatalogAllowed={false}
                    onCategoryClick={handleCategoryClick}
                />
            </PageLayout.LeftSidebar>
            <PageLayout.Content className={styles.queues} overlappingSidebar>
                <Switch>
                    <Route path={`${pagePath}/:queueId`}>
                        {({ match }) =>
                            match && (
                                <QueueContainer
                                    id={match.params.queueId}
                                    onSettingsClick={handleUpdateQueue}
                                    onTaskOfAutomaticProcessingClick={handleTaskOfAutomaticProcessingView}
                                    isHubConnected={isHubConnected}
                                />
                            )
                        }
                    </Route>
                    <Route path={pagePath}>
                        <div className={styles.queues__default}>
                            <FontAwesomeIcon icon={faFileUser} size="5x" className={styles.queues__defaultIcon} />
                            <span className={styles.queues__defaultMessage}>{t("choose-queue")}</span>
                        </div>
                    </Route>
                </Switch>
            </PageLayout.Content>
            <PageLayout.Sidebar
                title={title}
                onClose={onCloseSidebar}
                settings={settings}
                className={styles.queues__sidebar}
                classNameClosed={styles.queues__sidebar_closed}
                onBack={onBack}
                action={action}
            >
                {renderSidebarContent(project.id, getChannelsNamesState.data)}
            </PageLayout.Sidebar>
            <QueuesTasksSidebar />
            <QueuePendingTasksSidebar />
            <QueueOperatorSidebar allQueues={allQueues} />
            <QueuesTaskSidebar allQueues={allQueues} />
        </PageLayout>
    )
}

export default QueuesV2
