import { MenuItem } from "../../models/menu"
import { OldMenuPermissions } from "../menu/oldMenuPermissions"
import { isPermissionEnabled } from "../common/usePermissionsCheck"
import { isRefExists } from "../common/useRefCheck"
import { Reference } from "../../models/reference"
import { Permission } from "../../models/role"
import { CustomMenuValue } from "../../models/projectSettings"

export const getAllowedMenuItems = (
    items: MenuItem[],
    currentPermissions: Permission[],
    customMenus?: CustomMenuValue[],
    references?: Reference[]
) => {
    const filteredItems = items.filter(menuItem => {
        if (!menuItem.role && !menuItem.reference) {
            return true
        }
        let roles: string[] = []
        const localMenuPermission = OldMenuPermissions[menuItem.id]
        if (localMenuPermission) {
            roles.push(localMenuPermission)
        }

        if (Array.isArray(menuItem.role)) {
            roles = roles.concat(menuItem.role)
        } else if (menuItem.role) {
            roles.push(menuItem.role)
        }

        if (menuItem.rolesValidator) {
            return menuItem.rolesValidator(roles, currentPermissions)
        }

        const isRolesFit = roles.every(p => isPermissionEnabled(p, currentPermissions))
        let isRefsFit = true

        if (menuItem.reference) {
            isRefsFit = references ? isRefExists(menuItem.reference, references) : false
        }

        return isRolesFit && isRefsFit
    })

    let customMenusArr: MenuItem[] = []
    if (customMenus) {
        customMenusArr = customMenus.reduce<MenuItem[]>((acc, customMenus) => {
            acc.push({ id: customMenus.Id, submenus: [], icon: customMenus.Icon, title: customMenus.Title })
            return acc
        }, [])
    }

    return [...filteredItems, ...customMenusArr]
}
