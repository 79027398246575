import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { selectMonitoringOverviewCategoryId, selectMonitoringOverviewState } from "../../store/queues/selectors"
import QueueForAutomaticProcessingContent from "./QueueForAutomaticProcessingContent"
import debounce from "lodash/debounce"
import { selectCurrentProject } from "../../store/projects/selectors"
import { getMonitoringOverview } from "../../store/queues/thunks"
import { getAutomaticProcessing } from "../../store/tasks/thunks"
import { selectGetAutomaticProcessingState } from "../../store/tasks/selectors"
import { selectChannels } from "../../store/channels/selectors"
import { ChannelNames } from "../../models/channel"
import { TaskOfAutomaticProcessing } from "../../models/task"
import { ClassProps } from "../../utility/common/props"

interface QueueForAutomaticProcessingProps extends ClassProps {
    channelNames: ChannelNames
    onTaskClick: (task: TaskOfAutomaticProcessing) => void
}

const QueueForAutomaticProcessing: React.FC<QueueForAutomaticProcessingProps> = props => {
    const { channelNames, onTaskClick, className } = props
    const dispatch = useDispatch()
    const monitoringOverviewAsyncState = useSelector(selectMonitoringOverviewState)
    const categoryId = useSelector(selectMonitoringOverviewCategoryId)
    const automaticProcessingAsyncState = useSelector(selectGetAutomaticProcessingState)
    const channels = useSelector(selectChannels)
    const project = useSelector(selectCurrentProject)

    useEffect(() => {
        if (project) {
            dispatch(getMonitoringOverview(project.id))
        }
    }, [dispatch, project])

    const handleUpdate = debounce((isOpened: boolean) => {
        if (project) {
            dispatch(getMonitoringOverview(project.id))
            if (isOpened || automaticProcessingAsyncState.error) {
                dispatch(getAutomaticProcessing(project.id))
            }
        }
    }, 500)

    const handleQueueOpen = debounce(() => {
        if (project) {
            dispatch(getAutomaticProcessing(project.id))
            dispatch(getMonitoringOverview(project.id))
        }
    }, 500)

    if (!categoryId) {
        return null
    }

    return (
        <QueueForAutomaticProcessingContent
            className={className}
            overviewState={monitoringOverviewAsyncState}
            channelNames={channelNames}
            onUpdate={handleUpdate}
            onQueueOpen={handleQueueOpen}
            onTaskClick={onTaskClick}
            tasksState={automaticProcessingAsyncState}
            channels={channels}
            categoryId={categoryId}
        />
    )
}

export default QueueForAutomaticProcessing
