export interface PaginationResponse<ResponseType> {
    Items: ResponseType[]
    ItemsTotal: number
    PageSize: number
    PageFrom: number
    PageTotal: number
    Filtered?: number
}

export interface PaginationRequest {
    PageFrom?: number
    PageSize?: number
    Search?: string
}

export enum RequestType {
    PROJECT_USER = "users",
    SLOT = "slot"
}

export const ITEMS_PER_PAGE = 50
export const QUEUES_TASKS_PER_PAGE = 10
