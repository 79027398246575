import { faEnvelopeOpen } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useTranslation } from "react-i18next"
import styles from "./MassMailingEmptyView.module.scss"

const tNamespace = "massMailing:"

export const MassMailingEmptyView = () => {
    const { t } = useTranslation()

    return (
        <div className={styles.emptyView}>
            <FontAwesomeIcon size="5x" icon={faEnvelopeOpen} />
            <div className={styles.emptyView__text}>{t(`${tNamespace}empty-view`)}</div>
        </div>
    )
}
