import InputLabel, { InputLabelSharedProps } from "../InputLabel/InputLabel"
import { FormGroup } from "react-bootstrap"
import { ClassProps } from "../../../utility/common/props"
import { FormGroupProps } from "react-bootstrap/FormGroup"
import CloseButton from "../../CloseButton/CloseButton"
import styles from "./VerticalInputGroup.module.scss"

export interface VerticalInputGroupProps extends InputLabelSharedProps {
    onDelete?: () => void
    disabled?: boolean
}

const VerticalInputGroup: React.FC<VerticalInputGroupProps & FormGroupProps & ClassProps> = props => {
    const { children, onDelete, className, controlId, disabled, ...inputLabelProps } = props

    return (
        <FormGroup className={className} controlId={controlId}>
            <InputLabel {...inputLabelProps}>
                {onDelete && <CloseButton onClick={onDelete} disabled={disabled} className={styles.closeBtn} />}
            </InputLabel>
            {children}
        </FormGroup>
    )
}

export default VerticalInputGroup
