import React, { useContext, useEffect, useRef, useState } from "react"
import { Form, useFormikContext } from "formik"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCloudUpload } from "@fortawesome/pro-light-svg-icons"
import styles from "./ArticleImportFilesModalForm.module.scss"
import DroppableAreaContext from "../../../DroppableArea/DroppableAreaContext"
import ArticleImportFile from "../../../ArticleImportFile/ArticleImportFile"
import { useTranslation } from "react-i18next"
import { nameof } from "../../../../utility/common/nameof"
import { faPlus } from "@fortawesome/pro-light-svg-icons/faPlus"

export type ArticleImportFilesModalFormType = {
    Files: File[]
}

const tFilesNamespace = "common:files."
export const FILES_ACCEPT =
    ".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"

const ArticleImportFilesModalForm = () => {
    const { t } = useTranslation()
    const { files } = useContext(DroppableAreaContext)
    const [importFiles, setImportFiles] = useState<File[]>([])
    const { setFieldValue } = useFormikContext()
    const inputFileRef = useRef<HTMLInputElement>(null)

    useEffect(() => {
        setImportFiles([...importFiles, ...(files as File[])])
    }, [files])

    useEffect(() => {
        setFieldValue(nameof<ArticleImportFilesModalFormType>("Files"), importFiles, true)
    }, [importFiles, setFieldValue])

    const handleDeleteFile = (file: File) => {
        const indexToDelete = importFiles.findIndex(importFile => importFile.name === file.name)

        if (indexToDelete !== -1) {
            setImportFiles(prevFiles => {
                return [...prevFiles.slice(0, indexToDelete), ...prevFiles.slice(indexToDelete + 1)]
            })
        }
    }

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files == null || e.target.files.length === 0) {
            return
        }

        const selectedFiles = Array.from(e.target.files)
        setImportFiles([...importFiles, ...selectedFiles])

        e.target.value = ""
    }

    const handleFileSelectClick = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault()

        if (!inputFileRef.current) {
            return
        }

        inputFileRef.current.accept = FILES_ACCEPT
        inputFileRef.current.click()
    }

    return (
        <Form role="form">
            {importFiles.length === 0 ? (
                <div className={styles.articleImportFiles_empty}>
                    <FontAwesomeIcon
                        icon={faCloudUpload}
                        size="3x"
                        className={styles.draggingArea__dropPlaceContent__icon}
                    />
                    <div className={styles.draggingArea__dropPlaceContent__text}>
                        {t(`${tFilesNamespace}drag-files-for-import`)}
                    </div>
                    <button
                        className={styles.articleImportFiles__fileSelect}
                        onClick={(event: React.MouseEvent<HTMLElement>) => handleFileSelectClick(event)}
                    >
                        {t(`${tFilesNamespace}add-files-manually`)}
                    </button>
                </div>
            ) : (
                <>
                    <div className={styles.articleImportFiles__filesList}>
                        {importFiles.map((importFile, index) => (
                            <ArticleImportFile
                                key={`${importFile.name}-${index}`}
                                file={importFile}
                                onDelete={handleDeleteFile}
                            />
                        ))}
                    </div>
                    <button
                        className={styles.articleImportFiles__fileSelectMore}
                        onClick={(event: React.MouseEvent<HTMLElement>) => handleFileSelectClick(event)}
                    >
                        <FontAwesomeIcon icon={faPlus} size="lg" />
                        {t(`${tFilesNamespace}add-files`)}
                    </button>
                </>
            )}
            <input
                type="file"
                ref={inputFileRef}
                className={styles.articleImportFiles__input}
                onChange={handleFileChange}
                multiple
                accept={FILES_ACCEPT}
            />
        </Form>
    )
}

export default ArticleImportFilesModalForm
