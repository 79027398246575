import React, { useMemo } from "react"
import { JSAgentValues, RoutingAgentValues } from "../../../../../models/agent"
import { useSelector } from "react-redux"
import { selectAgents } from "../../../../../store/agents/selectors"
import { nameof } from "../../../../../utility/common/nameof"
import AgentFormWrapper from "../../../../AgentFormWrapper/AgentFormWrapper"
import { JSAgentFormProps } from "../JSAgentForm"
import CodeBlockValidatableInput from "../../../../CodeBlockValidatableInput/CodeBlockValidatableInput"
import DebouncedValidatableInput from "../../../../ValidatableInput/DebouncedValidatableInput"
import { formTranslation } from "../../../../../locales/form"
import ValidatableInput from "../../../../ValidatableInput/ValidatableInput"

const tNamespace = "agent:form."
const tNamespaceJSAgent = "agent:form.jsagent."

const FormikJSAgentAdvanced: React.FC<JSAgentFormProps> = props => {
    const { agent, t, handleSubmit, disabled, inProcess } = props

    const agents = useSelector(selectAgents)

    const agentOptions = useMemo(() => (agents ?? []).filter(a => !agent || a.Id !== agent.Id), [agent, agents])

    return (
        <AgentFormWrapper onSubmit={handleSubmit} disabled={disabled} loading={inProcess}>
            <div className="agent-form__section">
                <CodeBlockValidatableInput
                    id={"formSettings"}
                    label={t(`${tNamespaceJSAgent}additional-settings`)}
                    name={nameof<JSAgentValues>("Settings")}
                    language={"json"}
                />
                <DebouncedValidatableInput
                    as="select"
                    id="formAIAgent"
                    name={nameof<JSAgentValues>("AIAgent")}
                    label={t(`${tNamespaceJSAgent}ai-agent`)}
                >
                    {agentOptions.map(option => (
                        <option value={option.Id} key={option.Id}>
                            {option.Id}
                        </option>
                    ))}
                </DebouncedValidatableInput>
                <ValidatableInput
                    as="select"
                    id="formTitle"
                    type="text"
                    name={nameof<RoutingAgentValues>("FailOverAgent")}
                    label={t(`${tNamespace}agent-if-failure-current`)}
                    disabled={!agentOptions.length}
                >
                    <option value="">{t(formTranslation.nothingSelected)}</option>
                    {agentOptions.map(option => (
                        <option value={option.Id} key={option.Id}>
                            {option.Id}
                        </option>
                    ))}
                </ValidatableInput>
            </div>
        </AgentFormWrapper>
    )
}

export default FormikJSAgentAdvanced
