import { useAppSelector } from "../../../store/hooks"
import { useLocation } from "react-router-dom"
import { selectCurrentProjectId } from "../../../store/projects/selectors"

export const useQueueSidebarData = () => {
    const projectId = useAppSelector(selectCurrentProjectId) ?? ""

    const location = useLocation()
    const pathSegments = location.pathname.split("/")
    const queueId = pathSegments[pathSegments.length - 1]

    return {
        queueId,
        projectId
    }
}
