import React from "react"
import { Tab } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { Operator } from "../../models/operator"
import Tabs from "../Tabs/Tabs"
import OperatorInfo from "../OperatorInfo/OperatorInfo"
import OperatorQueuesForm from "../OperatorQueuesForm/OperatorQueuesForm"
import { Queue } from "../../models/queue"
import { ModifyOperatorQueues } from "../../permissions"
import usePermissionsCheck from "../../utility/common/usePermissionsCheck"
import OperatorTasks from "../OperatorTasks/OperatorTasks"
import { Task } from "../../models/task"
import { useAppDispatch } from "../../store/hooks"
import { actions } from "../../store/queues/slice"

const tNamespace = "queues:operator."

interface Props {
    projectId: string
    operator: Operator
    queues: Queue[]
    onClose: () => void
    isShowTasksEntry?: boolean
}

enum TabKeys {
    Tasks = "tasks",
    Profile = "profile",
    Queues = "queues"
}

const OperatorView: React.FC<Props> = props => {
    const { projectId, operator, queues, onClose, isShowTasksEntry = false } = props
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const modifyOperatorQueuesAllowed = usePermissionsCheck([ModifyOperatorQueues])

    const tabKeys = [
        isShowTasksEntry && TabKeys.Tasks,
        TabKeys.Profile,
        modifyOperatorQueuesAllowed && TabKeys.Queues
    ].filter<TabKeys>((key): key is TabKeys => !!key)

    const tabEntries = tabKeys.map(key => ({ key, value: t(`${tNamespace}${key}`) }))

    const handleOpenTaskSidebar = (task: Task, operator: Operator) => {
        dispatch(actions.openQueuesTaskSidebar({ task, operator, isBack: true }))
    }

    return (
        <Tabs id="operator-view-tabs" alwaysReload={false} entries={tabEntries}>
            <Tab.Pane eventKey={TabKeys.Tasks}>
                <OperatorTasks tasks={operator.Tasks} onTaskClick={handleOpenTaskSidebar} operator={operator} />
            </Tab.Pane>
            <Tab.Pane eventKey={TabKeys.Profile}>
                <OperatorInfo projectId={projectId} operator={operator} />
            </Tab.Pane>
            <Tab.Pane eventKey={TabKeys.Queues}>
                <OperatorQueuesForm projectId={projectId} operator={operator} queues={queues} onClose={onClose} />
            </Tab.Pane>
        </Tabs>
    )
}

export default OperatorView
