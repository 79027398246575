import React from "react"
import { Formik } from "formik"
import { ArticlePublishInfo, KnowledgeBaseArticlePublishType } from "../../models/knowledgeBase/knowledgeBasePublish"
import RadioButtonValidatableInput from "../RadioButtonValidatableInput/RadioButtonValidatableInput"
import { nameof } from "../../utility/common/nameof"
import { Button } from "react-bootstrap"
import LoadingButton from "../LoadingButton/LoadingButton"
import styles from "./ArticlePublishForm.module.scss"
import { formTranslation } from "../../locales/form"
import { useTranslation } from "react-i18next"
import { DatePickerInput } from "../DatePickerInput"
import { Form } from "react-bootstrap"
import { Time } from "../../utility/common/time"
import { getArticlePublishFormValidationSchema } from "./ArticlePublishForm.schema"
import { testId } from "./ArticlePublishForm.const"

export interface ArticlePublishFormProps {
    onCancel: () => void
    onSubmit: (publishInfo: ArticlePublishInfo) => void
}

const tNamespace = "knowledgeBase:"

const initialValues: ArticlePublishInfo = {
    Type: KnowledgeBaseArticlePublishType.Default,
    PublishDate: 0,
    UnpublishDate: 0,
    IsCompleted: false
}

const getDatePickerValue = (timestamp: number) => {
    return timestamp ? Time.stampToLocalDate(timestamp) : ""
}

const ArticlePublishForm = (props: ArticlePublishFormProps) => {
    const { onCancel, onSubmit } = props
    const { t } = useTranslation()

    const articlePublishFormSchema = getArticlePublishFormValidationSchema()

    return (
        <Formik<ArticlePublishInfo>
            initialValues={initialValues}
            validationSchema={articlePublishFormSchema}
            onSubmit={values => {
                onSubmit(values)
            }}
        >
            {formikProps => {
                const { Type, PublishDate, UnpublishDate } = formikProps.values
                const isDefaultPublishType = Type === KnowledgeBaseArticlePublishType.Default
                const datePickerMinDate = new Date()

                return (
                    <Form className={styles.articlePublishForm} onSubmit={formikProps.handleSubmit}>
                        <RadioButtonValidatableInput
                            id="formPublishDefaultType"
                            value={KnowledgeBaseArticlePublishType.Default}
                            name={nameof<ArticlePublishInfo>("Type")}
                            label={t(`${tNamespace}publish.default-label`)}
                            data-testid={testId.defaultPublish}
                        />
                        <RadioButtonValidatableInput
                            id="formPublishTimeoutType"
                            value={KnowledgeBaseArticlePublishType.Timeout}
                            name={nameof<ArticlePublishInfo>("Type")}
                            label={t(`${tNamespace}publish.timeout-label`)}
                            data-testid={testId.defaultPublish}
                        />
                        <div className={styles.articlePublishForm__group}>
                            <Form.Group className={styles.articlePublishForm__subgroup}>
                                <Form.Label>{t(`${tNamespace}publish.date.publish`)}</Form.Label>
                                <DatePickerInput
                                    name={nameof<ArticlePublishInfo>("PublishDate")}
                                    onChange={(dates: Date[]) => {
                                        formikProps.setFieldValue("PublishDate", dates[0].getTime(), true)
                                    }}
                                    onClear={() => {
                                        formikProps.setFieldValue("PublishDate", 0)
                                    }}
                                    value={getDatePickerValue(PublishDate)}
                                    showTimeInput
                                    minDate={datePickerMinDate}
                                    disabled={isDefaultPublishType}
                                    data-testid={testId.publishDate}
                                />
                            </Form.Group>
                            <Form.Group className={styles.articlePublishForm__subgroup}>
                                <Form.Label>{t(`${tNamespace}publish.date.unpublish`)}</Form.Label>
                                <DatePickerInput
                                    name={nameof<ArticlePublishInfo>("UnpublishDate")}
                                    onChange={(dates: Date[]) => {
                                        formikProps.setFieldValue("UnpublishDate", dates[0].getTime(), true)
                                    }}
                                    onClear={() => {
                                        formikProps.setFieldValue("UnpublishDate", 0)
                                    }}
                                    value={getDatePickerValue(UnpublishDate)}
                                    showTimeInput
                                    minDate={datePickerMinDate}
                                    disabled={isDefaultPublishType}
                                    data-testid={testId.unpublishDate}
                                />
                            </Form.Group>
                        </div>
                        <div className={styles.articlePublishForm__footer}>
                            <Button
                                className={styles.articlePublishForm__cancel}
                                variant="light"
                                onClick={onCancel}
                                data-testid={testId.cancelButton}
                            >
                                {t(formTranslation.cancel)}
                            </Button>
                            <LoadingButton
                                className={styles.articlePublishForm__submit}
                                variant="primary"
                                type="submit"
                                data-testid={testId.publishButton}
                            >
                                {t(formTranslation.publish)}
                            </LoadingButton>
                        </div>
                    </Form>
                )
            }}
        </Formik>
    )
}

export default ArticlePublishForm
