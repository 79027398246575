import React, { useMemo, useState } from "react"
import styles from "./AgentTypeForm.module.scss"
import { useTranslation } from "react-i18next"
import { AgentType } from "../../models/agent"
import SearchInput from "../SearchInput/SearchInput"
import FormAccordion from "../FormAccordion/FormAccordion"
import { TFunction } from "i18next"
import AgentTypeFormCard from "./AgentTypeFormCard"

const tNamespace = "agent:"

const agentTypes = [
    AgentType.AIAssist2,
    AgentType.NightAgent,
    AgentType.RoutingAgent,
    AgentType.ABAgent,
    AgentType.RestAgent,
    AgentType.EmailAgent,
    AgentType.InitialMsgAgent,
    AgentType.RemoteWorkplaceAgent,
    AgentType.FixedReplyAgent,
    AgentType.FinishDialogAgent,
    AgentType.SurveysAgent,
    AgentType.IdentificationAgent,
    AgentType.JqAgent,
    AgentType.JSAgent,
    AgentType.ScoreAgent
]

const agentIntegrationTypes = [
    AgentType.MEDODSIntegration,
    AgentType.ADFSAgent,
    AgentType.PsbBssAgent,
    AgentType.YandexMapAgent,
    AgentType.YandexMapGeocodeAgent
]

interface AgentTypesWithTitle {
    type: AgentType
    title: string
}

interface Props {
    onSelect: (type: AgentType) => void
}

const filterTypes = (types: AgentTypesWithTitle[], query: string) => {
    const q = query.trim().toLowerCase()
    return q ? types.filter(t => t.title.toLowerCase().includes(q) || t.type.toLowerCase().includes(q)) : types
}

const addTitles =
    (t: TFunction) =>
    (type: AgentType): AgentTypesWithTitle => ({
        type,
        title: t(`${tNamespace}agent-type.${type}`)
    })

const AgentTypeForm: React.FC<Props> = props => {
    const { t } = useTranslation()
    const { onSelect } = props
    const [searchInput, setSearchInput] = useState("")

    const agentTypesWithTitle: AgentTypesWithTitle[] = useMemo(() => agentTypes.map(addTitles(t)), [t])

    const agentIntegrationTypesWithTitle: AgentTypesWithTitle[] = useMemo(
        () => agentIntegrationTypes.map(addTitles(t)),
        [t]
    )

    return (
        <div className={styles.agentTypeForm}>
            <SearchInput
                onChange={searchValue => setSearchInput(searchValue)}
                className={styles.agentTypeForm__searchInput}
            />
            <div className={styles.agentTypeForm__content}>
                <FormAccordion
                    title={t(`${tNamespace}basic`)}
                    initialOpened={true}
                    className={styles.agentTypeForm__accordion}
                >
                    {filterTypes(agentTypesWithTitle, searchInput).map(v => (
                        <AgentTypeFormCard key={v.type} type={v.type} title={v.title} onSelect={onSelect} t={t} />
                    ))}
                </FormAccordion>
                <FormAccordion
                    title={t(`${tNamespace}integrations`)}
                    initialOpened={true}
                    className={styles.agentTypeForm__accordion}
                >
                    {filterTypes(agentIntegrationTypesWithTitle, searchInput).map(v => (
                        <AgentTypeFormCard key={v.type} type={v.type} title={v.title} onSelect={onSelect} t={t} />
                    ))}
                </FormAccordion>
            </div>
        </div>
    )
}

export default AgentTypeForm
