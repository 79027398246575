import { ChannelDto, ChannelProcessor, WebChatAdditionalData, ChannelNames } from "../../models/channel"
import { ChannelDeclarationsDto, WebhooksPrefixes } from "../../models/parameterDeclaration"
import { apiInstance } from "../instances/apiInstance"

const channelsController = {
    getDeclarations: (): Promise<ChannelDeclarationsDto> =>
        apiInstance.get("/channels/declarations").then(response => response.data),
    getChannels: (projectId: string): Promise<ChannelProcessor> =>
        apiInstance.get(`/${projectId}/channels`).then(response => response.data),
    getChannelNames: (projectId: string): Promise<ChannelNames> =>
        apiInstance.get(`/${projectId}/channels/names`).then(response => response.data),
    create: (request: ChannelDto): Promise<ChannelDto> =>
        apiInstance.post("/channels", request).then(response => response.data),
    update: (request: ChannelDto): Promise<ChannelDto> =>
        apiInstance.put("/channels", request).then(response => response.data),
    delete: (projectId: string, channelId: string): Promise<void> =>
        apiInstance.delete(`/${projectId}/channels/${channelId}`).then(response => response.data),
    getWebChatAdditionalData: (projectId: string, channelId: string): Promise<WebChatAdditionalData> =>
        apiInstance.get(`/${projectId}/channels/webchat/${channelId}/additional-data`).then(response => response.data),
    getWebhooksPrefixes: (): Promise<WebhooksPrefixes> =>
        apiInstance.get("/channels/webhooks-prefixes").then(response => response.data)
}

export default channelsController
