import { createApi } from "@reduxjs/toolkit/dist/query/react"
import tags from "./tags"
import axios from "axios"
import dynamicBaseQuery from "./dynamicBaseQuery"

export const instanceConfig = {
    baseURL: `/api/v2`,
    timeout: 25000,
    withCredentials: true
}

export const apiInstance = axios.create(instanceConfig)
export const apiInstanceV1 = axios.create({
    ...instanceConfig,
    baseURL: `/api`
})
export const apiInstanceRemoteWorkplace = axios.create({
    ...instanceConfig,
    baseURL: `/remote-workplace-authentication-token`
})

export const queryApiReducerPath = "queryApi" as const
export const queryApi = createApi({
    baseQuery: dynamicBaseQuery,
    reducerPath: queryApiReducerPath,
    tagTypes: tags,
    endpoints: () => ({})
})
