import { MonitoringOverview } from "../../models/queue"
import { TFunction } from "i18next"
import React from "react"
import { monitoringOverviewConverter } from "../../utility/queues/monitoringOverviewConverter"
import QueueInfoItem from "../QueueInfoItem/QueueInfoItem"
import { faClipboardCheck } from "@fortawesome/pro-light-svg-icons/faClipboardCheck"

const tNamespace = "queues:card."

interface MonitoringOverviewInfoItemProps {
    overview: MonitoringOverview
    categoryId: string
    t: TFunction
}

const MonitoringOverviewInfoItem: React.FC<MonitoringOverviewInfoItemProps> = ({ overview, categoryId, t }) => {
    const queue = monitoringOverviewConverter.toAutomaticProcessingQueue(overview, categoryId)
    return (
        <QueueInfoItem title={t(`${tNamespace}active-tasks`)} icon={faClipboardCheck} value={queue.ActiveTasksCount} />
    )
}

interface MonitoringOverviewInfoItemErrorProps {
    t: TFunction
}

export const MonitoringOverviewInfoItemError: React.FC<MonitoringOverviewInfoItemErrorProps> = ({ t }) => {
    return <QueueInfoItem title={t(`${tNamespace}active-tasks`)} icon={faClipboardCheck} variant="error" />
}

export default MonitoringOverviewInfoItem
