import React, { ChangeEvent, useCallback, useState } from "react"
import styles from "./SearchInput.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSearch, faTimes } from "@fortawesome/pro-light-svg-icons"
import { FormControl } from "react-bootstrap"
import { formTranslation } from "../../locales/form"
import { useTranslation } from "react-i18next"
import { ClassProps } from "../../utility/common/props"
import cn from "classnames"
import { testId } from "../../utility/tests/testId"
import debounce from "lodash/debounce"
import Spinner from "../Spinner/Spinner"

export interface SearchInputProps extends ClassProps {
    onChange: (searchValue: string) => void
    disabled?: boolean
    label?: string
    isLoading?: boolean
    placeholder?: string
    onFocus?: () => void
    onBlur?: () => void
    delay?: number
}

const SearchInput: React.FC<SearchInputProps> = props => {
    const { t } = useTranslation()
    const { onChange, className, disabled, label, isLoading = false, placeholder, onFocus, onBlur, delay = 300 } = props
    const [searchValue, setSearchValue] = useState("")

    const handleChangeCallback = useCallback(
        debounce((searchValue: string) => {
            onChange(searchValue)
        }, delay),
        []
    )

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value)
        handleChangeCallback(e.target.value)
    }

    const handleClear = () => {
        setSearchValue("")
        handleChangeCallback("")
    }

    return (
        <div className={cn(styles.wrap, className)}>
            <div className={cn(styles.left)}>
                <FontAwesomeIcon icon={faSearch} className={cn(styles.left__icon)} />
                {label && <span>{label}</span>}
            </div>
            <FormControl
                onChange={handleChange}
                value={searchValue}
                className={cn(styles.input, label && styles.input_withLabel)}
                placeholder={placeholder || t(formTranslation.search)}
                disabled={disabled}
                data-testid={testId.searchInput}
                onFocus={onFocus}
                onBlur={onBlur}
            />
            <div
                className={cn(styles.right, isLoading || styles.right_clear)}
                onClick={isLoading ? undefined : handleClear}
            >
                {isLoading ? (
                    <Spinner className={styles.right__loading} />
                ) : (
                    <FontAwesomeIcon icon={faTimes} className={styles.right__icon} />
                )}
            </div>
        </div>
    )
}

export default SearchInput
