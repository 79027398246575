import React, { useCallback, useEffect, useMemo, useState } from "react"
import UserCard from "../UserCard/UserCard"
import SettingsTableWithPagination from "../SettingsTable/SettingsTableWithPagination/SettingsTableWithPagination"
import { CellProps, Column } from "react-table"
import { Operator } from "../../models/operator"
import styles from "./QueueOperatorsTable.module.scss"
import OperatorStatusContainer from "../OperatorStatusContainer/OperatorStatusContainer"
import { useTranslation } from "react-i18next"
import UpdatedTime from "../UpdatedTime/UpdatedTime"
import { beautifyMsToHMSString } from "../../utility/common/time"
import { tableTranslation } from "../../locales/table"
import { useAppDispatch, useAppSelector } from "../../store/hooks"
import { actions } from "../../store/queues/slice"
import SettingsContextMenuItem from "../SettingsContextMenuItem/SettingsContextMenuItem"
import SettingsButton from "../SettingsButton/SettingsButton"
import SettingsContextMenu from "../SettingsContextMenu/SettingsContextMenu"
import { selectCurrentProjectId } from "../../store/projects/selectors"
import SearchInput from "../SearchInput/SearchInput"
import { OperatorPaginationResponse } from "../../models/operatorDto"
import { QUEUES_TASKS_PER_PAGE } from "../../models/pagination"
import { operatorStatusColors } from "../../helpers/color"
import { getOperatorsByQueue } from "../../store/operators/thunks"
import { parseQueueIdFromPath } from "../../api/utility/queuesV2.helpers"
import { testId } from "../../utility/tests/testId"
import useOperatorActions from "../../hooks/useOperatorTableActions"

type QueueOperatorsTableProps = {
    operatorsPagination?: OperatorPaginationResponse<Operator>
    queueId: string
}

const tNamespace = "queues:card."

const QueueOperatorsTable = (props: QueueOperatorsTableProps) => {
    const { operatorsPagination, queueId } = props
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const projectId = useAppSelector(selectCurrentProjectId) ?? ""
    const newQueueId = parseQueueIdFromPath()

    const [currentPageIndex, setCurrentPageIndex] = useState(0)
    const [searchValue, setSearchValue] = useState("")
    const handlePageChange = useCallback((pageIndex: number, searchValue = "") => {
        setCurrentPageIndex(pageIndex)
        setSearchValue(searchValue)
    }, [])

    const updateTable = useCallback(
        (projectId: string, queueId: string) => {
            const pagination = {
                PageFrom: currentPageIndex,
                PageSize: QUEUES_TASKS_PER_PAGE,
                ...(searchValue && { Search: searchValue })
            }

            dispatch(getOperatorsByQueue(projectId, newQueueId, pagination))
        },
        [currentPageIndex, searchValue, newQueueId]
    )

    const { handleDeleteFromQueue, handleMakeOffline } = useOperatorActions(projectId, queueId, updateTable)

    useEffect(() => {
        updateTable(projectId, newQueueId)
    }, [searchValue, currentPageIndex, newQueueId])

    const getRowItems = (operator: Operator) => {
        return [
            <SettingsContextMenuItem
                key="make-offline"
                id="make-offline"
                text={t(`${tNamespace}make-offline`)}
                onClick={() => handleMakeOffline(operator)}
            />,
            <SettingsContextMenuItem
                key="delete-from-queue"
                id="delete-from-queue"
                text={t(`${tNamespace}delete-from-queue`)}
                onClick={() => handleDeleteFromQueue(operator)}
            />
        ]
    }

    const columns: Array<Column<Operator>> = useMemo(
        () => [
            {
                Header: t(tableTranslation.fullOperatorName),
                id: "Operator",
                width: "200px",
                accessor: original => original.FirstName + " " + original.LastName,
                Cell: ({ row: { original } }: CellProps<Operator>) => (
                    <UserCard firstName={original.FirstName} lastName={original.LastName} inHeader />
                )
            },
            {
                Header: t(tableTranslation.status),
                id: "Status",
                width: "120px",
                accessor: original => original.Status.Description,
                Cell: ({ row: { original } }: CellProps<Operator>) => {
                    const statusColor = operatorStatusColors[original.Status.Description]

                    return (
                        <div className={styles.queueOperatorsTable__statusContainer}>
                            <OperatorStatusContainer
                                status={original.Status}
                                t={t}
                                className={styles.queueOperatorsTable__status}
                                currentColor={statusColor}
                            />
                        </div>
                    )
                }
            },
            {
                Header: t(tableTranslation.durationInStatus),
                id: "TimeInStatus",
                accessor: original => original.Status.UpdatedAt,
                Cell: ({ row: { original } }: CellProps<Operator>) => (
                    <div className={styles.queueOperatorsTable__duration}>
                        <UpdatedTime title="" timestamp={original.Status.UpdatedAt} format={beautifyMsToHMSString} />
                    </div>
                )
            },
            {
                Header: t(tableTranslation.activeTasks),
                id: "TasksCount",
                width: "120px",
                accessor: original => original.Tasks.length
            },
            {
                Header: t(tableTranslation.freeSlots),
                id: "FreeSlots",
                width: "120px",
                accessor: original => {
                    const diff = original.MaxCapacity - original.Tasks.length
                    return Math.max(diff, 0)
                }
            },
            {
                id: "Settings",
                disableSortBy: true,
                width: "50px",
                Cell: (props: CellProps<Operator>) => (
                    <SettingsContextMenu
                        className={styles.queueOperatorsTable__settings}
                        items={getRowItems(props.row.original)}
                    >
                        <SettingsButton className={styles.queueOperatorsTable__rowButton} />
                    </SettingsContextMenu>
                )
            }
        ],
        [getRowItems, t]
    )

    const handleOpenOperatorSidebar = (operator: Operator) => {
        dispatch(actions.closeQueuePendingTasksSidebar())
        dispatch(actions.closeQueueTasksSidebar())
        dispatch(actions.closeQueuesTaskSidebar())

        dispatch(actions.selectQueueOperator(operator.Id))
        dispatch(actions.openQueuesOperatorSidebar(operator))
    }

    return (
        <div className={styles.queueOperatorsTable}>
            <SearchInput
                onChange={searchValue => handlePageChange(0, searchValue)}
                className={styles.queueOperatorsTable__searchInput}
                delay={500}
                data-testid={testId.operatorsTableSearch}
            />
            {operatorsPagination?.Operators.length ? (
                <SettingsTableWithPagination
                    onRowClick={handleOpenOperatorSidebar}
                    columns={columns}
                    data={operatorsPagination.Operators}
                    onPageIndexChange={handlePageChange}
                    pageIndex={currentPageIndex}
                    pageSize={10}
                    recordsTotal={operatorsPagination.ItemsTotal}
                    hideSearch
                />
            ) : (
                <div className={styles.queueOperatorsTable_empty}>{t(`${tNamespace}no-active-employees`)}</div>
            )}
        </div>
    )
}

export default QueueOperatorsTable
