import React, { useEffect, useMemo } from "react"
import { FormikQueueFormProps } from "../FormikQueueForm/FormikQueueForm"
import { preventSubmitOnEnter } from "../../utility/common/preventSubmitOnEnter"
import { Form } from "react-bootstrap"
import { nameof } from "../../utility/common/nameof"
import { ExtraSettings, ExtraSettingsValue, QueueValues } from "../../models/queueValues"
import { FieldArray } from "formik"
import LoadingButton from "../LoadingButton/LoadingButton"
import { formTranslation } from "../../locales/form"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { selectCreateQueueState, selectUpdateQueueState } from "../../store/queues/selectors"
import { TFunction } from "i18next"
import { testId } from "../../utility/tests/testId"
import QueueExtraSetting from "./controls/QueueExtraSetting/QueueExtraSetting"
import { getSurveys } from "../../store/surveys/thunks"
import { getChannels, getChannelsDeclarations } from "../../store/channels/thunks"
import { selectChannelsDeclarations } from "../../store/channels/selectors"
import { getSettings } from "../../utility/common/getSettings"
import { AllowCatalogsFormValues } from "../../models/catalog"
import { EXTRA_SETTINGS_DEFAULT_VALUES, extraSettingsDefaultOptions } from "../../utility/queues/queueFormExtra"
import usePermissionsCheck from "../../utility/common/usePermissionsCheck"
import { ModifyQueuesForDialogTransfer } from "../../permissions"

const tNamespace = "queues:settings."

const getTitle = (setting: ExtraSettings, t: TFunction) => {
    switch (setting) {
        case ExtraSettings.DialogTimer:
            return t(`${tNamespace}dialog-timer`)
        case ExtraSettings.DialogFinish:
            return t(`${tNamespace}finish-dialog`)
        case ExtraSettings.AutoGreeting:
            return t(`${tNamespace}autogreeting`)
        case ExtraSettings.ClientSurvey:
            return t(`${tNamespace}client-survey`)
        case ExtraSettings.AdditionalResendConfig:
            return t(`${tNamespace}email-resend-config`)
        case ExtraSettings.EmailChannels:
            return t(`${tNamespace}email-channels`)
        case ExtraSettings.FastReplyTemplates:
            return t(`${tNamespace}fast-reply-templates`)
        case ExtraSettings.AutoHolding:
            return t(`${tNamespace}autoholding`)
        case ExtraSettings.AllowCatalogs:
            return t(`${tNamespace}allow-catalogs`)
        case ExtraSettings.DialogGroups:
            return t(`${tNamespace}dialog-groups`)
        case ExtraSettings.QueueTransferRestriction:
            return t(`${tNamespace}queue-transfer-restriction`)
    }
}

interface WithCatalogs {
    catalogs: AllowCatalogsFormValues[]
}

const QueueFormExtra: React.FC<FormikQueueFormProps & WithCatalogs> = props => {
    const { t, queue, values, handleSubmit, catalogs } = props
    const asyncState = useSelector(queue ? selectUpdateQueueState : selectCreateQueueState, shallowEqual)
    const dispatch = useDispatch()
    const channelDeclarations = useSelector(selectChannelsDeclarations)
    const isModifyTransferQueuesEnabled = usePermissionsCheck([ModifyQueuesForDialogTransfer])

    const extraSettingsOptions = extraSettingsDefaultOptions
        .map(setting => ({
            label: getTitle(setting, t),
            value: setting
        }))
        // Суть такая, что, если у нас уже есть настройка DialogTimeoutSettings для этой очереди, которая пришла с бэкэнда
        // То мы берем ее, если нет, то мы можем добавить эту настройку
        // В будущем тут будут другие дополнительные настройки со своей логикой добавления / не добавления
        .filter(setting => {
            if (setting.value === ExtraSettings.QueueTransferRestriction) {
                if (!isModifyTransferQueuesEnabled) {
                    return false
                }
            }
            return !values.extraSettings.some(value => value.type === setting.value)
        })

    const extraSettingsDefaultValues: ExtraSettingsValue[] = useMemo(
        () => [
            ...EXTRA_SETTINGS_DEFAULT_VALUES,
            {
                type: ExtraSettings.AllowCatalogs,
                value: {
                    Allowed: getSettings(
                        catalogs.map(catalog => catalog.id),
                        []
                    )
                }
            }
        ],
        [catalogs]
    )

    const handleAddExtraSetting = (setting: ExtraSettings, push: (setting: ExtraSettingsValue) => void) => {
        const extraSetting = extraSettingsDefaultValues.find(value => value.type === setting)
        if (extraSetting) {
            push(extraSetting)
        } else {
            throw new Error(`Can't add extra setting ${setting}`)
        }
    }

    useEffect(() => {
        if (!channelDeclarations) {
            dispatch(getChannelsDeclarations())
        }
    }, [channelDeclarations, dispatch])

    useEffect(() => {
        if (queue?.TenantId) {
            dispatch(getSurveys(queue.TenantId))
            channelDeclarations && dispatch(getChannels(queue?.TenantId, channelDeclarations))
        }
    }, [channelDeclarations, dispatch, queue])

    return (
        <Form className="queue-form" onSubmit={handleSubmit} onKeyPress={preventSubmitOnEnter}>
            <div className="queue-form__content">
                <div className="queue-form__section">
                    <FieldArray
                        name={nameof<QueueValues>("extraSettings")}
                        render={({ remove, push }) => (
                            <>
                                <Form.Group controlId="formExtraSettings">
                                    <Form.Control
                                        as="select"
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            handleAddExtraSetting(e.target.value as ExtraSettings, push)
                                        }}
                                        value=""
                                        disabled={!extraSettingsOptions.length}
                                    >
                                        <option value="" hidden>
                                            {t(`${tNamespace}select-extraSetting`)}
                                        </option>
                                        {extraSettingsOptions.map(option => (
                                            <option value={option.value} key={option.value}>
                                                {option.label}
                                            </option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>
                                {values.extraSettings.map((extraSetting, index) => (
                                    <QueueExtraSetting
                                        key={`queue-extra-setting-${extraSetting.type}-${index}`}
                                        setting={extraSetting}
                                        index={index}
                                        t={t}
                                        onDelete={() => remove(index)}
                                    />
                                ))}
                            </>
                        )}
                    />
                </div>
            </div>
            <div className="queue-form__footer">
                <LoadingButton
                    type="submit"
                    loading={asyncState.inProcess}
                    variant="primary"
                    testId={testId.saveQueue}
                    block
                >
                    {t(formTranslation.save)}
                </LoadingButton>
            </div>
        </Form>
    )
}

export default QueueFormExtra
