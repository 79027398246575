import { AnyAction } from "redux"
import { History } from "history"
import { combineReducers } from "@reduxjs/toolkit"
import { CombinedState } from "@reduxjs/toolkit/src/query/core/apiState"

// reducers
import { connectRouter, RouterState } from "connected-react-router"
import { ProjectState, ProjectReducer } from "./projects/slice"
import { MenuState, MenuReducer } from "./menu/slice"
import { PortalSystemState, PortalSystemReducer } from "./portalSystem/slice"
import { AuthState, AuthReducer } from "./auth/slice"
import { QueuesState, QueuesReducer } from "./queues/slice"
import { OperatorsState, OperatorsReducer } from "./operators/slice"
import { ConfigState, ConfigReducer } from "./config/slice"
import { SignUpState, SignUpReducer } from "./signUp/slice"
import { RolesState, RolesReducer } from "./roles/slice"
import { NotificationState, NotificationReducer } from "./notifications/slice"
import { UsersState, UsersReducer } from "./users/slice"
import { UserOperatorState, UserOperatorReducer } from "./userOperator/slice"
import { ChannelsState, ChannelsReducer } from "./channels/slice"
import { AgentsState, AgentsReducer } from "./agents/slice"
import { KnowledgeBaseState, KnowledgeBaseReducer } from "./knowledgeBase/slice"
import { ClassifiersState, ClassifiersReducer } from "./classifiers/slice"
import { KnowledgeBaseCommentsState, KnowledgeBaseCommentsReducer } from "./knowledgeBaseComments/slice"
import { PasswordState, PasswordReducer } from "./password/slice"
import { KnowledgeBaseSubscriptionsState, KnowledgeBaseSubscriptionsReducer } from "./knowledgeBaseSubscriptions/slice"
import { TasksState, TasksReducer } from "./tasks/slice"
import { SlotsState, SlotReducer } from "./slots/slice"
import { StickersState, StickerReducer } from "./stickers/slice"
import { SurveysState, SurveysReducer } from "./surveys/slice"
import { DialogsSlice, DialogsReducer } from "./dialogs/slice"
import { AppState, AppReducer } from "./app/slice"
import { queryApi } from "../api/api"
import { MassMailingReducer } from "./massMailing/slice"

export interface RootState {
    app: AppState
    router: RouterState
    project: ProjectState
    menu: MenuState
    portalSystem: PortalSystemState
    auth: AuthState
    queues: QueuesState
    operators: OperatorsState
    config: ConfigState
    signUp: SignUpState
    roles: RolesState
    userOperator: UserOperatorState
    users: UsersState
    notifications: NotificationState
    channels: ChannelsState
    agents: AgentsState
    knowledgeBase: KnowledgeBaseState
    knowledgeBaseComments: KnowledgeBaseCommentsState
    knowledgeBaseSubscriptions: KnowledgeBaseSubscriptionsState
    classifiers: ClassifiersState
    password: PasswordState
    tasks: TasksState
    slots: SlotsState
    stickers: StickersState
    surveys: SurveysState
    dialogs: DialogsSlice
    queryApi: CombinedState<{}, string, "queryApi">
    massMailing: ReturnType<typeof MassMailingReducer>
}

export const createRootReducer = (history: History) =>
    combineReducers<RootState, AnyAction>({
        router: connectRouter(history),
        app: AppReducer,
        project: ProjectReducer,
        menu: MenuReducer,
        portalSystem: PortalSystemReducer,
        auth: AuthReducer,
        queues: QueuesReducer,
        operators: OperatorsReducer,
        config: ConfigReducer,
        signUp: SignUpReducer,
        roles: RolesReducer,
        users: UsersReducer,
        userOperator: UserOperatorReducer,
        notifications: NotificationReducer,
        channels: ChannelsReducer,
        agents: AgentsReducer,
        knowledgeBase: KnowledgeBaseReducer,
        knowledgeBaseComments: KnowledgeBaseCommentsReducer,
        knowledgeBaseSubscriptions: KnowledgeBaseSubscriptionsReducer,
        classifiers: ClassifiersReducer,
        password: PasswordReducer,
        tasks: TasksReducer,
        slots: SlotReducer,
        stickers: StickerReducer,
        surveys: SurveysReducer,
        dialogs: DialogsReducer,
        queryApi: queryApi.reducer,
        massMailing: MassMailingReducer
    })

export default createRootReducer
