const TRANSLATION_NAMESPACE = "error:projects."

export const FETCH_PROJECTS_FAILED_MESSAGE = `${TRANSLATION_NAMESPACE}fetch-projects-failed`
export const FETCH_DEFAULT_SETTINGS_FAILED_MESSAGE = `${TRANSLATION_NAMESPACE}fetch-projects-failed`
export const SELECT_PROJECT_FAILED_MESSAGE = `${TRANSLATION_NAMESPACE}select-project-failed`
export const CREATE_PROJECT_FAILED_MESSAGE = `${TRANSLATION_NAMESPACE}create-project-failed`
export const INVITE_USERS_FAILED_MESSAGE = `${TRANSLATION_NAMESPACE}invite-users-failed`
export const UPDATE_PROJECT_FAILED_MESSAGE = `${TRANSLATION_NAMESPACE}update-project-failed`
export const UPDATE_LOGO_FAILED_MESSAGE = `${TRANSLATION_NAMESPACE}update-logo-failed`
export const BLOCK_USER_FAILED_MESSAGE = `${TRANSLATION_NAMESPACE}block-user-failed`
export const UPLOAD_PROJECT_FAILED_MESSAGE = `${TRANSLATION_NAMESPACE}upload-project-failed`
export const CREATE_PROJECT_COPY_FAILED_MESSAGE = `${TRANSLATION_NAMESPACE}create-project-copy-failed`
export const FETCH_REFERENCES_FAILED_MESSAGE = `${TRANSLATION_NAMESPACE}fetch-references-failed`
export const FETCH_PROJECT_SETTINGS_FAILED_MESSAGE = `${TRANSLATION_NAMESPACE}fetch-project-settings-failed`
export const UPDATE_PROJECT_SETTINGS_FAILED_MESSAGE = `${TRANSLATION_NAMESPACE}update-project-settings-failed`
export const SUBSCRIBE_ON_PROJECT_SETTINGS_UPDATE_FAILED = `${TRANSLATION_NAMESPACE}subscribe-on-project-settings-update-failed`
export const UNSUBSCRIBE_ON_PROJECT_SETTINGS_UPDATE_FAILED = `${TRANSLATION_NAMESPACE}unsubscribe-on-project-settings-update-failed`
export const FETCH_BANDWIDTH_GROUPS_FAILED_MESSAGE = `${TRANSLATION_NAMESPACE}fetch-bandwidth-groups-failed`
export const FETCH_USER_INVITE_SETTINGS_FAILED_MESSAGE = `${TRANSLATION_NAMESPACE}fetch-user-invite-settings-failed`
