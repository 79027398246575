import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { resetReducerState } from "../action"

export type AppState = Readonly<{
    loadingProcesses: Record<string, true>
}>

const initialState: AppState = {
    loadingProcesses: Object.create(null)
}

const app = createSlice({
    name: "app",
    initialState,
    reducers: {
        addProcess(state, action: PayloadAction<string>) {
            state.loadingProcesses[action.payload] = true
        },
        removeProcess(state, action: PayloadAction<string>) {
            delete state.loadingProcesses[action.payload]
        }
    },
    extraReducers(builder) {
        builder.addCase(resetReducerState, () => {
            return initialState
        })
    }
})

export const AppReducer = app.reducer

export const actions = app.actions
