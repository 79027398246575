import { JSAgentDeclaration, JSAgentSettingsTypes } from "../../../../../models/agent"
import DebouncedValidatableInput from "../../../../ValidatableInput/DebouncedValidatableInput"
import ValidatableInput from "../../../../ValidatableInput/ValidatableInput"
import React from "react"
import DebouncedCheckBox from "../../../../CheckBoxValidatableInput/DebouncedCheckBox"
import InfoIcon from "../../../../InfoIcon/InfoIcon"
import FormSection from "../../../../FormHelpers/Section/FormSection"
import FormControlWithAdd from "../../../../FormHelpers/ControlWithAdd/FormControlWithAdd"
import CodeBlockValidatableInput from "../../../../CodeBlockValidatableInput/CodeBlockValidatableInput"
import { useTranslation } from "react-i18next"

const tNamespace = "agent"

interface JSAgentSettingBuilderProps {
    declaration: JSAgentDeclaration
    index: number
}

export const JSAgentSettingBuilder = (props: JSAgentSettingBuilderProps) => {
    const { declaration, index } = props

    const { t } = useTranslation(tNamespace)

    switch (declaration.type) {
        case JSAgentSettingsTypes.Boolean:
            return <DebouncedCheckBox id={"formJSAgentSettings"} name={`Declaration[${index}].value`} />
        case JSAgentSettingsTypes.Enum:
            return (
                <DebouncedValidatableInput id="formJSAgentSettings" as="select" name={`Declaration[${index}].value`}>
                    {declaration.options?.map(option => (
                        <option value={option.id} key={option.id}>
                            {option.title}
                        </option>
                    ))}
                </DebouncedValidatableInput>
            )
        case JSAgentSettingsTypes.NumberArray:
        case JSAgentSettingsTypes.StringArray:
            return (
                <FormControlWithAdd
                    name={`Declaration[${index}].value`}
                    label={declaration.name}
                    icon={
                        declaration.description ? (
                            <InfoIcon id="formJSAgentSettingsDescription" content={declaration.description} />
                        ) : undefined
                    }
                    onCreateEmptyValue={() => ""}
                    small
                >
                    {({ name, remove }) =>
                        (declaration.value as string[])?.map((_, index: number) => (
                            <FormSection key={index}>
                                <ValidatableInput
                                    id="formCustomSectionArray"
                                    type={declaration.type === JSAgentSettingsTypes.NumberArray ? "number" : "text"}
                                    label={t(`form.jsagent.array-element`) + ` ${index + 1}`}
                                    name={`${name}[${index}]`}
                                    onDelete={() => remove(index)}
                                />
                            </FormSection>
                        ))
                    }
                </FormControlWithAdd>
            )
        case JSAgentSettingsTypes.Object:
            return (
                <CodeBlockValidatableInput
                    id={"formJSAgentSettingsObject"}
                    label={""}
                    name={`Declaration[${index}].value`}
                    language={"json"}
                />
            )
        case JSAgentSettingsTypes.Number:
        case JSAgentSettingsTypes.String:
        default:
            return (
                <ValidatableInput
                    id="formJSAgentSettings"
                    type={declaration.type === JSAgentSettingsTypes.Number ? declaration.type : "text"}
                    name={`Declaration[${index}].value`}
                />
            )
    }
}
