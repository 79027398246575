import { OperatorQueuesUpdatedMsg } from "../../models/operator"
import { Dispatch } from "../common/storeHelper"
import { actions } from "../../store/operators/slice"

export const updateOperatorsQueues = (
    tenantId: string | undefined,
    data: OperatorQueuesUpdatedMsg,
    dispatch: Dispatch
) => {
    if (!tenantId) return

    if (data.AddedTo.length) {
        data.AddedTo.map(d => {
            dispatch(
                actions.addOperatorToQueue({
                    OperatorId: data.OperatorId,
                    TenantId: tenantId,
                    QueueId: d.QueueId
                })
            )
        })
    }

    if (data.RemovedFrom.length) {
        data.RemovedFrom.map(d => {
            dispatch(
                actions.deleteFromQueueSuccess({
                    OperatorId: data.OperatorId,
                    TenantId: tenantId,
                    QueueId: d.QueueId
                })
            )
        })
    }
}
