import * as Yup from "yup"
import { ArticlePublishInfo, KnowledgeBaseArticlePublishType } from "../../models/knowledgeBase/knowledgeBasePublish"

const tNamespace = "knowledgeBase:publish."

const publishOrUnpublishTest = (field: string) =>
    Yup.number().test({
        name: "oneOfPublishOrUnpublish",
        message: `${tNamespace}empty-dates`,
        test: function (value) {
            const otherField = field === "PublishDate" ? "UnpublishDate" : "PublishDate"
            const otherValue = this.parent[otherField]

            return (value && !otherValue) || (!value && otherValue) || (value && otherValue)
        }
    })

const validateUnpublishDate = Yup.number().test({
    name: "unpublishDateLessThanPublishDate",
    message: `${tNamespace}wrong-unpublish-date`,
    test: function (value) {
        const publishDate = this.parent.PublishDate

        return !value || value >= publishDate
    }
})

export const getArticlePublishFormValidationSchema = () =>
    Yup.object<ArticlePublishInfo>().shape({
        PublishDate: Yup.number().when("Type", {
            is: KnowledgeBaseArticlePublishType.Timeout,
            then: publishOrUnpublishTest("PublishDate"),
            otherwise: Yup.number().nullable()
        }),
        UnpublishDate: Yup.number().when("Type", {
            is: KnowledgeBaseArticlePublishType.Timeout,
            then: publishOrUnpublishTest("UnpublishDate").concat(validateUnpublishDate),
            otherwise: Yup.number().nullable()
        })
    })
