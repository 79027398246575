import React, { useMemo } from "react"
import { Queue } from "../../models/queue"
import { useTranslation } from "react-i18next"
import styles from "./QueueCard.module.scss"
import TaskBoard from "../TaskBoard/TaskBoard"
import { Operator } from "../../models/operator"
import { Task } from "../../models/task"
import { faSlidersH } from "@fortawesome/pro-light-svg-icons"
import cn from "classnames"
import { ClassProps } from "../../utility/common/props"
import Can from "../Can/Can"
import { ModifyQueues, ViewQueuesV2 } from "../../permissions"
import { ChannelNames } from "../../models/channel"
import IconButton from "../IconButton/IconButton"
import QueueCardBase from "../QueueCardBase/QueueCardBase"
import { testId } from "../../utility/tests/testId"
import { useOpenedState } from "../../utility/common/useOpenedState"
import { useLocation } from "react-router-dom"
import QueueOperatorsTable from "../QueueOperatorsTable/QueueOperatorsTable"
import { OperatorPaginationResponse } from "../../models/operatorDto"
import QueueInfoItems from "../Queues/QueueInfoItems/QueueInfoItems"
import { compareOperatorFunction } from "../Queues/QueueInfoItems/helpers"
import QueueOperatorsView from "../Queues/QueueOperatorsView/QueueOperatorsView"

const tNamespace = "queues:card."

export interface QueueCardProps extends ClassProps {
    queue: Queue
    channelNames?: ChannelNames
    queues: Queue[]
    onPendingTaskClick?: (task: Task, queue: Queue) => void
    onAssignedTaskClick?: (task: Task, operator: Operator) => void
    onOperatorClick: (operator: Operator) => void
    onGetOperatorsInQueue: (queue: Queue) => Operator[]
    selectedTask: string | null
    selectedOperator: string | null
    onSettingsClick: (queue: Queue) => void
    foundedTasks?: Set<string>
    operatorsPagination?: OperatorPaginationResponse<Operator>
}

const QueueCard: React.FC<QueueCardProps> = props => {
    const {
        queue,
        channelNames,
        queues,
        className,
        onPendingTaskClick,
        onAssignedTaskClick,
        onOperatorClick,
        onSettingsClick,
        selectedTask,
        selectedOperator,
        onGetOperatorsInQueue,
        foundedTasks,
        operatorsPagination
    } = props
    const { t } = useTranslation()
    const [opened, toggleOpen] = useOpenedState(false)
    const location = useLocation()

    const isQueuesV2 = location.pathname.includes("queuesV2")

    const filteredOperators = useMemo(() => {
        const operators = onGetOperatorsInQueue(queue)
        operators.sort(compareOperatorFunction)
        if (!foundedTasks) {
            return operators
        }
        return operators.reduce<Operator[]>((acc, o) => {
            const operatorTasks = o.Tasks.filter(t => foundedTasks.has(t.Id))
            if (operatorTasks.length > 0) {
                acc.push({
                    ...o,
                    Tasks: operatorTasks
                })
            }
            return acc
        }, [])
    }, [foundedTasks, onGetOperatorsInQueue, queue])

    const processedQueue = useMemo(() => {
        if (!foundedTasks) {
            return queue
        }
        return {
            ...queue,
            PendingTasks: queue.PendingTasks.filter(t => foundedTasks.has(t.Id)),
            PendingIndividualTasks: queue.PendingIndividualTasks.filter(t => foundedTasks.has(t.Id))
        }
    }, [foundedTasks, queue])

    const handleSettingsClick = () => onSettingsClick(queue)
    const handlePendingTaskClick = (task: Task) => {
        onPendingTaskClick && onPendingTaskClick(task, queue)
    }

    const queueTaskBoards = [
        { title: `${tNamespace}queued-tasks`, tasks: processedQueue.PendingTasks },
        { title: `${tNamespace}queued-individual-tasks`, tasks: processedQueue.PendingIndividualTasks }
    ]

    return (
        <QueueCardBase
            title={queue.Name}
            className={cn(styles.queueCard, className)}
            onToggle={toggleOpen}
            isOpened={opened}
            isNewQueue={isQueuesV2}
            buttons={
                <Can permission={ModifyQueues}>
                    <IconButton
                        as="div"
                        className={styles.queueCard__button}
                        icon={faSlidersH}
                        variant="link"
                        onClick={handleSettingsClick}
                        testId={testId.editQueue}
                    />
                </Can>
            }
            items={<QueueInfoItems queue={queue} queues={queues} filteredOperators={filteredOperators} />}
        >
            <div>
                <div className={styles.queueCard__section}>
                    {!isQueuesV2 && <h6 className={styles.queueCard__sectionHeader}>{t(`${tNamespace}employees`)}</h6>}
                    <div
                        className={cn(
                            styles.queueCard__sectionBody,
                            styles.queueCard__operatorsSectionBody,
                            !filteredOperators.length && styles.queueCard__sectionBody_noActiveEmployees
                        )}
                    >
                        {isQueuesV2 && operatorsPagination && (
                            <Can permission={ViewQueuesV2}>
                                <QueueOperatorsTable operatorsPagination={operatorsPagination} queueId={queue.Id} />
                            </Can>
                        )}
                        {!isQueuesV2 && (
                            <QueueOperatorsView
                                queues={queues}
                                queue={queue}
                                channelNames={channelNames}
                                selectedTask={selectedTask}
                                selectedOperator={selectedOperator}
                                onTaskClick={onAssignedTaskClick && onAssignedTaskClick}
                                onOperatorClick={onOperatorClick}
                                filteredOperators={filteredOperators}
                            />
                        )}
                    </div>
                </div>
                {!isQueuesV2 &&
                    queueTaskBoards.map(({ title, tasks }) => (
                        <div className={styles.queueCard__section} key={title}>
                            <h6 className={styles.queueCard__sectionHeader}>{t(title)}</h6>
                            <div className={styles.queueCard__sectionBody}>
                                <TaskBoard
                                    tasks={tasks}
                                    channelNames={channelNames}
                                    onClick={handlePendingTaskClick}
                                    selectedTask={selectedTask}
                                />
                            </div>
                        </div>
                    ))}
            </div>
        </QueueCardBase>
    )
}

export default QueueCard
