import React from "react"
import styles from "./QueuesListLoader.module.scss"
import useDelay from "../../utility/common/useDelay"
import QueueCardLoader from "../QueueCard/QueueCardLoader"
import Skeleton from "../Skeleton/Skeleton"
import { duplicateComponent } from "../../utility/common/duplicateComponent"

interface Props {
    count: number
}

const QueuesListLoader: React.FC<Props> = props => {
    const { count } = props

    const display = useDelay()

    if (!display) {
        return null
    }

    return (
        <div className={styles.queuesListLoader}>
            <div className={styles.queuesListLoader__search}>
                <Skeleton />
            </div>
            <div>
                {duplicateComponent(count)(() => (
                    <div className={styles.queuesListLoader__item}>
                        <QueueCardLoader count={4} />
                    </div>
                ))}
            </div>
        </div>
    )
}

export default QueuesListLoader
