import React from "react"
import styles from "./OperatorStatusContainer.module.scss"
import selectorStyles from "../OperatorStatusSelector/OperatorStatusSelector.module.scss"
import cn from "classnames"
import { SelectedOperatorStatus } from "../../models/operatorStatus"
import { handleLocalizedTextTranslation } from "../../utility/common/handleLocalizedTextTranslation"
import { TFunction } from "i18next"
import { ClassProps } from "../../utility/common/props"
import { testId } from "../../utility/tests/testId"

export interface OperatorStatusContainerProps extends ClassProps {
    status: SelectedOperatorStatus
    t: TFunction
    sideElement?: JSX.Element
    testId?: string
    inHeader?: boolean
    currentColor?: string
}

const OperatorStatusContainer: React.FC<OperatorStatusContainerProps> = props => {
    const { status, sideElement, t, className, inHeader, currentColor } = props
    return (
        <>
            {inHeader ? (
                <>
                    <div
                        className={selectorStyles.operatorStatusSelector__circle}
                        style={{ backgroundColor: currentColor }}
                    />
                    <div
                        className={cn(styles.operatorStatus__title, styles.operatorStatus__title_lg)}
                        data-testid={testId.currentStatus}
                    >
                        {handleLocalizedTextTranslation(status.Title, t)}
                    </div>
                    {sideElement && sideElement}
                </>
            ) : (
                <div className={cn(styles.operatorStatus, className)} style={{ backgroundColor: currentColor }}>
                    <div className={styles.operatorStatus__title} data-testid={testId.currentStatus}>
                        {handleLocalizedTextTranslation(status.Title, t)}
                    </div>
                    {sideElement && <div className={styles.operatorStatus__sideElement}>{sideElement}</div>}
                </div>
            )}
        </>
    )
}

export default OperatorStatusContainer
