import { ListGroup } from "react-bootstrap"
import { ArticleView, SearchArticlesHighlights, SearchArticlesScores } from "../../models/article"
import { SearchResultsHeader } from "../SearchResultsHeader"
import { SearchQueryArticlesItem } from "./components/Item/Item"
import styles from "./SearchQueryArticles.module.scss"

interface SearchQueryArticlesProps {
    articles: ArticleView[]
    highlights?: SearchArticlesHighlights
    scores?: SearchArticlesScores
    onArticleClick: (articleCode: string) => void
}

export const SearchQueryArticles = (props: SearchQueryArticlesProps) => {
    const { articles, highlights, onArticleClick } = props

    return (
        <div>
            <SearchResultsHeader itemCount={articles ? articles.length : 0} />

            {articles && articles.length > 0 && (
                <div className={styles.articles}>
                    <ListGroup variant="flush">
                        {articles.map(article => (
                            <ListGroup.Item key={article.Id}>
                                <SearchQueryArticlesItem
                                    article={article}
                                    highlights={highlights && highlights[article.Id]}
                                    onArticleClick={onArticleClick}
                                />
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                </div>
            )}
        </div>
    )
}
