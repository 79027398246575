import { ParamValues } from "./parameterDeclaration"
import { WebChatChannelValues } from "./webChatValues"

export type ChannelValues = ChannelValuesBase | WebChatChannelValues | WhatsAppChannelValues

export interface ChannelValuesBase {
    Title: string
    BotSettings: BotSettingsValues
    WebHook?: string
    PreprocessorWebHook?: string
    Params: ParamValues
    ServerAddress?: string
    BandwidthSettingsValues?: BandwidthSettingsValues
}

export interface BotSettingsValues {
    Id: string
    BotId?: string
    Token: string
}

export type BandwidthSettingsValues = {
    IncludeChannelInGroup: boolean
    Group?: string
}

export interface WhatsAppChannelValues extends ChannelValuesBase {
    ProviderSettings: ProviderSettingsValues
}

export interface ProviderSettingsValues {
    provider: string
    login: string
    apipassword: string
    sourceAddress: string
    scenario_key: string
    host_url: string
    authType: WhatsappAuthType
    username: string
    password: string
    api_key: string
    subject: string
    apikey: string
}

export enum WhatsappAuthType {
    ApiKey = "ApiKey",
    LoginPassword = "LoginPassword"
}
