import React from "react"
import type { NotificationContentFunction } from "../../../utility/notifications/notificationContentType"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { CSS_VAR_COLORS } from "../../../helpers/color"
import { getIconByType } from "../../../utility/notifications/getIcon"
import styles from "./NotificationImportFilesItem.module.scss"
import ProgressBar from "react-bootstrap/ProgressBar"
import { useAppSelector } from "../../../store/hooks"
import { selectImportFiles, selectImportFilesProgress } from "../../../store/knowledgeBase/selectors"
import truncate from "lodash/truncate"
import { formatDistanceToNowLocal } from "../../../utility/common/time"

const tNamespace = "notification:import-files."

const NotificationImportFilesItem: NotificationContentFunction = (notification, t) => {
    const iconContent = <FontAwesomeIcon color={CSS_VAR_COLORS.PRIMARY} icon={getIconByType(notification.Type)} />
    const buttons = <></>
    const importFiles = useAppSelector(selectImportFiles)
    const progress = useAppSelector(selectImportFilesProgress)

    const mainContent = (
        <div className={styles.notification}>
            <div className={styles.notification__title}>{t(`${tNamespace}import`)}</div>
            <div className={styles.notification__content}>
                {progress < 100 && (
                    <span>
                        {importFiles.length > 1
                            ? t(`${tNamespace}files-count`, {
                                  file: truncate(importFiles[0].name, { length: 16, omission: "..." }),
                                  count: importFiles.length - 1
                              })
                            : importFiles[0] && truncate(importFiles[0].name, { length: 16, omission: "..." })}
                    </span>
                )}
                <span className={styles.notification__time}>{formatDistanceToNowLocal(notification.Timestamp)}</span>
            </div>
            <div>
                {progress && progress < 100 ? (
                    <ProgressBar now={progress} className={styles.notification__progress} />
                ) : (
                    <span>
                        {notification.Description?.Value === "ArticleUploadFinish"
                            ? t(`${tNamespace}files-imported`)
                            : t(`${tNamespace}files-uploaded`)}
                    </span>
                )}
            </div>
        </div>
    )

    return {
        iconContent,
        mainContent,
        buttons
    }
}

export default NotificationImportFilesItem
