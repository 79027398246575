import { DeepPartial } from "redux"
import { Project } from "./project"

export interface ProjectSettings {
    DialogTimeout?: DialogTimeoutProjectSettings
    Operator?: OperatorProjectSettings
    Statistics?: StatisticsProjectSettings
    Report?: ReportProjectSettings
    Agent?: AgentProjectSettings
    DialogFinish?: DialogFinishProjectSettings
    Security?: SecurityProjectSettings
    System?: SystemProjectSettings
    FinishDialog?: FinishDialogProjectSettings
}

export const isProjectSettings = (rawData: unknown): rawData is ProjectSettings => {
    return typeof rawData === "object" && rawData !== null
}

export interface DialogTimeoutProjectSettings {
    FirstTimeout?: TimeoutSettings
    SecondTimeout?: TimeoutSettings
    FinishDialogTimeout?: TimeoutSettings
    DialogInQueueTimeout?: TimeoutSettings
    DialogLifeTimeout?: TimeoutSettings
}

export type DialogFinishProjectSettings = {
    FinishDialogArticle: string
}

export interface TimeoutSettings {
    Value: number
    Message?: string
    Enabled: boolean
}

export const isTimeoutSettings = (setting: unknown): setting is TimeoutSettings => {
    if (typeof setting !== "object" || setting === null) {
        return false
    }

    return "Value" in setting
}

export interface OperatorProjectSettings {
    Common?: CommonOperatorProjectSettings
    ClientSurvey?: ClientSurveyOperatorProjectSettings
    DialogSurvey?: DialogSurveyOperatorProjectSettings
    KPI?: KPIOperatorProjectSettings
    Workplace?: WorkplaceOperatorProjectSettings
    NewWorkplace?: NewWorkplaceOperatorProjectSettings
    AutoGreeting?: AutoGreetingOperatorProjectSettings
    ResponseTemplate?: ResponseTemplateOperatorProjectSettings
    Hold?: HoldOperatorProjectSettings
    Notifications?: NotificationsOperatorProjectSettings
    OutgoingDialog?: OutgoingDialogOperatorProjectSettings
}

export interface CommonOperatorProjectSettings {
    AllowChatJoinRequests?: boolean
    AuditorDashboardSurveyId?: string
    EnableRegistrationSlots?: boolean
}

export interface ClientSurveyOperatorProjectSettings {
    MainSurveyId?: string
    ShortSurveyId?: string
    PhoneNumberMask?: string
}

export interface DialogSurveyOperatorProjectSettings {
    ChooseTopicToFinishDialogRequired?: boolean
    DialogTopicCatalogId?: string
    DialogProgramCatalogId?: string
    DialogSurveyId?: string
    UserDissatisfaction?: ProjectSettingsListValue
    DefaultDialogTopicIdFinishingByTimeout?: string
    ChooseProgramFieldName?: string
    AlwaysOpenChooseTopicWindow?: boolean
    ChooseTopicToHoldDialogRequired?: boolean
}

export interface ProjectSettingsListValue {
    Values: string[]
}

export interface KPIOperatorProjectSettings {
    DialogTimerEnabled?: boolean
    GreenDialogTimerTimeout?: number
    YellowDialogTimerTimeout?: number
    DialogWaitingForClientAnswerTimerEnabled?: boolean
    DialogWaitingForClientAnswerTimeout?: number
    DialogWaitingForOperatorAnswerTimerEnabled?: boolean
    DialogWaitingForOperatorAnswerTimeout?: number
    DialogTimerTimeoutPlusEnabled?: boolean
    DialogTimerTimeoutCustomLabelEnabled?: boolean
    DialogTimerTimeoutCustomLabelEnabledName?: string
}

export interface WorkplaceOperatorProjectSettings {
    ShowDialogHistory?: boolean
    ForwardingMessage?: boolean
    RespondWithArticleEnabled?: boolean
    UseSpellChecker?: boolean
    UseEmoji?: boolean
    SetPreviousTopicsAfterFinishDialog?: boolean
    LoadHistoryByEmail?: boolean
    LoadHistoryByPhoneNumber?: boolean
    HideIdentifiers?: boolean
    NotifyOperatorAboutReroute?: boolean
}

export interface AIAssistSettings {
    HideAIAssistInChat?: boolean
    UseAIAssistGPT?: boolean
    UseAIAssistLLM?: boolean
}

export interface NewWorkplaceOperatorProjectSettings {
    CustomSections: CustomSectionsValue
    AllowRestrictAccessToClients: boolean
    AutoLinkToClientWhenRedirect: boolean
    EnableClientsSection: boolean
    ClientsSectionName: string
    EnableLinkedClientsSection: boolean
    LinkedClientsSectionName: string
    ShowLinkClientFunctional?: boolean
    DialogSurveyId: string
    ClientSurveyId: string
    EnableSeparateNewAndInProgressDialogs: boolean
    SendMessageActionsAsButtons: SendMessageButtonsValues
    HideFiltersInDialogTransfer: boolean
    AIAssist?: AIAssistSettings
    DefaultCountOfPrevAppeal: number
    AdditionalCountOfPrevAppeal: number
    NameBotByAppealCard: string
}

export interface AutoGreetingOperatorProjectSettings {
    OperatorAutoGreetingEnabled: boolean
    OperatorAutoGreetingMale: string
}

export interface ResponseTemplateOperatorProjectSettings {
    FastReplyTemplates: string
    FastReplyHoldTemplates: ProjectSettingsListValue
    WaitingForClientResponseTimeout: number
    WaitingForClientResponseTimeoutMessages: ProjectSettingsListValue
    TriggerMessagesForRunClientHolding: ProjectSettingsListValue
    ClientHoldingTimeout: number
    ClientHoldingMessages: ProjectSettingsListValue
}

export interface HoldOperatorProjectSettings {
    AutoHoldingEnabled?: boolean
    DropDialogTimeout?: number
    FinishDialogWithoutClientResponseTimeout?: number
}

export interface NotificationsOperatorProjectSettings {
    PushNotificationEnabled?: boolean
    PushNotificationVolumeLevel?: number
    PushNotificationTimeout?: number
}

export interface OutgoingDialogOperatorProjectSettings {
    FinishOutgoingDialogAfterStart?: boolean
    OutgoingMessageIntentsRecordTypes?: ProjectSettingsListValue
    ErrorMessageWhenInvalidOperatorStatus?: string
    IsNotRememberUserLastChannel?: boolean
}

export interface StatisticsProjectSettings {
    FunnelSlots: ProjectSettingsListValue
    UnproductiveAnswers: ProjectSettingsListValue
    TimeRangeStartDefiningField: StatisticsPeriodOptions
    TimeRangeEndDefiningField: StatisticsPeriodOptions
    HandledCountField: StatisticsPeriodOptions
    ExcludedOperatorIds: ProjectSettingsListValue
    FCR: number
    FCRSeparateDifferentTopics: boolean
    SLEndDefiningField: StatisticsCalculatingOptions
    SLPeriod: number
    AHTStartDefiningField: StatisticsCalculatingOptions
}

export enum StatisticsPeriodOptions {
    Started = "started",
    Finished = "finished"
}

export enum StatisticsCalculatingOptions {
    FirstReply = "first_reply",
    Routed = "routed"
}

export interface ReportProjectSettings {
    CommonReportTimeZone: number
    CommonReportSlots: CommonReportSlotsValue
}

export interface CommonReportSlotsValue {
    Values: CommonReportSlotValue[]
}

export interface CommonReportSlotValue {
    SlotId: string
    ColumnTitle: string
}

export interface CustomSectionValue {
    Id: string
    Title: string
    Url: string
    ExpandByDefault: boolean
    FullHeight: boolean
}

export interface CustomMenuValue {
    Id: string
    Title: string
    Url: string
    Icon?: string
}

export interface SendMessageButtonsValues {
    Hold: boolean
}

export interface CustomSectionsValue {
    Values: CustomSectionValue[]
}

export type ObservedSlotValue = {
    ObservedSlot: string
    CardHighlightingColor: string
}

export type ObservedSlotsValue = {
    Values: ObservedSlotValue[]
}

export interface CustomMenusValue {
    Values: CustomMenuValue[]
}

export type CustomPermissionValue = {
    Id: string
    Name: string
}

export type CustomPermissionsValue = {
    CustomPermissionsAllow: boolean
    Values: CustomPermissionValue[]
}

export interface AgentProjectSettings {
    DefaultAgent: string
    DefaultAgentForOutgoingDialog: string
    OperatorAgent: string
    SystemEventsAgent: string
}

export interface FinishDialogProjectSettings {
    ArticleWhenFinish: string
}

export interface SecurityProjectSettings {
    InformationMaskingPatterns: ProjectSettingsListValue
}

export interface KnowledgeBaseConvertTemplateModel {
    FileName: string
    FileUrl: string
}

export interface ColorSchema {
    ColorSchemaUsing: boolean
    ColorSchemaBaseColor: string
    ColorSchemaLinkColor: string
    ColorSchemaEditorColor: string
}

export interface SystemProjectSettings {
    UpdateClientDataFromChannel: boolean
    ChannelOnDialogPanel: boolean
    OperatorQuestionaryId: string
    OperatorQuestionaryForNewUsersEnabled: boolean
    ProfilePersonalInfoQuestionaryId: string
    ProfileSystemInfoQuestionaryId: string
    ProfileContactInfoQuestionaryId: string
    DialogSwitchNotificationWebhook: string
    SwitchToBotWindowTitle: string
    SwitchToBotWindowMessage: string
    DisableMarkupModal: boolean
    KnowledgeBaseConvertTemplate: KnowledgeBaseConvertTemplateModel
    EnableMessageSendBehaviour: boolean
    AdditionalResendConfigDependsOnQueue: boolean
    ColorSchema: ColorSchema
    LogoHugeSizeChangeAllow: boolean
    ColorSchemaChangeAllow: boolean
    CustomMenus: CustomMenusValue
    CustomPermissions: CustomPermissionsValue
    AutoPinMessagesEnabled: boolean
    AutoPinMessagesTypes: string
    DisplayOperatorIdEnabled: boolean
    DisplayOperatorRoleEnabled: boolean
    AssignDialogsByLinkOverLimit: boolean
    ErrorMessageAssignDialogsByLinkOverLimit: string
    HighlightAppealCardsWithSpecialSlots: boolean
    FreeSwitchingBetweenStatusesAllow: boolean
    HideChoiceQueueIfNotRouted: boolean
    DefaultQueueIfNotRouted: string
    ObservedSlots: ObservedSlotsValue
}

export interface GetSettingsResponse {
    Settings: ProjectSettings
}

export type BandwidthGroup = {
    Id: string
    Title: string
}

export enum BandwidthGroupsKeys {
    Id = "Id",
    Title = "Title"
}

export type BandwidthGroupsNaming = {
    [group: string]: string
}

export interface BandwidthGroupsFromRef {
    Groups: BandwidthGroupsNaming
    Default: string
}

export interface UserInviteSettings {
    InternalUserCT: boolean
    AppealToAD: boolean
}

export type GetBandwidthGroupsResponse = BandwidthGroupsFromRef | string

export type GetUserInviteSettingsResponse = {
    InternalUserCT: boolean
    AppealToAD: boolean
}

export interface GetSettingsWithProjectResponse {
    Project: Project
    Settings: ProjectSettings
}

export interface DiffUpdateSettingsResponse {
    Diff: DeepPartial<ProjectSettings>
}
