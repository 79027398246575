import { toArticleSubscriptions } from "./toArticleSubscriptions"
import { ClientSubscriptionResponse } from "../../../models/Dialogs/linksToOperator"

export const getInitialValues = (clientSubscriptions: ClientSubscriptionResponse[], projectId: string) => ({
    Subscriptions: toArticleSubscriptions(
        clientSubscriptions.filter(subscription => subscription.Fields.IsLinkedToClient),
        projectId
    ),
    SubscriptionDiff: {}
})
