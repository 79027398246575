import React from "react"
import styles from "./IconButton.module.scss"
import { Button, ButtonProps } from "react-bootstrap"
import { IconProp, SizeProp } from "@fortawesome/fontawesome-svg-core"
import cn from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export interface IconButtonProps extends ButtonProps {
    icon: IconProp
    iconSize?: SizeProp
    iconClassName?: string
    notifications?: number
    hideIcon?: boolean
    spin?: boolean
    testId?: string
    onClick?: React.MouseEventHandler<HTMLButtonElement>
}

const IconButton: React.FC<IconButtonProps> = props => {
    const {
        icon,
        children,
        iconSize = "lg",
        iconClassName,
        className,
        notifications,
        hideIcon,
        spin = false,
        testId,
        ...buttonProps
    } = props

    return (
        <Button className={cn(styles.iconButton, className)} {...buttonProps} data-testid={testId}>
            {!hideIcon && (
                <FontAwesomeIcon
                    icon={icon}
                    size={iconSize}
                    spin={spin}
                    className={cn(typeof children !== "undefined" && styles.iconButton__icon, iconClassName)}
                />
            )}
            {children}
            {!!notifications && <span className={styles.iconButton__notificationBadge}>{notifications}</span>}
        </Button>
    )
}

export default IconButton
