import { useCallback, useEffect } from "react"

function useBeforeUnload(handler: () => void) {
    const handleBeforeUnload = useCallback(() => {
        handler && handler()

        // prevent alerts on page close
        return undefined
    }, [handler])

    useEffect(() => {
        if (!handler) {
            return
        }

        window.addEventListener("beforeunload", handleBeforeUnload)

        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload)
        }
    }, [handler, handleBeforeUnload])
}

export default useBeforeUnload
