import React from "react"
import PopoverTrigger from "../PopoverTrigger/PopoverTrigger"
import ArticlePublishForm, { ArticlePublishFormProps } from "../ArticlePublishForm/ArticlePublishForm"
import styles from "./ArticlePublishPopover.module.scss"

interface ArticlePublishPopoverProps extends ArticlePublishFormProps {
    children: JSX.Element
    show: boolean
}

const ArticlePublishPopover: React.FC<ArticlePublishPopoverProps> = props => {
    const { children, show, onCancel, onSubmit } = props

    return (
        <PopoverTrigger
            className={styles.articlePublishPopover}
            id="article-publish"
            content={<ArticlePublishForm onCancel={onCancel} onSubmit={onSubmit} />}
            show={show}
            placement="bottom-end"
        >
            {children}
        </PopoverTrigger>
    )
}

export default ArticlePublishPopover
