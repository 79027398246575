import React, { useEffect } from "react"
import { WithT } from "i18next"
import ContentEditor, { ContentEditorCommands } from "../../ContentEditor/ContentEditor"
import LazyContentEditor from "../../ContentEditor/LazyContentEditor"
import cn from "classnames"
import styles from "../DialogMessageInput/DialogMessageInput.module.scss"
import {
    useGetAllowedAudioExtensions,
    useGetAllowedFileExtensions,
    useGetAllowedVideoExtensions
} from "../../../utility/knowledgeBase/articleEditorEmbeds"
import { Dispatch } from "../../../utility/common/storeHelper"
import { useAppSelector } from "../../../store/hooks"
import { selectCurrentProjectId } from "../../../store/projects/selectors"

const MESSAGE_LINK_BUTTON_ID = "dialog-message-input-link-button"
const EXTERNAL_IDS_FOR_SELECTION_TOOLBAR = [MESSAGE_LINK_BUTTON_ID]
export enum EditorPlacement {
    message = "message",
    input = "input"
}

export interface DialogContentEditorProps extends WithT {
    content: string
    placementType: EditorPlacement
    handleChange?: (getContent: () => string) => void
    setCursorPosition?: (position: number) => void
    dispatch: Dispatch
    handleCreateCommands?: (comingCommands: ContentEditorCommands) => void
    formWrapperFixedHeight?: boolean
    readonlyChat?: boolean
    inputClassName?: string
    className?: string
    advancedPlaceholder?: string
}

const DialogContentEditor: React.FC<DialogContentEditorProps> = props => {
    const {
        content,
        advancedPlaceholder,
        placementType,
        handleChange,
        readonlyChat = false,
        inputClassName,
        formWrapperFixedHeight,
        dispatch,
        handleCreateCommands,
        className,
        t,
        setCursorPosition
    } = props
    const projectId = useAppSelector(selectCurrentProjectId)
    const allowedFileExtensions = useGetAllowedFileExtensions()
    const allowedVideoExtensions = useGetAllowedVideoExtensions()
    const allowedAudioExtensions = useGetAllowedAudioExtensions()

    const editorExtensionsProps = {
        projectId: projectId,
        onOpenArticle: (articleId: string) => void 0,
        onChangeLocation: (pathString: string) => void 0,
        onDispatch: dispatch
    }

    // внешнее изменение контента означает выполнение handleChange
    // TODO: возможно стоит переделать и понять почему onChange не триггерится сам
    useEffect(() => {
        if (handleChange) {
            handleChange(() => content)
        }
    }, [content])

    const component = {
        input: (
            <LazyContentEditor
                t={t}
                initialCarePos="end"
                onChange={handleChange}
                content={content}
                className={cn(
                    styles.messageInput__control,
                    styles.messageInput__control_advanced,
                    readonlyChat && styles.messageInput__control_disabled,
                    inputClassName,
                    formWrapperFixedHeight && styles.messageInput__control_stable
                )}
                allowedFileExtensions={allowedFileExtensions}
                isEditable={!readonlyChat}
                disableExtensions={["scenario", "emojimenu"]}
                onCreateCommands={handleCreateCommands}
                advancedPlaceholder={advancedPlaceholder}
                externalIdsForSelectionToolbar={EXTERNAL_IDS_FOR_SELECTION_TOOLBAR}
                isInDialogInput
                setCursorPosition={setCursorPosition}
                {...editorExtensionsProps}
            />
        ),
        message: (
            <ContentEditor
                className={className}
                allowedFileExtensions={allowedFileExtensions}
                allowedVideoExtensions={allowedVideoExtensions}
                allowedAudioExtensions={allowedAudioExtensions}
                content={content}
                onChange={() => void 0}
                isEditable={readonlyChat}
                disableExtensions={["scenario", "emojimenu"]}
                t={t}
                {...editorExtensionsProps}
            />
        )
    }

    return component[placementType]
}

export default DialogContentEditor
