import { apiInstance } from "../../instances/apiInstance"
import { TextRequest } from "../../../types/textRequest"
import {
    GetUsersAndRolesByPermissionsRequest,
    GetUsersAndRolesByPermissionsResponse,
    SearchUsersAndRolesResponse
} from "../../../models/knowledgeBase/permission"

const knowledgeBasePermissionsController = {
    searchUsersAndRolesByProject: (projectId: string, request: TextRequest): Promise<SearchUsersAndRolesResponse> =>
        apiInstance.post(`/${projectId}/users-and-roles`, request).then(response => response.data),
    getUsersAndRolesByPermissions: (
        projectId: string,
        request: GetUsersAndRolesByPermissionsRequest
    ): Promise<GetUsersAndRolesByPermissionsResponse> =>
        apiInstance
            .post(`/${projectId}/users-and-roles/by-knowledge-base-permissions`, request)
            .then(response => response.data)
}

export default knowledgeBasePermissionsController
