import React, { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { NamedProps, ValueType } from "react-select"
import { AsyncProps } from "react-select/async"
import { AsyncSelect } from "../Select/Select"
import { Form } from "react-bootstrap"
import { getIn, useField, useFormikContext } from "formik"
import { useTranslation } from "react-i18next"
import { OptionsType, OptionTypeBase } from "react-select/src/types"
import { formTranslation } from "../../locales/form"
import debounce from "lodash-es/debounce"
import cn from "classnames"
import { logError } from "../../utility/common/logError"

export interface OptionType extends OptionTypeBase {
    label: string
    value: string
    meta?: string
}

export type GetOptionsFunction = (
    inputValue: string,
    meta?: string,
    inputValueMeta?: string
) => Promise<OptionsType<OptionType>>

export type GetLabelFunction = (newValue: string, getOptions: GetOptionsFunction) => Promise<string>

export interface AsyncSearchableInputProps extends NamedProps, Omit<AsyncProps<OptionType>, "loadOptions"> {
    id: string
    name: string
    label?: ReactNode
    optionsMeta?: string
    getOptions: GetOptionsFunction
    onSelect?: (option: ValueType<OptionType, false>) => void
    onCustomInputChange?: <K, T>(name: string, value: K, initialValues: T) => void
    getCustomLabel?: GetLabelFunction
    useValueForDefaultOptions?: boolean
    customDefaultValue?: string
}

const isOptionType = (option: ValueType<OptionType, false>): option is OptionType =>
    !!option && option.hasOwnProperty("value") && option.hasOwnProperty("label")

const AsyncSearchableInput: React.FC<AsyncSearchableInputProps> = props => {
    const { t } = useTranslation()
    const {
        className,
        id,
        name,
        placeholder,
        label,
        optionsMeta,
        getOptions,
        onSelect,
        onCustomInputChange,
        getCustomLabel,
        onChange,
        defaultOptions = true,
        customDefaultValue,
        cacheOptions = true,
        useValueForDefaultOptions = true,
        children,
        ...selectProps
    } = props

    const [field, meta] = useField(name)
    const [fieldLabel, setFieldLabel] = useState("")
    const initialValue = useRef<string>()

    const { values, setFieldValue, initialValues } = useFormikContext()
    const setValueMemo = useMemo(
        () => (newValue: string) => setFieldValue(field.name, newValue, true),
        [setFieldValue, field.name]
    )

    const loadOptions = useCallback(
        debounce((input: string, callback: (options: OptionsType<OptionType>) => void) => {
            getOptions(input, optionsMeta).then(options => callback(options))
        }, 500),
        [optionsMeta]
    )

    const handleChange = useCallback(
        (option: ValueType<OptionType, false>) => {
            if (isOptionType(option)) {
                onCustomInputChange && onCustomInputChange(name, option, initialValues)
                setFieldLabel(option.label)
                onSelect ? onSelect(option) : setValueMemo(option.value)
            } else {
                setValueMemo("")
                setFieldLabel("")
            }
        },
        [setValueMemo, onSelect]
    )

    useEffect(() => {
        const updateLabel = async (currentValue: string) => {
            if (!currentValue) {
                const options = await getOptions("")
                if (options.length === 1) {
                    setFieldLabel(options[0].label)
                    setFieldValue(name, options[0].value)
                    return
                }

                const defaultOption = options.find(option => option.value === customDefaultValue)
                if (defaultOption) {
                    setFieldLabel(defaultOption.label)
                    setFieldValue(name, defaultOption.value)
                    return
                }

                setFieldLabel("")
                return
            }

            if (getCustomLabel) {
                const newLabel = await getCustomLabel(currentValue, getOptions).catch(error => {
                    logError("Failed to load custom labels", error)
                    return undefined
                })
                if (newLabel) {
                    setFieldLabel(newLabel)
                } else {
                    setValueMemo("")
                }
                return
            }

            const defaultOptions = await getOptions(useValueForDefaultOptions ? currentValue : "")
            const fieldValue = defaultOptions.find(o => o.value === currentValue)

            if (fieldValue) {
                setFieldLabel(fieldValue.label)
            } else {
                setFieldLabel("")
                setValueMemo("")
            }
        }

        const currentValue = getIn(values, name)
        if (currentValue !== initialValue.current && typeof currentValue === "string") {
            initialValue.current = currentValue
            updateLabel(currentValue)
        }

        return () => {
            initialValue.current = undefined
        }
    }, [getOptions, getCustomLabel, initialValues, name, setValueMemo, useValueForDefaultOptions])

    useEffect(() => {
        return () => loadOptions.cancel()
    }, [loadOptions])

    return (
        <Form.Group className={cn("searchable-input-container", className)} controlId={id}>
            {label && <Form.Label>{label}</Form.Label>}
            <AsyncSelect
                className={cn(meta.error && "is-invalid")}
                isClearable
                defaultOptions={defaultOptions}
                cacheOptions={cacheOptions}
                noOptionsMessage={() => t(formTranslation.noResultsFound)}
                loadingMessage={() => t("common:loading.default")}
                placeholder={placeholder || ""}
                onChange={onChange ?? handleChange}
                value={fieldLabel && field.value ? { label: fieldLabel, value: field.value } : undefined}
                loadOptions={loadOptions}
                invalid={!!meta.error}
                {...selectProps}
            />
            <Form.Control.Feedback type="invalid">{meta.error && t(meta.error)}</Form.Control.Feedback>
            {children}
        </Form.Group>
    )
}

export default AsyncSearchableInput
