import { useEffect, useState } from "react"
import { Tab } from "react-bootstrap"
import { TabPaneElement } from "../ValidatableFormTabs/ValidatableFormTabs"

const LazyTabPane: React.FC<{
    eventKey: string
    activeKey: string
    children: React.ReactNode
    innerRef?: React.RefObject<TabPaneElement>
}> = ({ eventKey, activeKey, children, innerRef }) => {
    const [hasRendered, setHasRendered] = useState(false)

    useEffect(() => {
        if (activeKey === eventKey && !hasRendered) {
            setHasRendered(true)
        }
    }, [activeKey, eventKey, hasRendered])

    return (
        <Tab.Pane eventKey={eventKey} ref={innerRef} key={eventKey}>
            {(hasRendered || activeKey === eventKey) && children}
        </Tab.Pane>
    )
}

export default LazyTabPane
