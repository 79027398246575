import cn from "classnames"
import { useEffect, useRef } from "react"
import { useDispatch } from "react-redux"
import { useRouteMatch } from "react-router-dom"
import { removeNewslineItem } from "../../../../api/controllers/knowledgeBase/helpers"
import { NewslineItem as NewslineItemType } from "../../../../models/knowledgeBase/newsline"
import { selectCurrentProjectId } from "../../../../store/projects/selectors"
import { useAppSelector } from "../../../../store/hooks"
import styles from "./NewslineItem.module.scss"

const DELAY = 1500
const KEYFRAMES = [
    { top: "100%", opacity: "0" },
    { top: "0", opacity: "1", offset: 0.25 },
    { top: "0", opacity: "1", offset: 0.75 },
    { top: "-100%", opacity: "0" }
]
const OPTIONS = {
    duration: 2000,
    iterations: 1
}

export interface NewslineItemProps {
    index: number
    total: number
    item: NewslineItemType
    isTabActive: boolean
}

export const NewslineItem = ({ item, index, total, isTabActive }: NewslineItemProps) => {
    const dispatch = useDispatch()
    const projectId = useAppSelector(selectCurrentProjectId)
    const articlePath = `/project/${projectId}/knowledge-base/article/view/${item.ArticleCode}`
    const isArticleOpened = Boolean(useRouteMatch(articlePath))
    const ref = useRef<HTMLLIElement>(null)

    useEffect(() => {
        if (isArticleOpened && item.IsHideAfterReading && projectId) {
            dispatch(removeNewslineItem(item.Id, projectId))
        }
    }, [isArticleOpened])

    useEffect(() => {
        if (total < 2 || !isTabActive) {
            return
        }

        let intervalId: NodeJS.Timeout
        let animation: Animation | undefined = undefined
        const timeoutId = setTimeout(() => {
            animation = ref.current?.animate(KEYFRAMES, OPTIONS)

            intervalId = setInterval(() => {
                animation = ref.current?.animate(KEYFRAMES, OPTIONS)
            }, DELAY * total)
        }, DELAY * index)

        return () => {
            clearTimeout(timeoutId)
            clearInterval(intervalId)
            animation?.cancel()
        }
    }, [item, index, total, isTabActive])

    return (
        <li className={cn(styles.item, total === 1 && styles.item_alone)} ref={ref}>
            <span className={styles.item__title}>{item.Title}</span>
        </li>
    )
}
