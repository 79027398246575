import { useSelector } from "react-redux"
import { selectReferences } from "../../store/projects/selectors"
import { useMemo } from "react"
import { Reference } from "../../models/reference"

export const isRefExists = (refType: string, references: Reference[]) => {
    return references.some(ref => ref.type === refType && ref.value === "true")
}

const useRefCheck = (refType: string) => {
    const references = useSelector(selectReferences)
    return useMemo(() => (references ? isRefExists(refType, references) : false), [references, refType])
}

export default useRefCheck
