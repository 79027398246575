export const projectPath = "/project"
export const createProjectPath = "/create-project"
export const createProjectCopyPath = "/create-project-copy"
export const sendSignUpEmailPath = "/sign-up"
export const signUpPath = "/sign-up"
export const loginPath = "/login"
export const welcomePath = "/welcome"
export const accessDeniedPath = "/access_denied"
export const articlePath = "/article"
export const forgotPasswordPath = "/forgot-password"
export const resetPasswordPath = "/reset-password"
export const sharedKnowledgeBasePath = "/share"
export const invitePath = "/invite"
export const debugTranslation = "/debug-translation"
