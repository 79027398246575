import { ExtendedSettings, Queue } from "../../../models/queue"
import {
    AwtMetricValue,
    ExtraSettings,
    ExtraSettingsValue,
    FinishedDialogsDailyMetricValue,
    FinishedDialogsMetricValue,
    MetricToAdd,
    QueueValues
} from "../../../models/queueValues"
import { Time } from "../../../utility/common/time"
import { intervalToDuration } from "date-fns"
import { getSettings } from "../../../utility/common/getSettings"
import { AllowCatalogsFormValues } from "../../../models/catalog"
import AsyncState from "../../../core/asyncState"
import { BandwidthGroup, BandwidthGroupsKeys } from "../../../models/projectSettings"
import { ALL_DIALOG_GROUPS, getGroupsByKey } from "./prepareDialogGroupsValues"

function getMetricSettingsValue(id: string, end: number) {
    const { hours, minutes } = intervalToDuration({ start: 0, end })
    return {
        id,
        hr: hours ?? 0,
        min: minutes ?? 0
    }
}

const getDialogGroupsValues = (
    groups: string[],
    bandwidthGroups: AsyncState<BandwidthGroup[]>,
    allGroupsSetting: string
): string[] => {
    if (groups.includes(ALL_DIALOG_GROUPS)) {
        return [allGroupsSetting]
    }

    if (bandwidthGroups.data) {
        return getGroupsByKey(groups, bandwidthGroups.data, BandwidthGroupsKeys.Id)
    }
    return []
}

export const getValuesFromQueue = (
    queue: Queue,
    extendedSettings: ExtendedSettings | undefined,
    catalogs: AllowCatalogsFormValues[],
    bandwidthGroups: AsyncState<BandwidthGroup[]>,
    allDialogGroupsSetting: string
): QueueValues => {
    let slValues = undefined
    if (!!queue.SlSettings) {
        const slSettings = queue.SlSettings
        const { Hr, Min } = Time.msToTimeSpan(slSettings.SlPeriodMSec)
        slValues = {
            slTarget: slSettings.SlTarget,
            slThresholdSec: Time.msToSeconds(slSettings.SlThresholdMSec),
            slPeriodHr: Hr,
            slPeriodMin: Min
        }
    }

    const extraSettings: ExtraSettingsValue[] = []
    if (!!extendedSettings?.DialogTimeoutSettings) {
        const {
            Days: DaysGreen,
            Hr: HrGreen,
            Min: MinGreen,
            Sec: SecGreen
        } = Time.msToTimeSpan(extendedSettings.DialogTimeoutSettings.Green)

        const {
            Days: DaysYellow,
            Hr: HrYellow,
            Min: MinYellow,
            Sec: SecYellow
        } = Time.msToTimeSpan(extendedSettings.DialogTimeoutSettings.Yellow)

        extraSettings.push({
            type: ExtraSettings.DialogTimer,
            value: {
                greenTimer: {
                    Days: DaysGreen,
                    Hours: HrGreen,
                    Minutes: MinGreen,
                    Seconds: SecGreen
                },
                yellowTimer: {
                    Days: DaysYellow,
                    Hours: HrYellow,
                    Minutes: MinYellow,
                    Seconds: SecYellow
                }
            }
        })
    }
    if (!!extendedSettings?.OperatorAutoGreeting) {
        extraSettings.push({
            type: ExtraSettings.AutoGreeting,
            value: {
                operatorAutoGreetingEnabled: extendedSettings.OperatorAutoGreeting.OperatorAutoGreetingEnabled,
                operatorAfterHoldGreetingEnabled:
                    extendedSettings.OperatorAutoGreeting.OperatorAfterHoldGreetingEnabled,
                template: extendedSettings.OperatorAutoGreeting.Template
            }
        })
    }
    if (!!extendedSettings?.ClientFormSurvey) {
        extraSettings.push({
            type: ExtraSettings.ClientSurvey,
            value: {
                mainSurveyId: extendedSettings.ClientFormSurvey.MainSurveyId,
                shortSurveyId: extendedSettings.ClientFormSurvey.ShortSurveyId
            }
        })
    }
    if (!!extendedSettings?.AdditionalResendConfig) {
        extraSettings.push({
            type: ExtraSettings.AdditionalResendConfig,
            value: {
                enable: extendedSettings.AdditionalResendConfig.Enable,
                template: extendedSettings.AdditionalResendConfig.Template
            }
        })
    }
    if (!!extendedSettings?.EmailChannels) {
        extraSettings.push({
            type: ExtraSettings.EmailChannels,
            value: {
                defaultChannelId: extendedSettings.EmailChannels.DefaultChannelId,
                additionalChannelsIds: extendedSettings.EmailChannels.AdditionalChannelsIds
            }
        })
    }
    if (!!extendedSettings?.FinishDialog) {
        extraSettings.push({
            type: ExtraSettings.DialogFinish,
            value: {
                finishDialogArticle: extendedSettings.FinishDialog.FinishDialogArticle
            }
        })
    }
    if (!!extendedSettings?.KnowledgeBase?.CatalogId) {
        extraSettings.push({
            type: ExtraSettings.FastReplyTemplates,
            value: {
                catalogId: extendedSettings.KnowledgeBase.CatalogId
            }
        })
    }
    if (!!extendedSettings?.AutoHoldingSettings) {
        const { Days, Hr, Min, Sec } = Time.msToTimeSpan(extendedSettings.AutoHoldingSettings.Timeout)

        extraSettings.push({
            type: ExtraSettings.AutoHolding,
            value: {
                enabled: extendedSettings.AutoHoldingSettings.Enabled,
                triggerMessages: extendedSettings.AutoHoldingSettings.TriggerMessages,
                messagesToSend: extendedSettings.AutoHoldingSettings.MessagesToSend,
                timeout: {
                    Days: Days,
                    Hours: Hr,
                    Minutes: Min,
                    Seconds: Sec
                },
                multipleSending: extendedSettings.AutoHoldingSettings.MultipleSending
            }
        })
    }
    if (extendedSettings?.AllowCatalogs) {
        extraSettings.push({
            type: ExtraSettings.AllowCatalogs,
            value: {
                Allowed: getSettings(
                    catalogs.map(catalog => catalog.id),
                    extendedSettings.AllowCatalogs.Allowed ?? []
                )
            }
        })
    }
    if (!!extendedSettings?.DialogGroups) {
        extraSettings.push({
            type: ExtraSettings.DialogGroups,
            value: {
                groups: getDialogGroupsValues(
                    extendedSettings.DialogGroups.Groups,
                    bandwidthGroups,
                    allDialogGroupsSetting
                )
            }
        })
    }
    if (!!extendedSettings?.RestrictedQueues?.Queues) {
        extraSettings.push({
            type: ExtraSettings.QueueTransferRestriction,
            value: {
                queues: extendedSettings.RestrictedQueues.Queues
            }
        })
    }

    let awtValues: AwtMetricValue[] = []
    if (!!queue.AwtStatsSettings) {
        awtValues = queue.AwtStatsSettings.map(setting => ({
            type: MetricToAdd.Awt,
            value: getMetricSettingsValue(setting.Id, setting.LookbehindMSec)
        }))
    }

    let finishedDialogsValues: FinishedDialogsMetricValue[] = []
    if (!!queue.FinishedDialogsSettings) {
        finishedDialogsValues = queue.FinishedDialogsSettings.map(setting => ({
            type: MetricToAdd.FinishedDialogs,
            value: getMetricSettingsValue(setting.Id, setting.LookbehindMSec)
        }))
    }

    let finishedDialogsDailyValues: FinishedDialogsDailyMetricValue[] = []
    if (!!queue.FinishedDialogsDailySettings) {
        finishedDialogsDailyValues = queue.FinishedDialogsDailySettings.map(setting => ({
            type: MetricToAdd.FinishedDialogsDaily,
            value: getMetricSettingsValue(setting.Id, setting.LocalResetTime)
        }))
    }

    let timeoutValues = undefined
    if (!!queue.TimeoutSettings) {
        const timeoutSettings = queue.TimeoutSettings
        const { Hr, Min, Sec } = Time.msToTimeSpan(timeoutSettings.TimeoutMSec)
        timeoutValues = {
            timeoutHr: Hr,
            timeoutMin: Min,
            timeoutSec: Sec,
            timeoutQueue: timeoutSettings.TimeoutQueueId
        }
    }

    return {
        name: queue.Name,
        priority: queue.Priority,
        taskSize: queue.TaskSize,
        slValues: slValues,
        timeoutValues: timeoutValues,
        metrics: [...awtValues, ...finishedDialogsValues, ...finishedDialogsDailyValues],
        extraSettings
    }
}
