import React from "react"
import { Form } from "react-bootstrap"
import { preventSubmitOnEnter } from "../../utility/common/preventSubmitOnEnter"
import LoadingButton from "../LoadingButton/LoadingButton"
import { useTranslation } from "react-i18next"
import { formTranslation } from "../../locales/form"
import { testId } from "../../utility/tests/testId"
import AgentFormHeader from "../AgentFormHeader/AgentFormHeader"
import { AgentType } from "../../models/agent"

interface Props {
    onSubmit?: (e?: React.FormEvent<HTMLFormElement>) => void
    agentType?: AgentType
    disabled?: boolean
    loading?: boolean
}

const AgentFormWrapper: React.FC<Props> = props => {
    const { t } = useTranslation()
    const { onSubmit, agentType, disabled, loading, children } = props

    return (
        <Form className="agent-form" onSubmit={onSubmit} onKeyPress={preventSubmitOnEnter}>
            <div className="agent-form__content">
                {agentType && <AgentFormHeader agentType={agentType} t={t} />}
                {children}
            </div>
            <div className="agent-form__footer">
                <LoadingButton
                    type="submit"
                    loading={loading}
                    variant="primary"
                    disabled={disabled}
                    block
                    testId={testId.saveAgent}
                >
                    {t(formTranslation.save)}
                </LoadingButton>
            </div>
        </Form>
    )
}

export default AgentFormWrapper
