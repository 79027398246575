import React, { useState } from "react"
import { nameof } from "../../utility/common/nameof"
import { shallowEqual, useSelector } from "react-redux"
import { selectCreateQueueState, selectUpdateQueueState } from "../../store/queues/selectors"
import { Form } from "react-bootstrap"
import { preventSubmitOnEnter } from "../../utility/common/preventSubmitOnEnter"
import ValidatableInput from "../ValidatableInput/ValidatableInput"
import Slider from "../Slider/Slider"
import Toggle from "../Toggle/Toggle"
import SlSettingsInput, { SlSettingsValues } from "./controls/SlSettingsInput"
import LoadingButton from "../LoadingButton/LoadingButton"
import { formTranslation } from "../../locales/form"
import { FormikQueueFormProps } from "../FormikQueueForm/FormikQueueForm"
import { QueueValues } from "../../models/queueValues"
import TimeoutSettingsInput, { TimeoutSettingsValues } from "./controls/TimeoutSettingsInput"
import { testId } from "../../utility/tests/testId"

const tNamespace = "queues:form."

const defaultTimeoutValues: TimeoutSettingsValues = {
    timeoutHr: 0,
    timeoutMin: 5,
    timeoutSec: 0,
    timeoutQueue: ""
}

const defaultSlSettingsValues: SlSettingsValues = {
    slTarget: 80,
    slThresholdSec: 20,
    slPeriodHr: 24,
    slPeriodMin: 0
}

const FormikQueueFormGeneral: React.FC<FormikQueueFormProps> = props => {
    const { queue, allQueues, t, values, handleSubmit, setFieldValue } = props

    const [timeoutEnabled, setTimeoutEnabled] = useState(!!values.timeoutValues)
    const [slEnabled, setSlEnabled] = useState(!!values.slValues)

    const handleTaskSizeChange = (v: number) => setFieldValue(nameof<QueueValues>("taskSize"), v, false)
    const handlePriorityChange = (v: number) => setFieldValue(nameof<QueueValues>("priority"), v, false)

    const handleTimeoutToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
        const checked = e.target.checked
        setTimeoutEnabled(checked)
        setFieldValue(nameof<QueueValues>("timeoutValues"), checked ? defaultTimeoutValues : undefined, false)
    }
    const handleSlToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
        const checked = e.target.checked
        setSlEnabled(checked)
        setFieldValue(nameof<QueueValues>("slValues"), checked ? defaultSlSettingsValues : undefined, false)
    }

    const asyncState = useSelector(queue ? selectUpdateQueueState : selectCreateQueueState, shallowEqual)

    return (
        <Form className="queue-form" onSubmit={handleSubmit} onKeyPress={preventSubmitOnEnter}>
            <div className="queue-form__content">
                {queue && (
                    <Form.Group>
                        <Form.Label>{t(`${tNamespace}id`)}</Form.Label>
                        <div>{queue.Id}</div>
                    </Form.Group>
                )}
                <div className="queue-form__section">
                    <ValidatableInput
                        id="formName"
                        type="text"
                        name={nameof<QueueValues>("name")}
                        label={t(`${tNamespace}name`)}
                        testId={testId.queueName}
                    />
                </div>
                <div className="queue-form__section">
                    <Form.Group controlId="formPriority">
                        <Form.Label>{t(`${tNamespace}priority`)}</Form.Label>
                        <Slider
                            min={0}
                            max={1000}
                            step={1}
                            marks={{ 0: "0", 1000: "1000" }}
                            onChange={handlePriorityChange}
                            value={values.priority}
                            testId={testId.priorityValue}
                        />
                    </Form.Group>
                    <Form.Group controlId="formTaskSize">
                        <Form.Label>{t(`${tNamespace}task-size`)}</Form.Label>
                        <Slider
                            min={1}
                            max={10}
                            step={1}
                            marks={{ 1: "1", 10: "10" }}
                            onChange={handleTaskSizeChange}
                            value={values.taskSize}
                            testId={testId.taskSizeValue}
                        />
                    </Form.Group>
                </div>
                <div className="queue-form__section">
                    <div className="queue-form__toggle">
                        <Toggle
                            id="isTimeoutEnabled"
                            icons={false}
                            className="toggle"
                            defaultChecked={timeoutEnabled}
                            onChange={handleTimeoutToggle}
                        />
                        <label htmlFor="isTimeoutEnabled" className="toggle__label">
                            {t(`${tNamespace}timeout-queue`)}
                        </label>
                    </div>
                    {timeoutEnabled && (
                        <TimeoutSettingsInput
                            name={nameof<QueueValues>("timeoutValues")}
                            queues={queue ? allQueues.filter(q => q.Id !== queue.Id) : allQueues}
                        />
                    )}
                    <div className="queue-form__toggle">
                        <Toggle
                            id="isSlEnabled"
                            icons={false}
                            className="toggle"
                            defaultChecked={slEnabled}
                            onChange={handleSlToggle}
                        />
                        <label htmlFor="isSlEnabled" className="toggle__label">
                            {t(`${tNamespace}sl-setup`)}
                        </label>
                    </div>
                    {slEnabled && <SlSettingsInput name={nameof<QueueValues>("slValues")} />}
                </div>
            </div>
            <div className="queue-form__footer">
                <LoadingButton
                    type="submit"
                    loading={asyncState.inProcess}
                    variant="primary"
                    testId={testId.saveQueue}
                    block
                >
                    {t(formTranslation.save)}
                </LoadingButton>
            </div>
        </Form>
    )
}

export default FormikQueueFormGeneral
