const tNamespace = "form:"

export const formTranslation = {
    continue: `${tNamespace}continue`,
    save: `${tNamespace}save`,
    cancel: `${tNamespace}cancel`,
    change: `${tNamespace}change`,
    add: `${tNamespace}add`,
    addRole: `${tNamespace}add-role`,
    send: `${tNamespace}send`,
    selectAll: `${tNamespace}select-all`,
    enableAll: `${tNamespace}enable-all`,
    turnOffAll: `${tNamespace}turn-off-all`,
    clearAll: `${tNamespace}clear-all`,
    title: `${tNamespace}title`,
    firstName: `${tNamespace}first-name`,
    enterFirstName: `${tNamespace}enter-first-name`,
    firstNameInvalid: `${tNamespace}first-name-invalid`,
    lastName: `${tNamespace}last-name`,
    enterLastName: `${tNamespace}enter-last-name`,
    lastNameInvalid: `${tNamespace}last-name-invalid`,
    middleName: `${tNamespace}middle-name`,
    email: `${tNamespace}email`,
    phoneNumber: `${tNamespace}phone-number`,
    maxConcurrentTasks: `${tNamespace}max-concurrent-tasks`,
    language: `${tNamespace}language`,
    password: `${tNamespace}password`,
    confirmPassword: `${tNamespace}confirm-password`,
    currentPassword: `${tNamespace}current-password`,
    newPassword: `${tNamespace}new-password`,
    repeatPassword: `${tNamespace}repeat-password`,
    idRequired: `${tNamespace}id-required`,
    titleRequired: `${tNamespace}title-required`,
    firstNameRequired: `${tNamespace}first-name-required`,
    lastNameRequired: `${tNamespace}last-name-required`,
    emailRequired: `${tNamespace}email-required`,
    emailInvalid: `${tNamespace}email-invalid`,
    passwordRequired: `${tNamespace}password-required`,
    passwordInvalid: `${tNamespace}password-invalid`,
    enterPassword: `${tNamespace}enter-password`,
    confirmPasswordRequired: `${tNamespace}confirm-password-required`,
    confirmPasswordIncorrect: `${tNamespace}confirm-password-incorrect`,
    currentPasswordRequired: `${tNamespace}current-password-required`,
    newPasswordRequired: `${tNamespace}new-password-required`,
    repeatPasswordRequired: `${tNamespace}repeat-password-required`,
    repeatPasswordInvalid: `${tNamespace}repeat-password-incorrect`,
    user: `${tNamespace}user`,
    role: `${tNamespace}role`,
    group: `${tNamespace}group`,
    login: `${tNamespace}login`,
    select: `${tNamespace}select`,
    selectDate: `${tNamespace}select-date`,
    selectButton: `${tNamespace}select-button`,
    support: `${tNamespace}support`,
    edit: `${tNamespace}edit`,
    delete: `${tNamespace}delete`,
    deleteAll: `${tNamespace}delete-all`,
    block: `${tNamespace}block`,
    noResultsFound: `${tNamespace}no-results-found`,
    notSet: `${tNamespace}not-set`,
    notFilled: `${tNamespace}not-filled`,
    nothingSelected: `${tNamespace}nothing-selected`,
    apply: `${tNamespace}apply`,
    copied: `${tNamespace}copied`,
    search: `${tNamespace}search`,
    enterText: `${tNamespace}enter-text`,
    enterRegex: `${tNamespace}enter-regex`,
    enterNumber: `${tNamespace}enter-number`,
    enterPositiveNumber: `${tNamespace}enter-positive-number`,
    enteredInvalidValue: `${tNamespace}entered-invalid-value`,
    maxValueIs: `${tNamespace}max-value-is`,
    yes: `${tNamespace}yes`,
    all: `${tNamespace}all`,
    selectFile: `${tNamespace}select-file`,
    upload: `${tNamespace}upload`,
    completed: `${tNamespace}completed`,
    connect: `${tNamespace}connect`,
    reject: `${tNamespace}reject`,
    type: `${tNamespace}type`,
    createAccount: `${tNamespace}create-account`,
    requiredField: `${tNamespace}required`,
    requiredFieldToFill: `${tNamespace}requiredToFill`,
    addPhrase: `${tNamespace}add-phrase`,
    noEmptyMessage: `${tNamespace}no-empty-message`,
    share: `${tNamespace}share`,
    publish: `${tNamespace}publish`
}
