import React, { HTMLAttributes, useEffect, useMemo, useState } from "react"
import styles from "./TaskBoard.module.scss"
import { Task } from "../../models/task"
import { Accordion } from "react-bootstrap"
import useResizeObserver from "use-resize-observer"
import TaskCard, { ClientLink } from "../TaskCard/TaskCard"
import { useTranslation } from "react-i18next"
import { Queue } from "../../models/queue"
import throttle from "lodash-es/throttle"
import { selectCurrentUserProjects } from "../../store/users/selectors"
import { useSelector } from "react-redux"
import { selectAllProjects } from "../../store/projects/selectors"
import { ChannelNames } from "../../models/channel"
import { getMainQueuePart } from "../../store/queues/slice"
import { useOpenedState } from "../../utility/common/useOpenedState"

const tNamespace = "queues:card."
const TASK_MAX_WIDTH = 215 // default task width

export interface TaskBoardProps {
    tasks: Task[]
    channelNames?: ChannelNames
    onClick: (task: Task) => void
    selectedTask: string | null
    boardQueue?: Queue
    queues?: Queue[]
}

const ToggleItem: React.FC<HTMLAttributes<HTMLDivElement>> = props => {
    return <div {...props} />
}

const TaskBoard: React.FC<TaskBoardProps> = props => {
    const { tasks, channelNames, onClick, boardQueue, queues, selectedTask } = props
    const [head, setHead] = useState<Task[]>([])
    const [tail, setTail] = useState<Task[]>([])
    const { ref, width } = useThrottledResizeObserver<HTMLDivElement>(2000)
    const [opened, toggleOpen] = useOpenedState(false)
    const { t } = useTranslation()

    useEffect(() => {
        if (width) {
            const countHeadTasks = Math.round(width / TASK_MAX_WIDTH)
            if (countHeadTasks < tasks.length) {
                setHead(tasks.slice(0, countHeadTasks - 1))
                setTail(tasks.slice(countHeadTasks - 1))
            } else {
                setHead(tasks)
                setTail([])
            }
        }
    }, [tasks, width, tasks.length])

    const currentUserProjects = useSelector(selectCurrentUserProjects)
    const projects = useSelector(selectAllProjects) ?? []

    const tryGetQueueLink = (task: Task): ClientLink | undefined => {
        // no need to create link, because all tasks are from the same queue
        if (boardQueue === undefined || queues === undefined) return undefined

        if (task.TenantId !== boardQueue.TenantId) {
            const project = projects.find(v => v.id === task.TenantId)
            return project
                ? { type: "project", itemId: project.id, text: project.name }
                : {
                      type: "unknown",
                      itemId: task.TenantId,
                      text: t(`${tNamespace}unknown-project`)
                  }
        }

        const queueId = task.QueueId && getMainQueuePart(task.QueueId)
        if (queueId && queueId !== boardQueue.Id) {
            const queue = queues.find(v => v.Id === queueId)
            return queue && { type: "queue", itemId: queue.Id, text: queue.Name }
        }

        return undefined
    }

    const renderTask = (task: Task) => {
        const userHasProject = currentUserProjects.includes(task.TenantId)
        return (
            <TaskCard
                key={task.Id}
                task={task}
                channelName={channelNames?.[task.Channel.Id]}
                link={tryGetQueueLink(task)}
                selected={selectedTask === task.Id}
                onClick={userHasProject ? onClick : undefined}
            />
        )
    }
    return (
        <Accordion as="div" className={styles.taskBoard}>
            <div className={styles.taskBoard__head} ref={ref}>
                {!!head.length ? (
                    head.map(renderTask)
                ) : (
                    <div className={styles.taskBoard__noTasks}>{t(`${tNamespace}no-tasks`)}</div>
                )}
                {!!tail.length && (
                    <Accordion.Toggle
                        eventKey="0"
                        className={styles.taskBoardToggle}
                        as={ToggleItem}
                        onClick={toggleOpen}
                    >
                        <div className={styles.taskBoardToggle__text}>
                            {opened ? t(`${tNamespace}collapse`) : t(`${tNamespace}view-all`)}
                        </div>
                        <div className={styles.taskBoardToggle__count}>
                            {`${tail.length} ${t(`${tNamespace}tasks-count`)}`}
                        </div>
                    </Accordion.Toggle>
                )}
            </div>
            {!!tail.length && (
                <Accordion.Collapse eventKey="0">
                    <div className={styles.taskBoard__tail}>{tail.map(renderTask)}</div>
                </Accordion.Collapse>
            )}
        </Accordion>
    )
}

function useThrottledResizeObserver<T extends HTMLElement>(wait: number) {
    const [size, setSize] = useState<{ width?: number; height?: number }>({})
    const onResize = useMemo(() => throttle(setSize, wait), [wait])
    const { ref } = useResizeObserver<T>({ onResize })

    return { ref, ...size }
}

export default TaskBoard
