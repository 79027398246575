import { selectCurrentPermissions } from "../../store/users/selectors"
import { useSelector } from "react-redux"
import { Permission } from "../../store/users/slice"

export const isPermissionEnabled = (permission: string, currentPermissions: Permission[]) => {
    return currentPermissions.some(p => p.Name === permission && p.IsEnabled)
}

function usePermissionsCheck(perms: string[], operation: "and" | "or" = "and") {
    const currentPerms = useSelector(selectCurrentPermissions)

    const hasPermission = (permission: string) => {
        return isPermissionEnabled(permission, Array.from(currentPerms))
    }

    return operation === "and" ? perms.every(hasPermission) : perms.some(hasPermission)
}

export default usePermissionsCheck
