import React, { useMemo } from "react"
import { Task, TaskOfAutomaticProcessing } from "../../models/task"
import TaskBoard from "../TaskBoard/TaskBoard"
import { ChannelNames } from "../../models/channel"
import { taskConverter } from "../../utility/common/taskConverter"
import { logError } from "../../utility/common/logError"

interface QueueForAutomaticProcessingBody {
    tasks: TaskOfAutomaticProcessing[]
    onTaskClick: (task: TaskOfAutomaticProcessing) => void
    channelsNames: ChannelNames
}

const QueueForAutomaticProcessingContent: React.FC<QueueForAutomaticProcessingBody> = props => {
    const { tasks, channelsNames, onTaskClick } = props

    const manualTasks = useMemo(() => tasks.map(taskConverter.fromAutomaticProcessing), [tasks])

    const handleTaskClick = (task: Task) => {
        const taskOfAutomaticProcessing = tasks.find(v => v.Id === task.Id)
        if (taskOfAutomaticProcessing) {
            onTaskClick(taskOfAutomaticProcessing)
        } else {
            logError(`Failed to select task. Task of the automatic processing not found. Id: ${task.Id}`)
        }
    }

    return <TaskBoard tasks={manualTasks} selectedTask={null} onClick={handleTaskClick} channelNames={channelsNames} />
}

export default QueueForAutomaticProcessingContent
