import React from "react"
import styles from "./Skeleton.module.scss"
import cn from "classnames"

export interface AdvancedSkeletonProps {
    count?: number
    style?: React.CSSProperties
    className?: string
}

const AdvancedSkeleton: React.FC<AdvancedSkeletonProps> = props => {
    const { count, style, className } = props

    return (
        <>
            {Array.from({ length: count || 1 }, (_, index) => (
                <div key={index} style={{ ...style }}>
                    <div className={cn(styles.skeleton, className && className)} />
                </div>
            ))}
        </>
    )
}

export default AdvancedSkeleton
