import { Hub } from "../hub"
import { IHub } from "../interfaces/IHub"
import { Dispatch } from "../../utility/common/storeHelper"
import { logHubError } from "../../utility/common/logError"
import { Store } from "../../store/store"
import { ProjectSettings } from "../../models/projectSettings"
import { updateProjectSettings } from "../../store/projects/actions"

const DIFF_UPDATE = "DiffUpdate"

const HUB_NAME = "ProjectSettingsHub"

export class ProjectSettingsHub {
    private _hub: IHub

    constructor(store: Store) {
        const reduxState = store.getState()
        let useAllTransportSignalR = false

        if (reduxState.config.config.data?.WebConfig.appSettings.useAllTransportSignalR) {
            useAllTransportSignalR = true
        }

        this._hub = new Hub("/project-settings-hub", useAllTransportSignalR)
        this.registerServerEvents(store.dispatch)
    }

    async subscribe(projectId: string) {
        await this._hub.subscribe("Subscribe", projectId)
    }

    async unsubscribe(projectId: string) {
        await this._hub.unsubscribe("Unsubscribe", projectId)
    }

    private registerServerEvents(dispatch: Dispatch) {
        this._hub.registerEvent(DIFF_UPDATE, data => {
            try {
                const ProjectSettingsData = data as ProjectSettings
                updateProjectSettings(dispatch, ProjectSettingsData)
            } catch (e) {
                logHubError(HUB_NAME, DIFF_UPDATE, e)
            }
        })
    }
}
