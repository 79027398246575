import React from "react"
import Skeleton from "../../../Skeleton/Skeleton"
import styles from "./QueuesTaskSidebarLoader.module.scss"

const QueuesTaskSidebarLoader = () => {
    return (
        <div className={styles.queuesTaskSidebarLoader}>
            <Skeleton />
        </div>
    )
}

export default QueuesTaskSidebarLoader
