import { LocalizedText } from "../types/localizedText"

export interface SelectedOperatorStatus {
    Value: OperatorStatusValue
    Description: string
    Title: LocalizedText
    UpdatedAt: number
    StatusSetTime: number
    IsOutgoingAvailable?: boolean
}

export enum OperatorStatusValue {
    Online,
    Offline,
    DoNotRoute
}

export const operatorStatusChangeReasons = {
    userClick: "user_click",
    statusTimerUpdate: "status_timer_update"
}

export type OperatorStatusChange = {
    Value: OperatorStatusValue
    Description: string
    StatusSetTime: number
}

export type OperatorStatusChangeWithReason = {
    Reason: string
} & OperatorStatusChange

export type OperatorStatus = {
    Value: OperatorStatusValue
    Description: string
    Title: LocalizedText
    Color?: string
    StatusSetTime: number
    IsOutgoingAvailable?: boolean
}

export interface GetOperatorStatusesResponse {
    CurrentStatus: SelectedOperatorStatusDto
    Statuses: OperatorStatus[]
}

export type SelectedOperatorStatusDto = {
    Value: OperatorStatusValue
    Description: string
    UpdatedAt: number
    StatusSetTime: number
    IsOutgoingAvailable?: boolean
}
