import React from "react"
import Skeleton from "../../Skeleton/Skeleton"
import styles from "./QueueCardBaseLoader.module.scss"
import AdvancedSkeleton from "../../Skeleton/AdvancedSkeleton"

const QueueCardBaseLoader = () => {
    return (
        <div className={styles.queueCardBaseLoader}>
            <div className={styles.queueCardBaseLoader__header}>
                <Skeleton />
            </div>
            <div className={styles.queueCardBaseLoader__items}>
                <AdvancedSkeleton className={styles.queueCardBaseLoader__item} count={8} />
            </div>
        </div>
    )
}

export default QueueCardBaseLoader
