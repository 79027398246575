import React, { useCallback } from "react"
import AgentFormWrapper from "../../../../AgentFormWrapper/AgentFormWrapper"
import DocLink from "../../../../DocLink/DocLink"
import DebouncedValidatableInput from "../../../../ValidatableInput/DebouncedValidatableInput"
import { nameof } from "../../../../../utility/common/nameof"
import { AgentValues, JSAgentSettingsTypes, JSAgentValues } from "../../../../../models/agent"
import { Form } from "react-bootstrap"
import Slider from "../../../../Slider/Slider"
import FormSection from "../../../../FormHelpers/Section/FormSection"
import { JSAgentFormProps } from "../JSAgentForm"
import InfoIcon from "../../../../InfoIcon/InfoIcon"
import { JSAgentSettingBuilder } from "../JSAgentSettingBuilder/JSAgentSettingBuilder"

const tNamespace = "agent:"
const tTimeNamespace = "common:time."

const FormikJSAgentSettingsForm: React.FC<JSAgentFormProps> = props => {
    const { inProcess, agentType, t, handleSubmit, disabled, values, setFieldValue } = props

    const handleTimeoutChange = (v: number) => {
        setFieldValue(nameof<JSAgentValues>("Timeout"), v, false)
    }

    const renderGeneralSettings = useCallback(() => {
        if (!values.Declaration?.length) return null

        return values.Declaration.map((declaration, index) => {
            if (!Object.values(JSAgentSettingsTypes).includes(declaration.type as JSAgentSettingsTypes)) return null

            if (
                declaration.type === JSAgentSettingsTypes.StringArray ||
                declaration.type === JSAgentSettingsTypes.NumberArray
            ) {
                return <JSAgentSettingBuilder declaration={declaration} index={index} />
            }

            return (
                <FormSection key={index}>
                    <Form.Label>
                        {declaration.name}
                        {declaration.description && (
                            <InfoIcon id="formJSAgentSettingsDescription" content={declaration.description} />
                        )}
                    </Form.Label>
                    <JSAgentSettingBuilder declaration={declaration} index={index} />
                </FormSection>
            )
        })
    }, [values.Declaration])

    return (
        <AgentFormWrapper agentType={agentType} onSubmit={handleSubmit} disabled={disabled} loading={inProcess}>
            <DocLink type={agentType}>{t(`${tNamespace}link.creating-agent-instructions`)}</DocLink>
            <div className="agent-form__section">
                <DebouncedValidatableInput
                    id="formTitle"
                    type="text"
                    name={nameof<AgentValues>("Id")}
                    label={t(`${tNamespace}form.title`)}
                />
                <Form.Group controlId="formAgentTimeout">
                    <Form.Label>{t(`${tNamespace}form.jsagent.timeout`)}</Form.Label>
                    <Slider
                        min={1}
                        max={20}
                        step={1}
                        marks={{ 1: "1", 20: `20 ${t(`${tTimeNamespace}sec`)}` }}
                        onChange={handleTimeoutChange}
                        value={values.Timeout}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>{t(`${tNamespace}form.jsagent.agent-settings`)}</Form.Label>
                    {renderGeneralSettings()}
                </Form.Group>
            </div>
        </AgentFormWrapper>
    )
}

export default FormikJSAgentSettingsForm
