import { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import {
    useListMassMailingReportsMutation,
    useMakeMassMailingReportMutation
} from "../../../../../../api/controllers/massMailing"
import Can from "../../../../../../components/Can/Can"
import LoadingButton from "../../../../../../components/LoadingButton/LoadingButton"
import { ReportsList } from "../../../../../../components/ReportsList/ReportsList"
import SidebarRight from "../../../../../../components/SidebarRight/SidebarRight"
import { ModifyMassMailing } from "../../../../../../permissions"
import { useAppSelector } from "../../../../../../store/hooks"
import { selectCurrentProjectId } from "../../../../../../store/projects/selectors"
import { testId } from "../../../../../../utility/tests/testId"
import { MassMailingReportsListLoader } from "./components/MassMailingReportsListLoader/MassMailingReportsListLoader"
import { usePrevious } from "./../../../../../../utility/knowledgeBase/usePrevious"
import IconButton from "../../../../../../components/IconButton/IconButton"
import { faRedo } from "@fortawesome/pro-light-svg-icons/faRedo"
import styles from "./useMassMailingReports.module.scss"

export interface useMassMailingReportsProps {
    id: string
}

const tNamespace = "reports"
const REPORTS_TOTAL = 1000

export const useMassMailingReports = ({ id }: useMassMailingReportsProps) => {
    const [isOpened, setIsOpened] = useState(false)
    const { t } = useTranslation(tNamespace)
    const projectId = useAppSelector(selectCurrentProjectId)
    const [getReports, { isLoading: isLoadingReports, data }] = useListMassMailingReportsMutation()
    const [makeReport, { isLoading: isLoadingReport }] = useMakeMassMailingReportMutation()
    const previousReports = usePrevious(data)
    const reports = data || previousReports

    const openReportsSidebar = useCallback(() => setIsOpened(true), [])
    const closeReportsSidebar = useCallback(() => setIsOpened(false), [])
    const loadReports = () => getReports({ Count: REPORTS_TOTAL, SendingJobId: id })

    const makeReportHandler = async () => {
        if (projectId) {
            await makeReport({ CustomerId: projectId, SendingJobId: id })
            loadReports()
        }
    }

    useEffect(() => {
        if (id && isOpened) {
            getReports({ Count: REPORTS_TOTAL, SendingJobId: id })
        }
    }, [id, getReports, isOpened])

    const renderSidebar = useCallback(() => {
        return (
            <SidebarRight open={isOpened} onClose={closeReportsSidebar} title={t("many")}>
                <div className={styles.btns}>
                    <Can permission={ModifyMassMailing}>
                        <LoadingButton
                            loading={isLoadingReport}
                            onClick={makeReportHandler}
                            testId={testId.makeNewMassMailingReport}
                            disabled={isLoadingReports}
                            className={styles.btn}
                        >
                            {t("make")}
                        </LoadingButton>
                    </Can>
                    <IconButton
                        disabled={isLoadingReports}
                        icon={faRedo}
                        onClick={loadReports}
                        variant="outline-primary"
                        testId={testId.reloadMassMailingItems}
                        className={styles.btn}
                    >
                        {t("reload")}
                    </IconButton>
                </div>
                {isLoadingReports && <MassMailingReportsListLoader />}
                {reports ? <ReportsList reports={reports.ReadyReports} /> : null}
            </SidebarRight>
        )
    }, [isOpened, isLoadingReports, isLoadingReport, reports, makeReportHandler])

    return {
        isOpened,
        openReportsSidebar,
        closeReportsSidebar,
        renderSidebar
    }
}
