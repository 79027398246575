import React from "react"
import { QueueName } from "../../models/queue"
import { ClassProps } from "../../utility/common/props"
import CloseButton from "../CloseButton/CloseButton"
import cn from "classnames"
import "./OperatorQueueItem.scss"

interface Props extends ClassProps {
    queueName: QueueName
    onClose: (id: string) => void
}

const OperatorQueueItem: React.FC<Props> = props => {
    const { className, queueName, onClose } = props

    const handleClose = () => {
        onClose(queueName.Id)
    }

    return (
        <div className={cn("operator-queue-item", className)}>
            <div className="operator-queue-item__name">{queueName.Name}</div>
            <CloseButton className="operator-queue-item__close" onClick={handleClose} />
        </div>
    )
}

export default OperatorQueueItem
