import React, { memo, useCallback, useEffect } from "react"
import AsyncQuery from "../Async/AsyncQuery"
import { useGetQueueQuery } from "../../api/controllers/queuesV2"
import { useAppDispatch, useAppSelector } from "../../store/hooks"
import { selectCurrentProjectId } from "../../store/projects/selectors"
import ErrorMessage from "../ErrorMessage/ErrorMessage"
import { TaskOfAutomaticProcessing } from "../../models/task"
import { Queue } from "../../models/queue"
import { useSelector } from "react-redux"
import { selectGetChannelsNamesState } from "../../store/channels/selectors"
import { queuesV2Hub } from "../../api/instances/queuesV2Hub"
import { subscribeOnQueueV2Data } from "../../store/queues/thunks"
import QueueCardBaseLoader from "../QueueCardBase/QueueCardBaseLoader/QueueCardBaseLoader"
import QueueCard from "../QueueCard/QueueCard"
import QueueForAutomaticProcessing from "../QueueForAutomaticProcessing/QueueForAutomaticProcessing"
import { selectMonitoringOverviewDisplayed } from "../../store/queues/selectors"
import { AutomaticProcessingCategoryId } from "../../utility/queues/queueCategoriesTree"
import QueuesListLoader from "../QueuesList/QueuesListLoader"
import Async from "../Async/Async"
import { selectActiveOperatorsByQueue } from "../../store/operators/selectors"
import { actions as operatorsActions } from "../../store/operators/slice"
import { actions as queuesActions } from "../../store/queues/slice"
import { skipToken } from "@reduxjs/toolkit/query"

export type QueueContainerProps = {
    id: string
    onSettingsClick: (queue: Queue) => void
    onTaskOfAutomaticProcessingClick: (task: TaskOfAutomaticProcessing) => void
    isHubConnected: boolean
}

const QueueContainer = (props: QueueContainerProps) => {
    const { id: queueId, onSettingsClick, onTaskOfAutomaticProcessingClick, isHubConnected } = props
    const projectId = useAppSelector(selectCurrentProjectId) ?? ""
    const channelNames = useAppSelector(selectGetChannelsNamesState)
    const isMonitoringOverviewDisplayed = useAppSelector(selectMonitoringOverviewDisplayed)
    const dispatch = useAppDispatch()
    const isAutomaticProcessingQueue = queueId === AutomaticProcessingCategoryId

    useEffect(() => {
        isHubConnected && dispatch(subscribeOnQueueV2Data(projectId, queueId))

        return () => {
            if (isHubConnected) {
                dispatch(operatorsActions.resetOperatorsByQueue())

                queuesV2Hub.unsubscribeFromQueue(projectId, queueId)
                dispatch(queuesActions.closeAllSidebars())
            }
        }
    }, [dispatch, projectId, queueId, isHubConnected])

    const getQueueQuery = useGetQueueQuery(!isAutomaticProcessingQueue ? { projectId, queueId } : skipToken, {
        refetchOnMountOrArgChange: true
    })

    const operatorsByQueue = useSelector(selectActiveOperatorsByQueue)

    const handleGetOperatorsInQueue = useCallback(
        (queue: Queue) => {
            const set = new Set(queue.OperatorsIds)
            return operatorsByQueue?.Operators.filter(o => set.has(o.Id)) || []
        },
        [operatorsByQueue]
    )

    return (
        <>
            {isMonitoringOverviewDisplayed && isAutomaticProcessingQueue ? (
                <Async
                    dataState={channelNames}
                    processView={<QueuesListLoader count={2} />}
                    errorView={({ message }) => <ErrorMessage text={message} />}
                >
                    {({ data }) => (
                        <QueueForAutomaticProcessing
                            channelNames={data}
                            onTaskClick={onTaskOfAutomaticProcessingClick}
                        />
                    )}
                </Async>
            ) : (
                <AsyncQuery
                    query={getQueueQuery}
                    processView={<QueueCardBaseLoader />}
                    errorView={({ message }) => <ErrorMessage text={message} />}
                >
                    {({ data }) => {
                        return (
                            <QueueCard
                                key={queueId}
                                queue={data}
                                channelNames={channelNames.data}
                                queues={[]}
                                onGetOperatorsInQueue={handleGetOperatorsInQueue}
                                onOperatorClick={() => void 0}
                                onSettingsClick={onSettingsClick}
                                selectedTask={null}
                                selectedOperator={null}
                                foundedTasks={undefined}
                                operatorsPagination={operatorsByQueue}
                            />
                        )
                    }}
                </AsyncQuery>
            )}
        </>
    )
}

export default memo(QueueContainer)
