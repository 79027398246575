import React from "react"
import { WithT } from "i18next"
import TimeoutControl from "../../../../ProjectSettings/TimeoutControl"
import InfoIcon from "../../../../InfoIcon/InfoIcon"
import styles from "./QueueAutoHolding.module.scss"
import DebouncedCheckBox from "../../../../CheckBoxValidatableInput/DebouncedCheckBox"
import CreatableGroupedSelect from "../../../../CreatableGroupedSelect/CreatableGroupedSelect"

export interface QueueAutoHoldingProps extends WithT {
    settingTemplate: string
}

const tNamespace = "queues:settings."

const QueueAutoHolding: React.FC<QueueAutoHoldingProps> = props => {
    const { settingTemplate, t } = props

    return (
        <>
            <DebouncedCheckBox
                id="formQueueAutoHoldingEnabled"
                name={`${settingTemplate}.enabled`}
                label={t(`${tNamespace}autoholding-multiple-sending-enabled`)}
                className={styles.queueAutoHolding__checkbox}
            />
            <CreatableGroupedSelect
                name={`${settingTemplate}.triggerMessages`}
                label={t(`${tNamespace}autoholding-trigger-messages`)}
                tooltip={t(`${tNamespace}autoholding-trigger-messages-tooltip`)}
                id="formQueueAutoHoldingTriggerMessages"
            />
            <TimeoutControl
                id="queueAutoHoldingTimeout"
                label={t(`${tNamespace}autoholding-timeout`)}
                tooltip={t(`${tNamespace}autoholding-timoeout-tooltip`)}
                name={`${settingTemplate}.timeout`}
                daysHidden
            />
            <CreatableGroupedSelect
                name={`${settingTemplate}.messagesToSend`}
                label={t(`${tNamespace}autoholding-messages-to-send`)}
                tooltip={t(`${tNamespace}autoholding-messages-to-send-tooltip`)}
                id="formQueueAutoHoldingMessagesToSend"
            />
            <DebouncedCheckBox
                id="formQueueAutoHoldingMultipleSending"
                name={`${settingTemplate}.multipleSending`}
                label={t(`${tNamespace}autoholding-multiple-sending`)}
                icon={
                    <InfoIcon
                        id="formQueueAutoHoldingMultipleSendingInfo"
                        content={t(`${tNamespace}autoholding-multiple-sending-tooltip`)}
                    />
                }
                className={styles.queueAutoHolding__checkbox}
            />
        </>
    )
}

export default QueueAutoHolding
