import React from "react"
import SettingsGroup from "../../../../SettingsGroup/SettingsGroup"
import { useTranslation } from "react-i18next"
import { Settings } from "../../../../../types/settings"
import { testId } from "../../../../../utility/tests/testId"
import { selectCatalogsMap } from "../../../../../store/knowledgeBase/selectors"
import { ItemId } from "@atlaskit/tree/types"
import { useAppSelector } from "../../../../../store/hooks"

const tNamespace = "knowledgeBase"

export interface AllowCatalogsProps {
    templateName: string
    values: Settings<ItemId>
}

const QueueAllowCatalogs: React.FC<AllowCatalogsProps> = props => {
    const { templateName, values } = props
    const { t } = useTranslation(tNamespace)
    const catalogsMap = useAppSelector(selectCatalogsMap)

    return (
        <div data-testid={testId.queueAllowCatalogsSettings}>
            <SettingsGroup<ItemId>
                name={templateName}
                settingsValues={values}
                getLabel={symbolCode => {
                    const tree = catalogsMap[symbolCode]
                    const item = tree.items[tree.rootId]

                    return item.data.title ?? t(`untitled`)
                }}
                withoutAccordion
            />
        </div>
    )
}

export default QueueAllowCatalogs
