import React, { DragEventHandler, PropsWithChildren, useState } from "react"
import { useTranslation } from "react-i18next"
import styles from "./DroppableArea.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCloudUpload } from "@fortawesome/pro-light-svg-icons"
import cn from "classnames"
import DroppableAreaContext from "./DroppableAreaContext"
import { UploadedFile, uploadFiles, DragEvent } from "../../utility/common/files"
import { testId } from "../../utility/tests/testId"
import { saveNotificationError } from "../../store/notifications/thunks"
import { useAppDispatch } from "../../store/hooks"
import { createSystemError } from "../../core/error"
import { errorTranslation } from "../../locales/error"

const tNamespace = "common:files."
const IMPORT_FILE_FAILED = "error:knowledgeBase:generating-file-failed"

export interface DroppableAreaProps {
    onDrop?: DragEventHandler<HTMLDivElement>
    className?: string
    contentClassName?: string
    dropAreaClassName?: string
    isHidePreview?: boolean
    acceptFilesFormat?: string[]
    isUploadFile?: boolean
}

const DroppableArea: React.FC<PropsWithChildren<DroppableAreaProps>> = props => {
    const {
        children,
        onDrop,
        className,
        contentClassName,
        dropAreaClassName,
        isHidePreview = false,
        acceptFilesFormat,
        isUploadFile = true
    } = props

    const { t } = useTranslation()
    const dispatch = useAppDispatch()

    const [isDraggingFiles, setIsDraggingFiles] = useState(false)
    const [isDropped, setIsDropped] = useState(false)
    const [files, setFiles] = useState<UploadedFile[] | File[]>([])

    const handleDrop = async (e: React.DragEvent<HTMLDivElement>) => {
        if (e.dataTransfer === null || e.dataTransfer.files === null || e.dataTransfer.files.length === 0) {
            return
        }
        setIsDropped(true)

        const uploadingFiles = []
        const files = e.dataTransfer.files

        for (let i = 0; i < files.length; i++) {
            const file = files[i]

            if (!acceptFilesFormat) {
                uploadingFiles.push(file)
                continue
            }

            const fileName = file.name
            const fileExtension = fileName.substring(fileName.lastIndexOf(".")).toLowerCase()
            const isFileAllowed = acceptFilesFormat.includes(fileExtension)

            if (!isFileAllowed) {
                const error = createSystemError(IMPORT_FILE_FAILED, {
                    reason: "File format is not allowed",
                    reasonKey: errorTranslation.invalidFileFormat
                })

                saveNotificationError(dispatch, error)
            } else {
                uploadingFiles.push(file)
            }
        }

        if (isUploadFile) {
            const newFiles = await uploadFiles(uploadingFiles)
            setFiles(newFiles)
        } else {
            setFiles(uploadingFiles)
        }

        onDrop && onDrop(e)
        setIsDropped(false)
    }

    const handleDragEvent: DragEventHandler<HTMLDivElement> = async (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault()
        e.stopPropagation()

        if (e.dataTransfer.types.includes("text/plain")) {
            return
        }

        switch (e.type) {
            case DragEvent.DragEnter || DragEvent.DragOver:
                setIsDraggingFiles(true)
                break
            case DragEvent.DragLeave:
                setIsDraggingFiles(false)
                break
            case DragEvent.Drop:
                setIsDraggingFiles(false)
                await handleDrop(e)
                break
        }
    }

    return (
        <DroppableAreaContext.Provider
            value={{
                isDropped: isDropped,
                files: files,
                setFiles: setFiles
            }}
        >
            <div
                className={cn(styles.draggingArea, className)}
                onDragEnter={handleDragEvent}
                data-testid={testId.draggingArea}
            >
                <div className={contentClassName}>{children}</div>
                {isDraggingFiles && (
                    <>
                        {!isHidePreview && (
                            <div
                                className={cn(styles.draggingArea__dropPlaceContent, dropAreaClassName)}
                                data-testid={testId.dropPlaceContent}
                            >
                                <FontAwesomeIcon
                                    icon={faCloudUpload}
                                    size="3x"
                                    className={styles.draggingArea__dropPlaceContent__icon}
                                />
                                <div className={styles.draggingArea__dropPlaceContent__text}>
                                    {t(`${tNamespace}drag-files-for-upload`)}
                                </div>
                            </div>
                        )}
                        <div
                            className={styles.draggingArea__dropPlace}
                            onDragOver={handleDragEvent}
                            onDragLeave={handleDragEvent}
                            onDrop={handleDragEvent}
                            data-testid={testId.dropPlace}
                        />
                    </>
                )}
            </div>
        </DroppableAreaContext.Provider>
    )
}

export default DroppableArea
