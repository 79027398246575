import { MassMailingEventData } from "../../models/massMailing"
import { massMailingActions } from "../../store/massMailing/slice"
import { Store } from "../../store/store"
import { logHubError } from "../../utility/common/logError"
import { Dispatch } from "../../utility/common/storeHelper"
import { Hub } from "../hub"
import { IHub } from "../interfaces/IHub"

const HUB_NAME = "MassMailingHub"
const SEND_EVENT = "SendEvent"

export class MassMailingHub {
    private _hub: IHub

    constructor(store: Store) {
        const reduxState = store.getState()
        const useAllTransportSignalR =
            reduxState.config.config.data?.WebConfig.appSettings.useAllTransportSignalR === true

        this._hub = new Hub(`/mass-sending-hub`, useAllTransportSignalR)

        this.registerServerEvents(store.dispatch)
    }

    async subscribe(projectId: string) {
        await this._hub.subscribe("Subscribe", projectId)
    }

    async unsubscribe(projectId: string) {
        await this._hub.unsubscribe("Unsubscribe", projectId)
    }

    private registerServerEvents(dispatch: Dispatch) {
        this._hub.registerEvent(SEND_EVENT, data => {
            try {
                const { MassSendingJob } = data as MassMailingEventData
                dispatch(massMailingActions.setMassMailings([MassSendingJob]))
            } catch (e) {
                logHubError(HUB_NAME, SEND_EVENT, e)
            }
        })
    }
}
