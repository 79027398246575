import React from "react"
import { TFunction } from "i18next"
import PostScoreArticleSelect from "../../../../PostScoreArticleSelect/PostScoreArticleSelect"
import { useProjectSettingCheck } from "../../../../../utility/common/useProjectSettingCheck"
import { DialogFinishProjectSettingsValues } from "../../../../../models/projectSettingsValues"

export interface QueueDialogFinishProps {
    fieldName: string
    t: TFunction
}

const tNamespace = "queues:settings."

const QueueDialogFinish: React.FC<QueueDialogFinishProps> = props => {
    const { fieldName, t } = props

    const articleFromProjectSettings = useProjectSettingCheck<DialogFinishProjectSettingsValues, "FinishDialogArticle">(
        "FinishDialogArticle"
    )

    return (
        <PostScoreArticleSelect
            label={t(`${tNamespace}finish-dialog-article`)}
            name={fieldName}
            defaultValue={articleFromProjectSettings}
            allowNoSelectedOption
        />
    )
}

export default QueueDialogFinish
