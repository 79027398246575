import React from "react"
import "./SampleQuestion.scss"
import { faTrash } from "@fortawesome/pro-light-svg-icons/faTrash"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ClassProps } from "../../../utility/common/props"
import cn from "classnames"
import { ComparisonType } from "../../../models/articleCompare"

export interface SampleQuestionProps extends ClassProps {
    content: string
    onSelect?: () => void
    onDelete?: () => void
    selected?: boolean
    isDeletable?: boolean
    comparisonType?: ComparisonType
}

const SampleQuestion: React.FC<SampleQuestionProps> = props => {
    const { content, onSelect, onDelete, className, isDeletable, selected = false, comparisonType } = props

    const handleDelete = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
        e.stopPropagation()
        onDelete && onDelete()
    }
    return (
        <div className={cn("sample-question", selected && "sample-question_selected", className)} onClick={onSelect}>
            <div className={cn("sample-question__content", `sample-question__${comparisonType}`)}>{content}</div>
            {isDeletable && (
                <div className="sample-question__controls">
                    <div className="sample-question__control">
                        <FontAwesomeIcon icon={faTrash} onClick={handleDelete} size="lg" />
                    </div>
                </div>
            )}
        </div>
    )
}

export default SampleQuestion
