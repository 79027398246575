import React, { useEffect, useState } from "react"
import Frame from "../Frame/Frame"
import styles from "./CustomMenu.module.scss"
import { useRouteMatch } from "react-router-dom"
import { useAppSelector } from "../../store/hooks"
import { testId } from "../../utility/tests/testId"
import { formatIntegrationUrl } from "../../utility/common/formatIntegrationUrl"
import { selectCurrentUser } from "../../store/users/selectors"

export interface CustomMenuProps {
    url: string
    frameName: string
    menuId: string
    projectId: string
}

const CustomMenu: React.FC<CustomMenuProps> = props => {
    const { url, frameName, projectId, menuId } = props
    const projectUrl = `/project/${projectId}/${menuId}`
    const match = useRouteMatch(projectUrl)
    const [isLoaded, setIsLoaded] = useState(false)
    const isMounted = match ? match.isExact : false
    const user = useAppSelector(selectCurrentUser)

    useEffect(() => {
        if (!isLoaded && isMounted) {
            setIsLoaded(true)
        }
    }, [isLoaded, isMounted])

    const formattedUrl = formatIntegrationUrl(url, menuId, undefined, user, projectId ?? "", true)

    return (
        <div data-testid={`${testId.customMenu}-${menuId}`}>
            {isLoaded ? (
                <Frame isMounted={isMounted} src={formattedUrl} name={frameName} frameClassName={styles.customMenu} />
            ) : null}
        </div>
    )
}

export default CustomMenu
