export enum SettingsType {
    general = "general",
    channels = "channels",
    channelScenario = "channel-scenario",
    agents = "agents",
    classifiers = "classifiers",
    users = "users",
    roles = "roles",
    slots = "slots",
    stickers = "stickers",
    articleTypes = "article-types",
    surveys = "surveys",
    dialog = "dialog",
    operator = "operator",
    statistics = "statistics",
    system = "system"
}
