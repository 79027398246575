import React, { useCallback, useMemo } from "react"
import styles from "./CreatableTagSelect.module.scss"
import { Form } from "react-bootstrap"
import { CreatableSelect } from "../Select/Select"
import Tag from "../Tag/Tag"
import { FieldArray, useField } from "formik"
import { WithT } from "i18next"
import { formTranslation } from "../../locales/form"
import cn from "classnames"
import { components, GroupedOptionsType, ValueType } from "react-select"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleDown } from "@fortawesome/pro-light-svg-icons"
import { FormikCustomErrorMsg } from "../../helpers/formik"

export type TCreatableSelectProps = React.ComponentPropsWithRef<typeof CreatableSelect>

export interface CreatableGroupedTagSelectProps extends WithT {
    id: string
    name: string
    label?: string
    disabled?: boolean
    placeholder: string
    placeholderOnAnySelected?: string
    nonCreatable?: boolean
    disableOnAllSelected?: boolean
    TagProps?: React.HTMLAttributes<HTMLDivElement>
    options?: GroupedOptionsType<{
        label: string
        value: string
    }>
    asSelector?: boolean
    className?: string
    disabledInlineErrors?: boolean
    selectedTagLabelShow?: boolean
    menuIsOpen?: boolean
}

export type TOptionItem = ValueType<
    {
        label: string
        value: string
    },
    false
>

const CreatableGroupedTagSelect: React.FC<CreatableGroupedTagSelectProps> = props => {
    const {
        id,
        name,
        label,
        placeholder,
        placeholderOnAnySelected,
        disableOnAllSelected,
        options = [],
        nonCreatable,
        TagProps,
        asSelector,
        disabled,
        className,
        disabledInlineErrors,
        selectedTagLabelShow,
        t,
        menuIsOpen
    } = props

    const [field, meta, { setValue, setError }] = useField(name)
    const hasOptions = Boolean(options.length)
    const defaultCSelectProps: TCreatableSelectProps = []

    const handleHeaderClick = (id: string) => {
        const el = document.querySelector(`#${id}`)
        const node = el?.parentElement?.nextElementSibling

        if (!node) {
            return
        }

        const classes = node.classList
        const collapsedCls = styles.creatableTagSelect__options_collapsed

        if (classes.contains(collapsedCls)) {
            node.classList.remove(collapsedCls)
        } else {
            node.classList.add(collapsedCls)
        }
    }

    const CustomGroupHeading = (props: React.ComponentPropsWithRef<typeof components.GroupHeading>) => {
        return (
            <div className={styles.creatableTagSelect__groupHeading} onClick={() => handleHeaderClick(props.id)}>
                <FontAwesomeIcon icon={faAngleDown} />
                <components.GroupHeading {...props} />
            </div>
        )
    }

    const handleOnChange = useCallback(
        (option: TOptionItem, pushFn: (obj: unknown) => void) => {
            if (!option) {
                return
            }

            if (hasOptions && field.value.includes(option.value)) {
                return
            }

            pushFn(option.value)
        },
        [field, hasOptions]
    )

    const handleAsSelectOnChange = useCallback(
        (option: TOptionItem) => {
            setValue(option?.value)

            if (meta.error) {
                setError(undefined)
            }
        },
        [setValue, setError, meta]
    )

    const filteredOptions = useMemo(() => {
        if (asSelector) {
            return options
        }

        if (hasOptions) {
            const newOptions = []

            for (const x of options) {
                newOptions.push({
                    ...x,
                    options: x.options.filter(op => !field.value.includes(op.value))
                })
            }

            return newOptions
        }

        return options
    }, [options, field, hasOptions, asSelector])

    let defaultValue = { label: placeholder, value: "" }

    if (placeholderOnAnySelected && field.value.length) {
        defaultValue = { label: placeholderOnAnySelected, value: "" }
    }

    if (nonCreatable || asSelector) {
        defaultCSelectProps["isValidNewOption"] = () => false
    }

    if (disableOnAllSelected) {
        defaultCSelectProps["isDisabled"] = filteredOptions.every(x => !x.options.length)
    }

    if (disabled) {
        defaultCSelectProps["isDisabled"] = true
    }

    const formatGroupLabel = (
        data: GroupedOptionsType<{
            label: string
            value: string
            extra?: { noGroup?: boolean }
        }>[0]
    ) =>
        data.extra?.noGroup ? null : <div className={styles.creatableTagSelect__groupHeading__label}>{data.label}</div>

    const formatOptionLabel = (data: { label: string; value: string; extra?: { noGroup?: boolean } }) => {
        return (
            <div
                className={cn(
                    styles.creatableTagSelect__option,
                    data.extra?.noGroup && styles.creatableTagSelect__option_noGroup
                )}
            >
                {data.label}
            </div>
        )
    }

    if (asSelector) {
        return (
            <Form.Group controlId={id} className={cn(className, styles.creatableTagSelect)}>
                {label && <Form.Label>{label}</Form.Label>}
                <CreatableSelect
                    {...defaultCSelectProps}
                    components={{ GroupHeading: CustomGroupHeading }}
                    className={cn(
                        styles.creatableTagSelect__tagInput,
                        hasOptions && styles.creatableTagSelect__tagInput_withArrow
                    )}
                    onChange={handleAsSelectOnChange}
                    placeholder={placeholder}
                    options={filteredOptions}
                    noOptionsMessage={() => null}
                    formatGroupLabel={formatGroupLabel}
                    menuIsOpen={menuIsOpen}
                    formatOptionLabel={formatOptionLabel}
                />
                {!disabledInlineErrors && (
                    <FormikCustomErrorMsg name={name} className={styles.creatableTagSelect__error} />
                )}
            </Form.Group>
        )
    }

    return (
        <FieldArray
            name={name}
            render={({ push, remove }) => (
                <>
                    <Form.Group controlId={id} className={cn(className, styles.creatableTagSelect)}>
                        {label && <Form.Label>{label}</Form.Label>}
                        <CreatableSelect
                            {...defaultCSelectProps}
                            components={{ GroupHeading: CustomGroupHeading }}
                            className={cn(
                                styles.creatableTagSelect__tagInput,
                                hasOptions && styles.creatableTagSelect__tagInput_withArrow
                            )}
                            value={defaultValue}
                            options={filteredOptions}
                            noOptionsMessage={() => null}
                            onChange={option => handleOnChange(option, push)}
                            formatCreateLabel={(inputValue: string) => `${t(formTranslation.add)}: ${inputValue}`}
                            formatGroupLabel={formatGroupLabel}
                            //menuIsOpen
                        />
                        {!asSelector && Boolean(field.value.length) && (
                            <div className={styles.creatableTagSelect__tags}>
                                {field.value.map((value: string, index: number) => {
                                    let tagTitle = value

                                    if (selectedTagLabelShow) {
                                        for (const suboptionsGroup of options) {
                                            const optionLabelByValue = suboptionsGroup.options.find(
                                                x => x.value === value
                                            )

                                            if (optionLabelByValue) {
                                                tagTitle = optionLabelByValue.label
                                                break
                                            }
                                        }
                                    }

                                    return (
                                        <Tag
                                            {...TagProps}
                                            className={cn(styles.creatableTagSelect__tag, TagProps?.className)}
                                            key={`${value}-${index}`}
                                            index={index}
                                            title={tagTitle}
                                            onDelete={() => remove(index)}
                                        />
                                    )
                                })}
                            </div>
                        )}
                    </Form.Group>
                    {!disabledInlineErrors && (
                        <FormikCustomErrorMsg name={name} className={styles.creatableTagSelect__error} />
                    )}
                </>
            )}
        />
    )
}

export default CreatableGroupedTagSelect
