import React, { useEffect } from "react"
import styles from "./WizardView.module.scss"
import WizardStage from "../WizardStage/WizardStage"
import { goBack, push } from "connected-react-router"
import { actions } from "../../store/projects/slice"
import { createProjectCopy, getProjectCreatingStatus, selectProject } from "../../store/projects/thunks"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import {
    selectCreatedProjectCopy,
    selectProjectCopyWizardFormValues,
    selectProjectCopyWizardStage
} from "../../store/projects/selectors"
import { ProjectCopyWizardFormValues } from "../../models/projectWizard"
import { nameof } from "../../utility/common/nameof"
import { Col, Row } from "react-bootstrap"
import * as Yup from "yup"
import WizardCompleteImg from "../../assets/images/crafttalk/ctWizardComplete.png"

import ValidatableInput from "../ValidatableInput/ValidatableInput"
import { CreateProjectFromCopyRequest } from "../../models/project"
import { projectPath } from "../../routerPaths"
import projectsController from "../../api/controllers/projects"
import { debounceAsync } from "../../utility/common/debounceAsync"
import { Dispatch } from "../../utility/common/storeHelper"
import { projectIdRegex } from "../../utility/common/projectIdRegex"
import WizardProjectUpload from "../WizardProjectUpload/WizardProjectUpload"
import CopyWizardForm from "../CopyWizardForm/CopyWizardForm"
import { definedMenuItems } from "../../utility/menu/definedMenuItems"
import Spinner from "../Spinner/Spinner"
import { changeProject } from "../../store/users/thunks"

const tNamespace = "wizard:"

const createRequest = (data: ProjectCopyWizardFormValues): CreateProjectFromCopyRequest => {
    return {
        ProjectName: data.ProjectName,
        ProjectId: data.ProjectId,
        ArchiveName: data.ArchiveName
    }
}

const getDefaultValues = (): ProjectCopyWizardFormValues => {
    return {
        ProjectName: "",
        ProjectId: "",
        ArchiveName: ""
    }
}

const CopyWizardView: React.FC = () => {
    const { t } = useTranslation()
    const stage = useSelector(selectProjectCopyWizardStage)
    const wizardFormValues = useSelector(selectProjectCopyWizardFormValues)
    const createdProject = useSelector(selectCreatedProjectCopy)
    const dispatch = useDispatch<Dispatch>()

    useEffect(() => {
        dispatch(getProjectCreatingStatus())
    }, [wizardFormValues, dispatch])

    const validateProjectNotExists = debounceAsync(async (id: string) => {
        const exists = await projectsController.exists(id)
        return !exists
    }, 500)

    return (
        <div className={styles.wizardView}>
            <Row className={styles.wizardView__body}>
                <Col sm={12} md={8} lg={6} xl={3} className={styles.wizardView__formContainer}>
                    <CopyWizardForm
                        initialValues={wizardFormValues ? wizardFormValues : getDefaultValues()}
                        stage={stage}
                        onSubmit={values => dispatch(actions.nextCopyWizardStage({ ...values }))}
                        onComplete={values => dispatch(createProjectCopy(createRequest(values)))}
                    >
                        <WizardStage
                            title={t(`${tNamespace}project-name.title`)}
                            onBack={() => dispatch(goBack())}
                            validationSchema={Yup.object().shape({
                                ProjectName: Yup.string().requiredExcludeEmpty(
                                    t(`${tNamespace}project-name.validation-failed`)
                                )
                            })}
                        >
                            <ValidatableInput
                                id="projectName"
                                type="text"
                                name={nameof<ProjectCopyWizardFormValues>("ProjectName")}
                                placeholder={t(`${tNamespace}project-name.placeholder`)}
                            />
                        </WizardStage>
                        <WizardStage
                            title={t(`${tNamespace}project-id.title`)}
                            onBack={() => dispatch(actions.prevCopyWizardStage())}
                            validationSchema={Yup.object().shape({
                                ProjectId: Yup.string().test(
                                    "projectId",
                                    t(`${tNamespace}project-id.validation-exists`),
                                    async value => {
                                        await Yup.object()
                                            .shape({
                                                ProjectId: Yup.string()
                                                    .requiredExcludeEmpty(
                                                        t(`${tNamespace}project-id.validation-required`)
                                                    )
                                                    .min(2, t(`${tNamespace}project-id.validation-min-2`))
                                                    .matches(projectIdRegex, {
                                                        message: t(`${tNamespace}project-id.validation-invalid`),
                                                        excludeEmptyString: true
                                                    })
                                            })
                                            .validate({ ProjectId: value })

                                        return value ? await validateProjectNotExists(value) : false
                                    }
                                )
                            })}
                        >
                            <ValidatableInput
                                id="projectId"
                                type="text"
                                name={nameof<ProjectCopyWizardFormValues>("ProjectId")}
                                placeholder={t(`${tNamespace}project-id.placeholder`)}
                            />
                        </WizardStage>
                        <WizardStage
                            isComplete
                            title={t(`${tNamespace}workspace-upload.title`)}
                            description={t(`${tNamespace}workspace-upload.description`)}
                            onBack={() => dispatch(actions.prevCopyWizardStage())}
                            validationSchema={Yup.object().shape({
                                ArchiveName: Yup.string().requiredExcludeEmpty(
                                    t(`${tNamespace}workspace-upload.validation-failed`)
                                )
                            })}
                        >
                            <WizardProjectUpload />
                        </WizardStage>
                        <WizardStage
                            hideActions
                            title={t(`${tNamespace}workspace-process.title`)}
                            description={t(`${tNamespace}workspace-process.description`)}
                            onBack={() => dispatch(actions.prevCopyWizardStage())}
                            validationSchema={Yup.object().shape({
                                ArchiveName: Yup.string().requiredExcludeEmpty(
                                    t(`${tNamespace}workspace-upload.validation-failed`)
                                )
                            })}
                        >
                            <Spinner size={80} className={styles.wizardView__processSpinner} />
                        </WizardStage>
                        <WizardStage
                            title={t(`${tNamespace}complete.title`)}
                            description={t(`${tNamespace}complete.description`)}
                            nextButtonText={t(`${tNamespace}complete.next`)}
                            onNext={async () => {
                                if (createdProject) {
                                    await dispatch(changeProject(createdProject))
                                    await dispatch(selectProject(createdProject))
                                }
                                dispatch(push(`${projectPath}/${createdProject}/${definedMenuItems.Settings.id}`))
                                dispatch(actions.resetCopyWizard())
                            }}
                        >
                            <img src={WizardCompleteImg} alt="" className={styles.wizardView__img} />
                        </WizardStage>
                    </CopyWizardForm>
                </Col>
            </Row>
        </div>
    )
}

export default CopyWizardView
