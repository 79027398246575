import { NewslineItem } from "../../../models/knowledgeBase/newsline"
import { newslineApi } from "./newsline"

export const removeNewslineItem = (itemId: string, projectId: string) => {
    return newslineApi.util.updateQueryData("getNewsline", projectId, (oldState: NewslineItem[]) => {
        return oldState.filter(oldItem => oldItem.Id !== itemId)
    })
}

export const fetchNewsline = (projectId: string) => {
    return newslineApi.endpoints.getNewsline.initiate(projectId, {
        forceRefetch: true
    })
}
