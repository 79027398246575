import React from "react"
import styles from "./DialogEmailMessageText.module.scss"
import cn from "classnames"
import { useTranslation } from "react-i18next"
import { STARTER_MESSAGE } from "../../../../../../utility/dialogs/constant"

export const tEmailNamespace = "dialogs:messages.email"
const tNamespace = "dialogs:"

export type DialogEmailMessageTextProps = {
    messageMeta: Record<string, string>
    messageText: string
}

const blockQuotesRegex = /<blockquote[\w\W]*<\/blockquote>/gm

const DialogEmailMessageText = (props: DialogEmailMessageTextProps) => {
    const { messageMeta, messageText } = props
    const { t } = useTranslation()

    if (STARTER_MESSAGE.test(messageText)) {
        return <span>{t(`${tNamespace}list.starter-message`)}</span>
    }

    const emailMessageWithoutQuotes = messageText.replace(blockQuotesRegex, "")

    return (
        <div>
            <span className={styles.messageBasic__text__email__subject}>
                {t(`${tEmailNamespace}.subject`)}
                {messageMeta["email_subject"]}
            </span>
            <div
                className={cn(styles.messageBasic__text, styles.messageBasic__text__email)}
                dangerouslySetInnerHTML={{
                    __html: emailMessageWithoutQuotes
                }}
            ></div>
        </div>
    )
}

export default DialogEmailMessageText
