import { removeLastSlash } from "./urlHelper"
import { invitePath } from "../../routerPaths"
import { definedMenuItems } from "../menu/definedMenuItems"
import { SettingsType } from "../../pages/ProjectSettings/models"

export enum FormType {
    Add = "add",
    Edit = "edit"
}

export interface FormPathParams {
    formId?: string
    param?: string
}

export const getAddPath = (param = "") => removeLastSlash(`${FormType.Add}/${param}`)

export const buildInvitePath = () => `${definedMenuItems.Settings.id}/${SettingsType.users}${invitePath}`

export const buildAddChannelPath = (param?: string) =>
    `${definedMenuItems.Settings.id}/${SettingsType.channels}/${getAddPath(param)}`

export const getEditPath = (param = "") => removeLastSlash(`${FormType.Edit}/${param}`)

export const buildEditChannelPath = (param?: string) =>
    `${definedMenuItems.Settings.id}/${SettingsType.channels}/${getEditPath(param)}`
