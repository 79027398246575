import React, { ReactNode } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleDown } from "@fortawesome/pro-light-svg-icons/faAngleDown"
import SidebarContextMenu from "../SidebarContextMenu/SidebarContextMenu"
import { ClassProps } from "../../utility/common/props"
import styles from "./SidebarContextMenuSelect.module.scss"

interface Props extends ClassProps {
    items: ReactNode
    selected: ReactNode | string
    containerClassName?: string
}

const SidebarContextMenuSelect: React.FC<Props> = props => {
    const { items, selected } = props

    return (
        <SidebarContextMenu
            placement="bottom-start"
            containerClassName={styles.select}
            className={styles.select__menu}
            items={items}
        >
            <div className={styles.select__item}>
                <div className={styles.select__selected}>{selected}</div>
                <div className={styles.select__arrow}>
                    <FontAwesomeIcon icon={faAngleDown} />
                </div>
            </div>
        </SidebarContextMenu>
    )
}

export default SidebarContextMenuSelect
