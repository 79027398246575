import React, { useCallback, useEffect, useState } from "react"
import { Task } from "../../../models/task"
import { CellProps, Column } from "react-table"
import styles from "./QueuesTable.module.scss"
import SettingsTableWithPagination from "../../SettingsTable/SettingsTableWithPagination/SettingsTableWithPagination"
import UserCard from "../../UserCard/UserCard"
import { formatDistanceToNowLocal } from "../../../utility/common/time"
import cn from "classnames"
import ChannelIcon from "../../ChannelIcon/ChannelIcon"
import { mapChannelTypeToClassName } from "../../../utility/channels/channel"
import { channelTypeStringConverter } from "../../../utility/channels/channelTypeStringConverter"
import { useTranslation } from "react-i18next"
import { tableTranslation } from "../../../locales/table"
import { Queue, TaskPaginationRequest, TaskPaginationResponse } from "../../../models/queue"
import { queuesV2ApiController } from "../../../api/controllers/queuesV2"
import { useAppDispatch, useAppSelector } from "../../../store/hooks"
import SearchInput from "../../SearchInput/SearchInput"
import { QUEUES_TASKS_PER_PAGE } from "../../../models/pagination"
import { Operator } from "../../../models/operator"
import { selectActiveOperatorsByQueue } from "../../../store/operators/selectors"
import { testId } from "../../../utility/tests/testId"

type QueuesTasksTableProps = {
    handleAssignedTaskView?: (task: Task, operator?: Operator) => void
    handlePendingTaskView?: (task: Task, queue: Queue) => void
    tasksPagination?: TaskPaginationResponse<Task>
    queueId: string
    projectId: string
    queryTriggerCallback: (args: TaskPaginationRequest) => void
}

const CHANNEL_TYPE_PREFIX = "task-info__channel-"
const tTaskNamespace = "queues:task."

const QueuesTasksTable = (props: QueuesTasksTableProps) => {
    const { tasksPagination, handleAssignedTaskView, handlePendingTaskView, queueId, projectId, queryTriggerCallback } =
        props
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const operators = useAppSelector(selectActiveOperatorsByQueue)
    const currentQueue = useAppSelector(queuesV2ApiController.endpoints.getQueue.select({ projectId, queueId }))

    const [currentPageIndex, setCurrentPageIndex] = useState(0)
    const [searchValue, setSearchValue] = useState("")
    const handlePageChange = useCallback((pageIndex: number, searchValue = "") => {
        setCurrentPageIndex(pageIndex)
        setSearchValue(searchValue)
    }, [])

    useEffect(() => {
        queryTriggerCallback({
            queueId: queueId,
            projectId: projectId,
            paginationParams: {
                PageFrom: currentPageIndex,
                PageSize: QUEUES_TASKS_PER_PAGE,
                ...(searchValue && { Search: searchValue })
            }
        })
    }, [searchValue, currentPageIndex])

    const handleRowClick = async (task: Task) => {
        const currentTaskOperator = operators.Operators.find(operator => operator.Id === task.PreferredOperatorId)

        if (handleAssignedTaskView) {
            handleAssignedTaskView(task, currentTaskOperator)

            return
        }

        if (handlePendingTaskView && currentQueue.data) {
            handlePendingTaskView(task, currentQueue.data)
        }
    }

    const columns: Array<Column<Task>> = [
        {
            Header: t(tableTranslation.fullClientName),
            id: "Client",
            width: "200px",
            accessor: original =>
                `${original.Client.FirstName} ${original.Client.LastName} ${original.Client.MiddleName} ${original.Client.LastName}`,
            Cell: ({ row: { original } }: CellProps<Task>) => (
                <div className={styles.queuePendingTasksTable__userCell}>
                    <UserCard
                        firstName={original.Client.FirstName}
                        lastName={original.Client.LastName}
                        middleName={original.Client.MiddleName}
                        isClient
                        isNewWorkplace
                    />
                    <span className={styles.queuePendingTasksTable__taskPreview}>{original.Preview?.Text}</span>
                </div>
            )
        },
        {
            Header: t(tableTranslation.channel),
            id: "Channel",
            width: "110px",
            accessor: original => original.Channel.Title,
            Cell: ({ row: { original } }: CellProps<Task>) => {
                const channelType = channelTypeStringConverter.toChannelType(original.Channel.Type)
                const taskInfoCardClass = mapChannelTypeToClassName(channelType, CHANNEL_TYPE_PREFIX)

                return (
                    <div className={styles.queuePendingTasksTable__cardContainer}>
                        <div className={cn(styles.queuePendingTasksTable__card, styles[taskInfoCardClass])}>
                            <ChannelIcon className={styles.queuePendingTasksTable__cardNameIcon} type={channelType} />
                            <div className={styles.queuePendingTasksTable__cardName}>{original.Channel.Title}</div>
                        </div>
                    </div>
                )
            }
        },
        {
            Header: t(tableTranslation.duration),
            id: "Duration",
            width: "100px",
            accessor: original =>
                original.AssignedAt
                    ? formatDistanceToNowLocal(original.AssignedAt)
                    : t(`${tTaskNamespace}no-operator-duration`)
        }
    ]

    return (
        <div className={styles.queuePendingTasksTable}>
            <SearchInput
                onChange={searchValue => handlePageChange(0, searchValue)}
                data-testid={testId.queueTasksSearch}
            />
            <SettingsTableWithPagination
                onRowClick={task => handleRowClick(task)}
                columns={columns}
                data={tasksPagination?.Tasks ?? []}
                onPageIndexChange={handlePageChange}
                pageIndex={currentPageIndex}
                pageSize={QUEUES_TASKS_PER_PAGE}
                recordsTotal={tasksPagination?.ItemsTotal ?? 0}
                hideSearch
            />
        </div>
    )
}

export default QueuesTasksTable
