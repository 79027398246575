var prefix = "fal"
var iconName = "folder-bookmark-light"
var width = 18
var height = 15
var ligatures = []
var unicode = ""
var svgPathData =
    "M1.125 2.143v10.714c0 .593.503 1.072 1.125 1.072h13.5c.622 0 1.125-.48 1.125-1.072V4.286c0-.593-.503-1.072-1.125-1.072h-1.125v5.893a.53.53 0 0 1-.267.456.59.59 0 0 1-.549.023l-1.996-.951-1.997.95a.59.59 0 0 1-.549-.023A.53.53 0 0 1 9 9.108V2.846a2.4 2.4 0 0 1-.33-.258L7.407 1.386c-.21-.2-.495-.315-.794-.315H2.25c-.622 0-1.125.48-1.125 1.072M13.5 3.214h-3.238q-.069 0-.137-.003V8.24l1.434-.683a.58.58 0 0 1 .503 0l1.438.683zm.563-1.071h1.687c1.241 0 2.25.96 2.25 2.143v8.571C18 14.04 16.991 15 15.75 15H2.25C1.009 15 0 14.04 0 12.857V2.143C0 .96 1.009 0 2.25 0h4.363c.598 0 1.17.224 1.592.626l1.263 1.202c.21.201.495.315.794.315z"
exports.definition = {
    prefix: prefix,
    iconName: iconName,
    icon: [width, height, ligatures, unicode, svgPathData]
}

exports.faFolderBookmarkLight = exports.definition
exports.prefix = prefix
exports.iconName = iconName
exports.width = width
exports.height = height
exports.ligatures = ligatures
exports.unicode = unicode
exports.svgPathData = svgPathData
