import React, { useCallback, useEffect, useMemo } from "react"
import { Formik, FormikProps } from "formik"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import Tab from "react-bootstrap/Tab"
import { AgentType, JSAgentValues } from "../../../../models/agent"
import { AgentFormProps } from "../../AgentForm"
import { selectCurrentProject } from "../../../../store/projects/selectors"
import {
    selectAgentDeclarations,
    selectCreateAgentState,
    selectUpdateAgentState
} from "../../../../store/agents/selectors"
import { TabPaneElement } from "../../../ValidatableFormTabs/ValidatableFormTabs"
import { getValidationSchema } from "../../../../utility/agents/agentValidation"
import { createAgent, getAgentDeclarations, updateAgent } from "../../../../store/agents/thunks"
import AgentFormLoader from "../../AgentFormLoader"
import { buildJSAgentRequest } from "../../../../utility/agents/agentRequest"
import OnSubmitValidationError from "../../../OnSubmitValidationError/OnSubmitValidationError"
import { Dispatch } from "../../../../utility/common/storeHelper"
import { WithT } from "i18next"
import FormikJSAgentSettingsForm from "./JSAgentSettingsForm/JSAgentSettingsForm"
import FormikJSAgentCodeForm from "./JSAgentCodeForm/JSAgentCodeForm"
import { defaultJSAgentValues, getValuesFromJSAgent } from "../../../../utility/agents/javascriptAgentValues"
import FormikJSAgentAdvanced from "./JSAgentAdvanced/JSAgentAdvanced"

export interface JSAgentFormProps extends AgentFormProps, FormikProps<JSAgentValues>, WithT {
    inProcess: boolean
}

const JSAgentForm: React.FC<AgentFormProps> = props => {
    const { t } = useTranslation()
    const { agent, tabEntries, submitCallback, validateTabs, ownedByThisScenario } = props

    const dispatch = useDispatch<Dispatch>()
    const project = useSelector(selectCurrentProject)
    const declarations = useSelector(selectAgentDeclarations)

    const settingsRef = React.useRef<TabPaneElement>(null)
    const codeRef = React.useRef<TabPaneElement>(null)
    const advancedRef = React.useRef<TabPaneElement>(null)

    const asyncState = useSelector(agent ? selectUpdateAgentState : selectCreateAgentState, shallowEqual)

    const validationSchema = useMemo(
        () => getValidationSchema(AgentType.JSAgent, ownedByThisScenario),
        [ownedByThisScenario]
    )

    const validateJSAgentTabs = useCallback(() => {
        validateTabs?.([settingsRef])
    }, [validateTabs])

    const initialValues = useMemo(() => (agent ? getValuesFromJSAgent(agent) : defaultJSAgentValues), [agent])

    useEffect(() => {
        if (project && (!declarations || !declarations[AgentType.JSAgent])) {
            dispatch(getAgentDeclarations(project.id, AgentType.JSAgent))
        }
    }, [project, dispatch, declarations, AgentType.JSAgent])

    if (!project) {
        return null
    }

    if (!initialValues) {
        return <AgentFormLoader count={3} />
    }

    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values: JSAgentValues) => {
                const request = buildJSAgentRequest(project.id, values, agent?.Params["agent-api-version"])

                if (agent) {
                    dispatch(updateAgent(request, submitCallback))
                } else {
                    dispatch(createAgent(request, submitCallback))
                }
            }}
        >
            {formikProps => (
                <>
                    <OnSubmitValidationError formikProps={formikProps} onCallback={validateJSAgentTabs} />
                    <Tab.Pane eventKey={tabEntries[0].key} ref={settingsRef}>
                        <FormikJSAgentSettingsForm {...props} {...formikProps} t={t} inProcess={asyncState.inProcess} />
                    </Tab.Pane>
                    <Tab.Pane eventKey={tabEntries[1].key} ref={codeRef}>
                        <FormikJSAgentCodeForm {...props} {...formikProps} t={t} inProcess={asyncState.inProcess} />
                    </Tab.Pane>
                    <Tab.Pane eventKey={tabEntries[2].key} ref={advancedRef}>
                        <FormikJSAgentAdvanced {...props} {...formikProps} t={t} inProcess={asyncState.inProcess} />
                    </Tab.Pane>
                </>
            )}
        </Formik>
    )
}

export default JSAgentForm
