import React from "react"
import styles from "./ExtraSettingWrapper.module.scss"
import cn from "classnames"
import CloseButton from "../../../../CloseButton/CloseButton"
import InfoIcon from "../../../../InfoIcon/InfoIcon"

export interface ExtraSettingWrapperProps {
    title: string
    onDelete: () => void
    children: React.ReactNode
    infoIconProps?: {
        id: string
        content: string
    }
}

const ExtraSettingWrapper: React.FC<ExtraSettingWrapperProps> = ({ title, onDelete, children, infoIconProps }) => (
    <div className={styles.extraSetting}>
        <div className={styles.extraSetting__header}>
            <div className={cn(styles.extraSetting__title, styles.extraSetting__title__headerTitle)}>
                {title}
                {infoIconProps && <InfoIcon id={infoIconProps.id} content={infoIconProps.content} />}
            </div>

            <CloseButton onClick={onDelete} className={styles.extraSetting__deleteIcon} />
        </div>
        <div className={styles.extraSetting__content}>{children}</div>
    </div>
)

export default ExtraSettingWrapper
