import { MoveTaskRequest, RerouteTaskRequest, TaskOfAutomaticProcessing, SwitchTaskRequest } from "../../models/task"
import { mapConverters } from "../../utility/common/mapConverters"
import { apiInstance } from "../instances/apiInstance"
import { apiInstanceV1 } from "../instances/apiInstanceV1"

const tasksController = {
    move: (tenantId: string, request: MoveTaskRequest): Promise<boolean> =>
        apiInstance.post(`/${tenantId}/tasks/move`, request).then(response => response.data),
    reroute: (request: RerouteTaskRequest): Promise<void> =>
        apiInstance.post(`/tasks/reroute`, request).then(response => response.data),
    getAutomaticProcessing: (tenantId: string): Promise<TaskOfAutomaticProcessing[]> =>
        apiInstance
            .get(`/${tenantId}/tasks/automatic-processing`)
            .then(response => response.data.map(mapConverters.taskConverter.mapTaskOfAutomaticProcessing)),
    switch: (request: SwitchTaskRequest) =>
        apiInstanceV1
            .post("dialogs/switch_to_operator", undefined, {
                params: { dialogId: request.TaskId, operatorId: request.OperatorId, queueId: request.QueueId }
            })
            .then(response => response.data)
}

export default tasksController
