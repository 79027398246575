import { Channel, ChannelType, WebChatChannel } from "../../models/channel"

export const mapChannelTypeToClassName = (channelType: ChannelType, prefix?: string): string => {
    prefix = prefix ? prefix : ""
    switch (channelType) {
        case ChannelType.WebChat:
            return `${prefix}web-chat`
        case ChannelType.Phone:
            return `${prefix}phone`
        case ChannelType.Telegram:
            return `${prefix}telegram`
        case ChannelType.Viber:
            return `${prefix}viber`
        case ChannelType.Whatsapp:
            return `${prefix}whatsapp`
        case ChannelType.Email:
            return `${prefix}email`
        case ChannelType.Skype:
            return `${prefix}skype`
        case ChannelType.Test:
            return `${prefix}test`
        case ChannelType.Unknown:
            return `${prefix}unknown`
        case ChannelType.VK:
            return `${prefix}vk`
        case ChannelType.Odnoklassniki:
            return `${prefix}odnoklassniki`
        case ChannelType.Facebook:
            return `${prefix}facebook`
        case ChannelType.MSBot:
            return `${prefix}ms-bot`
        case ChannelType.Teams:
            return `${prefix}teams`
        case ChannelType.YandexAlisa:
            return `${prefix}yandex-alisa`
        case ChannelType.YandexDialogs:
            return `${prefix}yandex-dialogs`
        case ChannelType.Instagram:
            return `${prefix}instagram`
        case ChannelType.IntegrationChannel:
            return `${prefix}integration_channel`
    }
}

export const generateChannelId = () => `channel_${(~~(Math.random() * 1e8)).toString(16)}`

export const getChannelById = ({ channels, id }: { channels: Channel[]; id: string }): Channel | undefined => {
    return channels.find(channel => channel.Id === id)
}

export function isWebChatChannel(channel: Channel): channel is WebChatChannel {
    return "ClientConfig" in channel
}

export const FAKE_EMAIL_CHANNEL_ID_PREFIX = "FAKE_EMAIL"
