import React from "react"
import { useTranslation } from "react-i18next"
import { Form, Col } from "react-bootstrap"
import { useField } from "formik"

const tNamespace = "queues:settings."

export interface SlSettingsValues {
    slTarget: number
    slThresholdSec: number
    slPeriodHr: number
    slPeriodMin: number
}

interface Props {
    name: string
}

const SlSettingsInput: React.FC<Props> = props => {
    const { t } = useTranslation()
    const { name } = props
    const [slTargetField, slTargetMeta] = useField(getFullName("slTarget"))
    const [slThresholdField, slThresholdMeta] = useField(getFullName("slThresholdSec"))
    const [slPeriodHrField, slPeriodHrMeta] = useField(getFullName("slPeriodHr"))
    const [slPeriodMinField, slPeriodMinMeta] = useField(getFullName("slPeriodMin"))

    function getFullName(fieldName: keyof SlSettingsValues) {
        return `${name}.${fieldName}`
    }

    return (
        <>
            <Form.Label htmlFor="formSlTarget">{t(`${tNamespace}sl-target`)}</Form.Label>
            <Form.Row>
                <Col md={4}>
                    <Form.Row>
                        <Form.Group as={Col} sm={8} controlId="formSlTarget">
                            <Form.Control
                                type="number"
                                name={slTargetField.name}
                                onChange={slTargetField.onChange}
                                onBlur={slTargetField.onBlur}
                                value={slTargetField.value}
                                isInvalid={slTargetMeta.touched && !!slTargetMeta.error}
                            />
                            <Form.Control.Feedback type="invalid">
                                {slTargetMeta.error && t(slTargetMeta.error)}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Label column sm={4}>
                            %
                        </Form.Label>
                    </Form.Row>
                </Col>
                <Col md={5}>
                    <Form.Row>
                        <Form.Label column sm={3}>
                            {t(`${tNamespace}in`)}
                        </Form.Label>
                        <Form.Group as={Col} sm={5} controlId="formSlThreshold">
                            <Form.Control
                                type="number"
                                name={slThresholdField.name}
                                onChange={slThresholdField.onChange}
                                onBlur={slThresholdField.onBlur}
                                value={slThresholdField.value}
                                isInvalid={slThresholdMeta.touched && !!slThresholdMeta.error}
                            />
                            <Form.Control.Feedback type="invalid">
                                {slThresholdMeta.error && t(slThresholdMeta.error)}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Label column sm={4}>
                            {t(`${tNamespace}sec`)}
                        </Form.Label>
                    </Form.Row>
                </Col>
            </Form.Row>
            <Form.Label>{t(`${tNamespace}sl-period`)}</Form.Label>
            <Form.Row>
                <Col md={5}>
                    <Form.Row>
                        <Form.Group as={Col} sm={5} controlId="formSlPeriodHr">
                            <Form.Control
                                type="number"
                                name={slPeriodHrField.name}
                                onChange={slPeriodHrField.onChange}
                                onBlur={slPeriodHrField.onBlur}
                                value={slPeriodHrField.value}
                                isInvalid={slPeriodHrMeta.touched && !!slPeriodHrMeta.error}
                            />
                            <Form.Control.Feedback type="invalid">
                                {slPeriodHrMeta.error && t(slPeriodHrMeta.error)}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Label column sm={7}>
                            {t(`${tNamespace}hr`)}
                        </Form.Label>
                    </Form.Row>
                </Col>
                <Col md={5}>
                    <Form.Row>
                        <Form.Group as={Col} sm={5} controlId="formSlPeriodMin">
                            <Form.Control
                                type="number"
                                name={slPeriodMinField.name}
                                onChange={slPeriodMinField.onChange}
                                onBlur={slPeriodMinField.onBlur}
                                value={slPeriodMinField.value}
                                isInvalid={slPeriodMinMeta.touched && !!slPeriodMinMeta.error}
                            />
                            <Form.Control.Feedback type="invalid">
                                {slPeriodMinMeta.error && t(slPeriodMinMeta.error)}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Label column sm={7}>
                            {t(`${tNamespace}min`)}
                        </Form.Label>
                    </Form.Row>
                </Col>
            </Form.Row>
        </>
    )
}

export default SlSettingsInput
