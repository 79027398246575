import { NewslineSubscription } from "./newsline"
import { KnowledgeBasePermission } from "./permission"
import { KnowledgeBaseSubscriptionValue } from "./subscription"

export type UserAccessPrimitive = KnowledgeBasePermission | KnowledgeBaseSubscriptionValue | NewslineSubscription

export type OnChangeAccessItemFunction = (data: UserAccessPrimitive) => void

export type UserAccessDifferenceAction = "added" | "removed"

export enum UserAccessActions {
    Added = "added",
    Removed = "removed"
}

export type UserAccessDifferenceValue = {
    value: UserAccessPrimitive
    action: UserAccessDifferenceAction
}

export type UserAccessDifference = {
    [subscriptionString: string]: UserAccessDifferenceValue
}
