import { Queue } from "../../../models/queue"
import { Operator } from "../../../models/operator"
import { OperatorStatusValue } from "../../../models/operatorStatus"

export const countOperators = (queues: Queue[], activeOperators: Operator[]) => {
    const isFree = activeOperators.reduce<{ [id: string]: boolean }>((obj, operator) => {
        obj[operator.Id] = true
        return obj
    }, {})

    queues.forEach(q => {
        activeOperators.forEach(o => {
            if (q.OperatorsTasksCount?.[o.Id] > 0 && o.Tasks.length > 0) {
                isFree[o.Id] = false
            }
        })
    })

    let freeOperators = 0
    activeOperators.forEach(o => {
        if (isFree[o.Id]) freeOperators++
    })

    const loadedOperators = activeOperators.length - freeOperators
    return {
        freeOperators,
        loadedOperators
    }
}

export const compareOperatorFunction = (aOperator: Operator, bOperator: Operator) => {
    if (
        aOperator.Status.Value === OperatorStatusValue.Online &&
        bOperator.Status.Value !== OperatorStatusValue.Online
    ) {
        return -1
    } else if (
        aOperator.Status.Value !== OperatorStatusValue.Online &&
        bOperator.Status.Value === OperatorStatusValue.Online
    ) {
        return 1
    }
    if (
        aOperator.Status.Value === OperatorStatusValue.DoNotRoute &&
        bOperator.Status.Value !== OperatorStatusValue.DoNotRoute
    ) {
        return -1
    } else if (
        aOperator.Status.Value !== OperatorStatusValue.DoNotRoute &&
        bOperator.Status.Value === OperatorStatusValue.DoNotRoute
    ) {
        return 1
    }
    if (aOperator.LastName > bOperator.LastName) {
        return 1
    } else if (aOperator.LastName < bOperator.LastName) {
        return -1
    }
    return 0
}

export const getItemId = (queueId: string, id: string) => `${queueId}-${id}`
