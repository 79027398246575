import { useCallback } from "react"
import { useAppDispatch } from "../store/hooks"
import { makeOffline, deleteFromQueue } from "../store/operators/thunks"
import { Operator } from "../models/operator"

const useOperatorActions = (
    projectId: string,
    queueId: string,
    updateTable: (projectId: string, queueId: string) => void
) => {
    const dispatch = useAppDispatch()

    const handleMakeOffline = useCallback(
        (operator: Operator) => {
            dispatch(makeOffline(operator.Id))
            updateTable(projectId, queueId)
        },
        [dispatch, projectId, queueId, updateTable]
    )

    const handleDeleteFromQueue = useCallback(
        (operator: Operator) => {
            dispatch(
                deleteFromQueue(operator.Id, {
                    TenantId: projectId,
                    QueueId: queueId
                })
            )
            updateTable(projectId, queueId)
        },
        [dispatch, projectId, queueId, updateTable]
    )

    return { handleMakeOffline, handleDeleteFromQueue }
}

export default useOperatorActions
