import React, { MouseEvent, useRef, useState } from "react"
import styles from "./Keyboard.module.scss"
import cn from "classnames"
import { faCog } from "@fortawesome/pro-light-svg-icons/faCog"
import { faPlus } from "@fortawesome/pro-light-svg-icons/faPlus"
import IconControl from "../../../../IconControl/IconControl"
import MarkdownButtonRow from "../KeyboardButtonRow/KeyboardButtonRow"
import PopoverTrigger from "../../../../PopoverTrigger/PopoverTrigger"
import { usePopoverShown } from "../../../../../utility/knowledgeBase/usePopoverShown"
import AddMarkdownButtonForm from "../MarkdownButtonForm/AddMarkdownButtonForm/AddMarkdownButtonForm"
import { Popover } from "react-bootstrap"
import Overlay from "react-bootstrap/Overlay"
import MarkdownButtonBlockForm from "../MarkdownButtonForm/MarkdownButtonBlockForm/MarkdownButtonBlockForm"
import { MarkdownButtonBlock } from "../../../../../models/markdownButton"
import { KeyboardButtons } from "../../../../../utility/articleContentEditor/markdownButtons"
import { MarkdownButton } from "../../../../../utility/articleContentEditor/buttonsParser/buttonsParser"
import CloseButton from "../../../../CloseButton/CloseButton"
import { useForceUpdate } from "../../../../../utility/common/useForceUpdate"
import { Dispatch } from "../../../../../utility/common/storeHelper"
import popoverStyles from "../../../../PopoverTrigger/PopoverTrigger.module.scss"

interface Props {
    isEditable: boolean
    buttonRows: KeyboardButtons
    block: MarkdownButtonBlock
    handleUpdateKeyboard: (keyboard: KeyboardButtons, block: MarkdownButtonBlock) => void
    onDeleteKeyboard: () => void
    handleSelect: (e?: MouseEvent<HTMLDivElement>) => void
    projectId: string
    handleOpenArticle: (articleCode: string) => void
    dispatch: Dispatch
}

const Keyboard: React.FC<Props> = props => {
    const {
        buttonRows,
        block,
        handleUpdateKeyboard,
        onDeleteKeyboard,
        handleSelect,
        projectId,
        isEditable,
        handleOpenArticle,
        dispatch
    } = props

    const forceUpdate = useForceUpdate()
    const [row, setRow] = useState(1)

    const updateKeyboard = (keyboard: KeyboardButtons, block: MarkdownButtonBlock) => {
        handleSelect()
        handleUpdateKeyboard(keyboard, block)
        forceUpdate()
    }

    const {
        shown: addButtonFormShown,
        showPopover: showAddButtonForm,
        hidePopover: hideAddButtonForm
    } = usePopoverShown()

    const { shown: settingShown, showPopover: showSettingPopover, hidePopover: hideSettingPopover } = usePopoverShown()

    const ref = useRef<HTMLDivElement>(null)
    const target = useRef<HTMLDivElement>(null)

    const handleOpenAddButtonForm = (row: number) => () => {
        setRow(row)
        showAddButtonForm()
    }

    const handleAddButton = (button: MarkdownButton, row: number) => {
        buttonRows[`${row}`] = buttonRows[`${row}`] ? [...buttonRows[`${row}`], button] : [button]
        updateKeyboard(buttonRows, block)
    }

    const handleEditButton = (updatedButton: MarkdownButton) => {
        Object.keys(buttonRows).forEach(
            row => (buttonRows[row] = buttonRows[row].map(btn => (btn.id === updatedButton.id ? updatedButton : btn)))
        )
        updateKeyboard(buttonRows, block)
    }

    const handleEditBlock = (updatedBlock: MarkdownButtonBlock) => {
        updateKeyboard(buttonRows, updatedBlock)
    }

    const handleDeleteKeyboard = () => {
        handleSelect()
        onDeleteKeyboard()
    }

    const handleDeleteButton = (rowNumber: number, column: number) => {
        const row = `${rowNumber}`
        if (!buttonRows[row]) return

        const updatedButtonRows = { ...buttonRows }
        updatedButtonRows[row].splice(column, 1)

        if (!updatedButtonRows[row].length) {
            const rowCount = Object.keys(updatedButtonRows).length

            for (let i = rowNumber; i < rowCount; i++) {
                updatedButtonRows[`${i}`] = updatedButtonRows[`${i + 1}`]
            }
            delete updatedButtonRows[`${rowCount}`]
        }

        Object.keys(updatedButtonRows).length ? updateKeyboard(updatedButtonRows, block) : handleDeleteKeyboard()
    }

    return (
        <div className={cn(styles["keyboard"], !isEditable && styles["keyboard_disabled"])} ref={ref}>
            <div className={styles["keyboard__buttons"]}>
                <div className={styles["keyboard__button-rows"]}>
                    {Object.keys(buttonRows).map((row, index) => (
                        <div className={styles["keyboard__button-row"]} key={row}>
                            <MarkdownButtonRow
                                projectId={projectId}
                                onAdd={handleOpenAddButtonForm(index + 1)}
                                onEdit={handleEditButton}
                                buttonRow={buttonRows[row]}
                                disabled={!isEditable}
                                handleDeleteButton={handleDeleteButton}
                                isEditable={isEditable}
                                handleOpenArticle={handleOpenArticle}
                                dispatch={dispatch}
                            />
                        </div>
                    ))}
                </div>
                {isEditable && (
                    <div className={styles["keyboard__settings-btn"]}>
                        <IconControl
                            icon={faCog}
                            className={styles["keyboard__settings-icon"]}
                            onClick={settingShown ? hideSettingPopover : showSettingPopover}
                        />
                        <CloseButton className={styles["keyboard__settings-icon"]} onClick={handleDeleteKeyboard} />
                    </div>
                )}
            </div>
            {isEditable && (
                <PopoverTrigger
                    rootClose
                    className={styles["keyboard__add-btn-popover"]}
                    id="add-keyboard-button-name"
                    content={
                        <AddMarkdownButtonForm
                            onAdd={handleAddButton}
                            onClose={hideAddButtonForm}
                            row={row}
                            projectId={projectId}
                            dispatch={dispatch}
                        />
                    }
                    trigger="click"
                    placement="bottom-start"
                    show={addButtonFormShown}
                    container={ref.current}
                >
                    <div className={styles["keyboard__add-icon-container"]} ref={target}>
                        <IconControl
                            icon={faPlus}
                            className={styles["keyboard__add-icon"]}
                            onClick={handleOpenAddButtonForm(Object.keys(buttonRows).length + 1)}
                        />
                    </div>
                </PopoverTrigger>
            )}
            <Overlay target={target.current} show={settingShown} placement="bottom-start" container={ref.current}>
                {props => (
                    <Popover
                        id="add-keyboard-button-block-edit"
                        className={cn(popoverStyles["popover-trigger"], styles["keyboard__add-btn-popover"])}
                        {...props}
                    >
                        <Popover.Content>
                            <MarkdownButtonBlockForm
                                block={block}
                                onEdit={handleEditBlock}
                                close={hideSettingPopover}
                            />
                        </Popover.Content>
                    </Popover>
                )}
            </Overlay>
        </div>
    )
}

export default Keyboard
