import useDelay from "../../../../utility/common/useDelay"
import Skeleton from "../../../Skeleton/Skeleton"
import styles from "./Loader.module.scss"

export const SearchQueryArticlesLoader = () => {
    const display = useDelay()
    if (!display) return null

    return (
        <div className={styles.loader}>
            {new Array<number>(3).fill(0).map((_, i) => (
                <div key={i} className={styles.loader__item}>
                    <Skeleton />
                </div>
            ))}
        </div>
    )
}
