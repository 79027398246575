import { useMemo } from "react"
import { countOperators, getItemId } from "../QueueInfoItems/helpers"
import { Time } from "../../../utility/common/time"
import isNotNull from "../../../utility/common/isNotNull"
import { Queue } from "../../../models/queue"
import { useTranslation } from "react-i18next"
import { Operator } from "../../../models/operator"
import { useAppSelector } from "../../../store/hooks"
import { selectActiveOperatorsByQueue } from "../../../store/operators/selectors"

const tNamespace = "queues:card."

const useQueueStatistics = (queue: Queue, queues: Queue[], filteredOperators: Operator[]) => {
    const { t } = useTranslation()
    const operatorByQueue = useAppSelector(selectActiveOperatorsByQueue)
    const operatorsTotal = operatorByQueue.ItemsTotal
    const loadedOperatorsCount = Object.values(queue.OperatorsTasksCount).reduce((acc, cur) => {
        if (cur) acc += 1

        return acc
    }, 0)
    const freeOperatorsV2 = operatorsTotal - loadedOperatorsCount

    const { freeOperators, loadedOperators } = useMemo(
        () => countOperators(queues, filteredOperators),
        [queues, filteredOperators]
    )

    const activeTasksCount =
        queue.ActiveTasksCount ??
        filteredOperators.reduce(
            (acc, curr) =>
                acc +
                curr.Tasks.filter(t => t.QueueId && (t.QueueId === queue.Id || t.QueueId === `${queue.Id}:${curr.Id}`))
                    .length,
            0
        )

    const awtStatEntries =
        queue.Awt && queue.AwtStatsSettings
            ? Object.entries(queue.Awt).map(([id, awt]) => {
                  const lookbehindMSec = queue.AwtStatsSettings?.find(s => s.Id === id)?.LookbehindMSec
                  let description = ""
                  if (lookbehindMSec) {
                      description =
                          t(`${tNamespace}stat-entry-period`) + ": " + Time.humanizeDuration(lookbehindMSec, t)
                  }
                  return {
                      id: getItemId(queue.Id, id),
                      value: awt,
                      description
                  }
              })
            : []

    const finishedDialogsStatEntries =
        queue.Awt && queue.FinishedDialogsSettings
            ? queue.FinishedDialogsSettings.map(settings => {
                  const stat = queue.FinishedDialogs[settings.Id]

                  if (!stat) {
                      return null
                  }

                  return {
                      id: getItemId(queue.Id, settings.Id),
                      handled: stat.Handled,
                      lost: stat.Lost,
                      description:
                          t(`${tNamespace}stat-entry-period`) + ": " + Time.humanizeDuration(settings.LookbehindMSec, t)
                  }
              })
            : []

    const finishedDialogsDailyStatEntries =
        queue.Awt && queue.FinishedDialogsDailySettings
            ? queue.FinishedDialogsDailySettings.map(settings => {
                  const stat = queue.FinishedDialogsDaily[settings.Id]
                  if (!stat) return null
                  return {
                      id: getItemId(queue.Id, settings.Id),
                      handled: stat.Handled,
                      lost: stat.Lost,
                      description: `${t(`${tNamespace}reset`)}: ${Time.msToTimeString(settings.LocalResetTime)}`
                  }
              })
            : []
    const finishDialogsStat = finishedDialogsStatEntries.concat(finishedDialogsDailyStatEntries).filter(isNotNull)

    return {
        activeTasksCount,
        awtStatEntries,
        finishDialogsStat,
        freeOperatorsV2,
        freeOperators,
        loadedOperators,
        loadedOperatorsCount,
        operatorsTotal
    }
}

export default useQueueStatistics
