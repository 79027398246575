import React from "react"
import { Task } from "../../models/task"
import { Operator } from "../../models/operator"
import OperatorTask from "./OperatorTask/OperatorTask"
import styles from "./OperatorTasks.module.scss"
import { useTranslation } from "react-i18next"
import { testId } from "../../utility/tests/testId"
import { translation } from "./OperatorTasks.const"

export type OperatorTasksProps = {
    tasks: Task[]
    operator: Operator
    onTaskClick?: (task: Task, operator: Operator) => void
}

const tNamespace = "queues"

const OperatorTasks = (props: OperatorTasksProps) => {
    const { tasks, onTaskClick, operator } = props
    const { t } = useTranslation(tNamespace)

    return (
        <div data-testid={testId.operatorTasks}>
            {!tasks.length && (
                <div className={styles.operatorTasks__empty}>
                    <span>{t(translation.noActiveTasks)}</span>
                </div>
            )}
            {tasks.map(task => (
                <OperatorTask key={task.Id} task={task} operator={operator} onTaskClick={onTaskClick} />
            ))}
        </div>
    )
}

export default OperatorTasks
