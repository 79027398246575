import React from "react"
import styles from "./MenuItem.module.scss"
import cn from "classnames"
import MenuIcon from "../MenuIcon/MenuIcon"

export interface MenuItemComponentProps {
    id: string
    title: string
    isSelected: boolean
    onClick: () => void
    testId?: string
    icon?: string
    inHeader?: boolean
}

const MenuItemComponent: React.FC<MenuItemComponentProps> = props => {
    const { id, title, isSelected, onClick, testId, icon, inHeader } = props
    return (
        <button
            className={cn(styles.menuItem, isSelected && styles.menuItem_selected)}
            onClick={() => onClick()}
            data-testid={testId}
        >
            <div className={cn(styles.menuItem__container, !inHeader && styles.menuItem_sidebar)}>
                <MenuIcon menuId={id} className={styles.menuItem__menuIcon} iconUrl={icon} />
                {!inHeader && <div className={styles.menuItem__title}>{title}</div>}
            </div>
        </button>
    )
}

export default MenuItemComponent
