import { Dispatch } from "../../utility/common/storeHelper"
import { actions } from "./slice"
import { useLazyGetDialogTopicsQuery } from "../../api/controllers/dialogs"
import { dialogDraftsLocalStoreDriver } from "../../api/instances/dialogDraftsLocalStoreDriver"
import store from "../store"
import { scrollDownMessageInput } from "../../utility/dialogs/scrollDownMessageInput"
import trim from "lodash/trim"
import { MESSAGES_PER_PAGE } from "../../utility/dialogs/constant"

export function backToDialogList() {
    return (dispatch: Dispatch) => {
        dispatch(actions.unsetCurrentDialogId())
        dispatch(actions.unsetCurrentOperatorClientId())
    }
}

export function clearDialogsState() {
    return (dispatch: Dispatch) => {
        dispatch(actions.unsetCurrentDialogId())
        dispatch(actions.unsetCurrentOperatorClientId())
        dispatch(actions.setSearchCriterion(""))
    }
}

export function createDialog() {
    return (dispatch: Dispatch) => {
        dispatch(actions.unsetCurrentDialogId())
        dispatch(actions.unsetCurrentOperatorClientId())
        dispatch(actions.setCurrentOmniUserId(""))
        dispatch(actions.setCurrentChannel())
        dispatch(actions.finishCreatingDialog())
        dispatch(actions.createDialog())
    }
}

export const closeKnowledgeBaseSidebar = () => {
    return (dispatch: Dispatch) => {
        dispatch(actions.closeArticleSidebar())
        dispatch(actions.closeKnowledgeBaseSidebar())
    }
}

export const setMessageInputText = (text: string) => {
    return (dispatch: Dispatch) => {
        dispatch(actions.setMessageInput(text))
        dispatch(actions.setDraftedInput(text))
    }
}

export const addToMessageInputText = (
    text: string,
    isAddToStart = false,
    shouldHandleCursorPos = false,
    noNextLineAroundPasted = false
) => {
    return (dispatch: Dispatch) => {
        const state = store.getState().dialogs
        const lastCursorPosition = state.inputLastCursorPosition

        const draftEntityId = state.selectedDialogId ?? state.selectedOperatorClientId ?? ""
        const currentInputText = dialogDraftsLocalStoreDriver.get(draftEntityId)
        const filteredText = trim(text, "\\\n")
        const nextLine = noNextLineAroundPasted ? "" : "\n\n "

        let pasteText
        if (!shouldHandleCursorPos || lastCursorPosition < 0 || lastCursorPosition > (currentInputText?.length ?? 0)) {
            pasteText = currentInputText
                ? isAddToStart
                    ? `${filteredText}\n\n ${currentInputText}`
                    : `${currentInputText}\n\n ${filteredText}`
                : filteredText
        } else {
            pasteText =
                currentInputText !== undefined
                    ? `${currentInputText.substring(
                          0,
                          lastCursorPosition
                      )}${nextLine}${filteredText}${nextLine}${currentInputText.substring(lastCursorPosition)}`
                    : filteredText
        }

        dispatch(setMessageInputText(pasteText))

        dispatch(
            actions.setCursorPositionWithTextInserted({
                textLength: filteredText.length,
                lastPosition: pasteText.length
            })
        )

        // Without timeout, it doesn't work in empty input
        setTimeout(() => {
            if (!isAddToStart) {
                scrollDownMessageInput()
            }
        }, 0)
    }
}

export const switchToDialog =
    (
        dialogId: string,
        omniUserId: string,
        projectId: string,
        getTopics: ReturnType<typeof useLazyGetDialogTopicsQuery>[0]
    ) =>
    (dispatch: Dispatch) => {
        const storedMessage = dialogDraftsLocalStoreDriver.get(dialogId)
        dispatch(actions.setMessageInput(storedMessage || ""))
        dispatch(actions.unsetCurrentOperatorClientId())
        dispatch(actions.setCurrentDialogId(dialogId))
        dispatch(
            actions.setMessagesPaginationState({
                OmniUserId: omniUserId,
                StartTime: 0,
                Count: MESSAGES_PER_PAGE,
                FromTodayDialogs: true,
                ProjectId: projectId
            })
        )
        getTopics({
            ProjectId: projectId
        })
    }
