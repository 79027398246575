import { QueueName } from "../../models/queue"
import { Queue } from "../../models/queue"

export const getQueuesNames = (ids: string[], queues: Queue[]): QueueName[] => {
    const queuesNames = []
    for (const id of ids) {
        const queue = queues.find(v => v.Id === id)
        if (queue) queuesNames.push({ Id: queue.Id, Name: queue.Name })
        else queuesNames.push({ Id: id, Name: id })
    }

    return queuesNames
}
