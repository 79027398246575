import { DialogPerson } from "../../models/Dialogs/dialog"

enum operatorLang {
    firstname = "operator_firstname",
    middlename = "operator_middlename",
    lastname = "operator_lastname"
}

export const operatorsLangParser = (operator: Partial<DialogPerson>, withoutBrace: string, substring: string) => {
    switch (withoutBrace) {
        case operatorLang.firstname:
            return { substring: operator.Firstname ?? substring, isFulfilled: true }
        case operatorLang.middlename:
            return { substring: operator.Middlename ?? substring, isFulfilled: true }
        case operatorLang.lastname:
            return { substring: operator.Lastname ?? substring, isFulfilled: true }
        default:
            return { substring: withoutBrace, isFulfilled: false }
    }
}
