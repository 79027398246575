import { useState, useEffect, Dispatch, SetStateAction } from "react"

const onUnload = (event: BeforeUnloadEvent) => {
    event.preventDefault()
    event.returnValue = ""
    return ""
}

export default function useExitPrompt(initialState: boolean): [boolean, Dispatch<SetStateAction<boolean>>] {
    const [showExitPrompt, setShowExitPrompt] = useState(initialState)

    useEffect(() => {
        setShowExitPrompt(initialState)
    }, [initialState])

    useEffect(() => {
        const handleBeforeUnload = (event: BeforeUnloadEvent) => {
            if (showExitPrompt) {
                onUnload(event)
            }
        }

        if (showExitPrompt) {
            window.addEventListener("beforeunload", handleBeforeUnload)
        }

        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload)
        }
    }, [showExitPrompt])

    return [showExitPrompt, setShowExitPrompt]
}
