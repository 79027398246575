import { apiInstance } from "../../instances/apiInstance"
import {
    GetUserArticleSubscriptionResponse,
    KnowledgeBaseArticleSubscriptions,
    UpdateArticleSubscriptionsRequest
} from "../../../models/knowledgeBase/subscription"

const knowledgeBaseSubscriptions = {
    update: (
        projectId: string,
        articleCode: string,
        request: UpdateArticleSubscriptionsRequest
    ): Promise<KnowledgeBaseArticleSubscriptions> =>
        apiInstance.put(`${projectId}/article_subscriptions/${articleCode}`, request).then(response => response.data),
    get: (projectId: string, articleCode: string): Promise<KnowledgeBaseArticleSubscriptions> =>
        apiInstance.get(`${projectId}/article_subscriptions/${articleCode}`).then(response => response.data),
    subscribeUser: (projectId: string, articleCode: string): Promise<void> =>
        apiInstance.post(`${projectId}/article_subscriptions/${articleCode}/subscribe`),
    unsubscribeUser: (projectId: string, articleCode: string): Promise<void> =>
        apiInstance.post(`${projectId}/article_subscriptions/${articleCode}/unsubscribe`),
    getUserSubscription: (projectId: string, articleCode: string): Promise<GetUserArticleSubscriptionResponse> =>
        apiInstance.post(`${projectId}/article_subscriptions/${articleCode}/get`).then(response => response.data),
    getArticleSubscribers: (projectId: string, articleCode: string): Promise<string[]> =>
        apiInstance.get(`${projectId}/article_subscriptions/${articleCode}/get_users`).then(response => response.data)
}

export default knowledgeBaseSubscriptions
