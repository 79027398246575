import React, { useContext, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import "./Project.scss"
import { Redirect, RouteComponentProps } from "react-router"
import { getProjects } from "../../store/projects/thunks"
import { getMainMenu } from "../../store/menu/thunks"
import { selectGetProjectsState } from "../../store/projects/selectors"
import ProjectView from "../../components/ProjectView/ProjectView"
import { selectGetMainMenuState } from "../../store/menu/selectors"
import Async from "../../components/Async/Async"
import FullscreenError from "../../components/FullscreenError/FullscreenError"
import { getOperatorStatus } from "../../store/userOperator/thunks"
import AsyncState from "../../core/asyncState"
import { accessDeniedPath, welcomePath } from "../../routerPaths"
import ConfigContext from "../../components/ConfigContext/ConfigContext"
import Can from "../../components/Can/Can"
import { Login } from "../../permissions"
import { Project } from "../../models/project"
import { Menu } from "../../models/menu"
import { dialogsHub } from "../../api/instances/dialogsHub"

export type ProjectPageProps = RouteComponentProps

const ProjectPage: React.FC<ProjectPageProps> = () => {
    const dispatch = useDispatch()
    const {
        WebConfig: {
            appSettings: { authMode, signUpEnabled }
        }
    } = useContext(ConfigContext)

    const asyncState = AsyncState.merge2(useSelector(selectGetProjectsState), useSelector(selectGetMainMenuState))
    useEffect(() => {
        dispatch(getProjects())
        dispatch(getMainMenu())
        dispatch(getOperatorStatus())
    }, [dispatch])

    const renderProject = (can: boolean, projects: Project[], mainMenu: Menu) => {
        if (!can) {
            return <Redirect to={accessDeniedPath} />
        }
        if (projects.length === 0) {
            if (authMode === "oidc" && !signUpEnabled) {
                return <Redirect to={accessDeniedPath} />
            } else {
                return <Redirect to={welcomePath} />
            }
        }
        return <ProjectView projects={projects} mainMenu={mainMenu} />
    }

    useEffect(() => {
        dialogsHub.subscribe()
        dialogsHub.registerServerEvents()

        return () => {
            dialogsHub.unregisterServerEvents()
            dialogsHub.unsubscribe()
        }
    }, [])

    return (
        <div className="projects">
            <Async dataState={asyncState} errorView={({ message }) => <FullscreenError message={message} />}>
                {({ data: [projects, mainMenu] }) => (
                    <Can permission={Login}>{(can: boolean) => renderProject(can, projects, mainMenu)}</Can>
                )}
            </Async>
        </div>
    )
}

export default ProjectPage
