import { TFunction } from "react-i18next"
import * as Yup from "yup"
import { FileUploaderFileType } from "../../../../../components/FileUploader/FileUploader"
import { formTranslation } from "../../../../../locales/form"
import {
    MassMailingFormFields,
    MassMailingFormValues,
    MassMailingFormat,
    MassMailingMethod
} from "../../../../../models/massMailing"
import { logError } from "../../../../../utility/common/logError"

const tNamespace = "massMailing:form.error."
export const CORRECT_MAILING_CSV_HEADERS = {
    [MassMailingFormat.omni]: "ExtItemId,MessageMarkdown,IntentSymbolCode,OmniUserId,ExtUserId",
    [MassMailingFormat.channel]:
        "ExtItemId,MessageMarkdown,IntentSymbolCode,ChannelId1,ChannelUserId1,ChannelId2,ChannelUserId2,ChannelId3,ChannelUserId3,ChannelId4,ChannelUserId4"
}
type ArticleType = Exclude<MassMailingFormValues[MassMailingFormFields.article], undefined>

const validateFileHeaders = (headers: string, t: TFunction) => {
    return async function (this: Yup.TestContext, value: unknown) {
        const file = value as FileUploaderFileType
        const readError = this.createError({
            message: `${tNamespace}${MassMailingFormFields.attachment}-read`,
            path: MassMailingFormFields.attachment
        })

        if (file && file.File) {
            try {
                const text = await file.File.text()
                const firstLine = text.split("\n")[0]
                if (firstLine.startsWith(headers)) {
                    return true
                } else {
                    return this.createError({
                        message: t(`${tNamespace}${MassMailingFormFields.attachment}-headers`, {
                            receivedHeaders: firstLine.replaceAll(",", ", "),
                            expectedHeaders: headers.replaceAll(",", ", ")
                        }),
                        path: MassMailingFormFields.attachment
                    })
                }
            } catch (e) {
                logError(e, "Error reading and validating user's file:")
                return readError
            }
        }

        return readError
    }
}

export const getMassMailingFormSchema = (t: TFunction) =>
    Yup.object<MassMailingFormValues>()
        .shape({
            [MassMailingFormFields.title]: Yup.string().required(formTranslation.titleRequired).default(""),
            [MassMailingFormFields.format]: Yup.string()
                .oneOf([MassMailingFormat.omni, MassMailingFormat.channel])
                .default(MassMailingFormat.channel),
            [MassMailingFormFields.channel]: Yup.string()
                .when(MassMailingFormFields.format, {
                    is: MassMailingFormat.omni,
                    then: Yup.string().required(`${tNamespace}${MassMailingFormFields.channel}`)
                })
                .default(""),
            [MassMailingFormFields.attachment]: Yup.object<FileUploaderFileType>()
                .default(undefined)
                .nullable()
                .test({
                    name: "isAttachment",
                    message: `${tNamespace}${MassMailingFormFields.attachment}`,
                    test: value => !!value
                })
                .test({
                    name: "isAttachmentCsv",
                    message: `${tNamespace}${MassMailingFormFields.attachment}-csv`,
                    test: value => !!value && !!value.Name.match(/.csv$/)
                })
                .when(MassMailingFormFields.format, {
                    is: MassMailingFormat.omni,
                    then: Yup.object<FileUploaderFileType>().test({
                        name: "isOmniAttachmentHasCorrectHeaders",
                        test: validateFileHeaders(CORRECT_MAILING_CSV_HEADERS[MassMailingFormat.omni], t)
                    })
                })
                .when(MassMailingFormFields.format, {
                    is: MassMailingFormat.channel,
                    then: Yup.object<FileUploaderFileType>().test({
                        name: "isChannelAttachmentHasCorrectHeaders",
                        test: validateFileHeaders(CORRECT_MAILING_CSV_HEADERS[MassMailingFormat.channel], t)
                    })
                }),
            [MassMailingFormFields.isScheduled]: Yup.boolean().default(false),
            [MassMailingFormFields.scheduleTime]: Yup.number()
                .when(MassMailingFormFields.isScheduled, {
                    is: true,
                    then: Yup.number().min(Date.now(), `${tNamespace}${MassMailingFormFields.scheduleTime}`)
                })
                .default(0),
            [MassMailingFormFields.method]: Yup.string()
                .oneOf([MassMailingMethod.article, MassMailingMethod.message])
                .default(MassMailingMethod.message),
            [MassMailingFormFields.message]: Yup.string().default(""),
            [MassMailingFormFields.article]: Yup.object<ArticleType>().default(undefined)
        })
        .required()
