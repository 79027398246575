import { useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"
import { selectCurrentProjectId } from "../../../../store/projects/selectors"
import { searchArticles } from "../../../../store/knowledgeBase/thunks"
import { ClassProps } from "../../../../utility/common/props"
import { SearchArticlesInputLogic } from "../Logic"
import { SearchArticlesRequest } from "../../../../models/article"

export const SearchArticlesInputContainer = (props: ClassProps) => {
    const { className } = props
    const dispatch = useDispatch()
    const projectId = useSelector(selectCurrentProjectId)

    const handleSearch = useCallback(
        (request: SearchArticlesRequest) => {
            if (projectId) {
                dispatch(searchArticles(projectId, request))
            }
        },
        [projectId, dispatch]
    )

    return <SearchArticlesInputLogic onSearch={handleSearch} className={className} />
}
