import { useEffect } from "react";
import { getState } from "utils";
import { useTransformContext } from "./use-transform-context";
export var useTransformEffect = function (callback) {
    var libraryContext = useTransformContext();
    useEffect(function () {
        var unmountCallback;
        var unmount = libraryContext.onChange(function (ref) {
            unmountCallback = callback(getState(ref.instance));
        });
        return function () {
            unmount();
            unmountCallback === null || unmountCallback === void 0 ? void 0 : unmountCallback();
        };
    }, [callback, libraryContext]);
};
