import React, { useContext, useMemo } from "react"
import { Formik } from "formik"
import {
    ArticleSubscriptionFormValues,
    KnowledgeBaseArticleSubscriptions,
    KnowledgeBaseSubscriptionSelectItem
} from "../../models/knowledgeBase/subscription"
import UserAccessForm from "../UserAccessForm/UserAccessForm"
import { useTranslation } from "react-i18next"
import { nameof } from "../../utility/common/nameof"
import { filterProjectByPermissions } from "../../utility/knowledgeBase/permissionsUsersAndRoles"
import { Project } from "../../models/project"
import KnowledgeBasePermissionsContext from "../KnowledgeBasePermissionsProvider/KnowledgeBasePermissionsContext"
import { Button, Form } from "react-bootstrap"
import styles from "./ArticleSubscriptionForm.module.scss"
import { formTranslation } from "../../locales/form"
import LoadingButton from "../LoadingButton/LoadingButton"
import { preventSubmitOnEnter } from "../../utility/common/preventSubmitOnEnter"

export interface ArticleSubscriptionFormProps {
    title: string
    loading: boolean
    onSubmit: (values: ArticleSubscriptionFormValues, hidePopover: () => void) => void
    onCancel: () => void
    articleSubscriptions: KnowledgeBaseArticleSubscriptions
    subscriptionOptions: KnowledgeBaseSubscriptionSelectItem[]
    currentProject?: Project
    projects?: Project[]
}

const ArticleSubscriptionForm: React.FC<ArticleSubscriptionFormProps> = props => {
    const { articleSubscriptions, subscriptionOptions, onSubmit, onCancel, title, projects, currentProject, loading } =
        props

    const { t } = useTranslation()
    const { permissions } = useContext(KnowledgeBasePermissionsContext)
    const filteredProjects = useMemo(() => filterProjectByPermissions(permissions, projects), [permissions, projects])

    return (
        <Formik<ArticleSubscriptionFormValues>
            initialValues={{
                Subscriptions: articleSubscriptions.Subscriptions,
                SubscriptionDiff: {}
            }}
            onSubmit={values => {
                onSubmit(values, onCancel)
            }}
        >
            {formikProps => (
                <Form
                    className={styles.articleSubscriptionForm}
                    onSubmit={formikProps.handleSubmit}
                    onKeyPress={preventSubmitOnEnter}
                >
                    <UserAccessForm
                        type="subscription"
                        title={title}
                        name={nameof<ArticleSubscriptionFormValues>("Subscriptions")}
                        diffFieldName={nameof<ArticleSubscriptionFormValues>("SubscriptionDiff")}
                        subscriptionOptions={subscriptionOptions}
                        currentProject={currentProject}
                        projects={filteredProjects}
                        {...formikProps}
                    />
                    <div className={styles.articleSubscriptionForm__footer}>
                        <Button className={styles.articleSubscriptionForm__cancel} variant="light" onClick={onCancel}>
                            {t(formTranslation.cancel)}
                        </Button>
                        <LoadingButton
                            className={styles.articleSubscriptionForm__submit}
                            variant="primary"
                            loading={loading}
                            type="submit"
                        >
                            {t(formTranslation.save)}
                        </LoadingButton>
                    </div>
                </Form>
            )}
        </Formik>
    )
}

export default ArticleSubscriptionForm
