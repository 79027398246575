import React from "react"
import Async from "../Async/Async"
import { useSelector } from "react-redux"
import { selectGetQueueCategoriesState } from "../../store/queues/selectors"
import CatalogsLoader from "../Catalogs/CatalogsLoader"
import ErrorMessage from "../ErrorMessage/ErrorMessage"
import QueueCategories from "./QueueCategories"
import { ItemId } from "@atlaskit/tree/types"

export interface QueueCategoriesContainerProps {
    projectId: string
    onAddQueue: (categoryId: ItemId) => void
    onEditCatalogClick: () => void
    onCancelEditCatalogClick: () => void
    onCategoryClick?: (id: string) => void
    isSelectCatalogAllowed?: boolean
}

const QueueCategoriesContainer: React.FC<QueueCategoriesContainerProps> = props => {
    const { onAddQueue, projectId, ...otherProps } = props
    const apiState = useSelector(selectGetQueueCategoriesState)

    return (
        <Async
            dataState={apiState}
            processView={<CatalogsLoader itemsCount={2} />}
            errorView={({ message }) => <ErrorMessage text={message} />}
        >
            {_ => <QueueCategories projectId={projectId} onAddQueue={onAddQueue} {...otherProps} />}
        </Async>
    )
}

export default QueueCategoriesContainer
