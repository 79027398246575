import React, { useEffect, useState } from "react"
import { AsyncDataProps, AsyncErrorProps } from "../../../utility/common/asyncHelper"
import { getSystemErrorMessage, SystemError } from "../../../core/error"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { apiCallExceptionToSystemError } from "../../../utility/common/apiCallExceptionToSystemError"
import { saveNotificationError } from "../../../store/notifications/thunks"

interface AsyncFetchProps<Req extends Array<unknown>, Res> {
    fetchFunction: (arg: Req) => Promise<Res>
    requestParams: Req
    processView: JSX.Element
    errorView: (props: AsyncErrorProps) => JSX.Element
    children: (props: AsyncDataProps<Res>) => JSX.Element
    errorKey: string
    nonDataView?: JSX.Element
}

enum loadingStatus {
    process = "process",
    error = "error",
    success = "success"
}

function AsyncFetch<Req extends Array<unknown>, Res>(props: AsyncFetchProps<Req, Res>): JSX.Element {
    const { fetchFunction, processView, errorView, children, nonDataView, requestParams, errorKey } = props

    const [status, setStatus] = useState<loadingStatus>()
    const [dataState, setDataState] = useState<Res>()
    const [errorState, setErrorState] = useState<SystemError>()

    const { t } = useTranslation()
    const dispatch = useDispatch()

    useEffect(() => {
        setStatus(loadingStatus.process)

        fetchFunction(requestParams)
            .then(res => {
                setStatus(loadingStatus.success)
                setDataState(res)
            })
            .catch(e => {
                const error = apiCallExceptionToSystemError(errorKey, e)
                saveNotificationError(dispatch, error)
                setErrorState(error)
                setStatus(loadingStatus.error)
            })
    }, [...requestParams, errorKey, fetchFunction, dispatch])

    if (status === loadingStatus.process) {
        return processView
    }

    if (status === loadingStatus.error && errorState) {
        return errorView({ message: getSystemErrorMessage(errorState, t) })
    }

    return <>{dataState ? children({ data: dataState }) : nonDataView}</>
}

export default AsyncFetch
