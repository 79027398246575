import React, { useCallback } from "react"
import { Queue } from "../../../models/queue"
import { useAppDispatch, useAppSelector } from "../../../store/hooks"
import { actions } from "../../../store/queues/slice"
import SidebarRight from "../../SidebarRight/SidebarRight"
import { useTranslation } from "react-i18next"
import { selectQueuesTaskSidebar } from "../../../store/queues/selectors"
import TaskView from "../../TaskView/TaskView"
import IconControl from "../../IconControl/IconControl"
import { faSync } from "@fortawesome/pro-light-svg-icons"
import { useLazyGetTasksMessagesQuery } from "../../../api/controllers/queuesV2"
import { Operator } from "../../../models/operator"
import { useGetOperatorByIdQuery } from "../../../api/controllers/operators"
import AsyncQuery from "../../Async/AsyncQuery"
import ErrorMessage from "../../ErrorMessage/ErrorMessage"
import { skipToken } from "@reduxjs/toolkit/query"
import QueuesTaskSidebarLoader from "./QueuesTaskSidebarLoader/QueuesTaskSidebarLoader"

type QueuesTaskSidebarProps = {
    allQueues: Queue[]
}

const tNamespace = "queues:"

const QueuesTaskSidebar = (props: QueuesTaskSidebarProps) => {
    const { allQueues } = props
    const dispatch = useAppDispatch()
    const { t } = useTranslation()
    const { isOpened, task, operator, isBack } = useAppSelector(selectQueuesTaskSidebar)

    const handleCloseSidebar = useCallback(() => {
        dispatch(actions.closeQueuesTaskSidebar())
        dispatch(actions.closeQueuesOperatorSidebar())
    }, [dispatch])

    const [taskMessagesQueryTrigger] = useLazyGetTasksMessagesQuery()
    const operatorByIdQuery = useGetOperatorByIdQuery(operator?.Id ?? skipToken)

    const renderTaskView = (operatorData: Operator | undefined) => (
        <TaskView
            task={task}
            channelNames={undefined}
            operator={operatorData}
            queues={allQueues}
            onClose={() => dispatch(actions.closeQueuesTaskSidebar())}
        />
    )

    return (
        <SidebarRight
            open={isOpened}
            title={t(`${tNamespace}task-view`)}
            onCloseClick={handleCloseSidebar}
            onClose={() => void 0}
            onBack={isBack ? () => dispatch(actions.closeQueuesTaskSidebar()) : undefined}
            action={
                <IconControl
                    icon={faSync}
                    size="1x"
                    onClick={() => {
                        taskMessagesQueryTrigger(task.Id)
                    }}
                />
            }
        >
            {operator ? (
                renderTaskView(operator)
            ) : (
                <AsyncQuery
                    query={operatorByIdQuery}
                    processView={<QueuesTaskSidebarLoader />}
                    errorView={({ message }) => <ErrorMessage text={message} />}
                >
                    {({ data }) => renderTaskView(data)}
                </AsyncQuery>
            )}
        </SidebarRight>
    )
}

export default QueuesTaskSidebar
