import { ForgotPasswordRequest, ResetPasswordRequest, VerifyResetPasswordCodeResponse } from "./../../models/password"
import { SignUpRequestOidc, VerifySignUpCodeResponse } from "../../models/signUp"
import { apiInstance } from "../instances/apiInstance"
import { SignUpRequest, SendSignUpEmailRequest } from "../../models/signUp"
import { User } from "../../models/user"
import { LoginRequest } from "../../models/login"

const accountController = {
    login: (loginData: LoginRequest): Promise<User> =>
        apiInstance.post("/login", loginData).then(response => response.data),
    logout: (): Promise<void> => apiInstance.get("/logout").then(response => response.data),
    sendSignUpEmail: (request: SendSignUpEmailRequest): Promise<void> =>
        apiInstance.post("/sign_up/send_email", request).then(response => response.data),
    signUp: (request: SignUpRequest): Promise<User> =>
        apiInstance.post("/sign_up", request, { timeout: 60000 }).then(response => response.data),
    signUpOidc: (request: SignUpRequestOidc): Promise<User> =>
        apiInstance.post("/sign_up_oidc", request).then(response => response.data),
    verifySignUpCode: (code: string): Promise<VerifySignUpCodeResponse> =>
        apiInstance.get("/sign_up/verify/" + code).then(response => response.data),
    forgotPassword: (request: ForgotPasswordRequest): Promise<void> =>
        apiInstance.post("/forgot_password", request).then(response => response.data),
    resetPassword: (request: ResetPasswordRequest): Promise<void> =>
        apiInstance.post("/reset_password", request).then(response => response.data),
    verifyResetPasswordCode: (code: string): Promise<VerifyResetPasswordCodeResponse> =>
        apiInstance.get("/reset_password/verify/" + code).then(response => response.data)
}

export default accountController
