import React from "react"
import styles from "./ChannelIconLarge.module.scss"
import { ChannelTypeString } from "../../models/channel"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFacebookMessenger, faOdnoklassnikiSquare, faSkype, faTelegram } from "@fortawesome/free-brands-svg-icons"
import { faComment, faEnvelope, faPhoneAlt } from "@fortawesome/pro-light-svg-icons"
import { ReactComponent as MsTeamsLogo } from "../../assets/images/channels/msTeams.svg"
import { ReactComponent as ViberLogo } from "../../assets/images/channels/viber.svg"
import { ReactComponent as WhatsappLogo } from "../../assets/images/channels/whatsapp.svg"
import { ReactComponent as VkLogo } from "../../assets/images/channels/vk.svg"
import { ReactComponent as YandexDialogsLogo } from "../../assets/images/channels/yandexDialogs.svg"
import { ReactComponent as YandexAlisaLogo } from "../../assets/images/channels/yandexAlisa.svg"
import cn from "classnames"
import { ReactComponent as InstagramLogo } from "../../assets/images/channels/instagram.svg"

export interface ChannelIconLargeProps {
    type: string
    className?: string
}

const baseClassName = styles.channelIconLarge

const ChannelIconLarge: React.FC<ChannelIconLargeProps> = props => {
    const { type = ChannelTypeString.WebChat, className } = props
    const mapTypeToClassName = (type: string) => `${type}-icon`

    const getIconClassName = (iconClassName?: string) =>
        cn(baseClassName, iconClassName ? iconClassName : mapTypeToClassName(type), className)
    const iconImageClasName = (iconClassName?: string) =>
        cn(getIconClassName(iconClassName), styles.channelIconLarge_img)

    if (type.startsWith(ChannelTypeString.Whatsapp)) {
        return <WhatsappLogo className={getIconClassName()} />
    }

    switch (type) {
        case ChannelTypeString.WebChat:
            return <FontAwesomeIcon icon={faComment} className={getIconClassName(styles.webchatIcon)} />
        case ChannelTypeString.Phone:
            return <FontAwesomeIcon icon={faPhoneAlt} className={getIconClassName()} />
        case ChannelTypeString.Telegram:
            return <FontAwesomeIcon icon={faTelegram} className={getIconClassName(styles.telegramIcon)} />
        case ChannelTypeString.Viber:
            return <ViberLogo className={getIconClassName()} />
        case ChannelTypeString.Skype:
            return <FontAwesomeIcon icon={faSkype} className={getIconClassName()} />
        case ChannelTypeString.Email:
            return <FontAwesomeIcon icon={faEnvelope} className={getIconClassName()} />
        case ChannelTypeString.VK:
            return <VkLogo className={getIconClassName()} />
        case ChannelTypeString.Odnoklassniki:
            return (
                <FontAwesomeIcon icon={faOdnoklassnikiSquare} className={getIconClassName(styles.odnoklassnikiIcon)} />
            )
        case ChannelTypeString.Facebook:
            return <FontAwesomeIcon icon={faFacebookMessenger} className={getIconClassName(styles.facebookIcon)} />
        case ChannelTypeString.MSBot:
        case ChannelTypeString.Teams:
            return <MsTeamsLogo className={getIconClassName()} />
        case ChannelTypeString.YandexAlisa:
            return <YandexAlisaLogo className={getIconClassName()} />
        case ChannelTypeString.YandexDialogs:
            return <YandexDialogsLogo className={getIconClassName()} />
        case ChannelTypeString.Instagram:
            return <InstagramLogo className={getIconClassName()} />
        default:
            return <FontAwesomeIcon icon={faEnvelope} className={getIconClassName()} />
    }
}

export default ChannelIconLarge
