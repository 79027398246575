import React, { useEffect, useState } from "react"
import { OptionType } from "../../../../AsyncSearchableInput/AsyncSearchableInput"
import { TFunction } from "i18next"
import CreatableTagSelect from "../../../../CreatableTagSelect/CreatableTagSelect"
import { formTranslation } from "../../../../../locales/form"
import { useSelector } from "react-redux"
import { selectBandwidthGroups } from "../../../../../store/projects/selectors"
import { getCreatableTagSelectOptions } from "../../../../../helpers/options"

export interface QueueDialogGroupsProps {
    fieldName: string
    t: TFunction
}

const tNamespace = "queues:settings."

const QueueDialogGroups: React.FC<QueueDialogGroupsProps> = props => {
    const { fieldName, t } = props
    const bandwidthGroups = useSelector(selectBandwidthGroups)

    const [groups, setGroups] = useState<OptionType[]>([
        { value: t(formTranslation.all), label: t(formTranslation.all) }
    ])

    useEffect(() => {
        bandwidthGroups.data && setGroups([...groups, ...getCreatableTagSelectOptions(bandwidthGroups.data, "Title")])
    }, [bandwidthGroups])

    return (
        <CreatableTagSelect
            id={"formQueueDialogGroups"}
            name={fieldName}
            placeholder={!(groups.length === 1) ? t(`${tNamespace}select-type`) : t(formTranslation.all)}
            options={groups}
            disabled={groups.length === 1}
            disableOnAllSelected
            nonCreatable
            t={t}
        />
    )
}

export default QueueDialogGroups
