import React, { ReactNode } from "react"
import FormAccordion from "../FormAccordion/FormAccordion"
import SettingsGroupContent, { SettingsGroupContentProps } from "../SettingsGroupContent/SettingsGroupContent"

export type SettingsGroupWrapperProps = {
    dataTestId?: string
    children?: ReactNode
    className?: string
} & (
    | {
          withoutAccordion: true
      }
    | {
          withoutAccordion?: false
          title: string
      }
)

export type SettingsGroupProps<T> = SettingsGroupWrapperProps & SettingsGroupContentProps<T>

function SettingsGroup<T>(props: SettingsGroupProps<T>) {
    const { className, dataTestId, children, withoutAccordion, ...contentProps } = props

    if (withoutAccordion) {
        return (
            <>
                <SettingsGroupContent {...contentProps} />
                {children}
            </>
        )
    }

    return (
        <div data-testid={dataTestId}>
            <FormAccordion title={props.title} className={className}>
                <SettingsGroupContent {...contentProps} />
                {children}
            </FormAccordion>
        </div>
    )
}

export default SettingsGroup
