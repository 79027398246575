import { CurrentArticleState } from "../store/knowledgeBase/slice"
import { ArticleStatusType } from "./article"

export type ArticleCompareRequest = {
    FirstArticleId: string
    SecondArticleId: string
}

export type ArticleCompareUpdateState = {
    text: string
    Article: CurrentArticleState
}
export enum ComparisonType {
    Unmodified = "Unmodified",
    Removed = "Removed",
    Added = "Added",
    Ignored = "Ignored"
}

export enum MarkdownTypes {
    Widget = "Widget",
    Buttons = "Buttons",
    Scenario = "Scenario",
    QuickActions = "QuickActions",
    CodeBlock = "CodeBlock",
    File = "File",
    Link = "Link",
    Header = "Header",
    Image = "Image",
    Table = "Table",
    BulletedList = "BulletedList",
    CheckList = "CheckList",
    NumberedList = "NumberedList",
    Quote = "Quote",
    Text = "Text",
    Separator = "Separator",
    DottedSeparator = "DottedSeparator"
}

export enum BlocksWithUniqueRules {
    Quote = "Quote",
    Text = "Text",
    Link = "Link"
}

export type Comparison<T> = {
    ComparisonType: ComparisonType
    Value: T
    ReplacedValue?: T
}

export type TextComparison = {
    Comparison: Comparison<string>
    MarkdownType: MarkdownTypes
}

export type AnswersComparison = {
    Id: Comparison<string>
    Text: TextComparison[]
    Slots: Comparison<string>[]
}

export type QuestionComparison = {
    Sentence: Comparison<string>
}

export type ArticleParameterComparison = {
    SlotId: Comparison<string>
    Value: Comparison<string>
}

export type ArticleCompareResponse = {
    Comparison: {
        Answers: AnswersComparison[]
        Title: Comparison<string>[]
        Status: Comparison<ArticleStatusType>
        Questions: QuestionComparison[]
        Version: Comparison<number>
        Expire: Comparison<number>
        ModifiedDate: Comparison<number>
        ModifiedUserLogin: Comparison<string>
        Type: Comparison<string>
        Tags: Comparison<string>[]
        Parameters: ArticleParameterComparison[]
    }
}

export enum ArticleCompareMode {
    Default = "default",
    Version = "articleVersion",
    DifferentArticles = "differentArticles"
}

export type OffsetsRecord = {
    open: number
    close: number
}

export enum ComparisonBlockType {
    Removed = "Removed",
    Added = "Added",
    Ignored = "Ignored",
    Close = "Close"
}

export type ComparisonCloseColor = ComparisonBlockType.Added | ComparisonBlockType.Removed

export type QuestionAndAnswersComparisonResult = {
    CountAfterCompare: number
    OldCount: number
    isNothingToCompare: boolean
}
