import { RootState } from "../rootReducer"

export const selectGetQueuesState = (state: RootState) => state.queues.queues
export const selectQueues = (state: RootState) => Object.values(state.queues.queuesMap).flat()
export const selectCreateQueueState = (state: RootState) => state.queues.createQueue
export const selectUpdateQueueState = (state: RootState) => state.queues.updateQueue
export const selectUpdateIndividualQueuesState = (state: RootState) => state.queues.updateIndividualQueues
export const selectMonitoringOverviewState = (state: RootState) => state.queues.monitoringOverview
export const selectQueueCategories = (state: RootState) => state.queues.queueCategories
export const selectQueueCategoriesForReroute = (state: RootState) => state.queues.queueCategoriesForReroute
export const selectGetQueueCategoriesState = (state: RootState) => state.queues.getQueueCategories
export const selectMonitoringOverviewCategoryId = (state: RootState) =>
    state.queues.monitoringOverviewCategoryState.CategoryId
export const selectMonitoringOverviewDisplayed = (state: RootState) =>
    state.queues.monitoringOverviewCategoryState.Displayed
export const selectSearchQueuesResponse = (state: RootState) => state.queues.searchResponse
export const selectQueueExtendedSettings = (state: RootState) => state.queues.queueExtendedSettings.data
export const selectIsDialogTransferSidebarOpened = (state: RootState) => state.queues.isDialogTransferSidebarOpened
export const selectQueueTasksSidebar = (state: RootState) => state.queues.queuesTasksSidebar
export const selectQueuePendingTasksSidebar = (state: RootState) => state.queues.queuesPendingTasksSidebar
export const selectQueuesOperatorSidebar = (state: RootState) => state.queues.queuesOperatorSidebar
export const selectQueuesTaskSidebar = (state: RootState) => state.queues.queuesTaskSidebar
export const selectQueuesSidebar = (state: RootState) => state.queues.queueSidebar
