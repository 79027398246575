import React, { useEffect } from "react"
import { FormikSlotFormProps, tSlotFormNamespace } from "../SlotForm/SlotForm"
import { Form } from "react-bootstrap"
import { preventSubmitOnEnter } from "../../../utility/common/preventSubmitOnEnter"
import { nameof } from "../../../utility/common/nameof"
import { SlotType, SlotValues } from "../../../models/slot"
import { formTranslation } from "../../../locales/form"
import CheckBoxValidatableInput from "../../CheckBoxValidatableInput/CheckBoxValidatableInput"
import LoadingButton from "../../LoadingButton/LoadingButton"
import DebouncedValidatableInput from "../../ValidatableInput/DebouncedValidatableInput"
import InfoIcon from "../../InfoIcon/InfoIcon"

const tTooltipNamespace = "slot:tooltip."

const SlotFormAdvanced: React.FC<FormikSlotFormProps> = props => {
    const { asyncState, handleSubmit, t, values, setFieldValue } = props

    useEffect(() => {
        if (values.NextValueQuestion) {
            setFieldValue(nameof<SlotValues>("UsesMultipleValues"), true, false)
        }
    }, [values.NextValueQuestion])

    return (
        <Form className="slot-form" onSubmit={handleSubmit} onKeyPress={preventSubmitOnEnter}>
            <div className="slot-form__content">
                <div className="slot-form__section">
                    <CheckBoxValidatableInput
                        id="formPrefill"
                        name={nameof<SlotValues>("Prefill")}
                        label={t(`${tSlotFormNamespace}prefill-from-user-question`)}
                        icon={
                            <InfoIcon
                                id="tooltipShowCancelButton"
                                content={t(`${tTooltipNamespace}prefill-from-user-question`)}
                            />
                        }
                    />
                    {values.Type === SlotType.Dictionary && (
                        <CheckBoxValidatableInput
                            id="formDontShowButtons"
                            name={nameof<SlotValues>("DontShowButtons")}
                            label={t(`${tSlotFormNamespace}do-not-show-buttons`)}
                        />
                    )}
                    <CheckBoxValidatableInput
                        id="formSkipClassification"
                        name={nameof<SlotValues>("SkipClassification")}
                        label={t(`${tSlotFormNamespace}skip-classification-when-clarifying`)}
                        icon={
                            <InfoIcon
                                id="tooltipShowCancelButton"
                                content={t(`${tTooltipNamespace}skip-classification-when-clarifying`)}
                            />
                        }
                    />
                    <CheckBoxValidatableInput
                        id="formShowCancelButton"
                        name={nameof<SlotValues>("ShowCancelButton")}
                        label={t(`${tSlotFormNamespace}show-cancel-button`)}
                        icon={
                            <InfoIcon
                                id="tooltipShowCancelButton"
                                content={t(`${tTooltipNamespace}show-cancel-button`)}
                            />
                        }
                    />
                </div>
                <div className="slot-form__section">
                    {values.Type === SlotType.Calendar && (
                        <CheckBoxValidatableInput
                            id="formUseExternalDomainSource"
                            name={nameof<SlotValues>("UseExternalDomainSource")}
                            label={t(`${tSlotFormNamespace}use-agent-to-get-data`)}
                        />
                    )}
                    {values.Type === SlotType.Attachment && (
                        <>
                            <DebouncedValidatableInput
                                id="formNextValueQuestion"
                                as="textarea"
                                name={nameof<SlotValues>("NextValueQuestion")}
                                label={t(`${tSlotFormNamespace}question-to-get-next-value`)}
                                className="slot-form__textarea"
                            />
                            <CheckBoxValidatableInput
                                id="formUsesMultipleValues"
                                name={nameof<SlotValues>("UsesMultipleValues")}
                                label={t(`${tSlotFormNamespace}uses-multiple-values`)}
                                className={!!values.NextValueQuestion ? "slot-form__checkbox_disabled" : undefined}
                            />
                        </>
                    )}
                </div>
            </div>
            <div className="slot-form__footer">
                <LoadingButton type="submit" variant="primary" block loading={asyncState.inProcess}>
                    {t(formTranslation.save)}
                </LoadingButton>
            </div>
        </Form>
    )
}

export default SlotFormAdvanced
