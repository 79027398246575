import React from "react"
import styles from "./LinkClientToOperatorPopover.module.scss"
import PopoverTrigger from "../PopoverTrigger/PopoverTrigger"
import {
    LinkClientToOperatorForm,
    LinkClientToOperatorFormProps
} from "../LinkClientToOperatorForm/LinkClientToOperatorForm"

interface LinkClientToOperatorPopoverProps extends LinkClientToOperatorFormProps {
    children: JSX.Element
    show: boolean
}

const LinkClientToOperatorPopover: React.FC<LinkClientToOperatorPopoverProps> = props => {
    const { title, show, loading, children, onSubmit, onCancel, clientSubscriptions, clientId, currentProject } = props

    return (
        <PopoverTrigger
            className={styles.linkClientToOperatorPopover}
            id="link-client-to-operator"
            content={
                <LinkClientToOperatorForm
                    title={title}
                    loading={loading}
                    onSubmit={onSubmit}
                    onCancel={onCancel}
                    clientSubscriptions={clientSubscriptions}
                    clientId={clientId}
                    currentProject={currentProject}
                />
            }
            trigger="click"
            placement="bottom"
            show={show}
        >
            {children}
        </PopoverTrigger>
    )
}

export default LinkClientToOperatorPopover
