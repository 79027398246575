import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLongArrowLeft } from "@fortawesome/pro-light-svg-icons/faLongArrowLeft"
import { useTranslation } from "react-i18next"
import styles from "./ArticleCompareCancelButton.module.scss"
import { useDispatch, useSelector } from "react-redux"
import { selectOldArticle } from "../../store/knowledgeBase/selectors"
import { returnToComparedVersion } from "../../store/knowledgeBase/actions"

const tNamespace = "knowledgeBase"

const ArticleCompareCancelButton = () => {
    const { t } = useTranslation(tNamespace)

    const oldArticle = useSelector(selectOldArticle)
    const dispatch = useDispatch()
    const handleCancelClick = () => {
        if (oldArticle) {
            dispatch(returnToComparedVersion(oldArticle))
        }
    }

    return (
        <div className={styles.cancelButton} onClick={handleCancelClick}>
            <FontAwesomeIcon icon={faLongArrowLeft} className={styles.cancelButton__icon} size={"lg"} />
            {t(`article-actions.compare-cancel`)}
        </div>
    )
}

export default ArticleCompareCancelButton
