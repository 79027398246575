import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import AsyncState from "../../core/asyncState"
import { SystemError } from "../../core/error"
import { Sticker } from "../../models/sticker"
import { resetReducerState } from "../action"

export type StickersState = Readonly<{
    stickers: AsyncState<Sticker[]>
    createSticker: AsyncState<void>
    updateSticker: AsyncState<void>
    deleteSticker: AsyncState<void>
}>
const initialState: StickersState = {
    stickers: AsyncState.create(),
    createSticker: AsyncState.create(),
    updateSticker: AsyncState.create(),
    deleteSticker: AsyncState.create()
}

const stickers = createSlice({
    name: "stickers",
    initialState,
    reducers: {
        getStickersProcess(state) {
            state.stickers = state.stickers.toProcess()
        },
        getStickersSuccess(state, action: PayloadAction<Sticker[]>) {
            state.stickers = state.stickers.toSuccess(action.payload)
        },
        getStickersFailed(state, action: PayloadAction<SystemError>) {
            state.stickers = state.stickers.toFailed(action.payload)
        },
        createStickerProcess(state) {
            state.createSticker = state.createSticker.toProcess()
        },
        createStickerSuccess(state, action: PayloadAction<Sticker>) {
            state.createSticker = state.createSticker.toSuccess()
            state.stickers = state.stickers.map(v => [...v, action.payload])
        },
        createStickerFailed(state, action: PayloadAction<SystemError>) {
            state.createSticker = state.createSticker.toFailed(action.payload)
        },
        updateStickerProcess(state) {
            state.updateSticker = state.updateSticker.toProcess()
        },
        updateStickerSuccess(state, action: PayloadAction<Sticker>) {
            state.updateSticker = state.updateSticker.toSuccess()
            state.stickers = state.stickers.map(v =>
                v.map(sticker => (sticker.Id === action.payload.Id ? action.payload : sticker))
            )
        },
        updateStickerFailed(state, action: PayloadAction<SystemError>) {
            state.updateSticker = state.updateSticker.toFailed(action.payload)
        },
        deleteStickerProcess(state) {
            state.deleteSticker = state.deleteSticker.toProcess()
        },
        deleteStickerSuccess(state, action: PayloadAction<string>) {
            state.deleteSticker = state.deleteSticker.toSuccess()
            state.stickers = state.stickers.map(v => v.filter(sticker => sticker.Id !== action.payload))
        },
        deleteStickerFailed(state, action: PayloadAction<SystemError>) {
            state.deleteSticker = state.deleteSticker.toFailed(action.payload)
        }
    },
    extraReducers(builder) {
        builder.addCase(resetReducerState, () => {
            return initialState
        })
    }
})

export const StickerReducer = stickers.reducer

export const actions = stickers.actions
