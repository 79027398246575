import React, { useContext, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import PublicPage from "../../components/PublicPage/PublicPage"
import SendSignUpEmailForm from "../../components/SendSignUpEmailForm/SendSignUpEmailForm"
import { selectSentEmailAddress } from "../../store/signUp/selectors"
import { useTranslation } from "react-i18next"
import { actions } from "../../store/signUp/slice"
import ctEmailConfirm from "../../assets/images/crafttalk/ctEmailConfirm.png"
import ConfigContext from "../../components/ConfigContext/ConfigContext"
import { Redirect } from "react-router"

const tNamespace = "signUp:email-form."

const SendSignUpEmail: React.FC = () => {
    const {
        WebConfig: {
            appSettings: { signUpEnabled }
        }
    } = useContext(ConfigContext)
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const sentEmailAddress = useSelector(selectSentEmailAddress)

    useEffect(() => {
        return () => {
            dispatch(actions.sendSignUpEmailClear())
        }
    }, [dispatch])
    if (!signUpEnabled) {
        return <Redirect to="/" />
    }
    return (
        <PublicPage contentInCenter>
            {sentEmailAddress ? (
                <PublicPage.Message
                    title={t(`${tNamespace}email-sent-message-title`)}
                    text={t(`${tNamespace}email-sent-message-text`, { email: sentEmailAddress })}
                >
                    <img src={ctEmailConfirm} alt="" />
                </PublicPage.Message>
            ) : (
                <SendSignUpEmailForm />
            )}
        </PublicPage>
    )
}

export default SendSignUpEmail
