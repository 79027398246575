import { ProjectUser } from "../../models/projectUser"
import { SimpleUser } from "../../models/user"

export const convertProjectToSimpleUser = (projectUser: ProjectUser): SimpleUser => ({
    Login: projectUser.Login,
    FirstName: projectUser.FirstName,
    LastName: projectUser.LastName,
    Picture: projectUser.Picture,
    CurrentRoleId: projectUser.ProjectSettings.CurrentRoleId,
    ProjectId: projectUser.ProjectId
})
