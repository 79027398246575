import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import AsyncState from "../../core/asyncState"
import { SystemError } from "../../core/error"
import { getCurrentUserFailed, getCurrentUserSuccess } from "../users/actions"
import { actions as signUpActions } from "../signUp/slice"
import { loginFailed, loginProcess, loginSuccess, setUnauthorized } from "./actions"
import { resetReducerState } from "../action"

export type AuthState = Readonly<{
    authenticated: boolean
    authRequested: boolean
    login: AsyncState<void>
    logout: AsyncState<void>
}>

const initialState: AuthState = {
    authenticated: false,
    authRequested: false,
    login: AsyncState.create(),
    logout: AsyncState.create()
}

const auth = createSlice({
    name: "auth",
    initialState,
    reducers: {
        logoutProcess(state) {
            state.logout = state.logout.toProcess()
        },
        logoutSuccess(state) {
            state.authenticated = false
            state.authRequested = false
            state.logout = state.logout.toSuccess()
        },
        logoutFailed(state, action: PayloadAction<SystemError>) {
            state.logout = state.logout.toFailed(action.payload)
        }
    },
    extraReducers(builder) {
        builder
            .addCase(resetReducerState, () => {
                return initialState
            })
            .addCase(setUnauthorized, state => {
                state.authenticated = false
                state.authRequested = true
            })
            .addCase(loginProcess, state => {
                state.login = state.login.toProcess()
            })
            .addCase(loginSuccess, state => {
                state.authenticated = true
                state.authRequested = true
                state.login = state.login.toSuccess()
            })
            .addCase(loginFailed, (state, action: PayloadAction<SystemError>) => {
                state.login = state.login.toFailed(action.payload)
            })
            .addCase(getCurrentUserSuccess, state => {
                state.authenticated = true
                state.authRequested = true
            })
            .addCase(getCurrentUserFailed, state => {
                state.authenticated = false
                state.authRequested = false
            })
            .addCase(signUpActions.signUpByEmailSuccess, state => {
                state.authenticated = true
                state.authRequested = true
            })
    }
})

export const AuthReducer = auth.reducer

export const actions = auth.actions
