import React from "react"
import { AgentType } from "../../models/agent"
import { ClassProps } from "../../utility/common/props"
import { faFileMedical } from "@fortawesome/pro-light-svg-icons/faFileMedical"
import { faMapMarkerCheck } from "@fortawesome/pro-light-svg-icons/faMapMarkerCheck"
import { faMapMarkerAlt } from "@fortawesome/pro-light-svg-icons/faMapMarkerAlt"
import { faCommentTimes } from "@fortawesome/pro-light-svg-icons/faCommentTimes"
import { faCommentAltEdit } from "@fortawesome/pro-light-svg-icons/faCommentAltEdit"
import { faFileCheck } from "@fortawesome/pro-light-svg-icons/faFileCheck"
import { faFileMinus } from "@fortawesome/pro-light-svg-icons/faFileMinus"
import { faLockOpenAlt } from "@fortawesome/pro-light-svg-icons/faLockOpenAlt"
import { faLaptop } from "@fortawesome/pro-light-svg-icons/faLaptop"
import { faQuestionSquare } from "@fortawesome/pro-light-svg-icons/faQuestionSquare"
import { faCommentAltPlus } from "@fortawesome/pro-light-svg-icons/faCommentAltPlus"
import { faUserClock } from "@fortawesome/pro-light-svg-icons/faUserClock"
import { faEnvelopeOpen } from "@fortawesome/pro-light-svg-icons/faEnvelopeOpen"
import { faCommentLines } from "@fortawesome/pro-light-svg-icons/faCommentLines"
import { faUserFriends } from "@fortawesome/pro-light-svg-icons/faUserFriends"
import { faCommentAltDots } from "@fortawesome/pro-light-svg-icons/faCommentAltDots"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { SizeProp } from "@fortawesome/fontawesome-svg-core"
import cn from "classnames"
import { ReactComponent as JSLogo } from "../../assets/images/letters/js.svg"
import { ReactComponent as BSSLogo } from "../../assets/images/letters/bss.svg"
import { ReactComponent as ABLogo } from "../../assets/images/letters/ab.svg"
import { ReactComponent as JqLogo } from "../../assets/images/letters/jq.svg"
import { faStar } from "@fortawesome/pro-light-svg-icons"

const svgClassName = "svg-inline--fa"

export interface AgentIconProps extends ClassProps {
    type: string
    size?: SizeProp
}

const AgentIcon: React.FC<AgentIconProps> = props => {
    const { type, className, size } = props

    switch (type) {
        case AgentType.AIAssist2:
            return <FontAwesomeIcon icon={faCommentAltDots} className={className} size={size} />
        case AgentType.RoutingAgent:
            return <FontAwesomeIcon icon={faUserFriends} className={className} size={size} />
        case AgentType.InitialMsgAgent:
            return <FontAwesomeIcon icon={faCommentLines} className={className} size={size} />
        case AgentType.EmailAgent:
            return <FontAwesomeIcon icon={faEnvelopeOpen} className={className} size={size} />
        case AgentType.ABAgent:
            return <ABLogo className={cn(svgClassName, className)} />
        case AgentType.NightAgent:
            return <FontAwesomeIcon icon={faUserClock} className={className} size={size} />
        case AgentType.RestAgent:
            return <FontAwesomeIcon icon={faCommentAltPlus} className={className} size={size} />
        case AgentType.Unknown:
            return <FontAwesomeIcon icon={faQuestionSquare} className={className} size={size} />
        case AgentType.RemoteWorkplaceAgent:
            return <FontAwesomeIcon icon={faLaptop} className={className} size={size} />
        case AgentType.FixedReplyAgent:
            return <FontAwesomeIcon icon={faFileCheck} className={className} size={size} />
        case AgentType.FinishDialogAgent:
            return <FontAwesomeIcon icon={faCommentTimes} className={className} size={size} />
        case AgentType.ScoreAgent:
            return <FontAwesomeIcon icon={faStar} className={className} size={size} />
        case AgentType.SurveysAgent:
            return <FontAwesomeIcon icon={faCommentAltEdit} className={className} size={size} />
        case AgentType.IdentificationAgent:
            return <FontAwesomeIcon icon={faFileMinus} className={className} size={size} />
        case AgentType.JqAgent:
            return <JqLogo className={cn(svgClassName, className)} />
        case AgentType.JSAgent:
            return <JSLogo className={cn(svgClassName, className)} />
        case AgentType.MEDODSIntegration:
            return <FontAwesomeIcon icon={faFileMedical} className={className} size={size} />
        case AgentType.ADFSAgent:
            return <FontAwesomeIcon icon={faLockOpenAlt} className={className} size={size} />
        case AgentType.PsbBssAgent:
            return <BSSLogo className={cn("bss-agent", svgClassName, className)} />
        case AgentType.YandexMapAgent:
            return <FontAwesomeIcon icon={faMapMarkerAlt} className={className} size={size} />
        case AgentType.YandexMapGeocodeAgent:
            return <FontAwesomeIcon icon={faMapMarkerCheck} className={className} size={size} />
        default:
            return null
    }
}

export default AgentIcon
