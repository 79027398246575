import { OptionsType } from "react-select/src/types"
import { OptionType } from "../../components/AsyncSearchableInput/AsyncSearchableInput"
import { logError } from "../common/logError"
import { dialogsApi } from "../../api/controllers/dialogs"
import { Dispatch } from "../common/storeHelper"
import { ClientSubscriptionRequest, ClientSubscriptionResponse, LinkCase } from "../../models/Dialogs/linksToOperator"
import { OptionMeta } from "../knowledgeBase/accessOptions"

const sortByCaseAndName = (a: ClientSubscriptionResponse, b: ClientSubscriptionResponse) => {
    if (a.Case < b.Case) return -1
    if (a.Case > b.Case) return 1

    if (a.Case === LinkCase.OPERATOR && b.Case === LinkCase.OPERATOR) {
        if (a.Fields.Firstname < b.Fields.Firstname) return -1
        if (a.Fields.Firstname > b.Fields.Firstname) return 1

        if (a.Fields.Lastname < b.Fields.Lastname) return -1
        if (a.Fields.Lastname > b.Fields.Lastname) return 1
    }

    if (a.Case === LinkCase.ROLE && b.Case === LinkCase.ROLE) {
        if (a.Fields.Title < b.Fields.Title) return -1
        if (a.Fields.Title > b.Fields.Title) return 1
    }

    return 0
}

export const searchUsersAndRolesToLink =
    (dispatch: Dispatch, data: ClientSubscriptionRequest) =>
    async (text: string): Promise<OptionsType<OptionType>> => {
        try {
            const subscribes = await dispatch(
                dialogsApi.endpoints.postClientSubscriptions.initiate({ ...data, Query: text })
            )

            if (!subscribes.data) return []

            const sortedSubscribes = [...subscribes.data].sort(sortByCaseAndName)

            return (
                sortedSubscribes.map(data => ({
                    label:
                        data.Case === LinkCase.OPERATOR
                            ? `${data.Fields.Firstname} ${data.Fields.Lastname}`
                            : data.Fields.Title,
                    value: data.Case === LinkCase.OPERATOR ? data.Fields.OperatorId : data.Fields.RoleId,
                    meta: data.Case === LinkCase.OPERATOR ? OptionMeta.User : OptionMeta.Role
                })) || []
            )
        } catch (e) {
            logError(e)
            return []
        }
    }
