import React, { useCallback } from "react"
import { useTranslation } from "react-i18next"
import SidebarRight from "../SidebarRight/SidebarRight"
import { DialogTransferModalForm, IDialogTransferModalFormProps } from "../DialogTransferModal/DialogTransferModalForm"
import { selectIsDialogTransferSidebarOpened } from "../../store/queues/selectors"
import { useAppDispatch, useAppSelector } from "../../store/hooks"
import { actions } from "../../store/queues/slice"

const tNamespace = "dialogs:transfer."

const SIDEBAR_WIDTH = 550

interface Props extends IDialogTransferModalFormProps {
    className: string
    queueId?: string
}

const DialogTransferFormSidebar: React.FC<Props> = props => {
    const { channelId, onSubmitForm, includeCurrentUser, className, queueId } = props
    const { t } = useTranslation()
    const dispatch = useAppDispatch()

    const isShown = useAppSelector(selectIsDialogTransferSidebarOpened)
    const handleCloseClick = useCallback(() => {
        dispatch(actions.setIsDialogTransferSidebarOpened(false))
    }, [dispatch])

    return (
        <SidebarRight
            title={t(`${tNamespace}transfer-task`)}
            open={isShown}
            onCloseClick={handleCloseClick}
            onClose={() => void 0}
            width={SIDEBAR_WIDTH}
        >
            <div className={className}>
                <DialogTransferModalForm
                    isInDialog={false}
                    channelId={channelId}
                    onSubmitForm={onSubmitForm}
                    includeCurrentUser={includeCurrentUser}
                    queueId={queueId}
                />
            </div>
        </SidebarRight>
    )
}

export default DialogTransferFormSidebar
