import cn from "classnames"
import { memo, useEffect, useRef, useState } from "react"
import { useIsTabActive } from "../../hooks/useIsTabActive"
import { useAppSelector } from "../../store/hooks"
import { selectCurrentProjectId } from "../../store/projects/selectors"
import { testId } from "../../utility/tests/testId"
import PopoverTrigger from "../PopoverTrigger/PopoverTrigger"
import { BounceIndicator } from "./components/BounceIndicator/BounceIndicator"
import { NewslineItem } from "./components/NewslineItem/NewslineItem"
import { NewsList } from "./components/NewsList/NewsList"
import styles from "./Newsline.module.scss"
import { selectNews } from "../../store/knowledgeBase/selectors"
import { useDispatch } from "react-redux"
import { fetchNewsline } from "../../api/controllers/knowledgeBase/helpers"
import { newslineHub } from "../../api/instances/newslineHub"

interface NewslineProps {
    className?: string
}

const popperConfig = { modifiers: [{ name: "offset", options: { offset: [0, 0] } }] }

export const Newsline = memo(({ className }: NewslineProps) => {
    const [listKey, setListKey] = useState(0)
    const news = useAppSelector(selectNews)
    const dispatch = useDispatch()
    const newslineRef = useRef<HTMLDivElement>(null)
    const projectId = useAppSelector(selectCurrentProjectId)
    const isTabActive = useIsTabActive()

    useEffect(() => {
        setListKey(prevKey => prevKey + 1)
    }, [news])

    useEffect(() => {
        if (projectId) {
            dispatch(fetchNewsline(projectId))
        }
    }, [projectId, dispatch])

    useEffect(() => {
        newslineHub.subscribe()
        newslineHub.registerServerEvents()

        return () => {
            newslineHub.unregisterServerEvents()
            newslineHub.unsubscribe()
        }
    }, [])

    if (news && news.length) {
        return (
            <PopoverTrigger
                id="newsline-list"
                className={styles.popover}
                content={<NewsList list={news} />}
                trigger="click"
                placement="bottom"
                popperConfig={popperConfig}
                container={() => newslineRef.current}
                rootClose
            >
                <div className={cn(styles.newsline, className)} ref={newslineRef} data-testid={testId.newsline}>
                    <BounceIndicator />
                    <ul className={styles.news} key={listKey}>
                        {news.map((newsItem, i) => (
                            <NewslineItem
                                key={newsItem.ArticleCode}
                                item={newsItem}
                                index={i}
                                total={news.length}
                                isTabActive={isTabActive}
                            />
                        ))}
                    </ul>
                </div>
            </PopoverTrigger>
        )
    }

    return null
})
