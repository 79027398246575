import * as Yup from "yup"
import { NewslineKeys } from "../../../models/knowledgeBase/newsline"
import { NEWLINE_DESCRIPTION_REGEXP } from "../constants"

const tNamespace = "newsline:form.error."

export const NewslineFormSchema = Yup.object().shape({
    [NewslineKeys.lifeTime]: Yup.number().when(NewslineKeys.isEndless, {
        is: false,
        then: Yup.number().notOneOf([0], `${tNamespace}${NewslineKeys.lifeTime}`)
    }),
    [NewslineKeys.description]: Yup.string().matches(NEWLINE_DESCRIPTION_REGEXP, {
        message: `${tNamespace}${NewslineKeys.description}`,
        excludeEmptyString: true
    })
})
