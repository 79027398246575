import React from "react"
import { Button } from "react-bootstrap"
import PublicPage from "../../components/PublicPage/PublicPage"
import "./AccessDenied.scss"
import ctAccessDenied from "../../assets/images/crafttalk/ctAccessDenied.svg"
import { unsubscribeFromUsersData } from "../../store/userOperator/thunks"
import { redirectToLogout } from "../../utility/auth/credentialHelper"
import { useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"

const tNamespace = "error:403:"

const AccessDeniedPage: React.FC = () => {
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const handleLogout = () => {
        dispatch(unsubscribeFromUsersData())
        redirectToLogout()
    }
    return (
        <PublicPage
            hideLogo={true}
            hideLanguageSelector={true}
            hidePrivacyPolicy={true}
            hideTermOfUse={true}
            className="accessDenied"
            contentInCenter
        >
            <div className="accessDenied__content">
                <h1 className="accessDenied__title">{t(`${tNamespace}title`)}</h1>
                <p className="accessDenied__message">{t(`${tNamespace}message`)}</p>
                <div className="accessDenied__image">
                    <img src={ctAccessDenied} alt="" />
                </div>
                <div>
                    <Button className="accessDenied__button" onClick={handleLogout}>
                        {t(`${tNamespace}logout-button-title`)}
                    </Button>
                </div>
            </div>
        </PublicPage>
    )
}

export default AccessDeniedPage
