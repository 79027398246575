import React from "react"
import { useTranslation } from "react-i18next"
import TypeFormCard from "../TypeFormCard/TypeFormCard"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBracketsCurly } from "@fortawesome/pro-light-svg-icons/faBracketsCurly"
import { ReactComponent as BracketsCurlyPlus } from "../../assets/images/font-awesome-exported/faBracketsCurlyPlus.svg"

const tNamespace = "scenarioEditor:"

interface Props {
    onAddExistedSlot: () => void
    onCreateSlot: () => void
}

const AddArticleSlotForm: React.FC<Props> = props => {
    const { t } = useTranslation()
    const { onAddExistedSlot, onCreateSlot } = props

    return (
        <div className="choose-slot-form">
            <div className="choose-slot-form__content">
                <TypeFormCard
                    icon={<FontAwesomeIcon icon={faBracketsCurly} />}
                    onSelect={() => onAddExistedSlot()}
                    title={t(`${tNamespace}existing-slot`)}
                />
                <TypeFormCard
                    icon={<BracketsCurlyPlus />}
                    onSelect={() => onCreateSlot()}
                    title={t(`${tNamespace}new-slot`)}
                />
            </div>
        </div>
    )
}

export default AddArticleSlotForm
