import styles from "./MassMailingReportsListLoader.module.scss"
import Skeleton from "./../../../../../../../../components/Skeleton/Skeleton"

export const MassMailingReportsListLoader = () => {
    return (
        <div className={styles.loader}>
            <Skeleton />
        </div>
    )
}
