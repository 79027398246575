import React from "react"
import styles from "./QueueMetric.module.scss"
import { TFunction, WithT } from "i18next"
import CloseButton from "../../../CloseButton/CloseButton"
import { MetricToAdd, MetricValue } from "../../../../models/queueValues"
import HoursMinutesMetricsSettingsInput from "../HoursMinutesMetricsSettingsInput"

const tNamespace = "queues:settings."

const getTitle = (metric: MetricToAdd, t: TFunction) => {
    switch (metric) {
        case MetricToAdd.Awt:
            return t(`${tNamespace}awt`)
        case MetricToAdd.FinishedDialogs:
        case MetricToAdd.FinishedDialogsDaily:
            return t(`${tNamespace}handled-lost-chats`)
    }
}

interface Props extends WithT {
    index: number
    onDelete: () => void
    metric: MetricValue
}

const QueueMetric: React.FC<Props> = props => {
    const { t, index, onDelete, metric } = props

    const name = `metrics[${index}].value`

    return (
        <div className={styles.queueMetric}>
            <div className={styles.queueMetric__header}>
                <div className={styles.queueMetric__title}>{getTitle(metric.type, t)}</div>
                <CloseButton onClick={onDelete} className={styles.queueMetric__deleteIcon} />
            </div>
            <div className={styles.queueMetric__content}>
                <HoursMinutesMetricsSettingsInput id={metric.value.id} name={name} t={t} metricType={metric.type} />
            </div>
        </div>
    )
}

export default QueueMetric
