import React, { ReactElement } from "react"
import usePermissionsCheck from "../../utility/common/usePermissionsCheck"

interface Props {
    permission: string
    children: React.ReactNode
    no?: () => ReactElement | null
    additionalCondition?: boolean
}

const Can: React.FC<Props> = props => {
    const { permission, children, no, additionalCondition = true } = props
    const allowed = usePermissionsCheck([permission])

    if (typeof children === "function") {
        return children(allowed && additionalCondition)
    } else if (allowed && additionalCondition) {
        return children
    }

    if (no) {
        return no()
    }

    return null
}

export default Can
