export enum KnowledgeBaseArticlePublishType {
    Default = "Default",
    Timeout = "Timeout"
}

export interface ArticlePublishInfo {
    Type: KnowledgeBaseArticlePublishType
    PublishDate: number
    UnpublishDate: number
    IsCompleted: boolean
}
