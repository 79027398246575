import { ParamValues } from "./parameterDeclaration"
import { BandwidthSettingsValues } from "./channelValues"

export interface SimpleChannelWithUserId extends SimpleChannel {
    Id: string
    Type: ChannelTypeString
    Title?: string
    ChannelUserId?: string
}

export interface SimpleOperatorEmailChannel {
    Id: string
    Title: string
    Email: string
}

export interface SimpleChannel {
    Id: string
    Type: ChannelTypeString
    Title?: string
}

export interface SimpleChannelDto {
    Id: string
    Type: number
    Title?: string
}

export enum ChannelType {
    WebChat,
    Phone,
    Telegram,
    Viber,
    Whatsapp,
    Email,
    Skype,
    Test,
    Unknown,
    VK,
    Odnoklassniki,
    Facebook,
    MSBot,
    YandexAlisa,
    YandexDialogs,
    Teams,
    Instagram,
    IntegrationChannel
}

export enum ChannelTypeString {
    WebChat = "webchat",
    Phone = "phone",
    Telegram = "telegram",
    Viber = "viber",
    Whatsapp = "whatsapp",
    WhatsappInfoBIP = "whatsapp",
    WhatsappStreamTel = "whatsapp_streamtel",
    WhatsappMFMS = "whatsapp_mfms",
    Email = "email",
    Skype = "skype",
    Test = "testbot",
    Unknown = "unknown",
    VK = "vk",
    Odnoklassniki = "odnoklassniki",
    Facebook = "facebook",
    MSBot = "msbot",
    Teams = "teams",
    YandexAlisa = "yandex_alisa",
    YandexDialogs = "yandex_dialogs",
    Instagram = "instagram",
    IntegrationChannel = "integration_channel"
}

export enum WhatsappChannelType {
    InfoBIP = "whatsapp",
    StreamTel = "whatsapp_streamtel",
    MFMS = "whatsapp_mfms"
}

export const isWhatsappChannelType = (channelType: string): channelType is WhatsappChannelType =>
    channelType.startsWith(ChannelTypeString.Whatsapp)

export interface ChannelDto {
    Id: string
    Type: string
    Title: string
    ProjectId: string
    BotId?: string
    Token?: string
    WebHook?: string
    ServerAddress?: string
    PreprocessorWebHook?: string
    Params: ParamValues
    BandwidthSettingsValues?: BandwidthSettingsValues
}

export interface WebChatAdditionalData {
    insertCode: string
    testPageUrl: string
}

export interface WebChatAdditionalDataDict {
    [channelId: string]: WebChatAdditionalData
}

export interface ChannelProcessor {
    channels: ChannelDto[]
    webChatAdditionalData?: WebChatAdditionalDataDict
}

export type ChannelNames = Record<string, string | undefined>

export type CommonChannel = ChannelDto

export interface WebChatChannel extends ChannelDto {
    MainConfig: ParamValues
    ClientConfig: ParamValues
}

export type Channel = CommonChannel | WebChatChannel

export type ChannelReadyEvent = {
    ProjectId: string
    ChannelId: string
    ChannelType: string
    Payload: { [key: string]: string }
}
