import { GOOGLE_FONTS_CSS, GSTATIC_FONTS } from "../externalAPIs"

export const injectGoogleFontFamily = (fontName: string) =>
    new Promise((resolve, reject) => {
        const gStaticLink = document.createElement("link")
        gStaticLink.rel = "preconnect"
        gStaticLink.href = GSTATIC_FONTS
        document.head.appendChild(gStaticLink)

        const gfLink = document.createElement("link")
        gfLink.rel = "stylesheet"
        gfLink.href = GOOGLE_FONTS_CSS + `?family=${fontName}:300,400,500,700&display=swap`

        document.head.appendChild(gfLink)

        gfLink.onload = () => resolve(gfLink)
        gfLink.onerror = () => reject(new Error(`Style load error for ${gfLink.href}`))
    })

export const injectFontFaceFamilyOverAttach = async (fontName: string, fontPath: string) => {
    const fontEntity = new FontFace(fontName, `url(${fontPath})`)
    document.fonts.add(fontEntity)

    try {
        await fontEntity.load()
        return fontEntity
    } catch (e) {
        throw e
    }
}

export const injectFontFaceFamilyOverFetch = async (fontName: string, fontPath: string) => {
    try {
        const response = await fetch(fontPath, { cache: "no-cache" })
        const fontBin = await response.arrayBuffer()

        const fontEntity = new FontFace(fontName, fontBin)
        document.fonts.add(fontEntity)

        await fontEntity.load()

        return fontEntity
    } catch (e) {
        throw e
    }
}
