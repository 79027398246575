import * as linkify from "linkifyjs"

export function formatLinksInMarkdown(text: string) {
    const links = linkify.find(text)
    const formattedTextParts = []

    let lastIndex = 0
    if (links.length) {
        links.forEach(link => {
            const { href, start, end, value } = link

            const markdownLinkPattern = /\[(.*?)\]\((.*?)\)/g

            let isAlreadyFormatted = false
            let match
            while ((match = markdownLinkPattern.exec(text)) !== null) {
                const matchStart = match.index
                const matchEnd = markdownLinkPattern.lastIndex
                if (start >= matchStart && end <= matchEnd) {
                    isAlreadyFormatted = true
                    break
                }
            }

            formattedTextParts.push(text.slice(lastIndex, start))

            if (isAlreadyFormatted) {
                formattedTextParts.push(text.slice(start, end))
            } else {
                const markdownLink = `[${value}](${href})`
                formattedTextParts.push(markdownLink)
            }

            lastIndex = end
        })

        formattedTextParts.push(text.slice(lastIndex))

        return formattedTextParts.join("")
    }

    return text
}
