import { ErrorMessage, ErrorMessageProps, useFormikContext } from "formik"
import debounce from "lodash/debounce"
import isEqual from "lodash/isEqual"
import { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { usePrevious } from "../utility/knowledgeBase/usePrevious"

export const FormikSubmitListener = <T extends Record<string, unknown>>() => {
    const formik = useFormikContext<T>()
    const [lastValues, updateState] = useState(formik.values)

    const submitForm = useCallback(
        debounce(
            (): void => {
                formik.submitForm()
            },
            500,
            { maxWait: 1500 }
        ),
        []
    )

    useEffect(() => {
        const valuesEqualLastValues = isEqual(lastValues, formik.values)
        const valuesEqualInitialValues = isEqual(formik.values, formik.initialValues)

        if (!valuesEqualLastValues) {
            updateState(formik.values)
        }

        if (!valuesEqualLastValues && !valuesEqualInitialValues && formik.isValid) {
            submitForm()
        }

        return () => submitForm.cancel()
    }, [formik.values, formik.isValid])

    return null
}

export interface IFormikFormObserverProps<T extends unknown> {
    onValuesChange(values: T): void
    observeFields?: string[]
}

export const FormikFormObserver = <T extends Record<string, unknown>>({
    onValuesChange,
    observeFields = []
}: IFormikFormObserverProps<T>) => {
    const formik = useFormikContext<T>()
    const formikPrevValues = usePrevious<T>(formik.values)

    useEffect(() => {
        if (!formikPrevValues) {
            return
        }

        if (observeFields.length) {
            for (const observedField of observeFields) {
                if (formik.values[observedField] !== formikPrevValues[observedField]) {
                    onValuesChange(formik.values)
                    break
                }
            }
        } else {
            onValuesChange(formik.values)
        }
    }, [onValuesChange, formik.values, observeFields, formikPrevValues])

    return null
}

export interface IFormikCustomErrorMsgProps extends ErrorMessageProps {
    i18use?: boolean
}

export const FormikCustomErrorMsg: React.FC<IFormikCustomErrorMsgProps> = ({ i18use = true, ...props }) => {
    const { t } = useTranslation()

    return <ErrorMessage {...props} render={msg => <div className={props.className}>{i18use ? t(msg) : msg}</div>} />
}
