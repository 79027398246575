export const deduplArrayObjects = <T = unknown>(array: T[]) =>
    Array.from(new Set(array.filter(x => x !== undefined).map(x => JSON.stringify(x)))).map<T>(x => JSON.parse(x))

export const mapToArray = <K, V>(map: Map<K, V>): V[] => {
    const array: V[] = []
    map.forEach(value => array.push(value))
    return array
}

/**
 * Returns a new array with distinct elements from the given array.
 * @param array The input array
 * @returns A new array with distinct elements
 */
export function distinct<T>(array: T[]): T[] {
    return Array.from(new Set(array))
}

/**
 * Returns a new array with distinct elements from the given array,
 * using a key selector function to determine uniqueness.
 * @param array The input array
 * @param keySelector A function that returns a unique key for each element
 * @returns A new array with distinct elements
 */
export function distinctBy<T, K>(array: T[], keySelector: (item: T) => K): T[] {
    const seen = new Set<K>()
    return array.filter(item => {
        const key = keySelector(item)
        if (seen.has(key)) {
            return false
        }
        seen.add(key)
        return true
    })
}
