import React from "react"
import cn from "classnames"
import { mapArticleStatusToClassName } from "../../utility/knowledgeBase/knowledgeBase"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircle } from "@fortawesome/pro-light-svg-icons/faCircle"
import { ArticleStatusType } from "../../models/article"
import { useTranslation } from "react-i18next"
import "./ArticleStatus.scss"
import { testId } from "../../utility/tests/testId"

const tNamespace = "knowledgeBase:"

interface Props {
    status: ArticleStatusType
}

const ArticleStatus: React.FC<Props> = props => {
    const { status } = props
    const { t } = useTranslation()

    return (
        <div className={cn("article-status", mapArticleStatusToClassName(status))} data-testid={testId.articleStatus}>
            <FontAwesomeIcon icon={faCircle} size="sm" className="article-status__icon" />
            {t(`${tNamespace}article-status.${status.toLowerCase()}`)}
        </div>
    )
}

export default ArticleStatus
