import React, { RefObject, useCallback, useEffect, useState } from "react"
import { TabPane } from "react-bootstrap"
import { useParams } from "react-router-dom"
import { TabEntry } from "../../types/tabEntry"
import { SelectCallback } from "react-bootstrap/helpers"
import TabsBase from "../TabsBase/TabsBase"

export type ValidateTabsCallback = (tabElements: RefObject<TabPaneElement>[]) => void
export type ValidateTabRef = RefObject<TabPaneElement>

export interface ValidatableFormTabsProps {
    id: string
    alwaysReload: boolean
    entries: TabEntry[]
    useUrl?: boolean
    selectCallback?: SelectCallback
    children: React.ReactNode
    initialInvalidTabKeys?: string[]
    updateKeyOnChildrenChange?: boolean
}

export type TabPaneElement = TabPane & HTMLDivElement

const defaultInvalidTabKeys: string[] = []

const invalidInputClassName = "is-invalid"
const invalidInputSelector = `.${invalidInputClassName}`

const isInvalidInputChange = ({ type, target }: MutationRecord) =>
    type === "childList" && (target as HTMLElement)?.classList.contains("invalid-feedback")

const ValidatableFormTabs: React.FC<ValidatableFormTabsProps> = props => {
    const {
        entries,
        useUrl,
        selectCallback,
        children,
        initialInvalidTabKeys = defaultInvalidTabKeys,
        updateKeyOnChildrenChange = true
    } = props

    const { tabId } = useParams<{ tabId?: string }>()
    const defaultTab = useUrl && tabId ? tabId : entries[0].key
    const [invalidTabKeys, setInvalidTabKeys] = useState<string[]>(initialInvalidTabKeys)
    const [key, setKey] = useState(defaultTab)

    const handleSelect = (key: string | null, e: React.SyntheticEvent<unknown>) => {
        if (key === null) return
        setKey(key)
        if (selectCallback) selectCallback(key, e)
    }

    const hasInvalidFields = useCallback(
        (tabPaneElement: ValidateTabRef, key: string, mutationObserver?: MutationObserver) => {
            if (!tabPaneElement.current) return false

            const invalidInput = tabPaneElement.current.querySelector(invalidInputSelector)

            if (!invalidInput) {
                mutationObserver?.disconnect()
                return false
            }

            const accordions = tabPaneElement.current.querySelectorAll(".form-accordion")
            accordions?.forEach(a => {
                const invalidInputInAccordion = a.querySelector(invalidInputSelector)
                if (invalidInputInAccordion) {
                    a.classList.add(invalidInputClassName)
                } else {
                    a.classList.remove(invalidInputClassName)
                }
            })

            if (!mutationObserver) {
                const removeKeyFromInvalid = (key: string) => setInvalidTabKeys(keys => keys.filter(k => k !== key))

                // Detect when invalid fields will be fixed
                const observer = new MutationObserver(mutationRecords => {
                    mutationRecords.forEach(record => {
                        if (isInvalidInputChange(record)) {
                            if (!hasInvalidFields(tabPaneElement, key, observer)) {
                                removeKeyFromInvalid(key)
                            }
                        }
                    })
                })

                observer.observe(tabPaneElement.current, {
                    childList: true,
                    subtree: true,
                    attributeFilter: ["class"],
                    characterData: false
                })
            }
            return true
        },
        []
    )

    const validateTabs = useCallback(
        (tabElements: ValidateTabRef[]) => {
            const invalidKeysTemp: string[] = []

            tabElements.forEach((ref, index) => {
                const tabEntry = entries[index]
                if (tabEntry) {
                    if (hasInvalidFields(ref, tabEntry.key)) {
                        invalidKeysTemp.push(tabEntry.key)
                    }
                }
            })

            setInvalidTabKeys(invalidKeysTemp)
            if (invalidKeysTemp.length) {
                setKey(key => (invalidKeysTemp.includes(key) ? key : invalidKeysTemp[0]))
            }
        },
        [entries, hasInvalidFields]
    )

    useEffect(() => {
        if (updateKeyOnChildrenChange) {
            setInvalidTabKeys(initialInvalidTabKeys)
            setKey(defaultTab)
        }
    }, [defaultTab, entries, props.children, initialInvalidTabKeys, updateKeyOnChildrenChange])

    const renderChildren = (activeKey: string) => {
        if (typeof children === "function") {
            return children(activeKey, validateTabs)
        } else if (children && React.isValidElement(children)) {
            return React.cloneElement(children, {
                validateTabs,
                activeKey
            })
        } else {
            return null
        }
    }

    return (
        <TabsBase {...props} invalidTabKeys={invalidTabKeys} activeKey={key} handleSelect={handleSelect}>
            {renderChildren(key)}
        </TabsBase>
    )
}

export default ValidatableFormTabs
