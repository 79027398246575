import { OperatorJoinedTheProjectMsg } from "../../models/projectUser"
import { reloadProjectUsers, reloadUserAfterSettingsUpdate } from "../../store/users/thunks"
import { logHubError } from "../../utility/common/logError"
import { Hub } from "../hub"
import { IHub } from "../interfaces/IHub"
import { Dispatch } from "../../utility/common/storeHelper"
import { setOperatorStatusUpdated } from "../../store/userOperator/thunks"
import { reloadProjects } from "../../store/projects/thunks"
import i18n from "../../i18n"
import { deleteNotificationNewsWithoutLoading, saveNotificationNews } from "../../store/notifications/thunks"
import { NotificationDto } from "../../models/notificationDto"
import { Store } from "../../store/store"
import { SelectedOperatorStatusDto } from "../../models/operatorStatus"
import { getImportProgress, handleArticleUploadFinish } from "./helpers"

const OPERATOR_STATUS_UPDATED = "OperatorStatusUpdated"
const OPERATORS_SETTINGS_UPDATED = "OperatorsSettingsUpdated"
const OPERATOR_REMOVED_FROM_PROJECT = "OperatorRemovedFromProject"
const OPERATOR_ADDED_TO_PROJECT = "OperatorAddedToProject"
const OPERATOR_JOINED_THE_PROJECT = "OperatorJoinedTheProject"
const OPERATOR_GOT_NEWS = "OperatorGotNews"
const OPERATOR_APPROVE_NEWS = "OperatorApproveNews"

const REMOVED_FROM_PROJECT_KEY = "info:users:removed-from-project"
const ADDED_TO_PROJECT_KEY = "info:users:added-to-project"
const OPERATOR_JOINED_THE_PROJECT_KEY = "info:users:user-joined-the-project"

const HUB_NAME = "UsersHub"

export class UserHub {
    private _hub: IHub
    private _store: Store

    constructor(store: Store) {
        const reduxState = store.getState()
        let useAllTransportSignalR = false

        if (reduxState.config.config.data?.WebConfig.appSettings.useAllTransportSignalR) {
            useAllTransportSignalR = true
        }

        this._hub = new Hub(`/users-hub`, useAllTransportSignalR)
        this._store = store
        this.registerServerEvents(store.dispatch)
    }

    async connect() {
        await this._hub.connect()
        await this._hub.subscribe("Subscribe")
        this._hub.reconnectedCallback = async () => {
            await this._hub.subscribe("Subscribe")
        }
    }

    async disconnect() {
        await this._hub.unsubscribe("Unsubscribe")
        await this._hub.disconnect()
    }

    private registerServerEvents(dispatch: Dispatch) {
        this._hub.registerEvent(OPERATOR_STATUS_UPDATED, data => {
            try {
                dispatch(setOperatorStatusUpdated(data as SelectedOperatorStatusDto))
            } catch (e) {
                logHubError(HUB_NAME, OPERATOR_STATUS_UPDATED, e)
            }
        })
        this._hub.registerEvent(OPERATORS_SETTINGS_UPDATED, (data?) => {
            const projectId = data as string
            try {
                dispatch(reloadUserAfterSettingsUpdate(projectId))
            } catch (e) {
                logHubError(HUB_NAME, OPERATORS_SETTINGS_UPDATED, e)
            }
        })
        this._hub.registerEvent(OPERATOR_REMOVED_FROM_PROJECT, data => {
            try {
                const projectName = data as string
                dispatch(
                    reloadProjects({
                        Title: {
                            Value: `${i18n.t(REMOVED_FROM_PROJECT_KEY)} "${projectName}"`,
                            NeedLocalization: false
                        }
                    })
                )
            } catch (e) {
                logHubError(HUB_NAME, OPERATOR_REMOVED_FROM_PROJECT, e)
            }
        })
        this._hub.registerEvent(OPERATOR_ADDED_TO_PROJECT, data => {
            try {
                const projectName = data as string
                dispatch(
                    reloadProjects({
                        Title: {
                            Value: `${i18n.t(ADDED_TO_PROJECT_KEY)} "${projectName}"`,
                            NeedLocalization: false
                        }
                    })
                )
            } catch (e) {
                logHubError(HUB_NAME, OPERATOR_ADDED_TO_PROJECT, e)
            }
        })
        this._hub.registerEvent(OPERATOR_JOINED_THE_PROJECT, data => {
            try {
                const msg = data as OperatorJoinedTheProjectMsg
                dispatch(
                    reloadProjectUsers(msg.ProjectId, {
                        Title: {
                            Value: `${msg.OperatorEmail} ${i18n.t(OPERATOR_JOINED_THE_PROJECT_KEY)} "${
                                msg.ProjectName
                            }"`,
                            NeedLocalization: false
                        }
                    })
                )
            } catch (e) {
                logHubError(HUB_NAME, OPERATOR_JOINED_THE_PROJECT, e)
            }
        })
        this._hub.registerEvent(OPERATOR_GOT_NEWS, data => {
            try {
                const message = data as NotificationDto
                const isImportInProgress = getImportProgress(this._store)

                if (isImportInProgress) {
                    return
                }

                if (message.Description?.Value === "ArticleUploadFinish" && message.Context) {
                    handleArticleUploadFinish(this._store, message, dispatch, () =>
                        saveNotificationNews(dispatch, message)
                    )

                    return
                }

                saveNotificationNews(dispatch, message)
            } catch (e) {
                logHubError(HUB_NAME, OPERATOR_GOT_NEWS, e)
            }
        })
        this._hub.registerEvent(OPERATOR_APPROVE_NEWS, data => {
            try {
                const newsId = data as string
                deleteNotificationNewsWithoutLoading(dispatch, newsId)
            } catch (e) {
                logHubError(HUB_NAME, OPERATOR_APPROVE_NEWS, e)
            }
        })
    }
}
