import React, { useCallback, useMemo } from "react"
import { Formik, FormikProps } from "formik"
import * as Yup from "yup"
import { useTranslation } from "react-i18next"
import "./FormikQueueForm.scss"
import { ExtendedSettings } from "../../models/queue"
import "rc-slider/assets/index.css"
import { useDispatch, useSelector } from "react-redux"
import FormikQueueFormGeneral from "../QueueForm/QueueFormGeneral"
import { TabPaneElement, ValidateTabsCallback } from "../ValidatableFormTabs/ValidatableFormTabs"
import OnSubmitValidationError from "../OnSubmitValidationError/OnSubmitValidationError"
import QueueFormMetrics from "../QueueForm/QueueFormMetrics"
import { WithT } from "i18next"
import { QueueValues } from "../../models/queueValues"
import IndividualQueueForm from "../IndividualQueueForm/IndividualQueueForm"
import { QueueFormTabEntries, QueueProps } from "../QueueForm/QueueForm"
import QueueFormExtra from "../QueueForm/QueueFormExtra"
import { createQueue, updateQueue } from "../../store/queues/thunks"
import { formTranslation } from "../../locales/form"
import { selectBandwidthGroups } from "../../store/projects/selectors"
import { useAppSelector } from "../../store/hooks"
import { selectCatalogsMap } from "../../store/knowledgeBase/selectors"
import { AllowCatalogsFormValues } from "../../models/catalog"
import { getValuesFromQueue } from "./utility/getValuesFromQueue"
import { queueFormDefaultValues } from "./utility/queueFormDefaultValues"
import { buildCreateQueueRequest, buildUpdateQueueRequest } from "./utility/buildQueueRequests"
import LazyTabPane from "../LazyTabPane/LazyTabPane"

const tNamespace = "queues:form."

export type FormikQueueFormProps = QueueProps & FormikProps<QueueValues> & WithT

export interface FormQueueFormProps extends QueueProps {
    tabEntries: QueueFormTabEntries
    validateTabs?: ValidateTabsCallback
    extendedSettings?: ExtendedSettings
    activeKey: string
}

const FormikQueueForm: React.FC<FormQueueFormProps> = props => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { projectId, queue, onClose, validateTabs, tabEntries, categoryId, extendedSettings, activeKey } = props

    const allDialogGroupsSetting = t(formTranslation.all)
    const bandwidthGroups = useSelector(selectBandwidthGroups)

    const parametersRef = React.useRef<TabPaneElement>(null)
    const individualRef = React.useRef<TabPaneElement>(null)
    const metricsRef = React.useRef<TabPaneElement>(null)
    const extraRef = React.useRef<TabPaneElement>(null)

    const validateQueueFormTabs = useCallback(
        () => validateTabs && validateTabs([parametersRef, individualRef, metricsRef, extraRef]),
        [validateTabs]
    )

    const catalogsMap = useAppSelector(selectCatalogsMap)

    const catalogs: AllowCatalogsFormValues[] = useMemo(
        () =>
            Object.keys(catalogsMap).map(code => {
                const tree = catalogsMap[code]
                const item = tree.items[tree.rootId]

                return {
                    id: item.id,
                    title: item.data.title
                }
            }),
        [catalogsMap]
    )

    return (
        <Formik
            enableReinitialize={true}
            initialValues={
                queue
                    ? getValuesFromQueue(queue, extendedSettings, catalogs, bandwidthGroups, allDialogGroupsSetting)
                    : queueFormDefaultValues
            }
            validationSchema={() => {
                const nonNegativeOrZero = Yup.number()
                    .required(`${tNamespace}required`)
                    .min(0, `${tNamespace}min-value-0`)

                return Yup.object().shape({
                    name: Yup.string().requiredExcludeEmpty(`${tNamespace}name-required`),
                    slValues: Yup.object({
                        slTarget: Yup.number()
                            .required(`${tNamespace}sl-target-required`)
                            .min(1, `${tNamespace}min-value-1`)
                            .max(100, `${tNamespace}max-value-100`),
                        slThresholdSec: nonNegativeOrZero,
                        slPeriodHr: nonNegativeOrZero,
                        slPeriodMin: nonNegativeOrZero
                    })
                        .default(undefined)
                        .nullable(),
                    timeoutValues: Yup.object({
                        timeoutQueue: Yup.string().requiredExcludeEmpty(`${tNamespace}timeout-queue-required`)
                    })
                        .default(undefined)
                        .nullable(),
                    metrics: Yup.array().of(
                        Yup.object({
                            value: Yup.object({
                                hr: Yup.number().required(),
                                min: Yup.number().required()
                            })
                        })
                    )
                })
            }}
            onSubmit={(values: QueueValues) => {
                if (queue) {
                    dispatch(
                        updateQueue(
                            queue.TenantId,
                            queue.Id,
                            buildUpdateQueueRequest(values, categoryId, bandwidthGroups, allDialogGroupsSetting),
                            onClose
                        )
                    )
                } else {
                    dispatch(
                        createQueue(
                            projectId,
                            buildCreateQueueRequest(values, categoryId, bandwidthGroups, allDialogGroupsSetting),
                            onClose
                        )
                    )
                }
            }}
        >
            {formikProps => {
                return (
                    <>
                        <OnSubmitValidationError formikProps={formikProps} onCallback={validateQueueFormTabs} />
                        <LazyTabPane
                            eventKey={tabEntries[0].key}
                            innerRef={parametersRef}
                            key={tabEntries[0].key}
                            activeKey={activeKey}
                        >
                            <FormikQueueFormGeneral {...props} {...formikProps} t={t} />
                        </LazyTabPane>
                        {queue && (
                            <LazyTabPane
                                eventKey={tabEntries[1].key}
                                innerRef={individualRef}
                                key={tabEntries[1].key}
                                activeKey={activeKey}
                            >
                                <IndividualQueueForm {...props} queue={queue} />
                            </LazyTabPane>
                        )}
                        <LazyTabPane
                            eventKey={tabEntries[2].key}
                            innerRef={metricsRef}
                            key={tabEntries[2].key}
                            activeKey={activeKey}
                        >
                            <QueueFormMetrics {...props} {...formikProps} t={t} />
                        </LazyTabPane>
                        <LazyTabPane
                            eventKey={tabEntries[3].key}
                            innerRef={extraRef}
                            key={tabEntries[3].key}
                            activeKey={activeKey}
                        >
                            <QueueFormExtra {...props} {...formikProps} t={t} catalogs={catalogs} />
                        </LazyTabPane>
                    </>
                )
            }}
        </Formik>
    )
}

export default FormikQueueForm
