import React from "react"
import { Message } from "../../models/Dialogs/message"
import styles from "./OutdatedMessageList.module.scss"
import OutdatedMessageItem from "../OutdatedMessageItem/OutdatedMessageItem"
import { mapToArray } from "../../helpers/array"

export interface OutdatedMessageListProps {
    messages: Map<string, Message>
    reverseInOut?: boolean
}

const OutdatedMessageList: React.FC<OutdatedMessageListProps> = props => {
    const { messages, reverseInOut } = props
    const messagesArray = mapToArray<string, Message>(messages)
    return (
        <div className={styles.messageList}>
            {messagesArray.map(v => (
                <OutdatedMessageItem
                    key={v.MsgId}
                    className={styles.messageList__item}
                    message={v}
                    reverseInOut={reverseInOut}
                />
            ))}
        </div>
    )
}

export default OutdatedMessageList
