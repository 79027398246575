import {
    AddArticleCommentRequest,
    AddArticleReplyRequest,
    ArticleComment,
    GetArticleCommentsRequest,
    GetArticleReplyRequest
} from "../../../models/articleComment"
import { apiInstance } from "../../instances/apiInstance"

const knowledgeBaseCommentsController = {
    getArticleComments: (projectId: string, request: GetArticleCommentsRequest): Promise<ArticleComment[]> =>
        apiInstance.post(`${projectId}/article_comments/comment/list`, request).then(response => response.data),
    getArticleCommentThread: (projectId: string, request: GetArticleReplyRequest): Promise<ArticleComment[]> =>
        apiInstance.post(`${projectId}/article_comments/reply/list`, request).then(response => response.data),
    addArticleComment: (projectId: string, request: AddArticleCommentRequest): Promise<void> =>
        apiInstance.post(`${projectId}/article_comments/comment/add`, request).then(response => response.data),
    addArticleReply: (projectId: string, request: AddArticleReplyRequest): Promise<void> =>
        apiInstance.post(`${projectId}/article_comments/reply/add`, request).then(response => response.data)
}

export default knowledgeBaseCommentsController
