export const getFrameUrl = () => {
    return window.origin + `/clients/cloud`
}

export interface RouteMessage {
    menuId?: string
    language?: string
}

export const convertPathToOldFormat = (path: string) => {
    switch (path) {
        case "dialogs-old":
            return "dialogs"
        case "history":
            return "dialogs-search"
        case "knowledge-base-old":
            return "knowledgebase-editor"
        case "stats":
            return "new-stats"
        default:
            return path
    }
}

enum OldWorkplaceIds {
    DialogsOld = "dialogs-old",
    KnowledgeBaseOld = "knowledge-base-old",
    History = "history",
    Stats = "stats",
    SettingsEditor = "settings-editor",
    Monitoring = "monitoring",
    PersonalStats = "personal-stats",
    Reports = "reports",
    ClassifierLogs = "classifier-logs",
    DeepLinking = "deep-linking",
    Users = "users",
    Positions = "positions",
    Actions = "actions"
}

const oldWorkplaceIdsValues: string[] = Object.values(OldWorkplaceIds)

export const isWorkplaceIdOld = (menuId: string) => {
    return oldWorkplaceIdsValues.includes(menuId)
}
