import React, { useCallback } from "react"
import SidebarRight from "../../SidebarRight/SidebarRight"
import { useQueueSidebarData } from "../utils/useQueueSidebar"
import { useAppDispatch, useAppSelector } from "../../../store/hooks"
import { selectQueuesOperatorSidebar } from "../../../store/queues/selectors"
import { actions } from "../../../store/queues/slice"
import OperatorView from "../../OperatorView/OperatorView"
import { Queue } from "../../../models/queue"
import { useTranslation } from "react-i18next"

type QueueOperatorSidebarProps = {
    allQueues: Queue[]
}

const tNamespace = "queues:"

const QueueOperatorSidebar = (props: QueueOperatorSidebarProps) => {
    const { allQueues } = props
    const { projectId } = useQueueSidebarData()
    const { isOpened, operator } = useAppSelector(selectQueuesOperatorSidebar)
    const dispatch = useAppDispatch()
    const { t } = useTranslation()

    const handleToggleOperatorSidebar = useCallback(() => {
        dispatch(actions.closeQueuesOperatorSidebar())
    }, [dispatch])

    return (
        <SidebarRight
            open={isOpened}
            title={t(`${tNamespace}operator-view`)}
            onCloseClick={() => handleToggleOperatorSidebar()}
            onClose={() => void 0}
        >
            {operator && (
                <OperatorView
                    projectId={projectId}
                    operator={operator}
                    queues={allQueues}
                    onClose={handleToggleOperatorSidebar}
                    isShowTasksEntry
                />
            )}
        </SidebarRight>
    )
}

export default QueueOperatorSidebar
