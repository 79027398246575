import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { getMessageWithTranslationKey, SystemError } from "../../core/error"
import {
    Notification,
    NotificationType,
    NotificationPayload,
    NotificationInternalPayload,
    DeleteNewsState
} from "../../models/notification"
import AsyncState from "../../core/asyncState"
import toNotificationPayload from "../../utility/notifications/toNotificationPayload"
import notificationStackSort from "../../utility/notifications/sort"
import { NotificationDto } from "../../models/notificationDto"
import { notificationDtoConvert } from "../../utility/notifications/convert"
import { resetReducerState } from "../action"

export type NotificationState = Readonly<{
    current?: Notification
    stack: Notification[]
    newsStack: Notification[]
    newsState: AsyncState<boolean>
    deleteNewsState: DeleteNewsState
    isContainerOpen: boolean
}>

const initialState: NotificationState = {
    current: undefined,
    stack: [],
    newsStack: [],
    newsState: AsyncState.create(),
    deleteNewsState: {},
    isContainerOpen: false
}

const createNotificationFromError = (error: SystemError): Notification => {
    const [reason, reasonKey] = getMessageWithTranslationKey(error.error)
    const payload: NotificationInternalPayload = {
        Title: {
            Value: error.contextKey,
            NeedLocalization: true
        },
        Description: {
            Value: reasonKey || reason,
            NeedLocalization: !!reasonKey
        }
    }
    return createNotification("error", toNotificationPayload(payload))
}

const createNotificationFromInfo = (payload: NotificationPayload): Notification => {
    return createNotification("info", payload)
}

const createNotificationNews = (notificationDto: NotificationDto): Notification => {
    return notificationDtoConvert.toNotification(notificationDto)
}

const createNotification = (type: NotificationType, payload: NotificationPayload): Notification => ({
    Type: type,
    ...payload
})

const deleteNewsLoading = (state: DeleteNewsState, notificationId: string) => {
    const copiedState = { ...state }
    delete copiedState[notificationId]
    return copiedState
}

const notifications = createSlice({
    name: "notifications",
    initialState,
    reducers: {
        saveError(state, action: PayloadAction<SystemError>) {
            const errorNotification = createNotificationFromError(action.payload)
            state.current = errorNotification
            state.stack = [errorNotification, ...state.stack]
        },
        saveInfo(state, action: PayloadAction<NotificationPayload>) {
            const infoNotification = createNotificationFromInfo(action.payload)
            state.current = infoNotification
            state.stack = [infoNotification, ...state.stack]
        },
        saveNews(state, action: PayloadAction<NotificationDto>) {
            const newsNotification = createNotificationNews(action.payload)
            state.current = newsNotification

            switch (newsNotification.Type) {
                case "importFiles":
                    state.stack = [newsNotification, ...state.stack]
                    break
                default:
                    state.newsStack = notificationStackSort([newsNotification, ...state.newsStack])
            }
        },
        deleteNewsProcess(state, action: PayloadAction<string>) {
            state.deleteNewsState = {
                [action.payload]: true,
                ...state.deleteNewsState
            }
        },
        deleteNewsSuccess(state, action: PayloadAction<string>) {
            state.deleteNewsState = deleteNewsLoading(state.deleteNewsState, action.payload)
            state.newsStack = state.newsStack.filter(n => n.Id !== action.payload)
        },
        deleteNewsFailed(state, action: PayloadAction<string>) {
            state.deleteNewsState = deleteNewsLoading(state.deleteNewsState, action.payload)
        },
        getNewsProcess(state) {
            state.newsState = state.newsState.toProcess()
        },
        getNewsSuccess(state, action: PayloadAction<NotificationDto[]>) {
            state.newsStack = notificationStackSort(action.payload.map(createNotificationNews))
            state.newsState = state.newsState.toSuccess(true)
        },
        getNewsFailed(state, action: PayloadAction<SystemError>) {
            state.newsState = state.newsState.toFailed(action.payload)
        },
        openNotificationsList(state) {
            state.isContainerOpen = true
        },
        closeNotificationsList(state) {
            state.isContainerOpen = false
        },
        toggleNotificationsList(state) {
            state.isContainerOpen = !state.isContainerOpen
        },
        saveSuccess(state, action: PayloadAction<NotificationPayload>) {
            const result = createNotification("success", action.payload)
            state.current = result
            state.stack = [result, ...state.stack]
        },
        deleteNotification(state, action: PayloadAction<string>) {
            state.stack = state.stack.filter(n => n.Id !== action.payload)
        },
        clear(state) {
            state.stack = state.stack.filter(n => n.Type === "error")
        },
        clearCurrent(state, action: PayloadAction<string>) {
            if (state.current?.Id === action.payload) {
                state.current = undefined
            }
        }
    },
    extraReducers(builder) {
        builder.addCase(resetReducerState, () => {
            return initialState
        })
    }
})

export const NotificationReducer = notifications.reducer

export const actions = notifications.actions
