import React, { memo, useCallback, useEffect } from "react"
import { useAppDispatch, useAppSelector } from "../../../store/hooks"
import { useTranslation } from "react-i18next"
import { actions } from "../../../store/queues/slice"
import SidebarRight from "../../SidebarRight/SidebarRight"
import IconControl from "../../IconControl/IconControl"
import { faSync } from "@fortawesome/pro-light-svg-icons"
import { useLazyGetQueuePendingTasksQuery } from "../../../api/controllers/queuesV2"
import { useQueueSidebarData } from "../utils/useQueueSidebar"
import { Queue, TaskPaginationResponse } from "../../../models/queue"
import { Task } from "../../../models/task"
import { selectQueuePendingTasksSidebar } from "../../../store/queues/selectors"
import QueuesTasksTable from "../QueuesTable/QueuesTable"
import ErrorMessage from "../../ErrorMessage/ErrorMessage"
import AsyncQuery from "../../Async/AsyncQuery"
import QueuesTaskTableLoader from "../QueuesTaskTableLoader/QueuesTaskTableLoader"
import { QUEUES_TASKS_PER_PAGE } from "../../../models/pagination"
import styles from "../QueuesTasksSidebar/QueuesTasksSidebar.module.scss"

const tNamespace = "queues"

const QueuePendingTasksSidebar = () => {
    const { isOpened } = useAppSelector(selectQueuePendingTasksSidebar)
    const { queueId, projectId } = useQueueSidebarData()
    const dispatch = useAppDispatch()
    const { t } = useTranslation(tNamespace)

    const handleToggleQueueTasksSidebar = useCallback(() => {
        dispatch(actions.closeQueuePendingTasksSidebar())
    }, [dispatch])

    const [queuePendingTasksTrigger, queuePendingTasksQuery] = useLazyGetQueuePendingTasksQuery()
    const queuePendingTasksTriggerRequest = {
        projectId,
        queueId,
        paginationParams: {
            PageFrom: 0,
            PageSize: QUEUES_TASKS_PER_PAGE
        }
    }

    useEffect(() => {
        if (queueId && isOpened) {
            queuePendingTasksTrigger(queuePendingTasksTriggerRequest)
        }
    }, [queueId, isOpened])

    const handlePendingTaskClick = (task: Task, queue: Queue) => {
        dispatch(actions.openQueuesPendingTaskSidebar({ task, queue }))
    }

    return (
        <SidebarRight
            open={isOpened}
            title={t("queue-pending-tasks-view")}
            onCloseClick={() => handleToggleQueueTasksSidebar()}
            onClose={() => void 0}
            width={864}
            action={
                <IconControl
                    icon={faSync}
                    size="1x"
                    onClick={() => {
                        queuePendingTasksTrigger(queuePendingTasksTriggerRequest, false)
                    }}
                />
            }
        >
            <AsyncQuery
                query={queuePendingTasksQuery}
                processView={<QueuesTaskTableLoader />}
                errorView={({ message }) => <ErrorMessage text={message} />}
                validator={query => {
                    const tasks = query.data as TaskPaginationResponse<Task>

                    return !!tasks.Tasks.length
                }}
                emptyDataView={<span className={styles.queuesTasksSidebar_empty}>{t("no-pending-tasks")}</span>}
            >
                {({ data }) => (
                    <QueuesTasksTable
                        handlePendingTaskView={handlePendingTaskClick}
                        tasksPagination={data}
                        queueId={queueId}
                        projectId={projectId}
                        queryTriggerCallback={queuePendingTasksTrigger}
                    />
                )}
            </AsyncQuery>
        </SidebarRight>
    )
}

export default memo(QueuePendingTasksSidebar)
