import React, { useEffect, useState } from "react"
import styles from "./IdField.module.scss"
import { ReactComponent as Id } from "../../assets/images/letters/id.svg"
import copy from "copy-to-clipboard"
import { useTranslation } from "react-i18next"
import TooltipTrigger from "../TooltipTrigger/TooltipTrigger"
import { formTranslation } from "../../locales/form"
import { ClassProps } from "../../utility/common/props"
import cn from "classnames"

export interface IdFieldProps extends ClassProps {
    id?: string
    label?: string
}

const showTooltipDelay = 1000
let timer: number | undefined

const clearTimer = () => {
    clearTimeout(timer)
    timer = undefined
}

const IdField: React.FC<IdFieldProps> = ({ id, label, className }) => {
    const { t } = useTranslation()

    const [show, setShow] = useState(false)

    const handleClick = () => {
        id && copy(id)
        setShow(true)
    }

    useEffect(() => {
        if (show) {
            timer = window.setTimeout(() => {
                setShow(false)
                clearTimer()
            }, showTooltipDelay)
        }
    }, [show])

    useEffect(() => {
        return () => {
            if (timer) {
                clearTimer()
            }
        }
    }, [])

    return (
        <>
            {label}
            <TooltipTrigger id="id-field__tooltip" show={show} content={t(formTranslation.copied)} placement="top">
                <div className={cn(styles.idField, className, !id && styles.idField_disabled)}>
                    <Id className={styles.idField__img} />
                    <span onClick={handleClick} className={styles.idField__data}>
                        {id || t(formTranslation.notSet)}
                    </span>
                </div>
            </TooltipTrigger>
        </>
    )
}

export default IdField
