import { i18n } from "i18next"
import i18nInstance from "i18next"
import { locales } from "../../locales"
import { TFunction } from "react-i18next"

export const getLanguage = (i18n: i18n) => i18n.languages[0]

/**
 * Translate value with custom language
 * @param key - translation key
 * @param lng - custom languages
 */
export const tl = (key: string, lng: string) => i18nInstance.t(key, { lng })

export const getTranslatedLanguageName = (language: string) => {
    const result = locales.filter(l => l.id === language)
    return result.length === 1 ? result[0].name : "Unknown"
}

export const translateIfPossible = (data: string, t: TFunction, i18n: i18n) => {
    const [namespace, key] = data.split(":")
    const isTranslatable = key ? i18n.exists(key, { ns: namespace }) : false
    const result = isTranslatable ? t(data) : data

    return result
}
