import { KnowledgeBasePermittedAction } from "../../models/knowledgeBase/permission"
import { ArticleKind, ArticleShareFormValues } from "../../models/article"
import { createContext } from "react"

export interface ArticleViewContextType {
    permittedAction?: KnowledgeBasePermittedAction
    availableKinds: ArticleKind[]
    connected: boolean
    onShare: (values: ArticleShareFormValues, hidePopover: () => void) => void
}

const ArticleViewContext = createContext<ArticleViewContextType>({
    permittedAction: KnowledgeBasePermittedAction.View,
    availableKinds: [],
    connected: false,
    onShare: () => void 0
})

export default ArticleViewContext
