import { TFunction } from "i18next"

export enum SettingsLabelType {
    Roles,
    Logging
}

export const handleSettingsLabelTranslation = (
    tNameSpace: string,
    value: string,
    type: SettingsLabelType,
    t: TFunction
) => {
    switch (type) {
        case SettingsLabelType.Roles:
            return t(`${tNameSpace}permissions-list.${value.replaceAll("_", "-")}`)
        default:
            return t(tNameSpace.concat(value))
    }
}
