export const formatDateInput = (input: string, selectsRange: boolean) => {
    if (selectsRange) {
        input = input.slice(0, 16)
        return input
            .replace(/(\d{2})/, "$1.")
            .replace(/(\d{2})\.(\d{2})/, "$1.$2.")
            .replace(/(\d{2})\.(\d{2})\.(\d{4})/, "$1.$2.$3-")
            .replace(/(\d{2})\.(\d{2})\.(\d{4})-(\d{2})/, "$1.$2.$3-$4.")
            .replace(/(\d{2})\.(\d{2})\.(\d{4})-(\d{2})\.(\d{2})/, "$1.$2.$3-$4.$5.")
    } else {
        input = input.slice(0, 8)
        return input.replace(/(\d{2})/, "$1.").replace(/(\d{2})\.(\d{2})/, "$1.$2.")
    }
}
