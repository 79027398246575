import React from "react"
import "./CommandForm.scss"
import { useTranslation } from "react-i18next"
import TypeFormCard from "../TypeFormCard/TypeFormCard"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBracketsCurly } from "@fortawesome/pro-light-svg-icons/faBracketsCurly"
import { ReactComponent as BracketsCurlyMinus } from "../../assets/images/font-awesome-exported/faBracketsCurlyMinus.svg"
import { CommandNode, Connection } from "../../models/scenario"

const tNamespace = "scenarioEditor:"

interface Props {
    onSetSlotValue: (commandNode?: CommandNode, connection?: Connection) => void
    onDeleteSlot: (commandNode?: CommandNode, connection?: Connection) => void
    commandNode?: CommandNode
    connection?: Connection
}

const CommandForm: React.FC<Props> = props => {
    const { t } = useTranslation()
    const { onSetSlotValue, onDeleteSlot, commandNode, connection } = props

    return (
        <div className="command-form">
            <div className="command-form__content">
                <TypeFormCard
                    icon={<FontAwesomeIcon icon={faBracketsCurly} />}
                    onSelect={() => onSetSlotValue(commandNode, connection)}
                    title={t(`${tNamespace}set-slot-value`)}
                />
                <TypeFormCard
                    icon={<BracketsCurlyMinus />}
                    onSelect={() => onDeleteSlot(commandNode, connection)}
                    title={t(`${tNamespace}delete-slot-value`)}
                />
            </div>
        </div>
    )
}

export default CommandForm
