import { SendingJob } from "../../../../models/massMailing"
import { MassMailingListItem } from "../MassMailingListItem/MassMailingListItem"
import styles from "./MassMailingList.module.scss"

export interface MassMailingListProps {
    items: SendingJob[]
}

export const MassMailingList = ({ items }: MassMailingListProps) => {
    return (
        <ul className={styles.list}>
            {items.map(item => (
                <li key={item.Id}>
                    <MassMailingListItem item={item} />
                </li>
            ))}
        </ul>
    )
}
