import { useTranslation } from "react-i18next"
import styles from "./MassMailingListItemEmptyView.module.scss"

const tNamespace = "massMailing:groups.empty-"

interface MassMailingListItemEmptyViewProps {
    group: "active" | "finished"
}

export const MassMailingListItemEmptyView = ({ group }: MassMailingListItemEmptyViewProps) => {
    const { t } = useTranslation()
    return <div className={styles.massMailingListItemEmptyView}>{t(`${tNamespace}${group}`)}</div>
}
