import React from "react"
import { DebugWebchat } from "./WebchatCard"
import { Form, FormikProps } from "formik"
import styles from "./WebchatCard.module.scss"
import { preventSubmitOnEnter } from "../../../../../utility/common/preventSubmitOnEnter"
import ArticleSelect from "../../../../ArticleSelect/ArticleSelect"
import { nameof } from "../../../../../utility/common/nameof"
import { formTranslation } from "../../../../../locales/form"
import DebouncedCheckBox from "../../../../CheckBoxValidatableInput/DebouncedCheckBox"
import { Button } from "react-bootstrap"
import { Dispatch } from "redux"
import { TFunction } from "react-i18next"
import WebchatChannelSelect from "./components/WebchatChannelSelect/WebchatChannelSelect"

export interface WebchatCardForm {
    dispatch: Dispatch
    projectId: string
    t: TFunction<"translation">
    onSelect: () => void
    onDelete: () => void
    channels: string[]
    articleSymbolCode: string
}

const tNamespace = "knowledgeBase:webchat-card."

const WebchatCardForm: React.FC<WebchatCardForm & FormikProps<DebugWebchat>> = props => {
    const { handleSubmit, t, projectId, dispatch, onSelect, onDelete, channels, articleSymbolCode } = props
    const handleDelete = () => {
        onSelect()
        onDelete()
    }
    return (
        <Form onSubmit={handleSubmit} onKeyPress={preventSubmitOnEnter}>
            <ArticleSelect
                id={"formWebchatCardArticle"}
                name={nameof<DebugWebchat>("articleId")}
                dispatch={dispatch}
                projectId={projectId}
                searchBySymbolCode
                allowSearchWithoutPermissions
                label={t(`${tNamespace}article-select`)}
                filter={[articleSymbolCode]}
            />
            <WebchatChannelSelect
                id={"formWebchatCardChannel"}
                name={nameof<DebugWebchat>("channelId")}
                dispatch={dispatch}
                projectId={projectId}
                label={t(`${tNamespace}channel-select`)}
                filter={channels}
            />
            <DebouncedCheckBox
                id={"formShowWebchatCardInput"}
                name={nameof<DebugWebchat>("showInput")}
                label={t(`${tNamespace}use-input`)}
            />
            <div className={styles.webchatCard__bottons}>
                <Button className={styles.webchatCard__bottons__botton} variant="light" onClick={handleDelete}>
                    {t(formTranslation.cancel)}
                </Button>
                <Button className={styles.webchatCard__bottons__botton} variant="primary" type="submit">
                    {t(formTranslation.save)}
                </Button>
            </div>
        </Form>
    )
}

export default WebchatCardForm
