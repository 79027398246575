import { KnowledgeBaseAccessType } from "./permission"

export enum NewslineKeys {
    isInNewsline = "IsInNewsline",
    isHideAfterReading = "IsHideAfterReading",
    isEndless = "IsEndless",
    lifeTime = "LifeTime",
    description = "Description",
    subscriptions = "Subscriptions"
}

export interface NewslineSubscription {
    Type: KnowledgeBaseAccessType
    Value: string
    ProjectId: string
}

export interface NewslineItem {
    Id: string
    Title: string
    Description: string
    ArticleCode: string
    IsHideAfterReading: boolean
}

export interface NewslineSettings {
    [NewslineKeys.isInNewsline]: boolean
    [NewslineKeys.isHideAfterReading]: boolean
    [NewslineKeys.isEndless]: boolean
    [NewslineKeys.lifeTime]: number
    [NewslineKeys.description]: string
    [NewslineKeys.subscriptions]: NewslineSubscription[]
}

export type NewslineFormValues = NewslineSettings
