import { useMemo } from "react"
import styles from "./PortalSystem.module.scss"
import cn from "classnames"
import { usePortal } from "../../hooks/usePortal"
import { useAppSelector } from "../../store/hooks"
import { useActions } from "../../hooks/useAction"
import { selectPortalShownState } from "../../store/portalSystem/selectors"
import { testId } from "../../utility/tests/testId"
import { PortalSystemProps } from "./PortalSystem.props"

export const PORTAL_SYSTEM_MOUNT_ID = "PORTAL_SYSTEM_MOUNT_ID"

export const PortalSystem = (props: PortalSystemProps) => {
    const { portalRootId, portalId, hideDisabled, children, onClose, className } = props
    const shown = useAppSelector(state => selectPortalShownState(state, portalId))
    const { hidePortal } = useActions()

    const portalMount = useMemo(() => document.getElementById(portalRootId || PORTAL_SYSTEM_MOUNT_ID), [])
    const Portal = usePortal(portalMount)

    const handleHideDialog = () => {
        !hideDisabled && hidePortal({ portalId })
        onClose && onClose()
    }

    return (
        <Portal>
            <div
                data-testid={testId.portalSystem}
                className={cn(styles.portal__wrap, shown && styles.portal__wrap_shown, className)}
                onClick={handleHideDialog}
            >
                {children}
            </div>
        </Portal>
    )
}
