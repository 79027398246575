import format from "date-fns/format"
import { useTranslation } from "react-i18next"

export enum DatePickerInputErrorType {
    incomplete = "incomplete",
    format = "format",
    invert = "invert",
    from = "from",
    to = "to",
    both = "both"
}

const tNamespace = "date:date-input.error-"
const formatDate = (date: Date) => format(date, "dd.MM.yyyy HH:mm")

export const useDatePickerErrorHandler = (setter: (value: string | undefined) => void) => {
    const { t } = useTranslation()

    return (
        type?: DatePickerInputErrorType.format | DatePickerInputErrorType.invert | DatePickerInputErrorType.incomplete,
        minDate?: Date | null,
        maxDate?: Date | null
    ) => {
        let errorMessage: string | undefined

        if (type) {
            errorMessage = t(`${tNamespace}${type}`)
        } else if (minDate && maxDate) {
            errorMessage = t(`${tNamespace}${DatePickerInputErrorType.both}`, {
                from: formatDate(minDate),
                to: formatDate(maxDate)
            })
        } else if (minDate) {
            errorMessage = t(`${tNamespace}${DatePickerInputErrorType.from}`, {
                from: formatDate(minDate)
            })
        } else if (maxDate) {
            errorMessage = t(`${tNamespace}${DatePickerInputErrorType.to}`, {
                to: formatDate(maxDate)
            })
        }

        setTimeout(() => {
            setter(errorMessage)
        }, 0)
    }
}
