import { push } from "connected-react-router"
import { useDispatch } from "react-redux"
import styles from "./NewsListItem.module.scss"
import { testId } from "../../../../utility/tests/testId"
import { NewslineItem } from "../../../../models/knowledgeBase/newsline"
import { useAppSelector } from "../../../../store/hooks"
import { selectCurrentProjectId } from "../../../../store/projects/selectors"

interface NewsListItemProps {
    item: NewslineItem
}

export const NewsListItem = ({ item }: NewsListItemProps) => {
    const dispatch = useDispatch()
    const projectId = useAppSelector(selectCurrentProjectId)
    const articlePath = `/project/${projectId}/knowledge-base/article/view/${item.ArticleCode}`

    const onClick = () => {
        dispatch(push(articlePath))
    }

    return (
        <li className={styles.item} onClick={onClick} data-testid={`${testId.newsItem}-${item.Id}`}>
            <div className={styles.item__title}>{item.Title}</div>
            <div>{item.Description}</div>
        </li>
    )
}
