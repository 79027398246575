import { actions } from "../../store/knowledgeBase/slice"
import { useCallback } from "react"
import { useAppDispatch } from "../../store/hooks"
import { useImportFileMutation } from "../../api/controllers/knowledgeBase/knowledgeBase"
import { saveNotificationInfo } from "../../store/notifications/thunks"

const tNamespace = "knowledgeBase:"

const useImportFiles = (catalogId: string, categoryId: string) => {
    const dispatch = useAppDispatch()
    const [importFile] = useImportFileMutation()

    const importFiles = useCallback(
        async (files: File[]) => {
            saveNotificationInfo(dispatch, {
                Title: {
                    Value: `${tNamespace}import-files-notification.title`,
                    NeedLocalization: true
                },
                Description: {
                    Value: `${tNamespace}import-files-notification.await-uploading`,
                    NeedLocalization: true
                }
            })

            dispatch(actions.setImportFiles(files))

            for (const file of files) {
                const formData = new FormData()
                formData.append("file", file)
                formData.append("catalogId", catalogId)
                formData.append("parentId", categoryId)

                await importFile({
                    body: formData
                })

                const progress = 100 / files.length
                const overallProgress = Math.ceil(progress)

                dispatch(actions.setImportFilesProgress(overallProgress))
            }

            dispatch(actions.clearImportFilesProgress())
        },
        [catalogId, categoryId, dispatch, importFile]
    )

    return { importFiles }
}

export default useImportFiles
