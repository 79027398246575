import { TreeMap } from "../../types/treeMap"
import { moveItemOnTree, mutateTree, TreeData, TreeDestinationPosition, TreeSourcePosition } from "@atlaskit/tree"
import { ItemId } from "@atlaskit/tree/types"
import { getPreviousAndNextItem } from "../knowledgeBase/categoriesTree"
import { TreeItemMutation } from "@atlaskit/tree/dist/types/utils/tree"

export function convertItemsToTreesMap<T, U extends TreeData = TreeData>(items: T[], convertFn: (item: T) => U) {
    const trees = items.map(convertFn)
    const treesMap = trees.reduce<TreeMap<U>>(
        (result, tree) => ({
            ...result,
            [tree.rootId]: tree
        }),
        {}
    )
    return { trees, treesMap }
}

export const moveItem = (
    itemId: ItemId,
    tree: TreeData,
    source: TreeSourcePosition,
    destination: TreeDestinationPosition
) => {
    const draggableItem = tree.items[itemId]
    const newTree = moveItemOnTree(tree, source, destination)
    const destinationItem = newTree.items[destination.parentId]

    const [prevItem, nextItem] = getPreviousAndNextItem(itemId, destinationItem.children, newTree.items)

    return {
        newTree,
        draggableItem,
        sourceItem: newTree.items[source.parentId],
        destinationItem,
        prevItem,
        nextItem
    }
}

export function mutateTreeTyped<T extends TreeData = TreeData>(
    tree: TreeData,
    itemId: ItemId,
    mutation: TreeItemMutation
): T {
    return mutateTree(tree, itemId, mutation) as T
}
