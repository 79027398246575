import { Settings } from "../../types/settings"

export function getSettings<T, U>(
    allSettings: T[],
    selectedSettings: U[],
    getKey?: (value: T) => string,
    getSelectedKey?: (value: U) => string
): Settings<T> {
    const settings: Settings<T> = {}

    const defaultGetKey = (value: T) => String(value)
    const defaultGetSelectedKey = (value: U) => String(value)

    allSettings.forEach(setting => {
        settings[(getKey || defaultGetKey)(setting)] = {
            value: setting,
            isChecked: false
        }
    })

    selectedSettings.forEach(selectedSetting => {
        const key = (getSelectedKey || defaultGetSelectedKey)(selectedSetting)
        const setting = settings[key]
        if (setting !== undefined) {
            setting.isChecked = true
        }
    })

    return settings
}
