import { useContext, useEffect, useLayoutEffect } from "react"
import { ThemingInjectorScenario } from "../../utility/theming"
import ConfigContext from "../ConfigContext/ConfigContext"
import {
    selectCurrentProjectState,
    selectDefaultProjectSettingsState,
    selectProjectSettings,
    selectThemeInitializingStatus
} from "../../store/projects/selectors"
import { useDispatch, useSelector } from "react-redux"
import { getDefaultProjectSettings } from "../../store/projects/thunks"
import { actions } from "../../store/projects/slice"
import { DEFAULT_SYSTEM_PROJECT_SETTINGS } from "../ProjectSettings/System/defaultSettings"
import useOmniLoader from "../../hooks/useOmniLoader"

interface Props {
    isAuthorized: boolean
}

export const ThemingInjector: React.FC<Props> = ({ isAuthorized }) => {
    const dispatch = useDispatch()
    const { WebConfig } = useContext(ConfigContext)
    const isRootProject = WebConfig.isDefault
    const themeInitStatus = useSelector(selectThemeInitializingStatus)
    const projectState = useSelector(selectCurrentProjectState)
    const settings = useSelector(selectProjectSettings)
    const rootProjectSettings = useSelector(selectDefaultProjectSettingsState)
    const rootSysSettings = rootProjectSettings.data?.Settings.System

    const isAllowedInNonRoot =
        settings?.System?.ColorSchema?.ColorSchemaUsing && rootSysSettings?.ColorSchemaChangeAllow

    const colorSchema =
        (isAuthorized
            ? isRootProject || isAllowedInNonRoot
                ? settings?.System?.ColorSchema
                : rootSysSettings?.ColorSchema
            : WebConfig.themeSettings?.fallbackColorSchema) ?? DEFAULT_SYSTEM_PROJECT_SETTINGS.System.ColorSchema

    useEffect(() => {
        if (!isRootProject && isAuthorized && !rootProjectSettings.data) {
            dispatch(getDefaultProjectSettings())
        }
    }, [dispatch, isAuthorized, isRootProject, rootProjectSettings.data])

    useEffect(() => {
        // condition to avoid unexpected rerender
        if (!themeInitStatus.data) dispatch(actions.getThemeInitializingStatusProcess())
    }, [dispatch, themeInitStatus.data])

    useLayoutEffect(() => {
        if ((colorSchema && settings?.System) || !isAuthorized) {
            ThemingInjectorScenario.initializeColors(colorSchema)
            dispatch(actions.getThemeInitializingStatusSuccess(true))
        }

        if (!WebConfig.themeSettings) {
            return
        }

        ThemingInjectorScenario.run(WebConfig.themeSettings)
    }, [projectState.data, WebConfig.themeSettings, colorSchema, settings, isAuthorized, dispatch])

    useOmniLoader(themeInitStatus.inProcess, isAuthorized)

    return null
}
