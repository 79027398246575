import { push } from "connected-react-router"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import {
    MassMailingFormValues,
    MassMailingFormat,
    MassMailingMethod,
    SendingJobData,
    SendingJobMethod,
    SendingJobRunMode
} from "../../../../../models/massMailing"
import { createMassMailing } from "../../../../../store/massMailing/thunks"
import { selectCurrentProjectId } from "../../../../../store/projects/selectors"
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks"

const TIMEOUT_BEFORE_NEXT_VARIANT = 10000

export const useMassMailingFormSubmitter = () => {
    const dispatch = useAppDispatch()
    const { t } = useTranslation()
    const projectId = useAppSelector(selectCurrentProjectId)
    const [isLoading, setIsLoading] = useState(false)

    const handleSubmit = async (values: MassMailingFormValues) => {
        if (projectId) {
            setIsLoading(true)

            const sendingJobData: SendingJobData = {
                Title: values.title,
                FinishNewDialog: true,
                RunMode: values.isScheduled ? SendingJobRunMode.Schedule : SendingJobRunMode.Stop,
                ScheduleTime: values.scheduleTime > 0 ? values.scheduleTime : undefined,
                MessageMarkdown: values.message || undefined,
                IntentSymbolCode: values.article?.Code,
                Method:
                    values.method === MassMailingMethod.message
                        ? SendingJobMethod.SendMessage
                        : SendingJobMethod.SendIntent,
                SendingCascade:
                    values.format === MassMailingFormat.omni
                        ? {
                              TimeoutBeforeNextVariant: TIMEOUT_BEFORE_NEXT_VARIANT,
                              Variants: [
                                  {
                                      ChannelIdForOmniUsers: values.channel
                                  }
                              ]
                          }
                        : undefined
            }

            await dispatch(
                createMassMailing(projectId, sendingJobData, String(values.attachment?.Url), t, job => {
                    dispatch(push(`/project/${projectId}/mass-mailing/${job.Id}`))
                })
            )

            setIsLoading(false)
        }
    }

    return { handleSubmit, isLoading }
}
