export enum DialogsHubEventTypes {
    HANDLE_ROUTED_DIALOG = "HandleRoutedDialog",
    HANDLE_MESSAGE = "HandleMessage",
    DETACH_DIALOG = "DetachDialog",
    PINNED_MESSAGES = "PinnedMessages",
    HOLD_DIALOG_UPDATED_MESSAGE = "HoldDialogUpdatedMessage",
    LINKED_CLIENT_ADDED = "LinkedClientAdded",
    LINKED_CLIENT_REMOVED = "LinkedClientRemoved"
}
export type DialogsHubEventCallback = (data: unknown) => Promise<void>
export type DialogsHubEvents = Record<DialogsHubEventTypes, DialogsHubEventCallback>
