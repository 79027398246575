import React, { useEffect, useMemo } from "react"
import ValidatableFormTabs from "../ValidatableFormTabs/ValidatableFormTabs"
import { TabEntry } from "../../types/tabEntry"
import { useTranslation } from "react-i18next"
import { Queue } from "../../models/queue"
import FormikQueueForm from "../FormikQueueForm/FormikQueueForm"
import { ItemId } from "@atlaskit/tree/types"
import { useDispatch } from "react-redux"
import { Dispatch } from "../../utility/common/storeHelper"
import { getCatalogs } from "../../store/knowledgeBase/thunks"
import { useLazyGetQueueExtendedSettingsQuery } from "../../api/controllers/queues"

export const tNamespace = "queues:form."

export interface QueueProps {
    projectId: string
    queue?: Queue
    onClose: () => void
    allQueues: Queue[]
    categoryId: ItemId
}

export type QueueFormTabEntries = [TabEntry, TabEntry, TabEntry, TabEntry]

const QueueForm: React.FC<QueueProps> = props => {
    const { t } = useTranslation()
    const dispatch = useDispatch<Dispatch>()
    const { queue } = props

    const tabEntries = useMemo<QueueFormTabEntries>(() => {
        const tabEntriesAll: QueueFormTabEntries = [
            { key: "parameters", value: t(`${tNamespace}parameters`) },
            { key: "individual", value: t(`${tNamespace}individual`) },
            { key: "metrics", value: t(`${tNamespace}metrics`) },
            { key: "extra", value: t(`${tNamespace}extra`) }
        ]

        return tabEntriesAll
    }, [t])

    const [loadQueueExtendedSettings, { data: queueExtendedSettings }] = useLazyGetQueueExtendedSettingsQuery()

    useEffect(() => {
        if (queue) {
            dispatch(getCatalogs(queue.TenantId, false))

            if (queue.TenantId && queue.Id) {
                loadQueueExtendedSettings({
                    projectId: queue.TenantId,
                    queueId: queue.Id
                })
            }
        }
    }, [queue])

    return (
        <ValidatableFormTabs
            id="queueFormTabs"
            alwaysReload={false}
            entries={tabEntries}
            updateKeyOnChildrenChange={false}
        >
            <FormikQueueForm {...props} tabEntries={tabEntries} extendedSettings={queueExtendedSettings} />
        </ValidatableFormTabs>
    )
}

export default QueueForm
