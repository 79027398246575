import { Dispatch } from "../common/storeHelper"
import { OptionsType } from "react-select/src/types"
import { OptionType } from "../../components/AsyncSearchableInput/AsyncSearchableInput"
import { ArticleView, SearchArticlesRequest } from "../../models/article"
import knowledgeBaseController from "../../api/controllers/knowledgeBase"
import { saveNotificationError } from "../../store/notifications/thunks"
import { TFunction } from "i18next"
import { apiCallExceptionToSystemError } from "../common/apiCallExceptionToSystemError"
import { SEARCH_ARTICLES_FAILED_MESSAGE } from "../../store/knowledgeBase/constants"

export const NOTHING_SELECTED = "unknown"

export const searchArticles =
    (
        dispatch: Dispatch,
        t: TFunction,
        projectId?: string,
        searchBySymbolCode?: boolean,
        allowWithoutPermissions?: boolean,
        pageSize = 50,
        filter: string[] = [],
        returnIdValue?: boolean,
        returnOnlyAnswers?: boolean,
        returnOnlyScenarios?: boolean,
        allowNoSelectedOption?: boolean
    ) =>
    async (query: string, meta?: string, extId?: string): Promise<OptionsType<OptionType>> => {
        try {
            if (!projectId) {
                return []
            }
            const request: SearchArticlesRequest = {
                SearchBySymbolCode: extId ? false : searchBySymbolCode,
                AllowWithoutPermissions: allowWithoutPermissions,
                PageSize: pageSize,
                ...(extId ? { ExtId: extId } : { Text: query })
            }
            const { Articles = [] } = await knowledgeBaseController.searchArticles(projectId, request)
            const condition = returnOnlyAnswers
                ? (a: ArticleView) => a.Answers
                : returnOnlyScenarios
                ? (a: ArticleView) => a.Scenario
                : (a: ArticleView) => a.Answers || a.Scenario

            const filteredArticles = Articles.filter(a => condition(a) && !filter.includes(a.SymbolCode)).map(
                ({ Title, SymbolCode, ExtId, Id }) => ({
                    label: Title || t("knowledgeBase:untitled"),
                    value: returnIdValue ? Id : SymbolCode,
                    meta: ExtId
                })
            )
            return allowNoSelectedOption
                ? [{ label: t("form:nothing-selected"), value: NOTHING_SELECTED }, ...filteredArticles]
                : filteredArticles
        } catch (e) {
            const error = apiCallExceptionToSystemError(SEARCH_ARTICLES_FAILED_MESSAGE, e)
            saveNotificationError(dispatch, error)
            return []
        }
    }
