import React, { ReactNode, useState } from "react"
import styles from "./ArticleAnswers.module.scss"
import { useDispatch, useSelector } from "react-redux"
import { selectAnswersFrom, selectCurrentAnswer, selectCurrentArticle } from "../../store/knowledgeBase/selectors"
import { selectSlots } from "../../store/slots/selectors"
import { KnowledgeBasePermittedAction } from "../../models/knowledgeBase/permission"
import IconButton from "../IconButton/IconButton"
import { useTranslation } from "react-i18next"
import { faPlus } from "@fortawesome/pro-light-svg-icons/faPlus"
import ArticleAnswerItem from "../ArticleAnswerItem/ArticleAnswerItem"
import { FieldArray, Formik } from "formik"
import { Form } from "react-bootstrap"
import { ArticleAnswer, ArticleAnswersFormValues } from "../../models/article"
import { nameof } from "../../utility/common/nameof"
import { DragDropContext, Droppable, DropResult } from "react-beautiful-dnd"
import ArticleAnswersForm from "../ArticleAnswersForm/ArticleAnswersForm"
import * as Yup from "yup"
import { TFunction } from "i18next"
import { actions } from "../../store/knowledgeBase/slice"

interface Props {
    isOpenAddForm?: boolean
    isEdit?: boolean
    onSelectAnswer?: (answerId: string) => void
}

const tNamespace = "knowledgeBase:answers-modal."

const ArticleAnswers: React.FC<Props> = props => {
    const { isEdit = false, isOpenAddForm = false, onSelectAnswer } = props
    const [isAdding, setIsAdding] = useState(isOpenAddForm)
    const { t } = useTranslation()
    const currentArticle = useSelector(selectCurrentArticle)
    const answersForm = useSelector(selectAnswersFrom)
    const slots = useSelector(selectSlots)
    const currentAnswer = useSelector(selectCurrentAnswer)
    const dispatch = useDispatch()
    if (!currentArticle || !answersForm) {
        return null
    }
    const { PermittedAction } = currentArticle
    if (isEdit && PermittedAction !== KnowledgeBasePermittedAction.Edit) {
        return null
    }

    const handleAddCancel = () => setIsAdding(false)

    const getAddFrom = () =>
        isAdding ? (
            <ArticleAnswersForm t={t} slots={slots} onCancel={handleAddCancel} onSelectAnswer={onSelectAnswer} />
        ) : (
            <IconButton variant="light" icon={faPlus} onClick={() => setIsAdding(true)}>
                {t(`${tNamespace}add`)}
            </IconButton>
        )

    const handleSubmit = (values: ArticleAnswersFormValues) => {
        dispatch(actions.updateArticleAnswersForm(values))
    }

    return (
        <div className={styles.articleAnswers}>
            <div className={styles.articleAnswers__content}>
                <ArticleAnswersEdit
                    {...props}
                    t={t}
                    answersForm={answersForm}
                    onSubmit={handleSubmit}
                    addForm={isEdit && getAddFrom()}
                >
                    {(answers, onItemRemove) => (
                        <div className={styles.articleAnswers__items}>
                            {answers.map((answer, index) => (
                                <ArticleAnswerItem
                                    key={index}
                                    t={t}
                                    answer={answer}
                                    index={index}
                                    slots={slots}
                                    isSelected={currentAnswer?.Id === answer.Id}
                                    isEdit={isEdit}
                                    disableInteraction={answers.length === 1}
                                    onRemove={() =>
                                        onItemRemove && onItemRemove(index, currentAnswer?.Id === answer.Id)
                                    }
                                    onSelectAnswer={onSelectAnswer}
                                />
                            ))}
                        </div>
                    )}
                </ArticleAnswersEdit>
            </div>
        </div>
    )
}

type OnItemRemoveType = (index: number, isSelected: boolean) => void

interface ArticleAnswersEditProps extends Props {
    answersForm: ArticleAnswersFormValues
    addForm: ReactNode
    onSubmit: (values: ArticleAnswersFormValues) => void
    children: (answers: ArticleAnswer[], onItemRemove?: OnItemRemoveType) => ReactNode
    t: TFunction
}

const ArticleAnswersEdit: React.FC<ArticleAnswersEditProps> = props => {
    const { children, isEdit, onSubmit, answersForm, addForm, onSelectAnswer } = props

    if (!isEdit) {
        return <>{children(answersForm.Answers)}</>
    }

    return (
        <Formik<ArticleAnswersFormValues>
            initialValues={answersForm}
            onSubmit={onSubmit}
            validationSchema={Yup.object().shape({
                answers: Yup.array().of(Yup.object()).min(1, `${tNamespace}non-empty-answers`)
            })}
        >
            {formikProps => (
                <Form onSubmit={formikProps.handleSubmit}>
                    <FieldArray name={nameof<ArticleAnswersFormValues>("Answers")}>
                        {arrayHelpers => (
                            <DragDropContext
                                onDragEnd={(result: DropResult) => {
                                    if (!result.destination || result.destination.index === result.source.index) return
                                    arrayHelpers.move(result.source.index, result.destination.index)
                                    formikProps.submitForm()
                                }}
                            >
                                <Droppable droppableId="articleAnswers">
                                    {provided => (
                                        <div
                                            {...provided.droppableProps}
                                            className="article-answers__dnd-field"
                                            ref={provided.innerRef}
                                        >
                                            {children(formikProps.values.Answers, (index, isSelected) => {
                                                arrayHelpers.remove(index)
                                                const answers = formikProps.values.Answers
                                                formikProps
                                                    .submitForm()
                                                    .then(() => isSelected && onSelectAnswer?.(answers[0].Id))
                                            })}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        )}
                    </FieldArray>
                    {addForm}
                </Form>
            )}
        </Formik>
    )
}

export default ArticleAnswers
