import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { resetReducerState } from "../action"

export interface PortalSystemState {
    portalIds: {
        [id: number | string]: {
            shown: boolean
            meta: unknown
        }
    }
}

export type PayloadActionShowPortal<T = unknown> = {
    portalId: number | string
    portalMeta?: T
}

export type PayloadActionHidePortal = {
    portalId: number | string
}

export type PayloadActionSetPortalMeta = {
    portalId: number | string
    protalMeta: unknown
}

const initialState: PortalSystemState = {
    portalIds: {}
}

const portalSystemSlice = createSlice({
    name: "portalSystem",
    initialState,
    reducers: {
        showPortal: <T = unknown>(state: PortalSystemState, action: PayloadAction<PayloadActionShowPortal<T>>) => {
            const { portalId, portalMeta = null } = action.payload

            state.portalIds[portalId] = {
                shown: true,
                meta: portalMeta
            }
        },
        hidePortal: (state, action: PayloadAction<PayloadActionHidePortal>) => {
            const portalId = action.payload.portalId

            if (!state.portalIds[portalId]) {
                return
            }

            state.portalIds[portalId] = {
                ...state.portalIds[portalId],
                shown: false
            }
        },
        setPortalMeta: (state, action: PayloadAction<PayloadActionSetPortalMeta>) => {
            const { portalId, protalMeta } = action.payload

            if (!state.portalIds[portalId]) {
                return
            }

            state.portalIds[portalId] = {
                ...state.portalIds[portalId],
                meta: protalMeta
            }
        }
    },
    extraReducers(builder) {
        builder.addCase(resetReducerState, () => {
            return initialState
        })
    }
})

export const { showPortal, hidePortal, setPortalMeta } = portalSystemSlice.actions

export const portalSystemActions = {
    showPortal,
    hidePortal,
    setPortalMeta
}

export const actions = portalSystemSlice.actions

export const PortalSystemReducer = portalSystemSlice.reducer
