import styles from "./SearchResultsHeader.module.scss"
import { useTranslation } from "react-i18next"

const tNamespace = "common:search."

export interface SearchResultsHeaderProps {
    itemCount: number
}

export const SearchResultsHeader = ({ itemCount }: SearchResultsHeaderProps) => {
    const { t } = useTranslation()

    return (
        <div className={styles.header}>
            {itemCount !== 0 ? `${t(`${tNamespace}results-found`)}: ${itemCount}` : t(`${tNamespace}nothing-found`)}
        </div>
    )
}
