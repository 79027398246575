import React from "react"
import { useTranslation } from "react-i18next"
import { Form, Col } from "react-bootstrap"
import { useField } from "formik"

const tNamespace = "queues:settings."

export interface IndividualTimeoutSettingsValues {
    timeoutHr: number
    timeoutMin: number
    timeoutSec: number
}

interface Props {
    name: string
}

const IndividualTimeoutSettingsInput: React.FC<Props> = props => {
    const { t } = useTranslation()
    const { name } = props
    const [timeoutHrField, timeoutHrMeta] = useField(getFullName("timeoutHr"))
    const [timeoutMinField, timeoutMinMeta] = useField(getFullName("timeoutMin"))
    const [timeoutSecField, timeoutSecMeta] = useField(getFullName("timeoutSec"))

    function getFullName(fieldName: keyof IndividualTimeoutSettingsValues) {
        return `${name}.${fieldName}`
    }

    return (
        <>
            <Form.Row>
                <Col md={4}>
                    <Form.Row>
                        <Form.Group as={Col} sm={5} controlId="formTimeoutHr">
                            <Form.Control
                                type="number"
                                name={timeoutHrField.name}
                                onChange={timeoutHrField.onChange}
                                onBlur={timeoutHrField.onBlur}
                                value={timeoutHrField.value}
                                isInvalid={timeoutHrMeta.touched && !!timeoutHrMeta.error}
                            />
                            <Form.Control.Feedback type="invalid">{timeoutHrMeta.error}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Label column sm={7}>
                            {t(`${tNamespace}hr`)}
                        </Form.Label>
                    </Form.Row>
                </Col>
                <Col md={4}>
                    <Form.Row>
                        <Form.Group as={Col} sm={5} controlId="formTimeoutMin">
                            <Form.Control
                                type="number"
                                name={timeoutMinField.name}
                                onChange={timeoutMinField.onChange}
                                onBlur={timeoutMinField.onBlur}
                                value={timeoutMinField.value}
                                isInvalid={timeoutMinMeta.touched && !!timeoutMinMeta.error}
                            />
                            <Form.Control.Feedback type="invalid">{timeoutMinMeta.error}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Label column sm={7}>
                            {t(`${tNamespace}min`)}
                        </Form.Label>
                    </Form.Row>
                </Col>
                <Col md={4}>
                    <Form.Row>
                        <Form.Group as={Col} sm={5} controlId="formTimeoutSec">
                            <Form.Control
                                type="number"
                                name={timeoutSecField.name}
                                onChange={timeoutSecField.onChange}
                                onBlur={timeoutSecField.onBlur}
                                value={timeoutSecField.value}
                                isInvalid={timeoutSecMeta.touched && !!timeoutSecMeta.error}
                            />
                            <Form.Control.Feedback type="invalid">{timeoutSecMeta.error}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Label column sm={7}>
                            {t(`${tNamespace}sec`)}
                        </Form.Label>
                    </Form.Row>
                </Col>
            </Form.Row>
        </>
    )
}

export default IndividualTimeoutSettingsInput
