import styles from "./QueueForAutomaticProcessing.module.scss"
import TaskCardLoader from "../TaskCard/TaskCardLoader"
import React from "react"

const QueueForAutomaticProcessingContentLoader = () => {
    return (
        <div className={styles.queueForAutomaticProcessing__loader}>
            {new Array<number>(3).fill(0).map((_, i) => (
                <TaskCardLoader key={i} />
            ))}
        </div>
    )
}

export default QueueForAutomaticProcessingContentLoader
