import React from "react"
import AgentFormWrapper from "../../../../AgentFormWrapper/AgentFormWrapper"
import { nameof } from "../../../../../utility/common/nameof"
import { JSAgentValues } from "../../../../../models/agent"
import CodeBlockValidatableInput from "../../../../CodeBlockValidatableInput/CodeBlockValidatableInput"
import { JSAgentFormProps } from "../JSAgentForm"

const tNamespace = "agent:form.jsagent."

const FormikJSAgentCodeForm: React.FC<JSAgentFormProps> = props => {
    const { t, inProcess, handleSubmit, disabled } = props

    return (
        <AgentFormWrapper onSubmit={handleSubmit} disabled={disabled} loading={inProcess}>
            <div className="agent-form__section">
                <CodeBlockValidatableInput
                    id="formAgentCode"
                    name={nameof<JSAgentValues>("Code")}
                    label={t(`${tNamespace}js-code`)}
                />
            </div>
        </AgentFormWrapper>
    )
}

export default FormikJSAgentCodeForm
