import React, { useCallback, useMemo } from "react"
import styles from "./CreatableTagSelect.module.scss"
import { Form } from "react-bootstrap"
import { CreatableSelect } from "../Select/Select"
import Tag from "../Tag/Tag"
import { FieldArray, useField } from "formik"
import { WithT } from "i18next"
import { formTranslation } from "../../locales/form"
import cn from "classnames"
import { MenuPlacement, OptionsType, ValueType } from "react-select"

export type TCreatableSelectProps = React.ComponentPropsWithRef<typeof CreatableSelect>
export type WithPickedCreatableSelectProps = Pick<TCreatableSelectProps, "options">

export interface CreatableTagSelectProps extends WithT, WithPickedCreatableSelectProps {
    id: string
    name: string
    label?: string
    placeholder: string
    placeholderOnAnySelected?: string
    nonCreatable?: boolean
    options?: TOptionsArr
    disableOnAllSelected?: boolean
    TagProps?: React.HTMLAttributes<HTMLDivElement>
    className?: string
    disabled?: boolean
    icon?: React.ReactElement
    selectedTagLabelShow?: boolean
    placement?: MenuPlacement
}

export type TOptionsArr = OptionsType<{
    label: string
    value: string
}>

export type TOptionItem = ValueType<
    {
        label: string
        value: string
    },
    false
>

const CreatableTagSelect: React.FC<CreatableTagSelectProps> = props => {
    const {
        id,
        name,
        label,
        placeholder,
        placeholderOnAnySelected,
        disableOnAllSelected,
        options = [],
        nonCreatable,
        TagProps,
        className,
        t,
        disabled,
        selectedTagLabelShow,
        icon,
        placement
    } = props

    const [field] = useField(name)
    const hasOptions = Boolean(options.length)
    const defaultCSelectProps: TCreatableSelectProps = []

    const handleOnChange = useCallback(
        (option: TOptionItem, pushFn: (obj: unknown) => void) => {
            if (!option) {
                return
            }

            if (hasOptions && field.value.includes(option.value)) {
                return
            }

            pushFn(option.value)
        },
        [field, hasOptions]
    )

    const filteredOptions = useMemo(() => {
        if (hasOptions) {
            return options.filter(op => !field.value.includes(op.value))
        }

        return options
    }, [options, field, hasOptions])

    let defaultValue = { label: placeholder, value: "" }

    if (placeholderOnAnySelected && field.value.length) {
        defaultValue = { label: placeholderOnAnySelected, value: "" }
    }

    if (nonCreatable) {
        defaultCSelectProps["isValidNewOption"] = () => false
    }

    if (disableOnAllSelected) {
        defaultCSelectProps["isDisabled"] = !filteredOptions.length
    }

    if (disabled) {
        defaultCSelectProps["isDisabled"] = disabled
    }

    if (placement) {
        defaultCSelectProps["menuPlacement"] = placement
    }

    return (
        <FieldArray
            name={name}
            render={({ push, remove }) => (
                <>
                    <Form.Group controlId={id} className={cn(styles.creatableTagSelect, className)}>
                        {label && (
                            <Form.Label>
                                {label} {icon}
                            </Form.Label>
                        )}
                        <CreatableSelect
                            {...defaultCSelectProps}
                            className={cn(
                                styles.creatableTagSelect__tagInput,
                                hasOptions && styles.creatableTagSelect__tagInput_withArrow
                            )}
                            value={defaultValue}
                            options={filteredOptions}
                            noOptionsMessage={() => null}
                            onChange={option => handleOnChange(option, push)}
                            formatCreateLabel={(inputValue: string) => `${t(formTranslation.add)}: ${inputValue}`}
                        />
                        {Boolean(field.value.length) && (
                            <div className={styles.creatableTagSelect__tags}>
                                {field.value.map((value: string, index: number) => {
                                    let tagTitle = value

                                    if (selectedTagLabelShow) {
                                        const optionLabelByValue = options.find(x => x.value === value)

                                        if (optionLabelByValue) {
                                            tagTitle = optionLabelByValue.label
                                        } else {
                                            return null
                                        }
                                    }

                                    return (
                                        <Tag
                                            {...TagProps}
                                            className={cn(styles.creatableTagSelect__tag, TagProps?.className)}
                                            key={`${value}-${index}`}
                                            index={index}
                                            title={tagTitle}
                                            onDelete={() => remove(index)}
                                        />
                                    )
                                })}
                            </div>
                        )}
                    </Form.Group>
                </>
            )}
        />
    )
}

export default CreatableTagSelect
