import React, { useCallback, useEffect, useMemo, useRef } from "react"
import { ClassProps } from "../../utility/common/props"
import Editor from "rich-markdown-editor"
import RichMarkdownEditor, { Props as EditorProps } from "rich-markdown-editor"
import KeyboardNode, { CodeBlock, MarkdownNode, QuickActionsNode } from "./extensions/Keyboard/KeyboardNode"
import { WithT } from "i18next"
import { Dispatch } from "../../utility/common/storeHelper"
import { makeArticleContentEditorDictionary } from "../../utility/articleContentEditor/dictionary"
import { getArticleEditorEmbeds } from "../../utility/knowledgeBase/articleEditorEmbeds"
import { articleContentEditorTranslation } from "../../locales/articleContentEditor"
import { uploadFileToUrl } from "../../utility/common/files"
import { PageLayoutContentId } from "../PageLayout/PageLayout"
import { getPerformanceObserver, logPerformanceObserverInitializingError } from "../../performance"
import { useParams } from "react-router-dom"
import { setCaretAtStartEnd } from "../../helpers/text"
import type { TreeItem } from "@atlaskit/tree/types"
import { useAppDispatch, useAppSelector } from "../../store/hooks"
import { selectCurrentUser } from "../../store/users/selectors"
import { SendKeyCombinations } from "../UserProfileForm/UserProfileForm"
import { useSelector } from "react-redux"
import { selectCursorPosition, selectShouldHandleCursorPosition } from "../../store/dialogs/selectors"
import { setCursorInputPosition } from "../../utility/dialogs/setEditorCursorPosition"

type Deps = ClassProps & WithT

export interface ContentEditorProps extends Deps {
    allowedFileExtensions?: string[]
    allowedVideoExtensions?: string[]
    allowedAudioExtensions?: string[]
    content: string
    onInit?: () => void
    onDocStateChange?: (getContent: () => string) => void
    onChange?: (getContent: () => string) => void
    setCursorPosition?: (position: number) => void
    isEditable?: boolean
    projectId?: string
    onOpenArticle?: (articleCode: string, categoryItem?: TreeItem, catalogCode?: string) => void
    onChangeLocation?: (path: string) => void
    onDispatch?: Dispatch
    disableExtensions?: EditorProps["disableExtensions"]
    onCreateCommands?: (commands: ContentEditorCommands) => void
    externalIdsForSelectionToolbar?: string[]
    channels?: string[]
    articleSymbolCode?: string
    isInArticle?: boolean
    isInDialogInput?: boolean
    initialCarePos?: "start" | "end"
    advancedPlaceholder?: string
}

// Список команд можно дополнять, исходя из своих нужд, тут не все (их более 30 на самом деле)
export type ContentEditorCommands = {
    link: ({ href }: { href?: string }) => void
    ordered_list: () => void
    bullet_list: () => void
    insertHtml: (value: string) => void
}

export const DEFAULT_EDITOR_DIS_EXT: NonNullable<EditorProps["disableExtensions"]> = [
    "code_block",
    "code_fence",
    "container_notice",
    "emoji",
    "emojimenu",
    "widget",
    "quickactions"
]

const ContentEditor: React.FC<ContentEditorProps> = props => {
    const {
        projectId,
        t,
        onOpenArticle,
        onChangeLocation,
        onDispatch,
        content,
        allowedFileExtensions = [],
        allowedVideoExtensions = [],
        allowedAudioExtensions = [],
        isEditable,
        onInit,
        onDocStateChange,
        onChange,
        className,
        disableExtensions = DEFAULT_EDITOR_DIS_EXT,
        onCreateCommands,
        externalIdsForSelectionToolbar,
        channels,
        articleSymbolCode,
        isInArticle,
        advancedPlaceholder,
        isInDialogInput,
        initialCarePos,
        setCursorPosition
    } = props

    const ref = useRef<RichMarkdownEditor>(null)
    const { shareId } = useParams<{ shareId?: string }>()
    const lastCursorPosition = useSelector(selectCursorPosition)
    const shouldHandleCursorPosition = useSelector(selectShouldHandleCursorPosition)
    const dispatch = useAppDispatch()

    useEffect(() => {
        const rmeDom = ref.current?.view.dom

        if (initialCarePos && content && rmeDom) {
            const caretStartPos = initialCarePos === "start"
            // @ts-ignore
            setCaretAtStartEnd(rmeDom, caretStartPos)
        }
    }, [content, initialCarePos])

    useEffect(() => {
        if (shouldHandleCursorPosition && isEditable) {
            setCursorInputPosition(dispatch, ref.current, lastCursorPosition)
        }
    }, [shouldHandleCursorPosition])

    useEffect(() => {
        let observer: PerformanceObserver | undefined

        try {
            observer = getPerformanceObserver("ContentEditor")
            observer?.observe({ type: "event", buffered: true })
        } catch (e) {
            logPerformanceObserverInitializingError("ContentEditor", e)
        }

        return () => {
            observer?.disconnect()
        }
    }, [])

    const dictionary = useMemo(() => makeArticleContentEditorDictionary(t), [t])

    const embeds = useMemo(
        () => getArticleEditorEmbeds(t, allowedFileExtensions, allowedVideoExtensions, allowedAudioExtensions),
        [t, allowedFileExtensions, allowedVideoExtensions, allowedAudioExtensions]
    )

    const extensions = useMemo(() => {
        if ((projectId || shareId) && onOpenArticle && onChangeLocation && onDispatch) {
            return [
                new KeyboardNode({
                    projectId,
                    onOpenArticle,
                    onDispatch,
                    t,
                    onChangeLocation,
                    channels,
                    articleSymbolCode
                }),
                new CodeBlock({ projectId, onOpenArticle, onDispatch, t }),
                new MarkdownNode({ projectId, onOpenArticle, onDispatch, t }),
                new QuickActionsNode({ projectId, onOpenArticle, onDispatch, t })
            ]
        } else {
            return []
        }
    }, [projectId, shareId, onOpenArticle, onChangeLocation, onDispatch, t, channels, articleSymbolCode])

    const isOnEnterSubmit = useAppSelector(selectCurrentUser)?.KeyCombination === SendKeyCombinations.Enter
    const tipOnNewLine = isOnEnterSubmit ? "newLineEmptyOnKeyEnter" : "newLineEmpty"

    const placeholder = isInArticle
        ? t(articleContentEditorTranslation["newLineBlock"])
        : t(articleContentEditorTranslation[tipOnNewLine])

    const handleCreateCommands = (commands: ContentEditorCommands) => {
        onCreateCommands && onCreateCommands(commands)
    }

    const getValue = useCallback(() => {
        if (!isEditable && isInDialogInput && advancedPlaceholder) {
            return advancedPlaceholder
        }
        return content && content.length > 0 ? content : "\\" // string "\\" tell Editor to show placeholder
    }, [isEditable, isInDialogInput, content, advancedPlaceholder])

    return (
        <Editor
            ref={ref}
            className={className}
            defaultValue={content}
            value={getValue()}
            placeholder={placeholder}
            readOnly={!isEditable}
            autoFocus={isEditable}
            onChange={onChange}
            onDocStateChange={onDocStateChange}
            onInit={onInit}
            uploadImage={isEditable ? uploadFileToUrl : undefined}
            uploadFile={isEditable ? uploadFileToUrl : undefined}
            uploadVideo={isEditable ? uploadFileToUrl : undefined}
            onCreateCommands={handleCreateCommands}
            uploadAduio={isEditable ? uploadFileToUrl : undefined}
            allowedFileExtensions={allowedFileExtensions}
            allowedVideoExtensions={allowedVideoExtensions}
            allowedAudioExtensions={allowedAudioExtensions}
            embeds={embeds}
            dictionary={dictionary}
            extensions={extensions}
            disableExtensions={disableExtensions}
            scrollableContainerId={PageLayoutContentId}
            hiddenTopOffset={120}
            externalIdsForSelectionToolbar={externalIdsForSelectionToolbar}
            isNewLineOnKeyEnterInDialog={!isOnEnterSubmit && isInDialogInput}
            isInArticle={isInArticle}
            onKeyDown={() => {
                setTimeout(() => setCursorPosition?.(ref.current?.view.state.selection.from || 0), 1)
            }}
            handleDOMEvents={{
                click: view => {
                    setCursorPosition?.(view.state.selection.from)
                    return false
                }
            }}
        />
    )
}

export default ContentEditor
