const FEATURE_FLAG_PREFIX = "FeatureFlag_"

export const SHARING_ENABLED = "enable_kb_sharing"
export const JS_AGENT_REF_REQUIRED = "enable_javascript_agent"
export const BSS_AGENT_REF_REQUIRED = "enable_bss_agent"
export const NEW_WORKPLACE_ENABLED = "new_workplace_enabled"
export const MASS_MAILING_ENABLED = "enable_bulk_mailing"
export const UNIQUE_WEIGHT_ROLE_ENABLED = "UniqueWeightRoleEnable"
export const FEATURE_FLAG_WEIGHT_ROLE_ENABLED = FEATURE_FLAG_PREFIX + "WeightRoleEnable"
export const INTERNAL_ROLE_ENABLED = "InternalRoleEnabled"
export const APPEAL_TO_AD = "AppealToAD"
export const INTERNAL_USER_CT = "InternalUserCT"
export const ENABLE_REGISTRATION_SLOTS = "EnableRegistrationSlotsRef"
export const QUEUES_V2_ENABLED = "enable_queues_v2_enabled"
