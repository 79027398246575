import { createAction } from "@reduxjs/toolkit"
import { portalSystemActions } from "./portalSystem/slice"

export const resetReducerState = createAction("root/resetReducerState")

const actions = {
    ...portalSystemActions
}

export default actions
