import React from "react"
import { useSelector } from "react-redux"
import { selectCurrentProjectId } from "../../store/projects/selectors"
import ErrorMessage from "../ErrorMessage/ErrorMessage"
import { ArticleHistoryElement, ArticleHistoryRequest, ArticleHistoryResponse } from "../../models/ArticleHistory"
import { useTranslation } from "react-i18next"
import { formatArticleHistory } from "./helpers"
import ArticleHistoryList from "./ArticleHistoryList/ArticleHistoryList"
import AsyncFetch from "../Async/AsyncFetch/AsyncFetch"
import knowledgeBaseController from "../../api/controllers/knowledgeBase"
import { GET_ARTICLE_HISTORY_FAILED_MESSAGE } from "../../store/knowledgeBase/constants"
import AdvancedSkeleton from "../Skeleton/AdvancedSkeleton"
import style from "./ArticleHistoryContainer.module.scss"

export interface ArticleHistoryProps {
    articleCode: string
}

export type ArticleHistoryGroupedByDate = Record<string, ArticleHistoryElement[]>

const ArticleHistoryContainer: React.FC<ArticleHistoryProps> = props => {
    const { articleCode } = props
    const projectId = useSelector(selectCurrentProjectId)
    const { t } = useTranslation()
    const strictProjectId = projectId ?? ""
    const params: ArticleHistoryRequest = [strictProjectId, articleCode]

    return (
        <AsyncFetch<ArticleHistoryRequest, ArticleHistoryResponse>
            fetchFunction={knowledgeBaseController.getArticleHistory}
            requestParams={params}
            processView={<AdvancedSkeleton count={3} className={style.ArticleHistoryLoader} />}
            errorView={({ message }) => <ErrorMessage text={message} />}
            errorKey={GET_ARTICLE_HISTORY_FAILED_MESSAGE}
        >
            {({ data }) => {
                const groupedByDate = formatArticleHistory(data.History)

                return <ArticleHistoryList groupedHistory={groupedByDate} t={t}></ArticleHistoryList>
            }}
        </AsyncFetch>
    )
}

export default ArticleHistoryContainer
