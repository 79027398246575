import React, { useEffect, useState } from "react"
import { beautifyMsToHMSString, DAY_IN_MS } from "../../utility/common/time"
import useBeforeUnload from "../../hooks/useBeforeUnload"
import { SelectedOperatorStatus } from "../../models/operatorStatus"
import { useDispatch } from "react-redux"
import { testId } from "../../utility/tests/testId"
import { useTranslation } from "react-i18next"
import { saveStatusTimer } from "../../store/userOperator/actions"

const tNamespace = "operator"

export interface OperatorStatusTimerProps {
    status: SelectedOperatorStatus
}

const OperatorStatusTimer: React.FC<OperatorStatusTimerProps> = ({ status }) => {
    const dispatch = useDispatch()
    const { t } = useTranslation(tNamespace)
    const prevStatusTimestamp = status.StatusSetTime ?? Date.now()
    const [elapsedTime, setElapsedTime] = useState(0)

    useEffect(() => {
        const updateTimer = () => {
            const ms = Date.now() - prevStatusTimestamp
            setElapsedTime(ms)
        }

        const timerId = setInterval(updateTimer, 1000)

        return () => {
            if (timerId) {
                clearInterval(timerId)
            }
        }
    }, [prevStatusTimestamp])

    useBeforeUnload(() => {
        dispatch(saveStatusTimer(status, prevStatusTimestamp, true))
    })

    return (
        <div data-testid={testId.operatorStatusTimer}>
            {elapsedTime > DAY_IN_MS ? t("timer-more-than-day") : beautifyMsToHMSString(elapsedTime)}
        </div>
    )
}

export default OperatorStatusTimer
