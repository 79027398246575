import React, { useState } from "react"
import { FormikAgentFormProps } from "../../AgentForm"
import { FieldArray, FormikProps } from "formik"
import { NightAgentValues, ScheduleValue } from "../../../../models/agent"
import ValidatableInput from "../../../ValidatableInput/ValidatableInput"
import { nameof, nameof2 } from "../../../../utility/common/nameof"
import { formTranslation } from "../../../../locales/form"
import { ParamValues } from "../../../../models/parameterDeclaration"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus } from "@fortawesome/pro-light-svg-icons/faPlus"
import AgentScheduleSettingsInput from "../../../AgentScheduleSettingsInput/AgentScheduleSettingsInput"
import { useModal } from "../../../../utility/common/useModal"
import CalendarModal from "../../../CalendarModal/CalendarModal"
import { format } from "date-fns"
import DocLink from "../../../DocLink/DocLink"
import AgentFormWrapper from "../../../AgentFormWrapper/AgentFormWrapper"

const tNamespace = "agent:form."
const tLinkNamespace = "agent:link."

const FormikNightAgentFormGeneral: React.FC<FormikAgentFormProps & FormikProps<NightAgentValues>> = props => {
    const {
        asyncState,
        agentOptions,
        agentType,
        t,
        handleSubmit,
        setFieldValue,
        values,
        disabled,
        ownedByThisScenario
    } = props

    const [scheduleToEdit, setScheduleToEdit] = useState<number | null>(null)
    const { modalOpen, openModal, closeModal, onExited } = useModal(() => (
        <CalendarModal
            show={modalOpen}
            onClose={closeModal}
            onSubmit={handleDateChange}
            onExited={onExited}
            datesBetween
            adjustDateOnChange
        />
    ))

    const handleDateChange = (dates: Date[]) => {
        if (scheduleToEdit !== null) {
            const formattedDates = dates.map(date => format(date, "yyyy-MM-dd"))
            setFieldValue(
                `Schedule[${scheduleToEdit}].Dates`,
                [...values.Schedule[scheduleToEdit].Dates, ...formattedDates],
                false
            )
            setScheduleToEdit(null)
        }
    }

    return (
        <AgentFormWrapper
            onSubmit={handleSubmit}
            disabled={disabled}
            loading={asyncState.inProcess}
            agentType={agentType}
        >
            <DocLink type={agentType}>{t(`${tLinkNamespace}creating-agent-instructions`)}</DocLink>
            <div className="agent-form__section">
                <ValidatableInput
                    id="formTitle"
                    type="text"
                    name={nameof<NightAgentValues>("Id")}
                    label={t(`${tNamespace}title`)}
                />
            </div>
            <div className="agent-form__section agent-form__section_border">
                <FieldArray
                    name={nameof<NightAgentValues>("Schedule")}
                    render={({ push, remove }) => (
                        <>
                            <div className="agent-form__add-section">
                                {t(`${tNamespace}schedule`)}
                                <span
                                    className="agent-form__button-icon"
                                    onClick={() => {
                                        push({
                                            NightStartHr: "21",
                                            NightStartMin: "00",
                                            NightEndHr: "09",
                                            NightEndMin: "00",
                                            AllDay: false,
                                            WeekDays: Array(7).fill(0),
                                            Dates: []
                                        })
                                    }}
                                >
                                    <FontAwesomeIcon icon={faPlus} />
                                </span>
                            </div>
                            {values.Schedule?.map((schedule: ScheduleValue, index: number) => (
                                <AgentScheduleSettingsInput
                                    key={index}
                                    index={index}
                                    onDelete={() => remove(index)}
                                    onChooseDate={() => {
                                        setScheduleToEdit(index)
                                        openModal()
                                    }}
                                />
                            ))}
                        </>
                    )}
                />
                <div className="agent-form__section">
                    <ValidatableInput
                        id="formMessage"
                        type="text"
                        name={nameof2<NightAgentValues, ParamValues>("Params", "message")}
                        label={t(`${tNamespace}non-working-hours-message`)}
                        disabled={ownedByThisScenario}
                    />
                </div>
            </div>
            <div className="agent-form__section">
                <div className="agent-form__section-subtitle">{t(`${tNamespace}switch-to-agent`)}</div>
                <ValidatableInput
                    as="select"
                    id="formDefaultAgent"
                    type="text"
                    name={nameof2<NightAgentValues, ParamValues>("Params", "default_agent")}
                    label={t(`${tNamespace}during-working-hours`)}
                    disabled={!agentOptions.length || ownedByThisScenario}
                >
                    <option value="">{t(formTranslation.nothingSelected)}</option>
                    {agentOptions.map(option => (
                        <option value={option.Id} key={option.Id}>
                            {option.Id}
                        </option>
                    ))}
                </ValidatableInput>
                <ValidatableInput
                    as="select"
                    id="formNightDefaultAgent"
                    type="text"
                    name={nameof2<NightAgentValues, ParamValues>("Params", "night_default_agent")}
                    label={t(`${tNamespace}during-non-working-hours`)}
                    disabled={!agentOptions.length || ownedByThisScenario}
                >
                    <option value="">{t(formTranslation.nothingSelected)}</option>
                    {agentOptions.map(option => (
                        <option value={option.Id} key={option.Id}>
                            {option.Id}
                        </option>
                    ))}
                </ValidatableInput>
            </div>
        </AgentFormWrapper>
    )
}

export default FormikNightAgentFormGeneral
