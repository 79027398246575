import { MonitoringOverview, Queue, QueueType } from "../../models/queue"

const queueId = "automatic-processing-queue"

export const monitoringOverviewConverter = {
    toAutomaticProcessingQueue(value: MonitoringOverview, categoryId: string): Queue {
        return {
            Id: queueId,
            Name: "",
            CategoryId: categoryId,
            ActiveTasksCount: value.AutomaticProcessedDialogs,
            IndividualPriority: 0,
            Type: QueueType.Auto,
            IndividualTimeoutSettings: {
                TimeoutMSec: 0
            },
            LastPickTime: 0,
            OperatorsIds: [],
            OperatorsTasksCount: {},
            PendingIndividualTasks: [],
            PendingTasks: [],
            Priority: 0,
            TaskSize: 0,
            TenantId: "",
            Awt: {},
            FinishedDialogs: {},
            FinishedDialogsDaily: {}
        }
    }
}
