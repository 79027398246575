import { OperatorStatus, SelectedOperatorStatus, SelectedOperatorStatusDto } from "../../models/operatorStatus"
import { LocalizedText } from "../../types/localizedText"

const UnknownTitleTranslation: LocalizedText = {
    Value: "menu:statuses.unknown",
    NeedLocalization: true
}

export const toSelectedOperatorStatusModel = (
    dto: SelectedOperatorStatusDto,
    allStatuses: OperatorStatus[]
): SelectedOperatorStatus => {
    const foundedStatus = allStatuses.find(s => s.Description === dto.Description)
    return {
        Value: dto.Value,
        Description: dto.Description,
        Title: foundedStatus ? foundedStatus.Title : UnknownTitleTranslation,
        UpdatedAt: dto.UpdatedAt,
        StatusSetTime: dto.StatusSetTime,
        IsOutgoingAvailable: dto.IsOutgoingAvailable
    }
}
