import ArticleSelect from "../ArticleSelect/ArticleSelect"
import InfoIcon from "../InfoIcon/InfoIcon"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { selectCurrentProjectId } from "../../store/projects/selectors"
import { useTranslation } from "react-i18next"

const tTooltipNamespace = "agent:tooltip."

export interface Props {
    label: string
    name: string
    defaultValue?: string
    onCustomInputChange?: <K, T>(name: string, value: K, initialValues: T) => void
    allowNoSelectedOption?: boolean
}

const PostScoreArticleSelect: React.FC<Props> = props => {
    const { label, name, defaultValue, onCustomInputChange, allowNoSelectedOption } = props

    const { t } = useTranslation()
    const dispatch = useDispatch()
    const projectId = useSelector(selectCurrentProjectId)

    return (
        <ArticleSelect
            id="formPostScoreArticle"
            label={label}
            name={name}
            defaultValue={defaultValue}
            searchBySymbolCode
            allowSearchWithoutPermissions
            allowNoSelectedOption={allowNoSelectedOption}
            returnOnlyScenarios
            projectId={projectId}
            dispatch={dispatch}
            onCustomInputChange={onCustomInputChange}
            icon={<InfoIcon id="iconPostScoreArticle" content={t(`${tTooltipNamespace}post-score-article`)} />}
        />
    )
}

export default PostScoreArticleSelect
