import React from "react"
import "./OperatorInfo.scss"
import cn from "classnames"
import { ClassProps } from "../../utility/common/props"
import { useTranslation } from "react-i18next"
import { Operator } from "../../models/operator"
import InfoField from "../InfoField/InfoField"
import OperatorCard from "../OperatorCard/OperatorCard"
import { formTranslation } from "../../locales/form"

const tNamespace = "queues:operator."

interface Props extends ClassProps {
    projectId: string
    operator: Operator
}

const OperatorInfo: React.FC<Props> = props => {
    const { operator, className } = props
    const { t } = useTranslation()

    return (
        <div className={cn("operator-info", className)}>
            <div className="operator-info__content">
                <div className="operator-info__section">
                    <InfoField name={t(`${tNamespace}operator`)}>
                        <OperatorCard operator={operator} isDisplayId />
                    </InfoField>
                    <InfoField name={t(formTranslation.maxConcurrentTasks)}>{operator.MaxCapacity}</InfoField>
                </div>
            </div>
        </div>
    )
}

export default OperatorInfo
