import { SendSystemMetricsRequest } from "@aibotdev/ct-analytics"
import { instanceConfig } from "../config"
import { apiInstance } from "../instances/apiInstance"

const metricsController = {
    sendSystemMetrics(request: SendSystemMetricsRequest) {
        const sendMetricsPath = "/system-metrics"
        // TODO add relevant path to send analytics to the server
        console.debug("System metrics report", request)
        //send(sendMetricsPath, request)
        return Promise.resolve()
    }
}

function send(path: string, request: unknown) {
    if (navigator.sendBeacon) {
        const fullPath = instanceConfig.baseURL + path
        const body = JSON.stringify(request)
        navigator.sendBeacon(fullPath, body)
    } else {
        apiInstance.post(path, request)
    }
}

export default metricsController
