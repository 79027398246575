import React from "react"
import styles from "./ArrowToggle.module.scss"
import cn from "classnames"

const OPENED_CLASS_NAME = styles.arrowToggleIcon_opened
const CLOSED_CLASS_NAME = styles.arrowToggleIcon_closed
interface Props {
    opened: boolean
}

const ArrowToggle: React.FC<Props> = props => {
    const { opened } = props
    const handleOpenState = () => (opened ? OPENED_CLASS_NAME : CLOSED_CLASS_NAME)
    return (
        <div className={styles.arrowToggle}>
            <div className={cn(styles.arrowToggle__icon, styles.arrowToggleIcon, handleOpenState())}>
                <div className={styles.arrowToggleIcon__lineLeft} style={{ backgroundColor: "currentColor" }} />
                <div className={styles.arrowToggleIcon__lineRight} style={{ backgroundColor: "currentColor" }} />
            </div>
        </div>
    )
}

export default ArrowToggle
