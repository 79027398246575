import {
    ArticleSubscriptionFormValues,
    UpdateArticleSubscriptionsRequest
} from "../../models/knowledgeBase/subscription"
import { isKnowledgeBaseSubscription } from "./knowledgeBase"
import { UserAccessActions } from "../../models/knowledgeBase/userAccess"

export const formatSubscriptionDiff = (formValues: ArticleSubscriptionFormValues) => {
    const initialValue: UpdateArticleSubscriptionsRequest = {
        AddedSubscriptions: [],
        RemovedSubscriptions: []
    }
    return Object.values(formValues.SubscriptionDiff).reduce<UpdateArticleSubscriptionsRequest>(
        (previousValue, currentValue) =>
            isKnowledgeBaseSubscription(currentValue.value)
                ? currentValue.action === UserAccessActions.Added
                    ? {
                          ...previousValue,
                          AddedSubscriptions: [currentValue.value, ...previousValue.AddedSubscriptions]
                      }
                    : currentValue.action === UserAccessActions.Removed
                    ? {
                          ...previousValue,
                          RemovedSubscriptions: [currentValue.value, ...previousValue.RemovedSubscriptions]
                      }
                    : previousValue
                : previousValue,
        initialValue
    )
}
