import React from "react"
import { KnowledgeBasePermission } from "../../models/knowledgeBase/permission"
import KnowledgeBasePermissionsContext from "./KnowledgeBasePermissionsContext"

export interface KnowledgeBasePermissionsProviderProps {
    permissions: KnowledgeBasePermission[]
    withNested: boolean
}

const KnowledgeBasePermissionsProvider: React.FC<KnowledgeBasePermissionsProviderProps> = props => {
    const { permissions, withNested, children } = props

    return (
        <KnowledgeBasePermissionsContext.Provider value={{ permissions: permissions, withNested: withNested }}>
            {children}
        </KnowledgeBasePermissionsContext.Provider>
    )
}

export default KnowledgeBasePermissionsProvider
