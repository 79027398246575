import { Hub } from "../hub"
import { IHub } from "../interfaces/IHub"
import { Store } from "../../store/store"
import { logHubError } from "../../utility/common/logError"
import { Dispatch } from "../../utility/common/storeHelper"
import { NewslineHubEvents, NewslineHubEventTypes as Events } from "../../models/newsline"
import { setNews } from "../../store/knowledgeBase/slice"
import { NewslineItem } from "../../models/knowledgeBase/newsline"

const HUB_NAME = "NewslineHub"

export const handleEvents = (events: NewslineHubEvents, eventHandler: IHub["registerEvent"]) =>
    Object.entries(events).forEach(([eventName, callback]) => {
        eventHandler(eventName, callback)
    })

export class NewslineHub {
    private _hub: IHub
    private _store: Store
    private _events: NewslineHubEvents

    constructor(store: Store) {
        const reduxState = store.getState()
        const useAllTransportSignalR =
            reduxState.config.config.data?.WebConfig.appSettings.useAllTransportSignalR === true

        this._hub = new Hub(`/news-line-hub`, useAllTransportSignalR)
        this._store = store

        this._events = this.createEvents(this._store, this._store.dispatch)
        this._hub.reconnectedCallback = async () => {
            await this.subscribe()
            this.registerServerEvents()
        }
    }

    async subscribe() {
        await this._hub.subscribe("Subscribe")
    }

    async unsubscribe() {
        await this._hub.unsubscribe("Unsubscribe")
    }

    getInstance(): IHub {
        return this._hub
    }

    private createEvents(_: Store, dispatch: Dispatch) {
        return {
            [Events.UpdateNewsline]: async (data: unknown) => {
                try {
                    if (data) {
                        dispatch(setNews(data as NewslineItem[]))
                    }
                } catch (e) {
                    logHubError(HUB_NAME, Events.UpdateNewsline, e)
                }
            }
        }
    }

    registerServerEvents() {
        handleEvents(this._events, this._hub.registerEvent.bind(this._hub))
    }

    unregisterServerEvents() {
        handleEvents(this._events, this._hub.unregisterEvent.bind(this._hub))
    }
}
