import { KnowledgeBasePermission } from "../../models/knowledgeBase/permission"
import { createContext } from "react"

export interface KnowledgeBasePermissionsContextType {
    permissions: KnowledgeBasePermission[]
    withNested: boolean
}

const KnowledgeBasePermissionsContext = createContext<KnowledgeBasePermissionsContextType>({
    permissions: [],
    withNested: true
})

export default KnowledgeBasePermissionsContext
