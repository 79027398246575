import { DaysHoursMinutesSecondsValues, ExtraSettings, ExtraSettingsValue } from "../../models/queueValues"
import {
    AdditionalResendConfig,
    AllowCatalogs,
    AutoHoldingSettings,
    ClientFormSurvey,
    DialogFinishSettings,
    DialogGroupsSettings,
    DialogTimeoutSettings,
    EmailChannels,
    OperatorAutoGreeting,
    RestrictedQueues
} from "../../models/queue"
import { getCheckedValues } from "../common/getCheckedValues"
import { Time } from "../common/time"
import { prepareDialogGroupsValues } from "../../components/FormikQueueForm/utility/prepareDialogGroupsValues"
import { BandwidthGroup } from "../../models/projectSettings"
import AsyncState from "../../core/asyncState"

const getDialogTimeoutMilliseconds = (value: DaysHoursMinutesSecondsValues): number =>
    Time.daysToMs(value.Days) +
    Time.hoursToMs(value.Hours) +
    Time.minutesToMs(value.Minutes) +
    Time.secondsToMs(value.Seconds)

export const processExtraSettings = (
    values: ExtraSettingsValue[],
    bandwidthGroups: AsyncState<BandwidthGroup[]>,
    allDialogGroupsSetting: string
) => {
    let dialogTimeoutSettings: DialogTimeoutSettings | null = null
    let dialogFinishSettings: DialogFinishSettings | null = null
    let operatorAutoGreeting: OperatorAutoGreeting | null = null
    let clientFormSurvey: ClientFormSurvey | null = null
    let additionalResendConfig: AdditionalResendConfig | null = null
    let emailChannels: EmailChannels | null = null
    let fastReplyCatalogId: string | null = null
    let autoHolding: AutoHoldingSettings | null = null
    let allowCatalogs: AllowCatalogs | null = null
    let groups: DialogGroupsSettings | null = null
    let restrictedQueues: RestrictedQueues | null = null

    values.forEach(value => {
        switch (value.type) {
            case ExtraSettings.DialogTimer:
                dialogTimeoutSettings = {
                    Green: getDialogTimeoutMilliseconds(value.value.greenTimer),
                    Yellow: getDialogTimeoutMilliseconds(value.value.yellowTimer)
                }
                break
            case ExtraSettings.DialogFinish:
                dialogFinishSettings = {
                    FinishDialogArticle: value.value.finishDialogArticle
                }
                break
            case ExtraSettings.AutoGreeting:
                operatorAutoGreeting = {
                    OperatorAfterHoldGreetingEnabled: value.value.operatorAfterHoldGreetingEnabled,
                    OperatorAutoGreetingEnabled: value.value.operatorAutoGreetingEnabled,
                    Template: value.value.template
                }
                break
            case ExtraSettings.ClientSurvey:
                clientFormSurvey = {
                    MainSurveyId: value.value.mainSurveyId,
                    ShortSurveyId: value.value.shortSurveyId
                }
                break
            case ExtraSettings.AdditionalResendConfig:
                additionalResendConfig = {
                    Enable: value.value.enable,
                    Template: value.value.template
                }
                break
            case ExtraSettings.EmailChannels:
                emailChannels = {
                    DefaultChannelId: value.value.defaultChannelId,
                    AdditionalChannelsIds: value.value.additionalChannelsIds
                }
                break
            case ExtraSettings.FastReplyTemplates:
                fastReplyCatalogId = value.value.catalogId
                break
            case ExtraSettings.AutoHolding:
                autoHolding = {
                    Enabled: value.value.enabled,
                    TriggerMessages: value.value.triggerMessages,
                    MessagesToSend: value.value.messagesToSend,
                    Timeout: getDialogTimeoutMilliseconds(value.value.timeout),
                    MultipleSending: value.value.multipleSending
                }
                break
            case ExtraSettings.AllowCatalogs:
                allowCatalogs = {
                    Allowed: getCheckedValues(value.value.Allowed)
                }
                break
            case ExtraSettings.DialogGroups:
                groups = {
                    Groups: prepareDialogGroupsValues(value.value.groups, bandwidthGroups, allDialogGroupsSetting)
                }
                break
            case ExtraSettings.QueueTransferRestriction:
                restrictedQueues = {
                    Queues: value.value.queues
                }
        }
    })

    return {
        dialogTimeoutSettings,
        operatorAutoGreeting,
        clientFormSurvey,
        additionalResendConfig,
        emailChannels,
        fastReplyCatalogId,
        autoHolding,
        allowCatalogs,
        groups,
        restrictedQueues,
        dialogFinishSettings
    }
}
