import React from "react"
import { ClassProps } from "../../utility/common/props"
import { Project } from "../../models/project"
import cn from "classnames"
import styles from "./ProjectCard.module.scss"
import TooltipTrigger from "../TooltipTrigger/TooltipTrigger"
import truncate from "lodash/truncate"

const PROJECT_NAME_MAX_LENGTH = 13

interface Props extends ClassProps {
    project: Project
    onClick?: () => void
    isShort?: boolean
}

const ProjectCard: React.FC<Props> = props => {
    const { project, onClick, className, isShort } = props
    const shortProjectName = truncate(project.name, { length: 13, omission: "..." })
    return (
        <TooltipTrigger
            id={project.id}
            content={project.name}
            placement="right"
            condition={isShort || project.name.length >= PROJECT_NAME_MAX_LENGTH}
        >
            <div
                className={cn(styles.projectCard, className, isShort && styles.projectCard_short)}
                onClick={() => onClick && onClick()}
                data-project-id={project.id}
                style={onClick && { cursor: "pointer" }}
            >
                <div className={styles.projectCard__image}>
                    {project.logo && <img src={project.logo} alt="" className={styles.projectCard__imageItem} />}
                </div>
                <div className={styles.projectCard__name}>
                    <span className={styles.projectCard__nameItem}>{shortProjectName}</span>
                </div>
            </div>
        </TooltipTrigger>
    )
}

export default ProjectCard
