import React, { useCallback, useMemo } from "react"
import { FormikProps } from "formik/dist/types"
import { ArticleFilterFormValues } from "../../../models/articleFilter"
import { WithT } from "i18next"
import { Button, Form } from "react-bootstrap"
import { preventSubmitOnEnter } from "../../../utility/common/preventSubmitOnEnter"
import { nameof } from "../../../utility/common/nameof"
import LoadingButton from "../../LoadingButton/LoadingButton"
import { formTranslation } from "../../../locales/form"
import { ArticleFilterFormProps } from "./ArticleFilterForm"
import { useSelector } from "react-redux"
import { selectArticleTypes } from "../../../store/knowledgeBase/selectors"
import { CreatableSelect, Select } from "../../Select/Select"
import { ValueType } from "react-select"
import { OptionType } from "../../AsyncSearchableInput/AsyncSearchableInput"
import Tag from "../../Tag/Tag"
import DebouncedValidatableInput from "../../ValidatableInput/DebouncedValidatableInput"
import { FieldArray } from "formik"
import ParameterValueControl from "../../ParameterValueControl/ParameterValueControl"
import { DatePickerInput } from "../../DatePickerInput/DatePickerInput"
import styles from "./ArticleFilterForm.module.scss"

const tNamespace = "knowledgeBase:article-filter."

const defaultArticleTypes = { Types: [] }

const FormikArticleFilterForm: React.FC<ArticleFilterFormProps & FormikProps<ArticleFilterFormValues> & WithT> =
    props => {
        const { t, handleSubmit, setFieldValue, values, onCancel, errors } = props

        const filterTypes = [
            { value: "Type", label: t(`${tNamespace}article-type`) },
            { value: "Tags", label: t(`${tNamespace}tags`) },
            {
                value: "ParameterValues",
                label: t(`${tNamespace}article-parameter`)
            },
            { value: "Date", label: t(`${tNamespace}modified-date`) }
        ]

        const articleTypes = useSelector(selectArticleTypes)
        const articleTypeOptions = (articleTypes || defaultArticleTypes).Types.map(Type => ({
            label: Type.Title,
            value: Type.Id
        }))
        const articleTypesMap = useMemo(
            () =>
                (articleTypes || defaultArticleTypes).Types.reduce((obj: { [key: string]: string }, type) => {
                    obj[type.Id] = type.Title
                    return obj
                }, {}),
            [articleTypes]
        )

        const handleSelectFilter = useCallback(
            (option: ValueType<OptionType, false>) => {
                if (option) {
                    switch (option.value) {
                        case "Type":
                            !values[option.value] && setFieldValue(option.value, "", false)
                            break
                        case "Tags":
                            !values[option.value] && setFieldValue(option.value, [], false)
                            break
                        case "ParameterValues":
                            setFieldValue(option.value, [...(values.ParameterValues || []), ""], false)
                            break
                        case "Date":
                            !values.DateFrom && setFieldValue("DateFrom", 0, false)
                            !values.DateTo && setFieldValue("DateTo", 0, false)
                            break
                    }
                }
            },
            [setFieldValue, values]
        )

        const handleDateChange = (dates: Date[]) => {
            setFieldValue(`DateFrom`, dates[0], false)
            setFieldValue(`DateTo`, dates[1], false)
        }

        const handleClearDates = () => {
            setFieldValue(`DateFrom`, 0, false)
            setFieldValue(`DateTo`, 0, false)
        }

        const handleSelectArticleType = useCallback(
            (option: ValueType<OptionType, false>) => {
                setFieldValue(nameof<ArticleFilterFormValues>("Type"), (option && option.value) || "", false)
            },
            [setFieldValue]
        )

        const handleSelectTag = useCallback(
            (option: ValueType<OptionType, false>) => {
                if (option) {
                    setFieldValue(
                        nameof<ArticleFilterFormValues>("Tags"),
                        [...(values.Tags || []), option.value],
                        false
                    )
                }
            },
            [setFieldValue, values.Tags]
        )

        const handleRemoveTag = useCallback(
            (tagIndex: number) => {
                if (values.Tags) {
                    setFieldValue(
                        nameof<ArticleFilterFormValues>("Tags"),
                        [...values.Tags.filter((_, i) => i !== tagIndex)],
                        false
                    )
                }
            },
            [setFieldValue, values.Tags]
        )

        return (
            <Form className={styles.form} onSubmit={handleSubmit} onKeyPress={preventSubmitOnEnter}>
                <div className={styles.form__content}>
                    <Form.Group controlId="filter">
                        <Form.Label>{t(`${tNamespace}add-filter`)}</Form.Label>
                        <Select
                            value={{ label: t(`${tNamespace}select-filter`), value: "" }}
                            isDisabled={!filterTypes.length}
                            options={filterTypes}
                            noOptionsMessage={() => t(formTranslation.noResultsFound)}
                            onChange={handleSelectFilter}
                        />
                    </Form.Group>
                    {values.DateFrom !== undefined && (
                        <Form.Group controlId="filter">
                            <DatePickerInput
                                name="articleFilterDate"
                                label={t(`${tNamespace}modified-date`)}
                                onChange={handleDateChange}
                                onClear={handleClearDates}
                                minDate={new Date(0)}
                                maxDate={new Date()}
                                selectsRange
                                manualEditing
                            />
                        </Form.Group>
                    )}
                    {values.Type !== undefined && (
                        <Form.Group controlId="filterArticleType">
                            <Form.Label>{t(`${tNamespace}article-type`)}</Form.Label>
                            <Select
                                isClearable
                                value={{
                                    label: values.Type
                                        ? articleTypesMap[values.Type] || ""
                                        : t(`${tNamespace}select-article-type`),
                                    value: values.Type
                                }}
                                isDisabled={!articleTypes || !articleTypes.Types.length}
                                options={articleTypeOptions}
                                noOptionsMessage={() => t(formTranslation.noResultsFound)}
                                onChange={handleSelectArticleType}
                            />
                        </Form.Group>
                    )}
                    {values.Tags !== undefined && (
                        <>
                            <Form.Group controlId="filterTags">
                                <Form.Label>{t(`${tNamespace}tags`)}</Form.Label>
                                <CreatableSelect
                                    value={{ label: t(`${tNamespace}add-tag`), value: "" }}
                                    options={[]}
                                    noOptionsMessage={() => null}
                                    onChange={handleSelectTag}
                                    formatCreateLabel={(inputValue: string) =>
                                        `${t(`${tNamespace}add-tag`)}: ${inputValue}`
                                    }
                                    hideDropdownIndicator
                                />
                            </Form.Group>
                            <div className="article-filter-form__tags">
                                {values.Tags.map((tag: string, index: number) => (
                                    <Tag
                                        className={styles.form__tag}
                                        key={`${tag}-${index}`}
                                        index={index}
                                        title={`#${tag}`}
                                        onDelete={handleRemoveTag}
                                    />
                                ))}
                            </div>
                        </>
                    )}
                    {values.ParameterValues && (
                        <FieldArray
                            name={nameof<ArticleFilterFormValues>("ParameterValues")}
                            render={({ name, remove }) =>
                                values.ParameterValues &&
                                values.ParameterValues.map((_, index) => (
                                    <ParameterValueControl
                                        title={t(`${tNamespace}parameter`)}
                                        onDelete={() => remove(index)}
                                        key={index}
                                    >
                                        <DebouncedValidatableInput
                                            id={`formParameterValues${index}`}
                                            type="text"
                                            name={`${name}.${index}`}
                                        />
                                    </ParameterValueControl>
                                ))
                            }
                        />
                    )}
                </div>
                <div className={styles.form__footer}>
                    <Button className={styles.form__cancel} variant="light" onClick={onCancel}>
                        {t(formTranslation.cancel)}
                    </Button>
                    <LoadingButton
                        className={styles.form__submit}
                        disabled={Boolean(Object.keys(errors).length)}
                        variant="primary"
                        type="submit"
                    >
                        {t(formTranslation.apply)}
                    </LoadingButton>
                </div>
            </Form>
        )
    }

export default FormikArticleFilterForm
