import { ChangeEvent, KeyboardEvent, useCallback, useEffect, useState } from "react"
import { ClassProps } from "../../../../utility/common/props"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import {
    selectCurrentArticlesFilter,
    selectKnowledgeBaseWorkMode,
    selectSearchArticlesState
} from "../../../../store/knowledgeBase/selectors"
import { setCurrentArticlesFilter, setKnowledgeBaseWorkMode } from "../../../../store/knowledgeBase/slice"
import { KnowledgeBaseWorkMode } from "../../../../models/knowledgeBase/workMode"
import { SearchArticlesRequest } from "../../../../models/article"
import { SearchArticlesInput } from "../.."
import { ArticleFilterFormValues } from "../../../../models/articleFilter"

interface SearchArticlesInputLogicProps extends ClassProps {
    onSearch: (request: SearchArticlesRequest) => void
}

const ENTER_KEY_CODE = 13

const isFiltersEnabled = (articlesFilter: ArticleFilterFormValues) => !!Object.keys(articlesFilter).length

export const SearchArticlesInputLogic = ({ className, onSearch }: SearchArticlesInputLogicProps) => {
    const dispatch = useDispatch()
    const searchArticlesState = useSelector(selectSearchArticlesState, shallowEqual)
    const workMode = useSelector(selectKnowledgeBaseWorkMode)
    const articlesFilter = useSelector(selectCurrentArticlesFilter)
    const [query, setQuery] = useState<string>("")
    const [enableRemoved, setEnableRemoved] = useState<boolean>(false)

    const handleChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            const query = e.target.value
            setQuery(query)
            if (!query && !isFiltersEnabled(articlesFilter)) {
                dispatch(setKnowledgeBaseWorkMode(KnowledgeBaseWorkMode.CatalogsView))
            }
        },
        [dispatch, articlesFilter]
    )

    const handleClear = useCallback(() => {
        setQuery("")
        dispatch(setKnowledgeBaseWorkMode(KnowledgeBaseWorkMode.CatalogsView))
        dispatch(setCurrentArticlesFilter({}))
    }, [dispatch])

    const handleSearch = useCallback(() => {
        if (!query && !isFiltersEnabled(articlesFilter)) {
            workMode === KnowledgeBaseWorkMode.Search &&
                dispatch(setKnowledgeBaseWorkMode(KnowledgeBaseWorkMode.CatalogsView))
            return
        }
        if (workMode === KnowledgeBaseWorkMode.CatalogsView) {
            dispatch(setKnowledgeBaseWorkMode(KnowledgeBaseWorkMode.Search))
            return
        }
        onSearch(
            query
                ? {
                      ...articlesFilter,
                      Text: query,
                      EnableRemoved: enableRemoved
                  }
                : articlesFilter
        )
    }, [articlesFilter, dispatch, enableRemoved, onSearch, query, workMode])

    const handleKeyDown = useCallback(
        (e: KeyboardEvent<HTMLInputElement>) => {
            switch (e.keyCode) {
                case ENTER_KEY_CODE:
                    handleSearch()
                    break
                default:
                    break
            }
        },
        [handleSearch]
    )

    const handleRemoved = () => {
        setEnableRemoved(currValue => !currValue)
        handleSearch()
    }

    useEffect(() => {
        handleSearch()
    }, [articlesFilter, handleSearch])

    return (
        <SearchArticlesInput
            className={className}
            query={query}
            enableRemoved={enableRemoved}
            onEnableRemoved={handleRemoved}
            loading={searchArticlesState.inProcess}
            onSearch={handleSearch}
            onClear={handleClear}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            isFiltersEnabled={isFiltersEnabled(articlesFilter)}
        />
    )
}
