import React, { MouseEventHandler } from "react"
import styles from "./ScrollButton.module.scss"
import { ClassProps } from "../../utility/common/props"
import cn from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleDown, faAngleUp, IconDefinition } from "@fortawesome/pro-light-svg-icons"
import { testId } from "../../utility/tests/testId"

export enum ScrollButtonDirection {
    up = "Up",
    down = "Down"
}

export interface ScrollButtonProps extends ClassProps {
    direction: ScrollButtonDirection
    hidden?: boolean
    disabled?: boolean
    onClick?: MouseEventHandler<HTMLDivElement>
}

const getDataTestId = (direction: ScrollButtonDirection): string => {
    return `scroll${direction}Button`
}

const getIcon = (direction: ScrollButtonDirection): IconDefinition => {
    switch (direction) {
        case ScrollButtonDirection.up:
            return faAngleUp
        case ScrollButtonDirection.down:
            return faAngleDown
        default:
            return faAngleUp
    }
}

const ScrollButton: React.FC<ScrollButtonProps> = props => {
    const { className, direction, hidden, disabled, onClick } = props

    return (
        <div
            className={cn(
                styles.scrollButton,
                disabled && styles.scrollButton_disabled,
                hidden && styles.scrollButton_hidden,
                className
            )}
            onClick={onClick}
            data-testid={testId[getDataTestId(direction)]}
        >
            <FontAwesomeIcon icon={getIcon(direction)} className={styles.scrollButton__icon} />
        </div>
    )
}

export default ScrollButton
