import React from "react"
import "./TaskCard.scss"
import Skeleton from "../Skeleton/Skeleton"

const TaskCardLoader = () => {
    return (
        <div className="task-card__loader">
            <Skeleton />
        </div>
    )
}

export default TaskCardLoader
