import { ArticleComment, ArticleCommentInput } from "../../models/articleComment"
import { BlockTypeWithConditions, CommandNode, CommandType, Connection, FormSlot } from "../../models/scenario"
import { OpenConditionFormCallback } from "../../components/ScenarioEditor/ScenarioContext"
import { Condition } from "../../models/scenarioCondition"
import { Agent, AgentType } from "../../models/agent"
import React, { useCallback, useEffect, useState } from "react"
import { TFunction } from "i18next"
import { onChooseAnswerFunc } from "../../types/knowledgeBaseFunctions"
import ArticleCommentThreadView from "../../components/ArticleCommentThreadView/ArticleCommentThreadView"
import BlockTypeForm from "../../components/BlockTypeForm/BlockTypeForm"
import ChooseArticleForm from "../../components/ChooseArticleForm/ChooseArticleForm"
import ChooseConditionsForm from "../../components/ChooseConditionsForm/ChooseConditionsForm"
import AddAgentBlockForm from "../../components/AddAgentBlockForm/AddAgentBlockForm"
import AgentTypeForm from "../../components/AgentTypeForm/AgentTypeForm"
import AgentForm from "../../components/AgentForm/AgentForm"
import {
    addAgentBlock,
    addCommandBlock,
    updateAgentBlock,
    updateCommandBlock
} from "../../utility/scenario/scenarioGraph"
import CommandForm from "../../components/CommandForm/CommandForm"
import SetSlotValueForm from "../../components/CommandForm/SetSlotValueForm"
import DeleteSlotValueForm from "../../components/CommandForm/DeleteSlotValueForm"
import ArticleAnswers from "../../components/ArticleAnswers/ArticleAnswers"
import SampleQuestionsContainer from "../../components/SampleQuestions/SampleQuestionsContainer"
import usePermissionsCheck from "../../utility/common/usePermissionsCheck"
import { ModifyAgents } from "../../permissions"
import AsyncState from "../../core/asyncState"
import { CurrentArticleState } from "../../store/knowledgeBase/slice"
import { sendArticleReply } from "../../store/knowledgeBaseComments/thunks"
import { Elements, OnLoadParams } from "react-flow-renderer"
import SlotForm from "../../components/SlotSettings/SlotForm/SlotForm"
import AddArticleSlotForm from "../../components/ChooseSlotForm/AddArticleSlotForm"
import CatalogSharingForm from "../../components/CatalogSharingForm/CatalogSharingForm"
import useSidebar, { SidebarContentBase } from "../../utility/common/useSidebar"
import ChooseSlotForm from "../../components/ChooseSlotForm/ChooseSlotForm"
import { Article } from "../../models/article"
import ArticleInformationForm from "../../components/ArticleInformationForm/ArticleInformationForm"
import ArticleSurveyForm from "../../components/ArticleSurveyForm/ArticleSurveyForm"
import { Dispatch } from "../../utility/common/storeHelper"
import { NewslineForm } from "../../components/NewslineForm/NewslineForm"
import { NewslineSettings } from "../../models/knowledgeBase/newsline"
import ArticleHistoryContainer from "../../components/ArticleHistory/ArticleHistoryContainer"

export enum SidebarContentType {
    articleCommentThread,
    addSlot,
    addSlotInScenario,
    createSlot,
    addExistingSlot,
    chooseBlockTypeForm,
    chooseArticleForm,
    chooseConditionForm,
    addAgentBlockForm,
    chooseAgentTypeForm,
    agentForm,
    commandBlockForm,
    setSlotValueForm,
    deleteSlotValueForm,
    chooseAnswer,
    sampleQuestions,
    shareCatalog,
    articleInformationForm,
    articleSurveyForm,
    newslineForm,
    articleHistoryForm
}

interface ArticleCommentThreadContent extends SidebarContentBase {
    type: SidebarContentType.articleCommentThread
    articleComment: ArticleComment
    articleTitle: string
}

interface ChooseBlockTypeFormContent extends SidebarContentBase {
    type: SidebarContentType.chooseBlockTypeForm
    connection: Connection
}

export type SelectSlotFunction = (slotId: string) => void

interface AddSlotFormContent extends SidebarContentBase {
    type: SidebarContentType.addSlot
    onAddExistedSlot: () => void
    onCreateSlot: () => void
}

interface AddSlotInScenarioFormContent extends SidebarContentBase {
    type: SidebarContentType.addSlotInScenario
    onAddExistedSlot: () => void
    onCreateSlot: () => void
}

interface CreateSlotFormContent extends SidebarContentBase {
    type: SidebarContentType.createSlot
    onCreate: (slotId: string) => void
}

interface AddExistingSlotFormContent extends SidebarContentBase {
    type: SidebarContentType.addExistingSlot
    onSelect: SelectSlotFunction
    selectedSlots?: FormSlot[]
}

interface ChooseArticleFormContent extends SidebarContentBase {
    type: SidebarContentType.chooseArticleForm
    connection: Connection
}

interface ChooseConditionFormContent extends SidebarContentBase {
    type: SidebarContentType.chooseConditionForm
    callback: OpenConditionFormCallback
    blockType: BlockTypeWithConditions
    condition?: Condition
}

interface AddAgentBlockFormContent extends SidebarContentBase {
    type: SidebarContentType.addAgentBlockForm
    connection: Connection
}

interface ChooseAgentTypeFormContent extends SidebarContentBase {
    type: SidebarContentType.chooseAgentTypeForm
    connection: Connection
}

interface AddAgentFormContent extends SidebarContentBase {
    type: SidebarContentType.agentForm
    agentType: AgentType
    agent?: Agent
    ownedByThisScenario?: boolean
    connection?: Connection
}

interface OpenCommandBlockFormContent extends SidebarContentBase {
    type: SidebarContentType.commandBlockForm
    connection?: Connection
    commandNode?: CommandNode
}

interface SetSlotValueFormContent extends SidebarContentBase {
    type: SidebarContentType.setSlotValueForm
    commandNode?: CommandNode
    connection?: Connection
}

interface DeleteSlotFormContent extends SidebarContentBase {
    type: SidebarContentType.deleteSlotValueForm
    commandNode?: CommandNode
    connection?: Connection
}

interface ChooseAnswer extends SidebarContentBase {
    type: SidebarContentType.chooseAnswer
    onSidebarClose: () => void
    isEdit?: boolean
    isOpenAddForm?: boolean
    onSelectAnswer?: (answerId: string) => void
}

interface SampleQuestionsContent extends SidebarContentBase {
    type: SidebarContentType.sampleQuestions
    isEditable: boolean
}

interface ShareCatalogContent extends SidebarContentBase {
    type: SidebarContentType.shareCatalog
    catalogCode: string
}

interface CreateSlotFormContent extends SidebarContentBase {
    type: SidebarContentType.createSlot
    onCreate: (slotId: string) => void
}

interface AddExistingSlotFormContent extends SidebarContentBase {
    type: SidebarContentType.addExistingSlot
    onSelect: SelectSlotFunction
    selectedSlots?: FormSlot[]
}

interface ArticleInformationFormContent extends SidebarContentBase {
    type: SidebarContentType.articleInformationForm
    article: Article
    isEdit?: boolean
}

interface ArticleSurveyFormContent extends SidebarContentBase {
    type: SidebarContentType.articleSurveyForm
    article: Article
    isEdit?: boolean
}

interface NewslineFormContent extends SidebarContentBase {
    type: SidebarContentType.newslineForm
    newsline?: NewslineSettings
}

interface ArticleHistoryFormContent extends SidebarContentBase {
    type: SidebarContentType.articleHistoryForm
    article: Article
}

export type HandleAddSlotFunction = (
    onSelect: SelectSlotFunction,
    onBack?: () => void,
    connection?: Connection,
    stepBack?: boolean
) => void

export type KnowledgeBaseSidebarContent =
    | ArticleCommentThreadContent
    | ChooseBlockTypeFormContent
    | AddSlotFormContent
    | AddSlotInScenarioFormContent
    | CreateSlotFormContent
    | AddExistingSlotFormContent
    | ChooseArticleFormContent
    | ChooseConditionFormContent
    | AddAgentBlockFormContent
    | ChooseAgentTypeFormContent
    | AddAgentFormContent
    | OpenCommandBlockFormContent
    | SetSlotValueFormContent
    | DeleteSlotFormContent
    | ChooseAnswer
    | SampleQuestionsContent
    | ShareCatalogContent
    | ArticleInformationFormContent
    | ArticleSurveyFormContent
    | NewslineFormContent
    | ArticleHistoryFormContent

const tKnowledgeBaseNamespace = "knowledgeBase:"
const tScenarioEditorNamespace = "scenarioEditor:"
const tAgentNamespace = "agent:"
const tNewslineNamespace = "newsline:"

export default function useKnowledgeBaseSidebar(
    dispatch: Dispatch,
    t: TFunction,
    state: AsyncState<CurrentArticleState>,
    projectId?: string
) {
    const modifyAgentsAllowed = usePermissionsCheck([ModifyAgents])

    const { closeSidebar, sidebarContent, handleChangeSidebarContent, sidebarClosed } =
        useSidebar<KnowledgeBaseSidebarContent>()
    const [selectedCondition, setSelectedCondition] = useState<string | null>(null)
    const [selectedNode, setSelectedNode] = useState<string | null>(null)
    //scenario state
    const [elements, setElements] = useState<Elements>([])
    const [instance, setInstance] = useState<OnLoadParams | null>(null)

    useEffect(() => {
        if (state.data?.Article?.SymbolCode) {
            closeSidebar()
        }
    }, [closeSidebar, state.data?.Article?.SymbolCode])

    const handleChooseBlock = useCallback(() => {
        setSelectedCondition(null)
        closeSidebar()
    }, [closeSidebar])
    const onCloseSidebar = useCallback(() => {
        closeSidebar()
        setSelectedNode(null)
        setSelectedCondition(null)
    }, [closeSidebar])

    const handleArticleCommentThreadOpen = useCallback(
        (articleComment: ArticleComment, articleTitle: string) => {
            handleChangeSidebarContent({
                type: SidebarContentType.articleCommentThread,
                title: t(`${tKnowledgeBaseNamespace}comment.replies`),
                articleComment,
                articleTitle
            })
        },
        [handleChangeSidebarContent, t]
    )

    const handleAddBlock = useCallback(
        (source?: string, sourceHandle?: string) => {
            handleChangeSidebarContent({
                type: SidebarContentType.chooseBlockTypeForm,
                title: t(`${tScenarioEditorNamespace}add-block`),
                connection: { source, sourceHandle }
            })
        },
        [handleChangeSidebarContent, t]
    )

    const handleCreateSlot = useCallback(
        (onSelect: SelectSlotFunction, onBack?: () => void) => {
            handleChangeSidebarContent({
                type: SidebarContentType.createSlot,
                title: t(`${tKnowledgeBaseNamespace}slot.add`),
                onCreate: onSelect,
                stepBack: true,
                onBack,
                extended: true
            })
        },
        [handleChangeSidebarContent, t]
    )

    const handleAddExistedSlot = useCallback(
        (onSelect: SelectSlotFunction, onBack?: () => void) => {
            handleChangeSidebarContent({
                type: SidebarContentType.addExistingSlot,
                title: t(`${tKnowledgeBaseNamespace}slot.add`),
                onSelect: id => {
                    onSelect(id)
                },
                stepBack: true,
                onBack
            })
        },
        [handleChangeSidebarContent, t]
    )

    const handleAddSlotInScenario = useCallback(
        (onSelect: SelectSlotFunction, onBack, connection?: Connection, stepBack = false) => {
            const handleBack = () => handleAddSlotInScenario(onSelect, onBack, connection, stepBack)
            handleChangeSidebarContent({
                type: SidebarContentType.addSlot,
                title: t(`${tScenarioEditorNamespace}add-field`),
                onAddExistedSlot: () => handleAddExistedSlot(onSelect, handleBack),
                onCreateSlot: () => handleCreateSlot(onSelect, handleBack),
                ...(stepBack && {
                    stepBack,
                    onBack: () => handleAddBlock(connection?.source, connection?.sourceHandle)
                })
            })
        },
        [handleChangeSidebarContent, t, handleAddExistedSlot, handleCreateSlot, handleAddBlock]
    )

    const handleAddSlot = useCallback(
        (onSelect: SelectSlotFunction, onBack?: () => void) => {
            const handleBack = () => handleAddSlot(onSelect, onBack)
            handleChangeSidebarContent({
                type: SidebarContentType.addSlot,
                title: t(`${tScenarioEditorNamespace}add-field`),
                onAddExistedSlot: () => handleAddExistedSlot(onSelect, handleBack),
                onCreateSlot: () => handleCreateSlot(onSelect, handleBack),
                stepBack: true,
                onBack
            })
        },
        [handleChangeSidebarContent, t, handleAddExistedSlot, handleCreateSlot]
    )

    const handleAddArticle = useCallback(
        (connection: Connection) => {
            handleChangeSidebarContent({
                type: SidebarContentType.chooseArticleForm,
                title: t(`${tScenarioEditorNamespace}go-to-article`),
                connection,
                stepBack: true,
                onBack: () => handleAddBlock(connection.source, connection.sourceHandle)
            })
        },
        [handleChangeSidebarContent, t, handleAddBlock]
    )

    const handleOpenConditionForm = useCallback(
        (
            callback: OpenConditionFormCallback,
            blockType: BlockTypeWithConditions,
            stepBack?: boolean,
            condition?: Condition,
            connection?: Connection
        ) => {
            handleChangeSidebarContent({
                type: SidebarContentType.chooseConditionForm,
                title: condition
                    ? t(`${tScenarioEditorNamespace}edit-condition`)
                    : t(`${tScenarioEditorNamespace}add-condition`),
                callback,
                blockType,
                ...(stepBack && {
                    stepBack,
                    onBack: () => handleAddBlock(connection?.source, connection?.sourceHandle)
                }),
                condition
            })
        },
        [handleChangeSidebarContent, t, handleAddBlock]
    )

    const handleAddAgentBlock = useCallback(
        (connection: Connection) => {
            handleChangeSidebarContent({
                type: SidebarContentType.addAgentBlockForm,
                title: t(`${tScenarioEditorNamespace}new-agent`),
                connection,
                stepBack: true,
                onBack: () => handleAddBlock(connection.source, connection.sourceHandle)
            })
        },
        [handleChangeSidebarContent, t, handleAddBlock]
    )

    const handleCreateAgent = useCallback(
        (connection: Connection) => {
            handleChangeSidebarContent({
                type: SidebarContentType.chooseAgentTypeForm,
                title: t(`${tAgentNamespace}add-agent`),
                connection,
                stepBack: true,
                onBack: () => handleAddAgentBlock(connection)
            })
        },
        [handleChangeSidebarContent, t, handleAddAgentBlock]
    )

    const handleOpenAgentForm = useCallback(
        (
            agentType: AgentType,
            agent?: Agent,
            ownedByThisScenario?: boolean,
            connection?: Connection,
            onCloseSidebar?: () => void
        ) => {
            handleChangeSidebarContent({
                type: SidebarContentType.agentForm,
                title: t(`${tAgentNamespace}agent-settings`),
                connection,
                ownedByThisScenario,
                agent,
                agentType,
                ...(connection && {
                    stepBack: true,
                    onBack: () => handleCreateAgent(connection)
                }),
                onCloseSidebar: onCloseSidebar
            })
        },
        [handleChangeSidebarContent, t, handleCreateAgent]
    )

    const handleOpenCommandForm = useCallback(
        (commandNode?: CommandNode, connection?: Connection, onCloseSidebar?: () => void) => {
            handleChangeSidebarContent({
                type: SidebarContentType.commandBlockForm,
                title: t(`${tScenarioEditorNamespace}add-command`),
                connection,
                commandNode,
                ...(connection && {
                    stepBack: true,
                    onBack: () => handleAddBlock(connection.source, connection.sourceHandle)
                }),
                onCloseSidebar: onCloseSidebar
            })
        },
        [handleChangeSidebarContent, t, handleAddBlock]
    )

    const handleOpenSetSlotValueForm = useCallback(
        (commandNode?: CommandNode, connection?: Connection, onCloseSidebar?: () => void) => {
            handleChangeSidebarContent({
                type: SidebarContentType.setSlotValueForm,
                title: t(`${tScenarioEditorNamespace}set-slot-value`),
                connection,
                commandNode,
                stepBack: true,
                onBack: () => handleOpenCommandForm(commandNode, connection, onCloseSidebar),
                onCloseSidebar: onCloseSidebar
            })
        },
        [handleChangeSidebarContent, t, handleOpenCommandForm]
    )

    const handleOpenDeleteSlotValueForm = useCallback(
        (commandNode?: CommandNode, connection?: Connection, onCloseSidebar?: () => void) => {
            handleChangeSidebarContent({
                type: SidebarContentType.deleteSlotValueForm,
                title: t(`${tScenarioEditorNamespace}delete-slot-value`),
                connection,
                commandNode,
                stepBack: true,
                onBack: () => handleOpenCommandForm(commandNode, connection, onCloseSidebar),
                onCloseSidebar: onCloseSidebar
            })
        },
        [handleChangeSidebarContent, t, handleOpenCommandForm]
    )

    const handleChooseAnswer = useCallback<onChooseAnswerFunc>(
        (isEdit?: boolean, isOpenAddForm?: boolean, onSelectAnswer?: (answerId: string) => void) => {
            handleChangeSidebarContent({
                type: SidebarContentType.chooseAnswer,
                title: t(`${tKnowledgeBaseNamespace}answers-modal.title`),
                onSidebarClose: handleChooseBlock,
                isEdit,
                isOpenAddForm,
                onSelectAnswer
            })
        },
        [handleChangeSidebarContent, handleChooseBlock, t]
    )

    const handleOpenQuestions = useCallback(
        (isEditable: boolean) => {
            handleChangeSidebarContent({
                type: SidebarContentType.sampleQuestions,
                title: t(`${tKnowledgeBaseNamespace}question-samples`),
                extended: true,
                isEditable
            })
        },
        [handleChangeSidebarContent, t]
    )

    const handleOpenCatalogSharing = useCallback(
        (catalogCode: string) => {
            handleChangeSidebarContent({
                type: SidebarContentType.shareCatalog,
                title: t(`${tKnowledgeBaseNamespace}sharing.title`),
                catalogCode
            })
        },
        [handleChangeSidebarContent, t]
    )

    const handleSendReply = useCallback(
        (articleTitle: string, rootArticleComment: ArticleComment, value: ArticleCommentInput) => {
            if (projectId) {
                dispatch(sendArticleReply(projectId, articleTitle, rootArticleComment, value))
            }
        },
        [dispatch, projectId]
    )

    const handleOpenArticleInfoForm = useCallback(
        (article: Article, isEdit = false) => {
            handleChangeSidebarContent({
                type: SidebarContentType.articleInformationForm,
                title: t(`${tKnowledgeBaseNamespace}article-information`),
                article,
                isEdit
            })
        },
        [handleChangeSidebarContent, t]
    )

    const handleOpenArticleHistoryForm = useCallback(
        (article: Article) => {
            handleChangeSidebarContent({
                type: SidebarContentType.articleHistoryForm,
                title: t(`${tKnowledgeBaseNamespace}article-history.title`),
                article
            })
        },
        [handleChangeSidebarContent, t]
    )

    const handleOpenSurveyForm = useCallback(
        (article: Article, isEdit = false) => {
            handleChangeSidebarContent({
                type: SidebarContentType.articleSurveyForm,
                title: t(`${tKnowledgeBaseNamespace}survey`),
                article,
                isEdit
            })
        },
        [handleChangeSidebarContent, t]
    )

    const handleOpenNewslineForm = useCallback(
        (newsline?: NewslineSettings) => {
            handleChangeSidebarContent({
                type: SidebarContentType.newslineForm,
                title: t(`${tNewslineNamespace}newsline`),
                newsline
            })
        },
        [handleChangeSidebarContent, t]
    )

    const setCommandHandler =
        (commandNode?: CommandNode, connection?: Connection) =>
        (commandType: CommandType, slotId: string | null, text: string | null) => {
            if (instance) {
                connection &&
                    addCommandBlock(commandType, slotId, text, setElements, instance, connection, handleAddBlock)
                commandNode && updateCommandBlock(commandType, slotId, text, setElements, commandNode)
            }
        }

    const renderSidebarContent = () => {
        if (!sidebarContent) return null

        switch (sidebarContent.type) {
            case SidebarContentType.articleCommentThread:
                return (
                    <ArticleCommentThreadView
                        articleTitle={sidebarContent.articleTitle}
                        rootComment={sidebarContent.articleComment}
                        onReplySend={handleSendReply}
                    />
                )
            case SidebarContentType.chooseBlockTypeForm:
                return (
                    <BlockTypeForm
                        onSelect={handleChooseBlock}
                        onAddBlock={handleAddBlock}
                        onAddArticle={handleAddArticle}
                        onAddAgent={handleAddAgentBlock}
                        onAddCommand={handleOpenCommandForm}
                        connection={sidebarContent.connection}
                    />
                )
            case SidebarContentType.chooseArticleForm:
                return (
                    <ChooseArticleForm
                        projectId={projectId}
                        onSelect={handleChooseBlock}
                        connection={sidebarContent.connection}
                    />
                )
            case SidebarContentType.addSlot:
            case SidebarContentType.addSlotInScenario:
                return (
                    <AddArticleSlotForm
                        onAddExistedSlot={sidebarContent.onAddExistedSlot}
                        onCreateSlot={sidebarContent.onCreateSlot}
                    />
                )
            case SidebarContentType.createSlot:
                return (
                    <SlotForm
                        onAddSlot={(slotId: string) => {
                            sidebarContent.onCreate(slotId)
                            closeSidebar()
                        }}
                    />
                )
            case SidebarContentType.addExistingSlot:
                return (
                    <ChooseSlotForm
                        onSelect={(slotId: string) => {
                            closeSidebar()
                            sidebarContent.onSelect(slotId)
                        }}
                    />
                )
            case SidebarContentType.chooseConditionForm:
                return (
                    <ChooseConditionsForm
                        onSelect={handleChooseBlock}
                        onSubmit={sidebarContent.callback}
                        blockType={sidebarContent.blockType}
                        condition={sidebarContent.condition}
                    />
                )
            case SidebarContentType.addAgentBlockForm:
                return (
                    <AddAgentBlockForm
                        onCreate={() => handleCreateAgent(sidebarContent.connection)}
                        onSelect={handleChooseBlock}
                        connection={sidebarContent.connection}
                        onAddBlock={handleAddBlock}
                    />
                )
            case SidebarContentType.chooseAgentTypeForm:
                return (
                    <AgentTypeForm
                        onSelect={(type: AgentType) => {
                            handleOpenAgentForm(type, undefined, true, sidebarContent.connection)
                        }}
                    />
                )
            case SidebarContentType.agentForm:
                return (
                    <AgentForm
                        agentType={sidebarContent.agentType}
                        agent={sidebarContent.agent}
                        submitCallback={(agent: Agent) => {
                            handleChooseBlock()
                            if (instance) {
                                sidebarContent.connection
                                    ? addAgentBlock(
                                          agent,
                                          handleAddBlock,
                                          setElements,
                                          instance,
                                          sidebarContent.connection
                                      )
                                    : updateAgentBlock(agent, setElements)
                            }
                        }}
                        ownedByThisScenario={sidebarContent.ownedByThisScenario}
                        disabled={!modifyAgentsAllowed}
                    />
                )
            case SidebarContentType.commandBlockForm:
                return (
                    <CommandForm
                        onSetSlotValue={handleOpenSetSlotValueForm}
                        onDeleteSlot={handleOpenDeleteSlotValueForm}
                        commandNode={sidebarContent.commandNode}
                        connection={sidebarContent.connection}
                    />
                )
            case SidebarContentType.setSlotValueForm:
                return (
                    <SetSlotValueForm
                        onSelect={handleChooseBlock}
                        commandNode={sidebarContent.commandNode}
                        setCommand={setCommandHandler(sidebarContent.commandNode, sidebarContent.connection)}
                    />
                )
            case SidebarContentType.deleteSlotValueForm:
                return (
                    <DeleteSlotValueForm
                        onSelect={handleChooseBlock}
                        commandNode={sidebarContent.commandNode}
                        setCommand={setCommandHandler(sidebarContent.commandNode, sidebarContent.connection)}
                    />
                )
            case SidebarContentType.chooseAnswer:
                return (
                    <ArticleAnswers
                        isEdit={sidebarContent.isEdit}
                        isOpenAddForm={sidebarContent.isOpenAddForm}
                        onSelectAnswer={sidebarContent.onSelectAnswer}
                    />
                )
            case SidebarContentType.sampleQuestions:
                return <SampleQuestionsContainer isEditable={sidebarContent.isEditable} />
            case SidebarContentType.shareCatalog:
                return <CatalogSharingForm catalogCode={sidebarContent.catalogCode} />
            case SidebarContentType.articleInformationForm:
                return (
                    <ArticleInformationForm
                        projectId={projectId}
                        article={sidebarContent.article}
                        onClose={closeSidebar}
                        isEdit={sidebarContent.isEdit}
                    />
                )
            case SidebarContentType.articleSurveyForm:
                const handleReturnToArticleSurvey = () => {
                    handleOpenSurveyForm(sidebarContent.article, sidebarContent.isEdit)
                }
                return (
                    <ArticleSurveyForm
                        projectId={projectId}
                        article={sidebarContent.article}
                        isEdit={sidebarContent.isEdit}
                        onAddSlot={(onSelect: SelectSlotFunction) =>
                            handleAddSlot(id => {
                                onSelect(id)
                                handleReturnToArticleSurvey()
                            }, handleReturnToArticleSurvey)
                        }
                    />
                )
            case SidebarContentType.newslineForm:
                return <NewslineForm newsline={sidebarContent.newsline} close={closeSidebar} />
            case SidebarContentType.articleHistoryForm:
                return <ArticleHistoryContainer articleCode={sidebarContent.article.SymbolCode} />
            default:
                return null
        }
    }

    return {
        sidebarClosed,
        closeSidebar,
        onCloseSidebar,
        sidebarContent,
        selectedCondition,
        setSelectedCondition,
        selectedNode,
        setSelectedNode,
        elements,
        setElements,
        instance,
        setInstance,
        renderSidebarContent,
        handleArticleCommentThreadOpen,
        handleAddBlock,
        handleAddSlot,
        handleAddSlotInScenario,
        handleAddExistedSlot,
        handleCreateSlot,
        handleAddArticle,
        handleOpenConditionForm,
        handleAddAgentBlock,
        handleCreateAgent,
        handleOpenAgentForm,
        handleOpenCommandForm,
        handleOpenSetSlotValueForm,
        handleOpenDeleteSlotValueForm,
        handleChooseAnswer,
        handleOpenQuestions,
        handleOpenCatalogSharing,
        handleOpenArticleInfoForm,
        handleOpenSurveyForm,
        handleOpenNewslineForm,
        handleOpenArticleHistoryForm
    }
}
