import { IHub } from "../interfaces/IHub"
import { Store } from "../../store/store"
import { Hub } from "../hub"
import { logError, logHubError } from "../../utility/common/logError"
import { Dispatch } from "../../utility/common/storeHelper"
import { RootState } from "../../store/rootReducer"
import {
    TaskAssignedMsg,
    TaskAssignedMsgDto,
    TaskCompletedMsg,
    TaskDetachedMsg,
    TaskFromQueueDeletedMsg,
    TaskPreviewUpdatedMsg,
    TaskQueuedMsgDto,
    TaskRoutedMsg,
    TaskRoutedMsgDto,
    TasksMovedMsg
} from "../../models/task"
import { taskConverter } from "../../utility/common/taskConverter"
import { actions as queuesActions, getMainQueuePart } from "../../store/queues/slice"
import { actions as operatorsActions } from "../../store/operators/slice"
import {
    OperatorBecameActiveMsg,
    OperatorBecameActiveMsgDto,
    OperatorBecameInactiveMsg,
    OperatorQueuesUpdatedMsg,
    OperatorStatusUpdatedMsg,
    OperatorStatusUpdatedMsgDto
} from "../../models/operator"
import { toSelectedOperatorStatusModel } from "../../utility/operatorStatus/convert"
import { OperatorDtoConverter } from "../../utility/operators/convert"
import { updateOperatorsQueues } from "../../utility/operators/updateOperatorsQueues"
import {
    QueueAddedMsg,
    QueueAwtUpdatedMsg,
    QueueExtendSettingsUpdatedMsg,
    QueueFinishedDialogsDailyUpdatedMsg,
    QueueFinishedDialogsUpdatedMsg,
    QueueOperatorTasksCountUpdatedMsg,
    QueueSlUpdatedMsg,
    QueueUpdatedMsg
} from "../../models/queue"
import { QueueCategory, QueueCategoryRemoveResponse } from "../../models/queueCategory"
import { QueuesHubEvents } from "../utility/queues"
import { selectCurrentProjectId } from "../../store/projects/selectors"
import {
    parseQueueIdFromPath,
    selectCurrentQueue,
    updateCurrentQueue,
    updateOperatorTasksCount
} from "../utility/queuesV2.helpers"

const HUB_NAME = "QueuesHubV2"

export class QueuesHubV2 {
    private _hub: IHub
    private _projectId: string
    private _queueId: string
    private _store: Store
    private _detachedTasks: string[]

    constructor(store: Store) {
        const reduxState = store.getState()
        let useAllTransportSignalR = false

        if (reduxState.config.config.data?.WebConfig.appSettings.useAllTransportSignalR) {
            useAllTransportSignalR = true
        }

        this._hub = new Hub(`/queues-hub-v2`, useAllTransportSignalR)
        this._store = store
        this._projectId = selectCurrentProjectId(reduxState) ?? ""
        this._queueId = parseQueueIdFromPath()
        this.registerServerEvents(store.dispatch, store.getState)

        this._detachedTasks = []

        this._hub.reconnectedCallback = async () => {
            await this.connect()
            this.registerServerEvents(store.dispatch, store.getState)
        }
    }

    private registerHubEvent(event: string, handler: (data: unknown) => void) {
        this._hub.registerEvent(event, data => {
            try {
                handler(data)
            } catch (e) {
                logHubError(HUB_NAME, event, e)
            }
        })
    }

    async connect() {
        await this._hub.connect()
    }

    async disconnect() {
        await this._hub.disconnect()
    }

    async subscribeToHub(projectId: string) {
        if (!this._hub.isConnected) {
            return Promise.resolve()
        }

        this._projectId = selectCurrentProjectId(this._store.getState()) ?? ""
        await this._hub.invoke("SubscribeTenant", projectId).catch(e => logError(e))
    }

    async unsubscribeFromHub(projectId: string) {
        if (!this._hub.isConnected) {
            return Promise.resolve()
        }

        await this._hub.invoke("UnsubscribeTenant", projectId).catch(e => logError(e))
    }

    async subscribeToQueue(projectId: string, queueId: string) {
        if (!this._hub.isConnected) {
            return Promise.resolve()
        }

        await this._hub.invoke("SubscribeQueue", projectId, queueId).catch(e => logError(e))
        this._queueId = parseQueueIdFromPath()
    }

    async unsubscribeFromQueue(projectId: string, queueId: string) {
        if (!this._hub.isConnected) {
            return Promise.resolve()
        }

        await this._hub.invoke("UnsubscribeQueue", projectId, queueId).catch(e => logError(e))
        this._queueId = ""
        this._detachedTasks = []
    }

    private registerServerEvents(dispatch: Dispatch, getState: () => RootState) {
        this.registerHubEvent(QueuesHubEvents.TASK_QUEUED, data => {
            const taskQueuedMsgDto = data as TaskQueuedMsgDto
            const currentQueue = selectCurrentQueue(
                this._projectId,
                taskQueuedMsgDto.ToIndividual ? getMainQueuePart(taskQueuedMsgDto.QueueId) : taskQueuedMsgDto.QueueId,
                this._store.getState()
            )

            if (currentQueue) {
                const updatedQueue = {
                    ...currentQueue,
                    AllTasksCount:
                        currentQueue.AllTasksCount !== undefined && !taskQueuedMsgDto.ToIndividual
                            ? currentQueue.AllTasksCount + 1
                            : currentQueue.AllTasksCount,
                    PendingIndividualTasksCount:
                        (currentQueue.PendingIndividualTasksCount ?? 0) !== undefined && taskQueuedMsgDto.ToIndividual
                            ? (currentQueue.PendingIndividualTasksCount ?? 0) + 1
                            : currentQueue.PendingIndividualTasksCount ?? 0
                }

                dispatch(updateCurrentQueue(this._projectId, getMainQueuePart(taskQueuedMsgDto.QueueId), updatedQueue))
            }
        })
        this.registerHubEvent(QueuesHubEvents.TASKS_MOVED, data => {
            const message = data as TasksMovedMsg
            const currentQueue = selectCurrentQueue(this._projectId, this._queueId, this._store.getState())

            if (currentQueue) {
                const updatedQueue = {
                    ...currentQueue,
                    AllTasksCount: (() => {
                        let count = currentQueue.AllTasksCount !== undefined ? currentQueue.AllTasksCount : 0
                        if (!message.FromIndividual) {
                            count -= message.TasksIds.length
                        }
                        if (!message.ToIndividual) {
                            count += message.TasksIds.length
                        }
                        return count
                    })(),
                    PendingIndividualTasksCount: (() => {
                        let count =
                            currentQueue.PendingIndividualTasksCount !== undefined
                                ? currentQueue.PendingIndividualTasksCount
                                : 0
                        if (message.FromIndividual) {
                            count -= message.TasksIds.length
                        }
                        if (message.ToIndividual) {
                            count += message.TasksIds.length
                        }
                        return count
                    })()
                }

                dispatch(updateCurrentQueue(this._projectId, this._queueId, updatedQueue))
            }
        })
        this.registerHubEvent(QueuesHubEvents.TASK_DETACHED, data => {
            const message = data as TaskDetachedMsg

            dispatch(operatorsActions.detachTaskV2(message))

            this._detachedTasks.push(message.TaskId)

            const currentQueue = selectCurrentQueue(this._projectId, this._queueId, this._store.getState())
            const operatorsTasksCount = { ...currentQueue?.OperatorsTasksCount }
            const operatorTaskCount = operatorsTasksCount[message.OperatorId]

            operatorsTasksCount[message.OperatorId] = operatorTaskCount - 1

            if (currentQueue) {
                const updatedQueue = {
                    ...currentQueue,
                    ActiveTasksCount:
                        currentQueue.ActiveTasksCount !== undefined && currentQueue.ActiveTasksCount > 0
                            ? currentQueue.ActiveTasksCount - 1
                            : currentQueue.ActiveTasksCount,
                    AllTasksCount:
                        currentQueue.AllTasksCount !== undefined && currentQueue.AllTasksCount > 0
                            ? currentQueue.AllTasksCount - 1
                            : currentQueue.AllTasksCount,
                    OperatorsTasksCount: operatorsTasksCount
                }

                dispatch(updateCurrentQueue(this._projectId, this._queueId, updatedQueue))
            }
        })
        this.registerHubEvent(QueuesHubEvents.TASK_ASSIGNED, data => {
            const taskAssignedMsgDto = data as TaskAssignedMsgDto
            const payload: TaskAssignedMsg = {
                ...taskAssignedMsgDto,
                Task: taskConverter.toTaskModel(taskAssignedMsgDto.Task)
            }
            dispatch(
                operatorsActions.addTaskV2({
                    Task: payload.Task,
                    OperatorId: payload.OperatorId
                })
            )
        })
        this.registerHubEvent(QueuesHubEvents.TASK_ROUTED, data => {
            const taskRoutedMsgDto = data as TaskRoutedMsgDto
            const payload: TaskRoutedMsg = {
                ...taskRoutedMsgDto,
                Task: taskConverter.toTaskModel(taskRoutedMsgDto.Task)
            }

            const currentQueue = selectCurrentQueue(this._projectId, this._queueId, this._store.getState())

            if (currentQueue) {
                const updatedQueue = {
                    ...currentQueue,
                    ActiveTasksCount:
                        currentQueue.ActiveTasksCount !== undefined
                            ? currentQueue.ActiveTasksCount + 1
                            : currentQueue.ActiveTasksCount,
                    PendingIndividualTasksCount:
                        currentQueue.PendingIndividualTasksCount !== undefined && taskRoutedMsgDto.FromIndividual
                            ? currentQueue.PendingIndividualTasksCount - 1
                            : currentQueue.PendingIndividualTasksCount
                }

                dispatch(updateCurrentQueue(this._projectId, this._queueId, updatedQueue))
            }

            dispatch(
                operatorsActions.addTaskV2({
                    Task: payload.Task,
                    OperatorId: payload.OperatorId
                })
            )
        })
        this.registerHubEvent(QueuesHubEvents.TASK_COMPLETED, data => {
            const msg = data as TaskCompletedMsg
            if (!msg.QueueId) {
                return
            }

            if (this._detachedTasks.includes(msg.TaskId)) {
                this._detachedTasks = this._detachedTasks.filter(taskId => taskId !== msg.TaskId)

                return
            }

            const currentQueue = selectCurrentQueue(this._projectId, msg.QueueId, this._store.getState())

            if (currentQueue) {
                const updatedQueue = {
                    ...currentQueue,
                    AllTasksCount:
                        currentQueue.AllTasksCount !== undefined
                            ? currentQueue.AllTasksCount - 1
                            : currentQueue.AllTasksCount,
                    PendingIndividualTasksCount:
                        currentQueue.PendingIndividualTasksCount !== undefined &&
                        currentQueue.PendingIndividualTasksCount > 0
                            ? currentQueue.PendingIndividualTasksCount - 1
                            : currentQueue.PendingIndividualTasksCount
                }

                dispatch(updateCurrentQueue(this._projectId, msg.QueueId, updatedQueue))
            }
        })
        this.registerHubEvent(QueuesHubEvents.TASK_FROM_QUEUE_DELETED, data => {
            const msg = data as TaskFromQueueDeletedMsg
            const currentQueue = selectCurrentQueue(this._projectId, this._queueId, this._store.getState())

            if (currentQueue) {
                const updatedQueue = {
                    ...currentQueue,
                    ActiveTasksCount:
                        currentQueue.ActiveTasksCount !== undefined && currentQueue.ActiveTasksCount > 0
                            ? currentQueue.ActiveTasksCount - 1
                            : 0,
                    AllTasksCount:
                        currentQueue.AllTasksCount !== undefined && currentQueue.AllTasksCount > 0
                            ? currentQueue.AllTasksCount - 1
                            : 0
                }

                dispatch(updateCurrentQueue(this._projectId, this._queueId, updatedQueue))
            }
        })
        this.registerHubEvent(QueuesHubEvents.TASK_PREVIEW_UPDATED, data => {
            const previewMessage = data as TaskPreviewUpdatedMsg
            dispatch(operatorsActions.updateOperatorsTaskPreviewV2(previewMessage))
        })
        this.registerHubEvent(QueuesHubEvents.OPERATOR_STATUS_UPDATED, data => {
            const dto = data as OperatorStatusUpdatedMsgDto
            const state = getState()
            const payload: OperatorStatusUpdatedMsg = {
                ...dto,
                Status: toSelectedOperatorStatusModel(dto.Status, state.userOperator.statuses)
            }

            dispatch(operatorsActions.updateOperatorStatusV2(payload))
        })
        this.registerHubEvent(QueuesHubEvents.OPERATOR_BECAME_ACTIVE, data => {
            const dto = data as OperatorBecameActiveMsgDto
            const state = getState()
            const payload: OperatorBecameActiveMsg = {
                Operator: OperatorDtoConverter.toOperator(dto.Operator, state.userOperator.statuses)
            }

            dispatch(operatorsActions.addActiveOperatorV2(payload))
        })
        this.registerHubEvent(QueuesHubEvents.OPERATOR_BECAME_INACTIVE, data => {
            dispatch(operatorsActions.removeInactiveOperatorV2(data as OperatorBecameInactiveMsg))
        })
        this.registerHubEvent(QueuesHubEvents.OPERATOR_QUEUES_UPDATED, data => {
            const updatedData = data as OperatorQueuesUpdatedMsg

            dispatch(queuesActions.updateQueueOperators(updatedData))
            updateOperatorsQueues(this._projectId, updatedData, dispatch)
        })
        this.registerHubEvent(QueuesHubEvents.QUEUE_ADDED, data => {
            dispatch(queuesActions.addQueue(data as QueueAddedMsg))
        })
        this.registerHubEvent(QueuesHubEvents.QUEUE_UPDATED, data => {
            dispatch(queuesActions.updateQueue(data as QueueUpdatedMsg))
        })
        this.registerHubEvent(QueuesHubEvents.QUEUE_SL_UPDATED, data => {
            dispatch(queuesActions.updateQueueSl(data as QueueSlUpdatedMsg))
        })
        this.registerHubEvent(QueuesHubEvents.QUEUE_AWT_UPDATED, data => {
            dispatch(queuesActions.updateQueueAwt(data as QueueAwtUpdatedMsg))
        })
        this.registerHubEvent(QueuesHubEvents.QUEUE_OPERATOR_TASKS_COUNT_UPDATED, data => {
            const message = data as QueueOperatorTasksCountUpdatedMsg
            const currentQueue = selectCurrentQueue(this._projectId, this._queueId, this._store.getState())
            const hasQueueOperator = currentQueue?.OperatorsIds.find(operator => operator === message.OperatorId)

            if (currentQueue && hasQueueOperator) {
                const updatedQueue = updateOperatorTasksCount(currentQueue, message)

                dispatch(updateCurrentQueue(this._projectId, this._queueId, updatedQueue))
            }
        })
        this.registerHubEvent(QueuesHubEvents.QUEUE_FINISHED_DIALOGS_UPDATED, data => {
            dispatch(queuesActions.updateQueueFinishedDialogs(data as QueueFinishedDialogsUpdatedMsg))
        })
        this.registerHubEvent(QueuesHubEvents.QUEUE_FINISHED_DIALOGS_DAILY_UPDATED, data => {
            dispatch(queuesActions.updateQueueFinishedDialogsDaily(data as QueueFinishedDialogsDailyUpdatedMsg))
        })
        this.registerHubEvent(QueuesHubEvents.QUEUE_EXTEND_SETTINGS_UPDATED, data => {
            dispatch(queuesActions.updateQueueExtendedSettings((data as QueueExtendSettingsUpdatedMsg).Settings))
        })
        this.registerHubEvent(QueuesHubEvents.QUEUE_CATEGORY_ADDED, data => {
            dispatch(queuesActions.createQueueCategorySuccess(data as QueueCategory))
        })
        this.registerHubEvent(QueuesHubEvents.QUEUE_CATEGORY_UPDATED, data => {
            dispatch(queuesActions.updateQueueCategorySuccess(data as QueueCategory))
        })
        this.registerHubEvent(QueuesHubEvents.QUEUE_CATEGORY_REMOVED, data => {
            dispatch(queuesActions.deleteQueueCategorySuccess((data as QueueCategoryRemoveResponse).CategoryId))
        })
    }
}
