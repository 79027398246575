import React from "react"
import { Formik } from "formik"
import UserAccessForm from "../UserAccessForm/UserAccessForm"
import { useTranslation } from "react-i18next"
import { nameof } from "../../utility/common/nameof"
import { Button, Form } from "react-bootstrap"
import styles from "./LinkClientToOperatorForm.module.scss"
import { formTranslation } from "../../locales/form"
import LoadingButton from "../LoadingButton/LoadingButton"
import { preventSubmitOnEnter } from "../../utility/common/preventSubmitOnEnter"
import { ClientSubscriptionResponse } from "../../models/Dialogs/linksToOperator"
import { Project } from "../../models/project"
import { ArticleSubscriptionFormValues } from "../../models/knowledgeBase/subscription"
import { getInitialValues } from "./helpers/getInitialValues"

export interface LinkClientToOperatorFormProps {
    title: string
    loading: boolean
    onSubmit: (values: ArticleSubscriptionFormValues, hidePopover: () => void) => void
    onCancel: () => void
    clientSubscriptions: ClientSubscriptionResponse[]
    clientId: string
    currentProject?: Project
}

const tNamespace = "dialogs:client-survey."

export const LinkClientToOperatorForm: React.FC<LinkClientToOperatorFormProps> = props => {
    const { clientSubscriptions, onSubmit, onCancel, title, loading, clientId, currentProject } = props

    const { t } = useTranslation()
    return (
        <Formik<ArticleSubscriptionFormValues>
            initialValues={getInitialValues(clientSubscriptions, currentProject?.id ?? "")}
            onSubmit={values => {
                onSubmit(values, onCancel)
            }}
        >
            {formikProps => {
                const isSubscriptionDiffs = Object.keys(formikProps.values.SubscriptionDiff).length === 0

                return (
                    <Form
                        className={styles.articleSubscriptionForm}
                        onSubmit={formikProps.handleSubmit}
                        onKeyPress={preventSubmitOnEnter}
                    >
                        <UserAccessForm
                            type="subscription"
                            title={title}
                            name={nameof<ArticleSubscriptionFormValues>("Subscriptions")}
                            diffFieldName={nameof<ArticleSubscriptionFormValues>("SubscriptionDiff")}
                            disableProjectsSelector
                            clientLinks
                            clientId={clientId}
                            currentProject={currentProject}
                            rolesPlaceholder={t(`${tNamespace}subscriptions-placeholder`)}
                            {...formikProps}
                        />
                        <div className={styles.articleSubscriptionForm__footer}>
                            <Button
                                className={styles.articleSubscriptionForm__cancel}
                                variant="light"
                                onClick={onCancel}
                            >
                                {t(formTranslation.cancel)}
                            </Button>
                            <LoadingButton
                                className={styles.articleSubscriptionForm__submit}
                                variant="primary"
                                loading={loading}
                                type="submit"
                                disabled={isSubscriptionDiffs}
                            >
                                {t(formTranslation.share)}
                            </LoadingButton>
                        </div>
                    </Form>
                )
            }}
        </Formik>
    )
}

export default LinkClientToOperatorForm
