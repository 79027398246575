import { ClientSubscriptionResponse, LinkCase } from "../../../models/Dialogs/linksToOperator"
import {
    KnowledgeBaseSubscriptionType,
    KnowledgeBaseSubscriptionValue
} from "../../../models/knowledgeBase/subscription"
import { KnowledgeBaseAccessType } from "../../../models/knowledgeBase/permission"

export const toArticleSubscriptions = (
    subscriptions: ClientSubscriptionResponse[],
    projectId: string
): KnowledgeBaseSubscriptionValue[] =>
    subscriptions.map((sub: ClientSubscriptionResponse) => ({
        Type: sub.Case === LinkCase.ROLE ? KnowledgeBaseAccessType.Role : KnowledgeBaseAccessType.Login,
        SubscriptionType: KnowledgeBaseSubscriptionType.Notify,
        Value: sub.Case === LinkCase.ROLE ? sub.Fields.RoleId : sub.Fields.OperatorId,
        ProjectId: projectId
    }))
