import React from "react"
import { Col, Form } from "react-bootstrap"
import { useField } from "formik"
import { WithT } from "i18next"
import { HoursMinutesSettingsValues, MetricToAdd } from "../../../models/queueValues"

const tCommonNamespace = "common:time."
const tNamespace = "queues:settings."

interface Props extends WithT {
    id: string
    name: string
    metricType?: MetricToAdd
}

const HoursMinutesMetricsSettingsInput: React.FC<Props> = props => {
    const { t, id, name, metricType } = props

    const getFullName = (fieldName: keyof HoursMinutesSettingsValues) => `${name}.${fieldName}`

    const [hrField, hrMeta] = useField(getFullName("hr"))
    const [minField, minMeta] = useField(getFullName("min"))

    return (
        <>
            {metricType === MetricToAdd.FinishedDialogs && (
                <Form.Label>{t(`${tNamespace}calculation-interval`)}</Form.Label>
            )}
            {metricType === MetricToAdd.Awt && <Form.Label>{t(`${tNamespace}awt-period`)}</Form.Label>}
            <Form.Row>
                {metricType === MetricToAdd.FinishedDialogsDaily && (
                    <Form.Label column sm={3}>
                        {t(`${tNamespace}reset-in`)}
                    </Form.Label>
                )}
                <Col md={4}>
                    <Form.Row>
                        <Form.Group as={Col} sm={5} controlId={`${id}hr`}>
                            <Form.Control
                                type="number"
                                name={hrField.name}
                                onChange={hrField.onChange}
                                onBlur={hrField.onBlur}
                                value={hrField.value}
                                isInvalid={hrMeta.touched && !!hrMeta.error}
                            />
                        </Form.Group>
                        <Form.Label column sm={7}>
                            {t(`${tCommonNamespace}hours`)}
                        </Form.Label>
                    </Form.Row>
                </Col>
                <Col md={4}>
                    <Form.Row>
                        <Form.Group as={Col} sm={5} controlId={`${id}min`}>
                            <Form.Control
                                type="number"
                                name={minField.name}
                                onChange={minField.onChange}
                                onBlur={minField.onBlur}
                                value={minField.value}
                                isInvalid={minMeta.touched && !!minMeta.error}
                            />
                        </Form.Group>
                        <Form.Label column sm={7}>
                            {t(`${tCommonNamespace}minutes`)}
                        </Form.Label>
                    </Form.Row>
                </Col>
            </Form.Row>
        </>
    )
}

export default HoursMinutesMetricsSettingsInput
