import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faClock } from "@fortawesome/pro-light-svg-icons/faClock"
import styles from "./ArticlePublishInfo.module.scss"
import { Time } from "../../utility/common/time"
import { testId } from "../../utility/tests/testId"

export type ArticlePublishInfoProps = {
    title: string
    timestamp: number
}

const ArticlePublishInfo = (props: ArticlePublishInfoProps) => {
    const { title, timestamp } = props
    const formattedTimestamp = Time.stampToLocalDate(timestamp).split(" ").reverse().join(" ")

    if (!timestamp) {
        return null
    }

    return (
        <div className={styles.articlePublishInfo} data-testid={testId.articlePublishInfo}>
            <span className={styles.articlePublishInfo__label}>{title}</span>
            <div className={styles.articlePublishInfo__date}>
                <FontAwesomeIcon icon={faClock} />
                <span>{formattedTimestamp}</span>
            </div>
        </div>
    )
}

export default ArticlePublishInfo
