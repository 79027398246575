import cn from "classnames"
import { useTranslation } from "react-i18next"
import { ReadyReport, ReadyReportStatus, ReportFormat } from "../../../../models/reports"
import { Time } from "../../../../utility/common/time"
import { testId } from "../../../../utility/tests/testId"
import styles from "./Report.module.scss"

const tNamespace = "reports:"
const tStatusNamespace = `${tNamespace}status.`
const REPORT_USER_STATUS = Object.values(ReadyReportStatus)
const REPORT_USER_FORMAT = Object.values(ReportFormat)

export interface ReportProps {
    report: ReadyReport
    index: number
}

export const Report = ({ report, index }: ReportProps) => {
    const { t } = useTranslation()
    const fileName = `${t(`${tNamespace}one`)} ${index}`
    const status = REPORT_USER_STATUS[report.ReadyReportStatus]
    const format = REPORT_USER_FORMAT[report.ReportFormat - 1]

    return (
        <div className={styles.report}>
            <div className={styles.report__line}>
                <div>{fileName}</div>
                <div className={styles.report__status}>
                    <div className={cn(styles.report__indicator, styles[`report__indicator_${status}`])} />
                    {t(`${tStatusNamespace}${status}`)}
                </div>
            </div>
            <div className={styles.report__line}>
                <div>
                    {report.ExternalUrl && (
                        <a
                            href={`${report.ExternalUrl}?file_name=${fileName}.${format}`}
                            target="_blank"
                            rel="noreferrer"
                            data-testid={`${testId.downloadReport}-${index}`}
                        >
                            {t(`${tNamespace}download`)}
                        </a>
                    )}
                </div>
                <div className={styles.reportsItem__date}>
                    {Time.stampToLocalDate(new Date(report.CreatedAt).getTime())}
                </div>
            </div>
        </div>
    )
}
