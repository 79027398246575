import React from "react"
import { getBeautifiedFileSize, getFileInfo, selectFileIcon } from "../../utility/common/files"
import styles from "./ArticleImportFile.module.scss"
import { CSS_VAR_COLORS } from "../../helpers/color"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/pro-light-svg-icons"
import { testId } from "../../utility/tests/testId"

export type ArticleImportFileProps = {
    file: File
    onDelete: (file: File) => void
}

const ArticleImportFile = (props: ArticleImportFileProps) => {
    const { file, onDelete } = props
    const { itemType, fileExtension } = getFileInfo(file.type)
    const beautifiedFileSize = getBeautifiedFileSize(file.size)

    return (
        <div data-testid={testId.articleImportFile} className={styles.articleImportFile}>
            <div>
                <FontAwesomeIcon
                    color={CSS_VAR_COLORS.PRIMARY}
                    icon={selectFileIcon(itemType, fileExtension)}
                    size="2x"
                />
            </div>
            <div className={styles.articleImportFile__content}>
                <span className={styles.articleImportFile__content__name}>{file.name}</span>
                <span className={styles.articleImportFile__content__size}>{beautifiedFileSize}</span>
            </div>
            <div className={styles.articleImportFile__delete} onClick={() => onDelete(file)}>
                <FontAwesomeIcon icon={faTimes} size="lg" />
            </div>
        </div>
    )
}

export default ArticleImportFile
