import { queryApi } from "../api"
import { Queue, TaskPaginationRequest, TaskPaginationResponse } from "../../models/queue"
import { Task } from "../../models/task"
import { Message } from "../../models/Dialogs/message"

export const queuesV2ApiController = queryApi.injectEndpoints({
    endpoints: build => {
        const createQueueTaskQuery = (queueSegment: string) =>
            build.query<TaskPaginationResponse<Task>, TaskPaginationRequest>({
                query: ({ projectId, queueId, paginationParams }) => ({
                    url: `${projectId}/queues/${queueId}/${queueSegment}`,
                    method: "POST",
                    body: paginationParams
                })
            })

        return {
            getQueue: build.query<Queue, { projectId: string; queueId: string }>({
                query: ({ projectId, queueId }) => ({
                    url: `${projectId}/queues/${queueId}`,
                    method: "GET"
                }),
                transformResponse: async (response: Queue): Promise<Queue> => {
                    return {
                        ...response,
                        //TODO: С переездом на новые очереди окончательно привести поле PendingIndividualTasks к number
                        PendingIndividualTasksCount: (response.PendingIndividualTasks as unknown as number) ?? 0
                    }
                }
            }),
            getQueueTasks: createQueueTaskQuery("tasks"),
            getQueuePendingTasks: createQueueTaskQuery("pending-tasks"),
            getTasksMessages: build.query<Message[], string>({
                query: taskId => ({
                    url: `dialogs/${taskId}/messages`,
                    method: "GET"
                })
            })
        }
    }
})

export const {
    useGetQueueQuery,
    useLazyGetQueueTasksQuery,
    useLazyGetQueuePendingTasksQuery,
    useLazyGetTasksMessagesQuery
} = queuesV2ApiController
