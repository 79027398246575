export const enum QueuesHubEvents {
    TASK_QUEUED = "TaskQueued",
    TASKS_MOVED = "TasksMoved",
    TASK_DETACHED = "TaskDetached",
    TASK_ASSIGNED = "TaskAssigned",
    TASK_ROUTED = "TaskRouted",
    TASK_COMPLETED = "TaskCompleted",
    TASK_FROM_QUEUE_DELETED = "TaskFromQueueDeleted",
    TASK_PREVIEW_UPDATED = "TaskPreviewUpdated",
    OPERATOR_STATUS_UPDATED = "OperatorStatusUpdated",
    OPERATOR_BECAME_ACTIVE = "OperatorBecameActive",
    OPERATOR_BECAME_INACTIVE = "OperatorBecameInactive",
    OPERATOR_QUEUES_UPDATED = "OperatorQueuesUpdated",
    QUEUE_ADDED = "QueueAdded",
    QUEUE_UPDATED = "QueueUpdated",
    QUEUE_SL_UPDATED = "QueueSlUpdated",
    QUEUE_AWT_UPDATED = "QueueAwtUpdated",
    QUEUE_OPERATOR_TASKS_COUNT_UPDATED = "OperatorTasksCountUpdated",
    QUEUE_FINISHED_DIALOGS_UPDATED = "QueueFinishedDialogsUpdated",
    QUEUE_FINISHED_DIALOGS_DAILY_UPDATED = "QueueFinishedDialogsDailyUpdated",
    QUEUE_EXTEND_SETTINGS_UPDATED = "QueueExtendSettingsUpdated",
    QUEUE_CATEGORY_ADDED = "QueueCategoryAdded",
    QUEUE_CATEGORY_UPDATED = "QueueCategoryUpdated",
    QUEUE_CATEGORY_REMOVED = "QueueCategoryRemoved"
}
