export const colors = ["#1C8E6A", "#A3CB38", "#008DB7", "#E9967A", "#F79F1F", "#65B6CE"]

export const colorByName = (name: string) => {
    let hash = 5381
    for (let i = 0; i < name.length; i++) hash = (hash * 33) ^ name.charCodeAt(i)
    const exactHash = hash >>> 0

    return colors[exactHash % colors.length]
}

export enum CSS_VAR_COLORS {
    PRIMARY = "var(--primary)",
    LINK = "var(--link)",
    EDITOR = "var(--editor)"
}

export const operatorStatusColors: Record<string, string> = {
    online: "#219653",
    offline: "#E34646",
    lunch: "#EB8E39",
    training: "#0EABDC",
    invisible: "#A3A3A3"
}
