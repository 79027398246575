import { Project } from "../../models/project"
import { OptionsType } from "react-select/src/types"
import { OptionType } from "../../components/AsyncSearchableInput/AsyncSearchableInput"
import knowledgeBasePermissionsController from "../../api/controllers/knowledgeBase/permissions"
import { KnowledgeBaseAccessType } from "../../models/knowledgeBase/permission"
import { ValueType } from "react-select"
import { logError } from "../common/logError"
import i18next from "i18next"
import { formTranslation } from "../../locales/form"

export enum OptionMeta {
    User = "user",
    Role = "role",
    All = "all"
}

export const projectsToOptions = (projects: Project[]): OptionsType<OptionType> =>
    projects.map(project => ({
        label: `${project.name} (${project.id})`,
        value: project.id
    }))

export const searchUsersAndRolesByProject =
    () =>
    async (text: string, projectId?: string): Promise<OptionsType<OptionType>> => {
        const allOption = {
            label: i18next.t(formTranslation.all),
            value: OptionMeta.All,
            meta: OptionMeta.All
        }
        try {
            if (!projectId) return []
            const foundedData = await knowledgeBasePermissionsController.searchUsersAndRolesByProject(projectId, {
                Text: text
            })
            const { Users, Roles } = foundedData
            const userOptions = Users.map(user => ({
                label: `${user.FirstName} ${user.LastName}`,
                value: user.Login,
                meta: OptionMeta.User
            }))
            const roleOptions = Roles.map(role => ({
                label: role.Title,
                value: role.Id,
                meta: OptionMeta.Role
            }))
            return [allOption, ...roleOptions, ...userOptions]
        } catch (e) {
            logError(e)
            return []
        }
    }

export const optionToKnowledgeBaseAccessType = (
    option: ValueType<OptionType, false>
): KnowledgeBaseAccessType | undefined => {
    if (!option) return undefined
    switch (option.meta) {
        case OptionMeta.User:
            return KnowledgeBaseAccessType.Login
        case OptionMeta.Role:
            return KnowledgeBaseAccessType.Role
        case OptionMeta.All:
            return KnowledgeBaseAccessType.All
        default:
            return undefined
    }
}
