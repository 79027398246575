import { useCallback } from "react"
import AsyncSearchableInput, { AsyncSearchableInputProps } from "../AsyncSearchableInput/AsyncSearchableInput"
import { useAppDispatch } from "../../store/hooks"
import { searchChannel } from "./helpers"
import { testId } from "../../utility/tests/testId"

export interface ChannelSelectProps extends Omit<AsyncSearchableInputProps, "getOptions"> {
    projectId: string | undefined
}

export const ChannelSelect = (props: ChannelSelectProps) => {
    const { projectId } = props
    const dispatch = useAppDispatch()
    const getOptions: AsyncSearchableInputProps["getOptions"] = useCallback(
        async (input: string) => {
            return projectId ? searchChannel(dispatch, projectId, input) : []
        },
        [dispatch, projectId]
    )

    return (
        <div data-testid={testId.channelSelectInput}>
            <AsyncSearchableInput {...props} getOptions={getOptions} />
        </div>
    )
}
