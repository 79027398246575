import React, { useEffect, useState } from "react"
import KnowledgeBaseUserAccessItem from "../KnowledgeBaseUserAccessItem/KnowledgeBaseUserAccessItem"
import type { SimpleUser } from "../../models/user"
import type { SimpleRole } from "../../models/role"
import {
    getUsersAndRolesByKnowledgeBasePermissions,
    rolesToMap,
    usersToMap
} from "../../utility/knowledgeBase/permissionsUsersAndRoles"
import "./KnowledgeBaseUserAccessItems.scss"
import type { KnowledgeBaseSubscriptionSelectItem } from "../../models/knowledgeBase/subscription"
import type { OnChangeAccessItemFunction, UserAccessPrimitive } from "../../models/knowledgeBase/userAccess"
import { KnowledgeBasePermittedActionSelectItem } from "../../models/knowledgeBase/permission"

interface Props {
    data: UserAccessPrimitive[]
    subscriptionOptions?: KnowledgeBaseSubscriptionSelectItem[]
    permittedActionOptions?: KnowledgeBasePermittedActionSelectItem[]
    projectId?: string
    onChange: OnChangeAccessItemFunction
    onRemove: OnChangeAccessItemFunction
    hideProject?: boolean
}

const KnowledgeBaseUserAccessItems: React.FC<Props> = props => {
    const { data, subscriptionOptions, permittedActionOptions, projectId, onChange, onRemove, hideProject } = props

    const [userMap, setUsersMap] = useState<{ [login: string]: SimpleUser }>({})
    const [roleMap, setRolesMap] = useState<{ [id: string]: SimpleRole }>({})

    useEffect(() => {
        const loadData = async () => {
            if (projectId && data?.length > 0) {
                const { Users, Roles } = await getUsersAndRolesByKnowledgeBasePermissions(data, projectId)
                setUsersMap(usersToMap(Users))
                setRolesMap(rolesToMap(Roles))
            }
        }
        loadData()
    }, [data, projectId])

    return (
        <div className="knowledge-base-permissions">
            {data.map((item, index) => (
                <KnowledgeBaseUserAccessItem
                    key={index}
                    className="knowledge-base-permissions__item"
                    data={item}
                    subscriptionOptions={subscriptionOptions}
                    permittedActionOptions={permittedActionOptions}
                    role={roleMap[item.Value]}
                    user={userMap[item.Value]}
                    onChange={onChange}
                    onRemove={onRemove}
                    hideProject={hideProject}
                />
            ))}
        </div>
    )
}

export default KnowledgeBaseUserAccessItems
