import { BandwidthGroup, BandwidthGroupsKeys } from "../../../models/projectSettings"
import AsyncState from "../../../core/asyncState"

export const ALL_DIALOG_GROUPS = "All"

export const getGroupsByKey = (groups: string[], bandwidthGroups: BandwidthGroup[], key: BandwidthGroupsKeys) => {
    return bandwidthGroups.reduce((acc: string[], bandwidthGroup: BandwidthGroup) => {
        if (key === BandwidthGroupsKeys.Id && groups.includes(bandwidthGroup[BandwidthGroupsKeys.Id])) {
            acc.push(bandwidthGroup.Title)
        }

        if (key === BandwidthGroupsKeys.Title && groups.includes(bandwidthGroup[BandwidthGroupsKeys.Title])) {
            acc.push(bandwidthGroup.Id)
        }
        return acc
    }, [])
}

export const prepareDialogGroupsValues = (
    values: string[],
    bandwidthGroups: AsyncState<BandwidthGroup[]>,
    allGroupsSetting: string
) => {
    if (values.includes(allGroupsSetting) || values.length === 0) {
        return [ALL_DIALOG_GROUPS]
    }

    if (bandwidthGroups.data) {
        const allGroupsIncluded = bandwidthGroups.data.every(group => values.includes(group.Title))
        const groupsIds = getGroupsByKey(values, bandwidthGroups.data, BandwidthGroupsKeys.Title)

        return allGroupsIncluded ? [ALL_DIALOG_GROUPS] : groupsIds
    }
    return [ALL_DIALOG_GROUPS]
}
