import { Form, FormikProps } from "formik"
import React from "react"
import { preventSubmitOnEnter } from "../../../utility/common/preventSubmitOnEnter"
import ArticleSelect from "../../ArticleSelect/ArticleSelect"
import { useDispatch } from "react-redux"
import { nameof } from "../../../utility/common/nameof"
export type ArticleSearchModalFormType = {
    Id?: string
}

export interface ArticleSearchModalFormProps extends FormikProps<ArticleSearchModalFormType> {
    projectId: string
    filter?: string[]
}

const ArticleSearchModalForm: React.FC<ArticleSearchModalFormProps> = props => {
    const { handleSubmit, projectId, filter } = props
    const dispatch = useDispatch()

    return (
        <Form role="form" onSubmit={handleSubmit} onKeyPress={preventSubmitOnEnter}>
            <ArticleSelect
                id={"modal-article-select"}
                name={nameof<ArticleSearchModalFormType>("Id")}
                dispatch={dispatch}
                projectId={projectId}
                searchBySymbolCode
                allowSearchWithoutPermissions
                returnIdValue
                returnOnlyAnswers
                filter={filter}
            />
        </Form>
    )
}

export default ArticleSearchModalForm
